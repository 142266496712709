import { FC } from "react";
import { PageHeader } from "../../../common/PageHeader";
import { useAppSelector } from "../../../../../store/hooks";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";

export const CommitmentsRecommendationsHeader: FC = () => {
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);

  return <PageHeader loading={loading} title="Recommendations" />;
};
