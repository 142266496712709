import { FC } from "react";
import { CardHeader, IconButton, Stack } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ResourceCardHeaderTitle } from "./components/ResourceCardHeaderTitle";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceTypeDataEmsSelector } from "../../../../../../store/account/selectors/resource-type-data/resourceTypeDataEmsSelector";
import { resourceTypeDataSpendingSelector } from "../../../../../../store/account/selectors/resource-type-data/resourceTypeDataSpendingSelector";
import { ResourceType } from "../../../../../../services/cloudchipr.api";
import { ResourceStatistic } from "../../common/ResourceStatistic";
import { money } from "../../../../../../utils/numeral/money";

interface ResourceCardHeaderProps {
  resourceType: ResourceType;
  collapsed: boolean;
  onCollapse(action: any): any;
  onFullScreen(action: any): any;
}

export const ResourceCardHeader: FC<ResourceCardHeaderProps> = ({
  resourceType,
  onCollapse,
  onFullScreen,
  collapsed,
}) => {
  const ems = useAppSelector((state) =>
    resourceTypeDataEmsSelector(state, resourceType),
  );

  const spending = useAppSelector((state) =>
    resourceTypeDataSpendingSelector(state, resourceType),
  );

  return (
    <CardHeader
      title={<ResourceCardHeaderTitle resourceType={resourceType} />}
      sx={{
        py: 1,
        pr: 2,
        pl: 1,
        bgcolor: "grey.800",
        ".MuiCardHeader-action": {
          minWidth: 40,
          alignSelf: "center",
        },
      }}
      action={
        collapsed ? (
          <Stack direction="row" spacing={1}>
            <ResourceStatistic color="red" value={money(ems)} />
            <ResourceStatistic color="blue" value={money(spending)} />
          </Stack>
        ) : (
          <IconButton
            onClick={onFullScreen}
            size="small"
            disableRipple
            sx={{ color: "grey.50", bgcolor: "#303030" }}
          >
            <FullscreenIcon color="inherit" />
          </IconButton>
        )
      }
      avatar={
        <IconButton
          onClick={onCollapse}
          sx={{
            color: "grey.50",
            transform: collapsed ? "rotate(0deg)" : "rotate(180deg)",
            transition: "transform 0.3s",
          }}
        >
          <ExpandMoreIcon />
        </IconButton>
      }
    />
  );
};
