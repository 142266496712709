import { FC } from "react";
import { Stack } from "@mui/material";
import { TagFilterItems } from "./filter-items/tag/TagFilterItems";
import { AccountFilterItems } from "./filter-items/account/AccountFilterItems";
import { GroupItemsCombinationOperator } from "../options/GroupItemsCombinationOperator";
import { isResourceExplorerTag } from "../../utils/helpers/typeCheckers";
import {
  ProviderType,
  ResourceExplorerAccounts,
  ResourceExplorerTags,
} from "../../../../../../../../../../../services/cloudchipr.api";

interface CategoryFiltersItemsProps {
  filter: ResourceExplorerAccounts | ResourceExplorerTags;
  categoryId: string;
  provider: ProviderType;
}

export const CategoryFiltersItems: FC<CategoryFiltersItemsProps> = ({
  filter,
  categoryId,
  provider,
}) => {
  return (
    <Stack spacing={2}>
      {filter.items.map((item, index) => {
        return (
          <Stack direction="row" spacing={1} key={index}>
            <GroupItemsCombinationOperator
              first={index === 0}
              type={isResourceExplorerTag(item) ? "tag" : "account"}
              provider={provider}
              categoryId={categoryId}
              operator={filter.combination_operator}
            />

            {isResourceExplorerTag(item) ? (
              <TagFilterItems
                categoryId={categoryId}
                provider={provider}
                filterItem={item}
                index={index}
              />
            ) : (
              <AccountFilterItems
                categoryId={categoryId}
                provider={provider}
                filterItem={item}
                index={index}
              />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
