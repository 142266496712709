import { FC } from "react";
import { CSVLink } from "react-csv";
import { IconButton, Tooltip } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import moment from "moment";
import { useAppSelector } from "../../../../../../../store/hooks";
import { commitmentsRecommendationsCSVDataSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsCSVDataSelector";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../../store/commitments/selectors/commitmentsCurrentOrgIdSelector";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";

export const CsvDataDownloader: FC = () => {
  const csvData = useAppSelector(commitmentsRecommendationsCSVDataSelector);
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);

  const fileName = `${formatDate(moment(), {
    type: "date",
  })}-${orgId}-Org_commitments_recommendations`;

  if (!csvData) {
    return null;
  }

  return (
    <Tooltip arrow title="Export CSV" placement="top">
      <span>
        <IconButton
          disabled={!csvData?.length}
          size="small"
          sx={{ "& a": { textDecoration: "none", color: "inherit" }, pb: 0 }}
        >
          <CSVLink
            filename={`${fileName}.csv`}
            data={csvData}
            enclosingCharacter=""
            separator=","
          >
            <DownloadOutlinedIcon fontSize="small" />
          </CSVLink>
        </IconButton>
      </span>
    </Tooltip>
  );
};
