import {
  ResourceExplorerAccounts,
  ResourceExplorerTags,
} from "../../../../services/cloudchipr.api";

export const emptyAccountsFilter: {
  combination_operator: string;
  accounts: ResourceExplorerAccounts;
} = {
  combination_operator: "and",
  accounts: {
    items: [{ value: "", operator: "contains" }],
    combination_operator: "and",
  },
};

export const emptyTagsFilter: {
  combination_operator: string;
  tags: ResourceExplorerTags;
} = {
  combination_operator: "and",
  tags: {
    items: [
      {
        tag_key: "",
        tag_values: "",
        operator: "contains",
      },
    ],
    combination_operator: "and",
  },
};
