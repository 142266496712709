import { FC, useCallback } from "react";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ResourceExplorerV2ViewCreateFolderOrViewTypes } from "../../../utils/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { createResourceExplorerLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerLoadingSelector";
import { createResourceExplorerFolderLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/createResourceExplorerFolderLoadingSelector";
import { createResourceExplorerHierarchyFolderOrViewThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/createResourceExplorerHierarchyFolderOrViewThunk";

interface ResourceExplorerV2ViewCreateFolderOrViewDialogActionsProps {
  onCloseDialog(): void;
  name: string;
  type: ResourceExplorerV2ViewCreateFolderOrViewTypes;
  folderId?: string;
}

export const ResourceExplorerV2ViewCreateFolderOrViewDialogActions: FC<
  ResourceExplorerV2ViewCreateFolderOrViewDialogActionsProps
> = ({ onCloseDialog, name, type, folderId }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const createViewLoading = useAppSelector(
    createResourceExplorerLoadingSelector,
  );

  const createFolderLoading = useAppSelector(
    createResourceExplorerFolderLoadingSelector,
  );

  const createViewCallbackHandler = useCallback(
    (id: string) => {
      navigate({ pathname: `/resource-explorer/${id}` });
    },
    [navigate],
  );

  const submitFormHandler = useCallback(async () => {
    const response = await dispatch(
      createResourceExplorerHierarchyFolderOrViewThunk({
        type,
        folderId,
        name,
      }),
    ).unwrap();

    if (response) {
      createViewCallbackHandler(response?.id);
    }
    onCloseDialog();
  }, [
    dispatch,
    name,
    type,
    onCloseDialog,
    createViewCallbackHandler,
    folderId,
  ]);

  return (
    <DialogActions>
      <Button onClick={onCloseDialog} color="tertiary">
        Cancel
      </Button>
      <LoadingButton
        variant="contained"
        loading={createViewLoading || createFolderLoading}
        onClick={submitFormHandler}
        disabled={!name?.length}
      >
        create
      </LoadingButton>
    </DialogActions>
  );
};
