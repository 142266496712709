import moment from "moment";
import { preDefinedLabelsByDateLabel } from "./preDefinedLabelsByDateLabel";
import { PreDefinedRange, PreDefinedRanges } from "../constants/types";
import { MomentDateTimeUnitsType } from "../../../../../utils/helpers/date-time/datetime-format";
import { DateLabel } from "../../../../../services/cloudchipr.api";

export const preDefinedThisMonth: PreDefinedRange = {
  dateLabel: "month_to_date",
  dateTo: moment().toISOString(),
  dateFrom: moment().startOf("month").toISOString(),
  shortLabel: preDefinedLabelsByDateLabel.month_to_date.short,
  label: preDefinedLabelsByDateLabel.month_to_date.long,
};

export const preDefinedThisYear: PreDefinedRange = {
  dateLabel: "year_to_date",
  dateTo: moment().toISOString(),
  dateFrom: moment().startOf("year").toISOString(),
  label: preDefinedLabelsByDateLabel.year_to_date.long,
  shortLabel: preDefinedLabelsByDateLabel.year_to_date.short,
};

const getRange = (
  amount: number,
  unit: MomentDateTimeUnitsType,
  dateLabel: DateLabel,
): PreDefinedRange => {
  let from = moment().subtract(amount, unit);
  let to = moment();

  if (unit === "month") {
    to = moment().subtract(1, "month").endOf("month");
    from = from.startOf("month");
  }

  return {
    dateLabel,
    dateTo: to.toISOString(),
    dateFrom: from.toISOString(),
    label: preDefinedLabelsByDateLabel[dateLabel].long,
    shortLabel: preDefinedLabelsByDateLabel[dateLabel].short,
  };
};

export const preDefinedRanges: PreDefinedRanges = {
  // daily
  past_seven_days: getRange(7, "day", "past_seven_days"),
  past_fourteen_days: getRange(14, "day", "past_fourteen_days"),
  past_thirty_days: getRange(30, "day", "past_thirty_days"),
  past_forty_five_days: getRange(45, "day", "past_forty_five_days"),
  past_ninety_days: getRange(90, "day", "past_ninety_days"),
  past_one_hundred_eighty_days: getRange(
    180,
    "day",
    "past_one_hundred_eighty_days",
  ),
  month_to_date: preDefinedThisMonth,

  // monthly
  last_one_month: getRange(1, "month", "last_one_month"),
  last_three_months: getRange(3, "month", "last_three_months"),
  last_six_months: getRange(6, "month", "last_six_months"),
  last_twelve_months: getRange(12, "month", "last_twelve_months"),

  // yearly
  year_to_date: preDefinedThisYear,
};
