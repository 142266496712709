import { FC, Fragment, useCallback, useState } from "react";
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  PopoverOrigin,
} from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { ResourceExplorerV2ViewCreateFolderOrViewDialog } from "./create-folder-or-view-dialog/ResourceExplorerV2ViewCreateFolderOrViewDialog";
import { ResourceExplorerV2ViewCreateFolderOrViewTypes } from "../utils/types";

interface ResourceExplorerV2NavigationAddActionMenuProps {
  open: boolean;
  onCloseMenu(): void;
  anchorEl: MenuProps["anchorEl"];
}
export const ResourceExplorerV2NavigationAddActionMenu: FC<
  ResourceExplorerV2NavigationAddActionMenuProps
> = ({ open, onCloseMenu, anchorEl }) => {
  const [dialogType, setDialogType] = useState<
    ResourceExplorerV2ViewCreateFolderOrViewTypes | undefined
  >();

  const viewClickHandler = useCallback(() => {
    setDialogType("view");
  }, []);

  const folderClickHandler = useCallback(() => {
    setDialogType("folder");
  }, []);

  const closeDialog = useCallback(() => {
    setDialogType(undefined);
    onCloseMenu();
  }, [onCloseMenu]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={onCloseMenu}
        anchorEl={anchorEl}
        disablePortal={false}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <MenuItem onClick={viewClickHandler}>
          <ListItemIcon>
            <InsertChartOutlinedOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>View</ListItemText>
        </MenuItem>

        <MenuItem onClick={folderClickHandler}>
          <ListItemIcon>
            <FolderOpenIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Folder</ListItemText>
        </MenuItem>
      </Menu>

      <ResourceExplorerV2ViewCreateFolderOrViewDialog
        onClose={closeDialog}
        type={dialogType}
      />
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
