import { FC, Fragment, ReactNode, useCallback } from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  FormControlLabel,
  Radio,
  Tooltip,
  Typography,
} from "@mui/material";

interface ToggleBoxItemProps {
  value: string;
  tooltip?: string;
  label: ReactNode;
  checked: boolean;
  disabled: boolean;
  description?: ReactNode;
  onChange?(value: string): void;
}

export const ToggleBoxItem: FC<ToggleBoxItemProps> = ({
  value,
  label,
  checked,
  description,
  onChange,
  disabled,
  tooltip,
}) => {
  const handleClick = useCallback(() => {
    if (!disabled && onChange) {
      onChange(value);
    }
  }, [onChange, value, disabled]);

  return (
    <Card
      variant="outlined"
      elevation={0}
      onClick={handleClick}
      sx={
        checked
          ? {
              borderColor: "primary.main",
              bgcolor: "primary.light",
            }
          : undefined
      }
    >
      <Tooltip arrow title={tooltip ?? ""}>
        <CardActionArea>
          <CardContent>
            <FormControlLabel
              disabled={disabled}
              control={<Radio />}
              checked={checked}
              label={
                <Fragment>
                  <Typography variant="subtitle2" gutterBottom={false}>
                    {label}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {description}
                  </Typography>
                </Fragment>
              }
            />
          </CardContent>
        </CardActionArea>
      </Tooltip>
    </Card>
  );
};
