import { FC } from "react";
import { Stack } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { DataPointSelector } from "./components/data-point-selector/DataPointSelector";
import { ResourceExplorerDateRangeSelector } from "./components/date-range-selector/ResourceExplorerDateRangeSelector";
import { GroupByV2Selector } from "./components/group-by-selector/GroupByV2Selector";
import { ResourceExplorerDateRange } from "./components/ResourceExplorerDateRange";
import { ResourceExplorerChartTypeSelect } from "./components/ResourceExplorerChartTypeSelect";
import { useAppSelector } from "../../../../../../../store/hooks";
import { isResourceExplorerAvailableSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isResourceExplorerAvailableSelector";

interface ResourceExplorerToolbarProps {
  viewId: string;
}

export const ResourceExplorerToolbar: FC<ResourceExplorerToolbarProps> = ({
  viewId,
}) => {
  const enableNewDateRangeSelector = useFlag("EnableNewDateRangeSelector");
  const isResourceExplorerAvailable = useAppSelector(
    isResourceExplorerAvailableSelector,
  );

  if (!isResourceExplorerAvailable) {
    return null;
  }

  return (
    <Stack
      px={2}
      pb={1}
      direction="row"
      borderBottom={1}
      borderColor="grey.300"
      justifyContent="space-between"
    >
      <Stack spacing={1.5} direction="row" flexWrap="wrap">
        <GroupByV2Selector />

        <Stack direction="row" justifySelf="end" spacing={1}>
          {enableNewDateRangeSelector ? (
            <ResourceExplorerDateRange />
          ) : (
            <ResourceExplorerDateRangeSelector />
          )}

          <DataPointSelector />
        </Stack>
      </Stack>

      <ResourceExplorerChartTypeSelect viewId={viewId} />
    </Stack>
  );
};
