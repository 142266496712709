import { FC, useCallback, useEffect } from "react";
import { TooltipProps } from "recharts";
import { CostByCategoryChartTooltipContent } from "./CostByCategoryChartTooltipContent";
import { CostByCategoryChartLegendContent } from "./CostByCategoryChartLegendContent";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { moneyFormatter } from "../../../../../../../../utils/numeral/moneyFormatter";
import { useMultiTypeChartContext } from "../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { MultiTypeChart } from "../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import { ChartType } from "../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { costByCategoryWidgetLoadingSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-by-category/metadata/costByCategoryWidgetLoadingSelector";
import {
  formatDate,
  formatDateByFrequencyType,
} from "../../../../../../../../utils/helpers/date-time/datetime-format";
import {
  DateDataPoint,
  DateLabelNullable,
  NullableDate,
} from "../../../../../../../../services/cloudchipr.api";
import { PieLegendContentProps } from "../../../../../../../../storybook/charts/multi-type-chart/components/chart-by-type/pie-chart/PieLegentContent";

export interface CostByCategoryWidgetChartProps {
  widgetId: string;
  chartType: ChartType;
  displayFilteredResources: boolean;
  frequency?: DateDataPoint;
  date: {
    label: DateLabelNullable;
    from: NullableDate;
    to: NullableDate;
  };
}

export const CostByCategoryWidgetChart: FC<CostByCategoryWidgetChartProps> = ({
  widgetId,
  chartType,
  frequency,
  date,
  displayFilteredResources,
}) => {
  const {
    chartType: { type: chartTypeFromProvider, onChange: onChartTypeChange },
  } = useMultiTypeChartContext();

  const chartDataLoading = useAppSelector((state) =>
    costByCategoryWidgetLoadingSelector(state, widgetId),
  );

  const xAxisLabelFormatter = useCallback(
    (value: string) => {
      return frequency ? formatDateByFrequencyType(frequency, value) : value;
    },
    [frequency],
  );

  const yAxisFormatter = useCallback((value: number | string) => {
    return moneyFormatter(+value);
  }, []);

  const TooltipContent = useCallback(
    (props: TooltipProps<any, any>) => {
      return (
        <CostByCategoryChartTooltipContent
          {...props}
          date={date}
          widgetId={widgetId}
          displayFilteredResources={displayFilteredResources}
        />
      );
    },
    [date, widgetId, displayFilteredResources],
  );

  const LegendContent = useCallback(
    (props: PieLegendContentProps) => {
      return (
        <CostByCategoryChartLegendContent
          {...props}
          widgetId={widgetId}
          displayFilteredResources={displayFilteredResources}
        />
      );
    },
    [widgetId, displayFilteredResources],
  );

  useEffect(() => {
    if (chartTypeFromProvider === chartType) {
      return;
    }

    onChartTypeChange(chartType);
  }, [chartTypeFromProvider, chartType, onChartTypeChange]);

  return (
    <MultiTypeChart
      zoom
      selectable
      highlight
      showLoader={false}
      loading={chartDataLoading}
      yAxisFormatter={yAxisFormatter}
      TooltipContent={TooltipContent}
      tooltipProps={{
        tooltipLabelFormatter: (label: string) =>
          formatDate(label, {
            type: frequency === "daily" ? "weekDayDate" : "monthNameYear",
          }),
      }}
      LegendContent={displayFilteredResources ? LegendContent : undefined}
      xAxisTickProps={{ labelFormatter: xAxisLabelFormatter }}
    />
  );
};
