import { FC } from "react";
import { Box, Collapse } from "@mui/material";
import { useToggle } from "rooks";
import { useFlag } from "@unleash/proxy-client-react";

import { IntegrationCustomMessageHeader } from "./IntegrationCustomMessageHeader";
import {
  IntegrationCustomMessageContent,
  IntegrationCustomMessageContentProps,
} from "./IntegrationCustomMessageContent";
import { IntegrationCustomMessageDialog } from "./dialog/IntegrationCustomMessageDialog";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";

type IntegrationCustomMessageContentExtendedProps = Pick<
  IntegrationCustomMessageContentProps,
  "actionFrom" | "message" | "type"
>;

export interface IntegrationCustomMessageProps
  extends IntegrationCustomMessageContentExtendedProps {
  onChange(message: string): void;
}

export const IntegrationCustomMessage: FC<IntegrationCustomMessageProps> = ({
  type,
  onChange,
  message,
  actionFrom,
}) => {
  const [collapsed, toggleCollapsed] = useToggle();
  const { open, openDialog, closeDialog } = useDialog();

  const enableWorkflowCustomMessage = useFlag("EnableWorkflowCustomMessage");

  if (!enableWorkflowCustomMessage || type === "webhook") {
    return null;
  }

  return (
    <Box bgcolor="grey.100" p={1} borderRadius={1}>
      <IntegrationCustomMessageHeader
        collapsed={collapsed}
        toggleCollapsed={toggleCollapsed}
        openDialog={openDialog}
      />
      <Collapse in={collapsed}>
        <IntegrationCustomMessageContent
          type={type}
          actionFrom={actionFrom}
          message={message}
        />
      </Collapse>
      <IntegrationCustomMessageDialog
        open={open}
        onClose={closeDialog}
        message={message}
        type={type}
        onChange={onChange}
        actionFrom={actionFrom}
      />
    </Box>
  );
};
