import { FC, useCallback, useMemo } from "react";
import { FilterValueAutocompleteRenderTagsComponent } from "../common/FilterValueAutocompleteRenderTagsComponent";
import { AutocompleteSelect } from "../../../../../../../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { TypographyWithTooltip } from "../../../../../../../../../../../../common/TypographyWithTooltip";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { billingAccountsSelector } from "../../../../../../../../../../../../../store/common/selectors/billing-accounts/billingAccountsSelector";
import { ProviderType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { categoryAccountsFilterFn } from "../../../../../../../../../../utils/helpers/categoryAccountsFilterFn";
import { RenderTagsComponentProps } from "../../../../../../../../../../../../common/select/autocomplete-select/components/AutocompleteRenderTags";

interface AccountFilterValueAutocompleteProps {
  value: string[];
  provider: ProviderType;
  onChange(value: string[]): void;
}

export const AccountFilterValueAutocomplete: FC<
  AccountFilterValueAutocompleteProps
> = ({ value, onChange, provider }) => {
  const accounts = useAppSelector(billingAccountsSelector);

  const renderOptions = useMemo(() => {
    return accounts
      ?.filter((account) => account.provider === provider)
      ?.map((account) => ({
        rawValue: account,
        value: account.id,
        label: (
          <TypographyWithTooltip
            variant="body2"
            title={account.provider_account_name}
          />
        ),
      }));
  }, [accounts, provider]);

  const RenderTagsComponent = useCallback((props: RenderTagsComponentProps) => {
    return (
      <FilterValueAutocompleteRenderTagsComponent label="Accounts" {...props} />
    );
  }, []);

  return (
    <AutocompleteSelect
      size="xsmall"
      label="Value"
      placeholder="Value"
      onChange={onChange}
      options={renderOptions ?? []}
      initialSelectedValues={value}
      filterFn={categoryAccountsFilterFn}
      RenderTagsComponent={RenderTagsComponent}
    />
  );
};
