import { FC, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDidMount } from "rooks";
import { Box } from "@mui/material";
import { CommitmentsRecommendationTypeSelect } from "./components/CommitmentsRecommendationTypeSelect";
import { CommitmentsRecommendationsContent } from "./components/CommitmentsRecommendationsContent";
import { CommitmentsRecommendationsHeader } from "./components/CommitmentsRecommendationsHeader";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { missingSetupSelector } from "../../../../store/commitments/selectors/missingSetupSelector";
import {
  resetCommitmentsRecommendations,
  setCommitmentsOrgId,
} from "../../../../store/commitments/commitmentsSlice";
import { getAllProvidersBillingStatusThunk } from "../../../../store/common/thunks/billing-status/getAllProvidersBillingStatusThunk";
import { ProviderBillingDataAccessController } from "../../common/billing/billing-data-access-controller/ProviderBillingDataAccessController";
import { getCommitmentsRecommendationsThunk } from "../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";
import { organizationsForCommitmentsFetchedSelector } from "../../../../store/commitments/selectors/organisations/organizationsForCommitmentsFetchedSelector";

export const CommitmentsRecommendations: FC = () => {
  const params = useParams();
  const orgId = params?.orgId ?? "";
  const dispatch = useAppDispatch();

  const missingSetup = useAppSelector(missingSetupSelector);
  const orgsFetched = useAppSelector(
    organizationsForCommitmentsFetchedSelector,
  );

  useEffect(() => {
    if (!orgsFetched) {
      return;
    }

    dispatch(resetCommitmentsRecommendations());
    dispatch(setCommitmentsOrgId(orgId));
    dispatch(getCommitmentsRecommendationsThunk());
  }, [dispatch, orgId, orgsFetched]);

  useDidMount(() => {
    dispatch(getAllProvidersBillingStatusThunk());
  });

  return (
    <Fragment>
      <CommitmentsRecommendationsHeader />

      {missingSetup ? (
        <ProviderBillingDataAccessController provider="aws" />
      ) : (
        <Box p={2}>
          <CommitmentsRecommendationTypeSelect />
          <CommitmentsRecommendationsContent />
        </Box>
      )}
    </Fragment>
  );
};
