import { Row } from "@tanstack/react-table";
import { ResourceExplorerGranularCostsData } from "../../../../../../../../../../store/resource-explorer/utils/types/resourceExplorerDataGrid";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { LabelColumnHeaderCell } from "../components/header-cells/LabelColumnHeaderCell";
import { LabelColumnCell } from "../components/labels-column/LabelColumnCell";
import { ResourceExplorerGranularCostCell } from "../components/common/ResourceExplorerGranularCostCell";
import { ResourceExplorerGranularDateHeadersCell } from "../components/header-cells/ResourceExplorerGranularDateHeadersCell";
import { ResourceExplorerGranularTotalCostHeaderCell } from "../components/header-cells/ResourceExplorerGranularTotalCostHeaderCell";
import { ResourceExplorerGrouping } from "../../../../../../../../../../services/cloudchipr.api";

export const resourceExplorerDataGridGranularViewColumns = (
  grouping: ResourceExplorerGrouping,
  keys?: string[],
) => {
  if (!keys) {
    return [];
  }

  const columns: ColumnSetupType<ResourceExplorerGranularCostsData>[] = [
    {
      minSize: 200,
      accessorKey: "field",
      header: () => (
        <LabelColumnHeaderCell showFilterIcon={grouping === "resource"} />
      ),
      enableSorting: grouping !== "none",
      cell: (cell) => {
        const data = cell.row.original;

        return (
          <LabelColumnCell
            provider={data.cloud_provider}
            grouping={data.resourceExplorerGrouping}
            label={data.label}
            field={data.field}
            accountId={data.metadata?.account_id}
            liveAccountId={data.metadata?.live_account_id}
            isTracked={!!data.metadata?.is_tracked}
            aggregatedByValue={data.metadata?.aggregated_by_value}
            accountExistInLiveUsage={!!data.accountExistInLiveUsage}
            filterValue={data.metadata?.filter_value}
            // @ts-expect-error  // TODO: https://app.clickup.com/t/6655588/C8R-4739
            clickable={data.metadata?.clickable}
          />
        );
      },
      meta: { sticky: "left", hideFromSettings: true, headerTitle: "Field" },
    },
    {
      accessorKey: "total_cost",
      minSize: 130,
      header: () => <ResourceExplorerGranularTotalCostHeaderCell />,
      cell: (cell) => {
        const cost = cell.row.original.total_cost;

        return <ResourceExplorerGranularCostCell cost={cost} />;
      },
      meta: {
        sticky: "left",
        hideFromSettings: true,
        headerTitle: "Total Cost",
      },
    },
  ];

  keys.forEach((key) => {
    columns.push({
      minSize: 130,
      accessorKey: key,
      header: () => <ResourceExplorerGranularDateHeadersCell date={key} />,
      meta: { headerTitle: key },
      sortingFn: (
        currentRow: Row<ResourceExplorerGranularCostsData>,
        nextRow: Row<ResourceExplorerGranularCostsData>,
      ) => {
        const current = currentRow.original[key]?.cost;
        const next = nextRow.original[key]?.cost;

        if (current === null) {
          return -1;
        }
        if (next === null) {
          return 1;
        }

        return current - next;
      },
      cell: (cell) => {
        const trend = cell.row.original[key]?.trend;
        const cost = cell.row.original[key]?.cost;
        const trendTooltip = cell.row.original[key]?.trendTooltip;

        return (
          <ResourceExplorerGranularCostCell
            cost={cost}
            trend={trend}
            trendTooltip={trendTooltip}
          />
        );
      },
    });
  });

  return columns;
};
