import {
  Breadcrumbs as MuiBreadcrumbs,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { FC } from "react";
import { TypographyWithTooltip } from "../../../../common/TypographyWithTooltip";
import {
  HierarchySelect,
  HierarchySelectProps,
} from "../../hierarchy-select/HierarchySelect";

interface HierarchicalBreadcrumbsProps extends HierarchySelectProps {
  folderName?: string;
  loading?: boolean;
}

export const HierarchicalBreadcrumbs: FC<HierarchicalBreadcrumbsProps> = ({
  folderName,
  loading,
  ...selectProps
}) => {
  if (loading) {
    return <Skeleton width={200} height={35} />;
  }

  return (
    <MuiBreadcrumbs
      separator={
        <Typography variant="body1" color="text.secondary">
          /
        </Typography>
      }
    >
      {folderName && (
        <Stack direction="row" spacing={1}>
          <FolderOpenIcon fontSize="small" />
          <TypographyWithTooltip
            title={folderName}
            color="text.primary"
            variant="body2"
          />
        </Stack>
      )}

      <HierarchySelect {...selectProps} />
    </MuiBreadcrumbs>
  );
};
