import { FC } from "react";
import { Card, Stack } from "@mui/material";
import { CommitmentsRecommendationsFilters } from "./filters/CommitmentsRecommendationsFilters";
import { CommitmentsRecommendationsMetadata } from "./metadata/CommitmentsRecommendationsMetadata";
import { CommitmentsRecommendationsDetails } from "./details/CommitmentsRecommendationsDetails";
import { CommitmentsRecommendationsTables } from "./tables/CommitmentsRecommendationsTables";

export const CommitmentsRecommendationsContent: FC = () => {
  return (
    <Card variant="outlined" sx={{ my: 2 }}>
      <Stack p={2} spacing={2}>
        <CommitmentsRecommendationsFilters />
        <CommitmentsRecommendationsMetadata />
        <CommitmentsRecommendationsDetails />
        <CommitmentsRecommendationsTables />
      </Stack>
    </Card>
  );
};
