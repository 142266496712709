import { FC } from "react";
import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { grey } from "@mui/material/colors";

interface TooltipTextProps extends TypographyProps {
  tooltipTitle: TooltipProps["title"];
  tooltipPlacement?: TooltipProps["placement"];
}

// todo: merge this component with `TypographyWithTooltip`
export const TooltipText: FC<TooltipTextProps> = ({
  children,
  tooltipTitle,
  tooltipPlacement,
  ...props
}) => {
  return (
    <Tooltip arrow title={tooltipTitle} placement={tooltipPlacement}>
      <Typography
        component="span"
        variant="body2"
        color="text.primary"
        sx={{
          display: "inline-block",
          textDecoration: "underline",
          textDecorationStyle: "dashed",
          textUnderlineOffset: 4,
          textDecorationColor: grey["A400"],
        }}
        {...props}
      >
        {children}
      </Typography>
    </Tooltip>
  );
};
