import { FC, Fragment } from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { DashboardV2FolderListItemActionsMenu } from "./DashboardV2FolderListItemActionsMenu";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";

interface DashboardV2FolderActionButtonProps {
  id: string;
  name: string;
}

export const DashboardV2FolderActionButton: FC<
  DashboardV2FolderActionButtonProps
> = ({ id, name }) => {
  const { cannot } = useAppAbility();
  const cannotDeleteDashboard = cannot("delete", "dashboard");
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (cannotDeleteDashboard) {
    return null;
  }

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <DashboardV2FolderListItemActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
      />
    </Fragment>
  );
};
