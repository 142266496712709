import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { widgetViewNameByViewIdIdSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/widgetViewNameByViewIdIdSelector";
import { getResourcesExplorerGroupingLabel } from "../../../../../../../resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { WidgetSetupSubHeaderV2 } from "../../common/v2/WidgetSetupSubHeaderV2";
import { selectedViewProvidersSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/common/SelectedViewProvidersSelector";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";
import { objectToUrl } from "../../../../../../../../utils/helpers/dataToUrlToDataConvertors";
import { costBreakdownV2WidgetSetupSelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";

export const CostBreakdownV2WidgetSetupSubHeader: FC = () => {
  const viewId = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("viewId"),
  );
  const widgetSetup = useAppSelector(costBreakdownV2WidgetSetupSelector);
  const viewName = useAppSelector((state) =>
    widgetViewNameByViewIdIdSelector(state, viewId),
  );
  const providers = useAppSelector(selectedViewProvidersSelector);

  const groupingLabel = getResourcesExplorerGroupingLabel(
    providers,
    widgetSetup?.grouping ?? "service",
    true,
  );

  const path = objectToUrl([
    {
      groupedBy: widgetSetup?.grouping,
      groupValues: widgetSetup?.group_values,
      forecastOption: widgetSetup?.forecastOption,
      dates: widgetSetup?.dates,
      dateGranularity: widgetSetup?.frequency,
      chartType: widgetSetup?.viewType,
      includedFilters: {},
    },
  ]);

  return (
    <WidgetSetupSubHeaderV2
      name={widgetSetup?.name ?? ""}
      icon="pie"
      dates={widgetSetup?.dates ?? {}}
      title={viewName ?? ""}
      menuTitle="Open in Resource Explorer"
      menuUrl={`/resource-explorer/${widgetSetup?.viewId}?path=${path}`}
      grouping={groupingLabel}
    />
  );
};
