import { getCronFrequency } from "../helpers/cron/getCronFrequency";

export const frequencySortingFn = (
  current: string | null,
  next: string | null,
) => {
  const currentFrequency = getCronFrequency(current);
  const nextFrequency = getCronFrequency(next);

  if (nextFrequency === currentFrequency) {
    return 0;
  }

  return nextFrequency > currentFrequency ? 1 : -1;
};
