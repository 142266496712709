import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { PrevPeriodCostColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/PrevPeriodCostColumnHeaderCell";
import { costBreakdownV2WidgetPrevTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetPrevTotalCostSelector";

interface CostBreakdownWidgetTableViewPrevPeriodTotalCostHeaderCellProps {
  widgetId: string;
}
export const CostBreakdownV2WidgetTablePrevPeriodTotalCostHeaderCell: FC<
  CostBreakdownWidgetTableViewPrevPeriodTotalCostHeaderCellProps
> = ({ widgetId }) => {
  const previousPeriodTotal = useAppSelector((state) =>
    costBreakdownV2WidgetPrevTotalCostSelector(state, widgetId),
  );

  return (
    <PrevPeriodCostColumnHeaderCell
      sum={previousPeriodTotal?.cost ?? 0}
      dateDetails={previousPeriodTotal?.dateDetails}
    />
  );
};
