import {
  cloudChiprApi,
  ResourceExplorerFilterKeyType,
  ResourceExplorerPossibleFilter,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";

const selector =
  cloudChiprApi.endpoints
    .getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFilters
    .select;

export const resourceExplorerPossibleFiltersByProviderSelector = (
  state: RootState,
  provider: ProviderType,
) => {
  const filters = selector({
    provider,
  })(state)?.data;

  return filters?.reduce(
    (result, item) => {
      result[item.key] = item;
      return result;
    },
    {} as Record<ResourceExplorerFilterKeyType, ResourceExplorerPossibleFilter>,
  );
};
