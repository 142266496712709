import { FC, Fragment, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import {
  AccountsCountTooltip,
  AccountsCountTooltipProps,
} from "./AccountsCountTooltip";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { allAccountsLengthSelector } from "../../../../../../../../store/dimensions/selectors/accounts/allAccountsLengthSelector";
import { categoryFilterTypeSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/categoryFilterTypeSelector";
import { dimensionCategoriesEntriesSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/dimensionCategoriesEntriesSelector";

export const AccountsCounts: FC<AccountsCountTooltipProps> = ({
  accountsCount,
}) => {
  const filterType = useAppSelector(categoryFilterTypeSelector);
  const allAccounts = useAppSelector(allAccountsLengthSelector);
  const categories = useAppSelector(dimensionCategoriesEntriesSelector);

  const allProvidersAccountsCount = useMemo(() => {
    return Object.values(accountsCount).reduce((sum, count) => {
      return sum + count;
    }, 0);
  }, [accountsCount]);

  if (filterType !== "account_based" || !categories?.length) {
    return null;
  }

  return (
    <Fragment>
      <Typography variant="subtitle1" fontWeight="medium">
        Selected Accounts
      </Typography>

      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography variant="body1">
          <Typography variant="inherit" component="span" fontWeight="medium">
            {allProvidersAccountsCount}
          </Typography>{" "}
          out of total{" "}
          <Typography variant="inherit" component="span" fontWeight="medium">
            {allAccounts}
          </Typography>{" "}
          connected accounts
        </Typography>

        <AccountsCountTooltip accountsCount={accountsCount} />
      </Stack>
    </Fragment>
  );
};
