import { FC, useMemo } from "react";
import { Card, Collapse } from "@mui/material";
import { useToggle } from "rooks";
import { CategoryCollapseHeader } from "../CategoryCollapseHeader";
import { CategoryAccountsSelect } from "../../../common/CategoryAccountsSelect";
import { SelectedAccounts } from "../../../common/SelectedAccounts";
import { getAccountIdsFromCategoryFilters } from "../../../../../../utils/helpers/getAccountIdsFromCategoryFilters";
import { DimensionCategory } from "../../../../../../../../../store/dimensions/utils/types/common";

interface CategoryCreateByAccountsProps {
  category: DimensionCategory;
  deleteDisabled?: boolean;
}

export const CategoryBasedOnAccounts: FC<CategoryCreateByAccountsProps> = ({
  category,
  deleteDisabled,
}) => {
  const [collapsed, toggleCollapsed] = useToggle();

  const selectedAccounts = useMemo(() => {
    return getAccountIdsFromCategoryFilters(category.filters);
  }, [category.filters]);

  return (
    <Card variant="outlined">
      <CategoryCollapseHeader
        deleteDisabled={!!deleteDisabled}
        title={`${selectedAccounts.length} accounts`}
        collapsed={collapsed}
        category={category}
        toggleCollapsed={toggleCollapsed}
      />

      <Collapse in={!collapsed}>
        <SelectedAccounts
          selectedAccounts={selectedAccounts}
          categoryId={category.id}
        />

        <CategoryAccountsSelect
          id={category.id}
          selectedAccounts={selectedAccounts}
        />
      </Collapse>
    </Card>
  );
};
