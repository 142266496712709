import { FC } from "react";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { PrevPeriodCostColumnHeaderCell } from "../../../../../../../../../resource-explorer/components/resource-explorer-card/components/resource-explorer-data-grid/components/columns/components/common/PrevPeriodCostColumnHeaderCell";
import { costBreakdownWidgetPrevTotalCostSelector } from "../../../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetPrevTotalCostSelector";

interface CostBreakdownWidgetTableViewPrevPeriodTotalCostHeaderCellProps {
  widgetId?: string;
}
export const CostBreakdownWidgetTableViewPrevPeriodTotalCostHeaderCell: FC<
  CostBreakdownWidgetTableViewPrevPeriodTotalCostHeaderCellProps
> = ({ widgetId }) => {
  const previousPeriodTotal = useAppSelector((state) =>
    costBreakdownWidgetPrevTotalCostSelector(state, widgetId),
  );

  return (
    <PrevPeriodCostColumnHeaderCell
      sum={previousPeriodTotal?.cost ?? 0}
      dateDetails={previousPeriodTotal?.dateDetails}
    />
  );
};
