import { resourceExplorerViewsHierarchyFoldersSelector } from "./resourceExplorerViewsHierarchyFoldersSelector";
import { RootState } from "../../../store";
import { ResourceExplorer } from "../../../../services/cloudchipr.api";

export const resourceExplorerViewsHierarchyViewsByFolderIdSelector = (
  state: RootState,
  folderId: string,
): ResourceExplorer[] | undefined => {
  const folders = resourceExplorerViewsHierarchyFoldersSelector(state);

  return folders?.find((item) => item.id === folderId)?.views;
};
