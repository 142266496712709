import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { getUsersMeOrganisationsCurrentDashboardsPossibleWidgetsSelector } from "./getUsersMeOrganisationsCurrentDashboardsPossibleWidgetsSelector";
import {
  GetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsApiResponse,
  WidgetStateReason,
  WidgetType,
} from "../../../../services/cloudchipr.api";

export type WidgetStatuses = Record<WidgetType, null | WidgetStateReason>;

export const dashboardsPossibleWidgetsSelector = createDraftSafeSelector(
  [getUsersMeOrganisationsCurrentDashboardsPossibleWidgetsSelector],
  (response) => {
    const data = response?.data
      ?.widgets as GetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsApiResponse["widgets"];

    return data?.reduce((acc, { type, cause_of_disabled_state }) => {
      acc[type] = cause_of_disabled_state;

      return acc;
    }, {} as WidgetStatuses);
  },
);
