import { costAndUsageSumWidgetByIdSelector } from "./costAndUsageSumWidgetByIdSelector";
import { RootState } from "../../../../store";
import { costAndUsageSumWidgetSetupSelector } from "../../setups/cost-and-usage/costAndUsageSumWidgetSetupSelector";
import { sortAlphabeticallyCallback } from "../../../../../utils/helpers/sorting";

export const costAndUsageSumWidgetEditChangesExistSelector = (
  state: RootState,
) => {
  const setup = costAndUsageSumWidgetSetupSelector(state);
  if (!setup?.id) {
    return false;
  }

  const widget = costAndUsageSumWidgetByIdSelector(state, setup?.id);

  if (!widget) {
    return false;
  }

  const setupFilters = setup?.filter ? [...setup.filter] : [];
  const widgetFilter = widget?.filter ? [...widget.filter] : [];

  return (
    !setupFilters.length ||
    setup.name !== widget.name ||
    setup.viewId !== widget.view_id ||
    setup.date.from !== widget.date_from ||
    setup.date.to !== widget.date_to ||
    setup.date.label !== widget.date_label ||
    setupFilters.sort(sortAlphabeticallyCallback)?.toString() !==
      widgetFilter.sort(sortAlphabeticallyCallback)?.toString()
  );
};
