import { FC, Fragment } from "react";
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import { RenderEmails } from "./RenderEmails";
import {
  Emails,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../../../services/cloudchipr.api";
import { IntegrationLogo } from "../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { money } from "../../../../../../../utils/numeral/money";
import { IntegrationNotification } from "../../../../../common/notification-selection/utils/types/types";

interface SchedulePreviewNotificationsProps {
  notifications?: IntegrationNotification[] | null;
  minThreshold?: number | null;
  snoozed?: boolean | null;
  emails: Emails;
}

export const SchedulePreviewNotifications: FC<
  SchedulePreviewNotificationsProps
> = ({ notifications, minThreshold, snoozed, emails }) => {
  const { data } = useGetUsersMeIntegrationsQuery(
    {},
    { skip: !notifications?.length },
  );

  return (
    <Grid container px={2} mb={4} alignItems="baseline">
      <Grid item sm={4}>
        <Typography variant="subtitle2">Action Notifications</Typography>
      </Grid>

      <Grid item sm={8}>
        {emails && (
          <Stack spacing={1} mb={2}>
            <RenderEmails label="To" emails={emails.to} />
            <RenderEmails label="Cc" emails={emails.cc} />
            <RenderEmails label="Bcc" emails={emails.bcc} />
          </Stack>
        )}

        {!!notifications?.length && (
          <Fragment>
            <Typography variant="body2" mb={1}>
              Integration(s)
            </Typography>
            <Stack direction="row">
              {data?.map(({ id, type, name }) => {
                const includes = notifications?.some(
                  ({ integration_id }) => integration_id === id,
                );

                if (!includes) {
                  return null;
                }

                return (
                  <Chip
                    key={id}
                    icon={
                      <Box pl={0.5} pt={0.5}>
                        <IntegrationLogo type={type} width={14} />
                      </Box>
                    }
                    label={name}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1, ".MuiChip-icon": { ml: 1 } }}
                  />
                );
              })}
            </Stack>
          </Fragment>
        )}

        <Stack direction="row" spacing={1}>
          {minThreshold && (
            <Chip
              size="small"
              label={`Minimum threshold: ${money(minThreshold)}`}
            />
          )}

          {!snoozed && <Chip size="small" label="Always Notify" />}
        </Stack>
      </Grid>
    </Grid>
  );
};
