import {
  Dashboard,
  DashboardFolder,
} from "../../../../../services/cloudchipr.api";

//TODO: merge with isResourceExplorerFolder after views variable rename
export const isDashboardFolder = (
  data: Dashboard | DashboardFolder,
): data is DashboardFolder => {
  return "items" in data;
};
