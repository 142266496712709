import { FC } from "react";
import { Button, Stack } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { FilterTriggerComponentProps } from "../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";

export const DashboardBreadcrumbNavigationTrigger: FC<
  FilterTriggerComponentProps
> = ({ onClick, open, label }) => {
  return (
    <Button
      color="tertiary"
      onClick={onClick}
      sx={{ fontWeight: "normal", textTransform: "none" }}
      startIcon={
        <Stack direction="row" spacing={0.5}>
          <GridViewOutlinedIcon fontSize="small" />
        </Stack>
      }
      endIcon={
        open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
        )
      }
    >
      {label}
    </Button>
  );
};
