import { FC } from "react";
import { CellContext } from "@tanstack/table-core";
import { Stack, Switch, Typography } from "@mui/material";
import { SavingsPlanRecommendation } from "../../../../../../../../services/cloudchipr.api";
import { savingsPlanTermLabels } from "../../../../../utilization-and-coverage/utils/constants/labels";

export const CommitmentsRecommendationsColumnsSwitchCell: FC<
  CellContext<SavingsPlanRecommendation, any>
> = ({ row }) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Switch
        size="small"
        checked={row.getIsSelected()}
        onChange={row.getToggleSelectedHandler()}
      />
      <Typography variant="body2">
        {savingsPlanTermLabels[row.original.term_in_years]}
      </Typography>
    </Stack>
  );
};
