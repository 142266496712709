import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { duplicateOffHoursThunk } from "./duplicateOffHoursThunk";
import { duplicateWorkflowThunk } from "./duplicateWorkflowThunk";
import { cloudChiprApi } from "../../../services/cloudchipr.api";

interface DuplicateOffHourThunkArgs {
  id: string;
  name: string;
  type: "off-hours" | "workflow";
}
export const duplicateAutomationThunk = createAsyncThunk(
  "schedules/duplicateAutomation",
  async ({ id, name, type }: DuplicateOffHourThunkArgs, { dispatch }) => {
    const { getV2UsersMeSchedules, getV2UsersMeOffHoursSchedules } =
      cloudChiprApi.endpoints;

    if (type === "off-hours") {
      const response = await dispatch(
        duplicateOffHoursThunk({
          id,
          name,
        }),
      ).unwrap();
      if (response) {
        enqueueSnackbar("Off Hours successfully duplicated.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
          },
        });
        dispatch(
          getV2UsersMeOffHoursSchedules.initiate({}, { forceRefetch: true }),
        );
      }
      return response;
    } else {
      const response = await dispatch(
        duplicateWorkflowThunk({
          id,
          name,
        }),
      ).unwrap();
      if (response) {
        enqueueSnackbar("Workflow successfully duplicated.", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
          },
        });
        dispatch(getV2UsersMeSchedules.initiate({}, { forceRefetch: true }));
      }
      return response;
    }
  },
);
