import { FC, Fragment, useMemo } from "react";
import { Chip, Stack } from "@mui/material";
import { useAppSelector } from "../../../../../../store/hooks";
import { awsNonDeletedAccountsSelector } from "../../../../../../store/accounts/selectors/aws/awsNonDeletedAccountsSelector";
import { gcpNonDeletedAccountsSelector } from "../../../../../../store/accounts/selectors/gcp/gcpNonDeletedAccountsSelector";
import { azureNonDeletedAccountsSelector } from "../../../../../../store/accounts/selectors/azure/azureNonDeletedAccountsSelector";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { AccountsListPopover } from "../../../../../common/accounts-with-popover/AccountsListPopover";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";

interface SchedulePreviewAccountsProps {
  accountIds: string[];
  provider: ProviderType;
}

export const SchedulePreviewAccounts: FC<SchedulePreviewAccountsProps> = ({
  accountIds,
  provider,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const awsAccounts = useAppSelector(awsNonDeletedAccountsSelector);
  const gcpAccounts = useAppSelector(gcpNonDeletedAccountsSelector);
  const azureAccounts = useAppSelector(azureNonDeletedAccountsSelector);

  const accounts = useMemo(() => {
    let accounts = null;

    if (provider === "aws") {
      accounts = awsAccounts;
    } else if (provider === "gcp") {
      accounts = gcpAccounts;
    } else if (provider === "azure") {
      accounts = azureAccounts;
    }

    return accounts
      ?.filter(({ id }) => accountIds.includes(id))
      .map((account) => ({
        id: account.id,
        provider_account_name:
          account.provider_account_name || account.provider_account_id || "",
      }));
  }, [accountIds, provider, awsAccounts, gcpAccounts, azureAccounts]);

  if (!accounts) {
    return null;
  }

  const firstPart = accounts.slice(0, 7);
  const secondPart = accounts.slice(7);

  return (
    <Stack direction="row" gap={1} flexWrap="wrap">
      {firstPart.map((account) => {
        return (
          <Chip
            key={account.id}
            variant="outlined"
            size="small"
            label={
              <TypographyWithTooltip
                title={account.provider_account_name}
                fontSize="inherit"
                maxWidth={110}
              />
            }
          />
        );
      })}

      {!!secondPart?.length && (
        <Fragment>
          <Chip
            onClick={openMenu}
            variant="outlined"
            size="small"
            label={`+ ${secondPart.length}`}
          />

          <AccountsListPopover
            open={open}
            anchor={anchor}
            accounts={secondPart}
            closeMenu={closeMenu}
          />
        </Fragment>
      )}
    </Stack>
  );
};
