import { DimensionCategories } from "../../../../../store/dimensions/utils/types/common";
import { CategoryWithCosts } from "../../../../../services/cloudchipr.api";

export const generateDimensionCategoriesFromCategoriesWithCosts = (
  categories: CategoryWithCosts[],
) => {
  return categories.reduce((acc, category) => {
    acc[category.id] = category;
    return acc;
  }, {} as DimensionCategories);
};
