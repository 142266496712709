import { FC, useCallback, useMemo } from "react";
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { ResourceActionMissingPermissionsTooltip } from "./ResourceActionMissingPermissionsTooltip";
import { InfoIconForSpecificResource } from "../InfoIconForSpecificResource";
import {
  CleanActionDetails,
  CleanActionTypes,
  getActionValue,
  getCleanActionOptions,
} from "../../../../../../../../../utils/clean-options";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../../../../services/cloudchipr.api";
import { useResourcesVisibility } from "../../../../../../common/utils/hooks/useResourcesVisibility.hook";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { stateAccountsSelector } from "../../../../../../../../../store/accounts/selectors/slice-data/stateAccountsSelector";
import { getResourceTypeName } from "../../../../../../../../../utils/helpers/resources/getResourceTypeName";

interface ResourceWithActionsProps {
  accountIds: string[];
  provider: ProviderType;
  resourceType: ResourceType;
  action?: string | null;
  snapshot?: boolean | null;
  onChange(resourceActions: Record<string, CleanActionTypes>): void;
}

export const ResourceWithActions: FC<ResourceWithActionsProps> = ({
  resourceType,
  onChange,
  action,
  snapshot,
  accountIds,
  provider,
}) => {
  const accounts = useAppSelector(stateAccountsSelector);

  const readWriteAccountsIds = useMemo(() => {
    if (!accounts) {
      return "";
    }

    const readWriteAccounts = accounts.filter((account) => {
      return (
        account.access_type === "read_write" && accountIds.includes(account.id)
      );
    });

    return readWriteAccounts.map((account) => account.id).join(", ");
  }, [accountIds, accounts]);

  const resourcesVisibility = useResourcesVisibility({
    accountIds: readWriteAccountsIds,
    provider,
  });

  const options = useMemo(() => {
    return getCleanActionOptions(resourceType).filter((option) =>
      Object.values(resourcesVisibility).every((visibility) => {
        return visibility?.[resourceType].actions[option.value] !== null;
      }),
    );
  }, [resourceType, resourcesVisibility]);

  const changeHandler = useCallback(
    (event: any) => {
      onChange({ [resourceType]: event.target.value as CleanActionTypes });
    },
    [onChange, resourceType],
  );

  const value = getActionValue(action, snapshot);

  const getMissingPermissionAccountIds = useCallback(
    (option: CleanActionDetails) => {
      return Object.keys(resourcesVisibility).reduce((acc, key) => {
        if (
          resourcesVisibility[key]?.[resourceType]?.actions?.[option.value] ===
          false
        ) {
          acc.push(key);
        }

        return acc;
      }, [] as string[]);
    },
    [resourcesVisibility, resourceType],
  );

  if (!Object.values(resourcesVisibility).length) {
    return null;
  }

  return (
    <Grid container mb={3}>
      <Grid item xs={12} sm={5}>
        <Typography variant="subtitle2" sx={{ mt: 1 }}>
          {getResourceTypeName(resourceType, { type: "long" })}
          <InfoIconForSpecificResource resourceType={resourceType} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7}>
        {!options.length ? (
          <Typography variant="caption">No actions available</Typography>
        ) : (
          <RadioGroup row value={value} onChange={changeHandler}>
            {options.map((option) => {
              const missingPermissionAccountIds =
                getMissingPermissionAccountIds(option);
              const formControlLabel = (
                <FormControlLabel
                  key={option.value}
                  checked={value === option.value}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              );

              if (missingPermissionAccountIds.length) {
                return (
                  <Stack direction="row" alignItems="center" key={option.value}>
                    {formControlLabel}
                    <ResourceActionMissingPermissionsTooltip
                      missingPermissionAccountIds={missingPermissionAccountIds}
                    />
                  </Stack>
                );
              }
              return formControlLabel;
            })}
          </RadioGroup>
        )}
      </Grid>
    </Grid>
  );
};
