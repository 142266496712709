import { CostAndUsageSumSetupType } from "../types/setups/costAndUsageSumSetupType";

export const costAndUsageSumDefaultFilters: CostAndUsageSumWidgetFilterType[] =
  [
    "total_cost",
    "previous_period_total_cost",
    "average_monthly_cost_details",
    "average_daily_cost_details",
    "forecasted_month_end_cost",
    "forecasted_quarter_end_cost",
    "live_usage_total_cost",
  ];

export const costAndUsageSumSetupDefaultData: CostAndUsageSumSetupType = {
  widgetType: "cost_and_usage_summary",
  name: "Cost and usage summary",
  viewId: undefined,
  date: { from: null, to: null, label: "past_fourteen_days" },
  filter: costAndUsageSumDefaultFilters,
  grouping: "service",
};

export type CostAndUsageSumWidgetFilterType =
  | "total_cost"
  | "previous_period_total_cost"
  | "average_monthly_cost_details"
  | "average_daily_cost_details"
  | "forecasted_month_end_cost"
  | "forecasted_quarter_end_cost"
  | "live_usage_total_cost";
