import { FC, ReactElement, useCallback, useMemo, useState } from "react";
import { Box, Divider, Stack } from "@mui/material";
import { FilterTemplateDropdownList } from "./FilterTemplateDropdownList";
import { FilterTemplateSearchBox } from "./FilterTemplateSearchBox";
import {
  AccountFilterSetV2,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";

interface FilterTemplateDropDownContentProps {
  onApplyFilterTemplate(filterSetId: string): void;
  appliedFilterSetId: string;
  filterTemplates: AccountFilterSetV2[];
  loadingByFilterSetId?: Record<string, boolean> | null;
  loading?: boolean;
  header?: ReactElement;
  provider: ProviderType | null;
}
export const FilterTemplateDropDownContent: FC<
  FilterTemplateDropDownContentProps
> = ({
  filterTemplates,
  onApplyFilterTemplate,
  appliedFilterSetId,
  loadingByFilterSetId,
  loading,
  header,
  provider,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const searchChangeHandler = useCallback((event: any) => {
    setSearchValue(event.target.value);
  }, []);

  const filteredData = useMemo(() => {
    if (!searchValue) {
      return filterTemplates;
    }
    return filterTemplates.filter((item) =>
      item.filter_set.name
        .toLowerCase()
        .trim()
        .includes(searchValue.trim().toLowerCase()),
    );
  }, [filterTemplates, searchValue]);

  return (
    <Stack pb={2}>
      <Box
        bgcolor="white"
        top={0}
        position="sticky"
        zIndex={(theme) => theme.zIndex.speedDial}
      >
        {header}

        <Divider />
        <FilterTemplateSearchBox onChange={searchChangeHandler} />
      </Box>
      <Stack pt={1}>
        <FilterTemplateDropdownList
          onApplyFilterTemplate={onApplyFilterTemplate}
          appliedFilterSetId={appliedFilterSetId ?? ""}
          filterTemplates={filteredData}
          provider={provider}
          loadingByFilterSetId={loadingByFilterSetId}
          loading={loading}
        />
      </Stack>
    </Stack>
  );
};
