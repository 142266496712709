import { ChangeEvent, FC } from "react";
import { Card, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { RenderToolbarProps } from "../../../../../../storybook/data-grid/utils/types/prop-types";

export const UsersTableToolbar: FC<RenderToolbarProps> = (props) => {
  const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    props.setGlobalFilter(event.target.value);
  };

  return (
    <Card variant="outlined" sx={{ mb: 2, py: 2, px: 3 }}>
      <TextField
        type="search"
        placeholder="Search"
        value={props.globalFilter}
        fullWidth
        size="small"
        onChange={changeHandler}
        sx={{ maxWidth: 272 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Card>
  );
};
