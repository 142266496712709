import { Box, Grid, GridSize } from "@mui/material";
import { FC, ReactNode, useCallback, useState } from "react";
import { ToggleBoxItem } from "./components/ToggleBoxItem";

export type ToggleBoxOption = {
  value: string;
  label: ReactNode;
  disabled?: boolean;
  tooltipText?: string;
  description?: ReactNode;
};

interface ToggleBoxProps {
  options: ToggleBoxOption[];
  defaultValue?: string;
  gridSize?: GridSize;
  onChange?(value: string): void;
}

export const ToggleBox: FC<ToggleBoxProps> = ({
  options,
  defaultValue,
  gridSize = 6,
  onChange,
}) => {
  const [value, setValue] = useState(defaultValue ?? "");

  const valueChangeHandler = useCallback(
    (value: string) => {
      setValue(value);
      onChange?.(value);
    },
    [onChange],
  );

  return (
    <Box>
      <Grid container spacing={2}>
        {options.map((option) => {
          return (
            <Grid item xs={gridSize} key={option.value}>
              <ToggleBoxItem
                tooltip={option.tooltipText}
                value={option.value}
                label={option.label}
                disabled={!!option.disabled}
                checked={option.value === value}
                description={option?.description}
                onChange={valueChangeHandler}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
