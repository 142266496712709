import { FC, useCallback, useEffect, useMemo } from "react";
import { TooltipProps } from "recharts";
import { CostBreakdownV2WidgetChartTooltipContent } from "./CostBreakdownV2WidgetChartTooltipContent";
import { useMultiTypeChartContext } from "../../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { moneyFormatter } from "../../../../../../../../../../utils/numeral/moneyFormatter";
import { MultiTypeChart } from "../../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import {
  DateDataPointNullable,
  ResourceExplorerCostsDateDetails,
} from "../../../../../../../../../../services/cloudchipr.api";
import { ChartType } from "../../../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { calculateCostBreakdownV2Total } from "../../../../../../widgets/cost-breakdown-v2/utils/helpers/calculateCostBreakdownV2Total";
import { formatDate } from "../../../../../../../../../../utils/helpers/date-time/datetime-format";

export interface CostBreakdownWidgetChartProps {
  chartType: ChartType;
  dateDetails?: ResourceExplorerCostsDateDetails;
  loading?: boolean;
  dateDataPoint: DateDataPointNullable;
}

export const CostBreakdownV2WidgetChart: FC<CostBreakdownWidgetChartProps> = ({
  chartType,
  dateDetails,
  loading,
  dateDataPoint,
}) => {
  const {
    chartType: { type: chartTypeFromProvider, onChange: onChartTypeChange },
    data,
  } = useMultiTypeChartContext();

  const total = useMemo(() => {
    return calculateCostBreakdownV2Total(data);
  }, [data]);

  const TooltipContent = useCallback(
    (props: TooltipProps<any, any>) => {
      return (
        <CostBreakdownV2WidgetChartTooltipContent
          {...props}
          total={total}
          dateDetails={dateDetails}
        />
      );
    },
    [dateDetails, total],
  );

  const yAxisFormatter = useCallback((value: number | string) => {
    return moneyFormatter(+value);
  }, []);

  useEffect(() => {
    if (chartTypeFromProvider === chartType) {
      return;
    }

    onChartTypeChange(chartType);
  }, [chartTypeFromProvider, chartType, onChartTypeChange]);

  return (
    <MultiTypeChart
      zoom
      selectable
      highlight
      loading={loading}
      TooltipContent={TooltipContent}
      tooltipProps={{
        showTotal: true,
        tooltipLabelFormatter:
          dateDataPoint === "daily"
            ? (label: string) => formatDate(label, { type: "weekDayDate" })
            : undefined,
      }}
      yAxisFormatter={yAxisFormatter}
      showLoader={false}
      emptyText="No data by your selected date range."
    />
  );
};
