import { FC, useCallback } from "react";
import { IconButton, Stack } from "@mui/material";
import ListIcon from "@mui/icons-material/List";
import CancelIcon from "@mui/icons-material/Cancel";
import { ResourceExplorerCategorySelect } from "./ResourceExplorerCategorySelect";
import { ResourceExplorerDimensionSelect } from "./dimension-select/ResourceExplorerDimensionSelect";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { resourceExplorerDimensionFilterAppliedSelector } from "../../../../../../store/resource-explorer/selectors/filters/dimension/resourceExplorerDimensionFilterAppliedSelector";
import { setResourceExplorerDimension } from "../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerGroupByChangeThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer/resourceExplorerGroupByChangeThunk";
import { currentResourceExplorerInitialGroupingSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/currentResourceExplorerInitialGroupingSelector";

export const ResourceExplorerDimensionFilterItem: FC = () => {
  const dispatch = useAppDispatch();

  const dimensionFilterApplied = useAppSelector(
    resourceExplorerDimensionFilterAppliedSelector,
  );

  const initialGrouping = useAppSelector(
    currentResourceExplorerInitialGroupingSelector,
  );

  const removeHandler = useCallback(() => {
    dispatch(setResourceExplorerDimension(null));
    dispatch(
      resourceExplorerGroupByChangeThunk({
        grouping: initialGrouping ?? "service",
      }),
    );
  }, [dispatch, initialGrouping]);

  if (!dimensionFilterApplied) {
    return null;
  }

  return (
    <Stack
      p={0.5}
      alignItems="center"
      spacing={0.5}
      direction="row"
      borderRadius={5}
      bgcolor="grey.100"
      width="fit-content"
    >
      <ListIcon color="action" />

      <ResourceExplorerDimensionSelect />

      <ResourceExplorerCategorySelect />

      <IconButton onClick={removeHandler} size="small">
        <CancelIcon color="action" fontSize="small" />
      </IconButton>
    </Stack>
  );
};
