import { FC, useMemo } from "react";
import { Stack, TooltipProps, Typography } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { InfoDataChip } from "../../../../../widgets/common/widget-header/source-and-name/InfoDataChip";
import { TooltipText } from "../../../../../../../../common/TooltipText";
import { Dates } from "../../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { organisationQuarterStartMonthSelector } from "../../../../../../../../../store/common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { calculateFromToByDatesData } from "../../../../../../../../common/date-range-picker-v2/utils/helpers/calculateFromToByDatesData";
import { generateLabelByDatesData } from "../../../../../../../../common/date-range-picker-v2/utils/helpers/generateLabelByDatesData";

export interface DateInfoChipV2Props extends Dates {
  tooltipPlacement?: TooltipProps["placement"];
}

export const DateInfoChipV2: FC<DateInfoChipV2Props> = ({
  tooltipPlacement,
  ...dates
}) => {
  const quarterStartMonth = useAppSelector(
    organisationQuarterStartMonthSelector,
  );

  const dateLabels = useMemo(() => {
    const { toDate, fromDate } = calculateFromToByDatesData(
      dates,
      quarterStartMonth,
    );

    return {
      label: generateLabelByDatesData(dates),
      tooltip: fromDate && toDate ? `${fromDate} - ${toDate}` : "",
    };
  }, [quarterStartMonth, dates]);

  return (
    <InfoDataChip>
      <Stack direction="row" spacing={0.5} alignItems="center" component="span">
        <AccessTimeIcon sx={{ fontSize: 18 }} />
        {dateLabels.tooltip ? (
          <TooltipText
            tooltipTitle={dateLabels.tooltip}
            tooltipPlacement={tooltipPlacement ?? "right"}
          >
            {dateLabels.label}
          </TooltipText>
        ) : (
          <Typography variant="body2" color="text.primary" component="span">
            {dateLabels.label}
          </Typography>
        )}
      </Stack>
    </InfoDataChip>
  );
};
