import moment from "moment/moment";
import { createCron } from "./createCron";

export const generateWeeklyCron = (days: number[], date: string | null) => {
  const cron = createCron({
    dayOfWeek: days,
    hour: +moment(date).hours(),
    minute: +moment(date).minutes(),
  }).split(" ");

  cron[2] = "?";

  return cron.join(" ");
};
