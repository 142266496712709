import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import {
  cloudChiprApi,
  PostV2UsersMeOffHoursSchedulesApiArg,
} from "../../../services/cloudchipr.api";
import { offHoursDataSelector } from "../selectors/off-hours/offHoursDataSelector";
import { RootState } from "../../store";

interface DuplicateOffHoursThunkArgs {
  id: string;
  name: string;
}
export const duplicateOffHoursThunk = createAsyncThunk(
  "schedules/duplicateOffHours",
  async ({ id, name }: DuplicateOffHoursThunkArgs, { dispatch, getState }) => {
    const { postV2UsersMeOffHoursSchedules } = cloudChiprApi.endpoints;
    const state = getState() as RootState;

    const data = offHoursDataSelector(state);
    const offHours = data?.find((item) => item.id === id);

    if (!offHours) {
      return;
    }

    const {
      start_date_time,
      end_date_time,
      start_cron,
      stop_cron,
      regions,
      action,
      time_zone,
      min_threshold,
      emails,
      accounts,
      notifications,
    } = offHours;
    const accountIds = accounts.map((item) => item.id);

    const body: PostV2UsersMeOffHoursSchedulesApiArg = {
      body: {
        name,
        start_date_time,
        end_date_time,
        start_cron,
        stop_cron,
        regions,
        action,
        time_zone,
        min_threshold,
        emails,
        notifications,
        account_ids: accountIds ?? [],
        filter: offHours?.filter ?? [],
        status: "inactive",
      },
    };
    try {
      return await dispatch(
        postV2UsersMeOffHoursSchedules.initiate(body, {
          fixedCacheKey: id,
        }),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
