import { FC } from "react";
import { Grid, TextField } from "@mui/material";
import { CategoryFilterType } from "../../../../../../../../../../../../../store/dimensions/utils/types/common";
import { capitalizeString } from "../../../../../../../../../../../../../utils/helpers/capitalizeString";

interface FilterTypeFieldProps {
  type: CategoryFilterType;
  size?: "small" | "big";
}

export const FilterTypeField: FC<FilterTypeFieldProps> = ({
  type,
  size = "big",
}) => {
  return (
    <Grid item xs={size === "small" ? 2 : 3}>
      <TextField
        fullWidth
        size="xsmall"
        label="Filter"
        focused={false}
        value={capitalizeString(type)}
        InputProps={{ readOnly: true }}
      />
    </Grid>
  );
};
