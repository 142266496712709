import { FC, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import { TagFilterKeyAutocomplete } from "./TagFilterKeyAutocomplete";
import { TagFilterValueField } from "./TagFilterValueField";
import { FilterItemDeleteButton } from "../common/FilterItemDeleteButton";
import { FilterTypeField } from "../common/FilterTypeField";
import { FilterOperatorField } from "../common/FilterOperatorField";
import {
  ProviderType,
  ResourceExplorerTag,
} from "../../../../../../../../../../../../../services/cloudchipr.api";
import { setCategoryFilterGroupFilterItemByIndex } from "../../../../../../../../../../../../../store/dimensions/dimensionsSlice";
import { useAppDispatch } from "../../../../../../../../../../../../../store/hooks";

interface TagFilterItemsProps {
  categoryId: string;
  index: number;
  provider: ProviderType;
  filterItem: ResourceExplorerTag;
}

export const TagFilterItems: FC<TagFilterItemsProps> = ({
  categoryId,
  provider,
  filterItem,
  index,
}) => {
  const dispatch = useAppDispatch();

  const changeHandler = useCallback(
    (filterItem: ResourceExplorerTag) => {
      dispatch(
        setCategoryFilterGroupFilterItemByIndex({
          id: categoryId,
          index,
          provider,
          tagFilter: filterItem,
        }),
      );
    },
    [dispatch, provider, index, categoryId],
  );

  return (
    <Box flex={1}>
      <Grid container spacing={2}>
        <FilterTypeField type="tag" size="small" />

        <FilterOperatorField
          size="small"
          filterItem={filterItem}
          onChange={changeHandler}
        />

        <TagFilterKeyAutocomplete
          value={filterItem.tag_key}
          provider={provider}
          filterItem={filterItem}
          onChange={changeHandler}
        />

        {filterItem.tag_values !== undefined && (
          <TagFilterValueField
            provider={provider}
            filterItem={filterItem}
            onChange={changeHandler}
          />
        )}

        <FilterItemDeleteButton
          type="tag"
          index={index}
          provider={provider}
          categoryId={categoryId}
        />
      </Grid>
    </Box>
  );
};
