import { FC, Fragment, useCallback } from "react";
import { FormikHelpers } from "formik";
import { Divider } from "@mui/material";
import { ActionsTypeSelector } from "./ActionsTypeSelector";
import { ResourcesActions } from "./resources-actions/ResourcesActions";
import {
  ProviderType,
  ResourceFiltersWithAction,
  ResourceType,
  ScheduleActionType,
} from "../../../../../../../../services/cloudchipr.api";
import {
  ScheduleActionsStepInitialData,
  SchedulesActionType,
} from "../../../../../common/utils/types/types";
import { CleanActionTypes } from "../../../../../../../../utils/clean-options";
import { useResourcesVisibility } from "../../../../../common/utils/hooks/useResourcesVisibility.hook";
import { ActionTypeSwitch } from "../../../../../common/create/actions/ActionTypeSwitch";

interface ScheduleActionTogglersProps {
  provider: ProviderType;
  resourceFilters?: ResourceFiltersWithAction[];
  currentAction: SchedulesActionType;
  accountIds: string[];
  setValues: FormikHelpers<ScheduleActionsStepInitialData>["setValues"];
  onFieldChange(key: string, value: any): void;
}

export const ScheduleActionTogglers: FC<ScheduleActionTogglersProps> = ({
  currentAction,
  resourceFilters,
  accountIds,
  onFieldChange,
  provider,
  setValues,
}) => {
  const resourcesVisibility = useResourcesVisibility({
    accountIds: accountIds.join(", "),
    provider,
  });

  const filterActionChangeHandler = useCallback(
    (resourceActions: Record<ResourceType, CleanActionTypes>) => {
      const resourcesNew = resourceFilters?.map((resource) => {
        const resourceType = resource.filter.type;
        const action = resourceActions[resourceType];

        if (action) {
          return {
            ...resource,
            action: action?.includes("delete") ? "terminate" : action,
            snapshot: action === "snapshot_and_delete",
          };
        }

        return resource;
      });

      onFieldChange("filter", resourcesNew);
    },
    [resourceFilters, onFieldChange],
  );

  const addActionToResources = useCallback(() => {
    if (!resourceFilters) {
      return;
    }

    const availableResources = resourceFilters.filter((resource) => {
      const resourceType = resource.filter.type;
      return Object.values(resourcesVisibility).some(
        (visibility) => visibility?.[resourceType]?.actions.delete,
      );
    });

    filterActionChangeHandler(
      availableResources.reduce(
        (acc, resource) =>
          ({ [resource.filter.type]: "delete", ...acc }) as Record<
            string,
            CleanActionTypes
          >,
        {} as Record<string, CleanActionTypes>,
      ),
    );
  }, [resourceFilters, filterActionChangeHandler, resourcesVisibility]);

  const actionChangeHandler = useCallback(
    (action: ScheduleActionType) => {
      onFieldChange("action", action);

      setValues((values) => {
        const newValues = { ...values, action };

        if (action === "notify") {
          newValues.grace_period = null;
        } else if (action === "clean") {
          newValues.emails = null;
          newValues.notifications = null;
        }

        return newValues;
      });

      if (action?.includes("clean") && !currentAction?.includes("clean")) {
        addActionToResources();
      }
    },
    [setValues, addActionToResources, onFieldChange, currentAction],
  );

  const cleanAndNotifyHandler = useCallback(() => {
    const action: SchedulesActionType = "clean_and_notify";

    setValues((values) => {
      const newValues: ScheduleActionsStepInitialData = { ...values, action };

      if (currentAction === action) {
        newValues.emails = null;
        newValues.action = "clean";
        onFieldChange("emails", null);
      }

      onFieldChange("action", newValues.action);

      return newValues;
    });
  }, [setValues, currentAction, onFieldChange]);

  return (
    <Fragment>
      <ActionsTypeSelector
        accountIds={accountIds}
        onChange={actionChangeHandler}
        action={currentAction}
      />

      {currentAction?.includes("clean") && (
        <Fragment>
          <ResourcesActions
            provider={provider}
            accountIds={accountIds}
            resourceFilters={resourceFilters}
            onFilterActionChange={filterActionChangeHandler}
          />

          <Divider />

          <ActionTypeSwitch
            onChange={cleanAndNotifyHandler}
            isChecked={!!currentAction?.includes("notify")}
            label="Enable Action Notifications"
            info="When enabled, Cloudchipr will send detailed list of resources after taking actions. "
          />
        </Fragment>
      )}
    </Fragment>
  );
};
