import { FC, Fragment } from "react";
import { Chip, Stack, Typography } from "@mui/material";

interface RenderEmailsProps {
  label: string;
  emails?: string[] | null;
}

export const RenderEmails: FC<RenderEmailsProps> = ({ emails, label }) => {
  if (!emails?.length) {
    return null;
  }

  return (
    <Fragment>
      <Typography variant="body2">{label}</Typography>

      <Stack direction="row" gap={1} flexWrap="wrap">
        {emails.map((email) => {
          return (
            <Chip key={email} label={email} size="small" variant="outlined" />
          );
        })}
      </Stack>
    </Fragment>
  );
};
