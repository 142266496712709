import { FC, Fragment, useCallback } from "react";
import { Stack } from "@mui/material";
import { LargestCostChangesPriceDirection } from "./app-bar/LargestCostChangesPriceDirection";
import { LargestCostChangesSortBy } from "./app-bar/LargestCostChangesSortBy";
import { LargestCostChangesSortByItemsCount } from "./app-bar/LargestCostChangesSortByItemsCount";
import { LargestCostChangesWidgetSetupContent } from "./content/LargestCostChangesWidgetSetupContent";
import { LargestCostChangesGroupingSelect } from "./app-bar/LargestCostChangesGroupingSelect";
import { LargestCostChangesMinChange } from "./app-bar/LargestCostChangesMinChange";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { sortedResourceExplorersSelector } from "../../../../../../../../store/resource-explorer/selectors/all-resource-explorers/sortedResourceExplorersSelector";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { WidgetSetupLayout } from "../common/WidgetSetupLayout";
import { WidgetViewSelect } from "../common/toolbar/WidgetViewSelect";
import { WidgetSetupDateRangeSelect } from "../common/toolbar/date-picker/WidgetSetupDateRangeSelect";
import { largestCostChangesWidgetViewSelectChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/largestCostChangesWidgetViewSelectChangeThunk";
import { largestCostChangesSetupChangeThunk } from "../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/largestCostChangesSetupChangeThunk";
import { buildingInProgressWidgetId } from "../../../../../../../../store/dashboards/thunks/widgets/utils/constants";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { WidgetSetupHeader } from "../common/WidgetSetupHeader";
import { buildLargestCostChangesWidgetThunk } from "../../../../../../../../store/dashboards/thunks/widgets/largest-cost-changes/buildLargestCostChangesWidgetThunk";
import { scrollPageToBottom } from "../../../../../../utils/helpers/page-scrolling-functions";
import { largesCostChangesSubmitButtonDisabledSelector } from "../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largesCostChangesSubmitButtonDisabledSelector";

interface LargestCostChangesSetupProps {
  onClose(): void;
  onSubmit(): void;
}
export const LargestCostChangesSetup: FC<LargestCostChangesSetupProps> = ({
  onClose,
  onSubmit,
}) => {
  const dispatch = useAppDispatch();

  const views = useAppSelector(sortedResourceExplorersSelector);
  const viewId = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("viewId"),
  );
  const setupDate = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("date"),
  );
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const setupId = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("id"),
  );
  const disabled = useAppSelector(
    largesCostChangesSubmitButtonDisabledSelector,
  );

  const dateChangeHandler = useCallback(
    (date: any) => {
      dispatch(largestCostChangesSetupChangeThunk({ date }));
    },
    [dispatch],
  );

  const viewChangeHandler = useCallback(
    (e: any) => {
      dispatch(largestCostChangesWidgetViewSelectChangeThunk(e.target.value));
    },
    [dispatch],
  );

  const submitHandler = useCallback(async () => {
    const responseId = await dispatch(
      buildLargestCostChangesWidgetThunk(),
    ).unwrap();
    onSubmit();

    if (responseId && !setupId) {
      scrollPageToBottom();
    }
  }, [dispatch, setupId, onSubmit]);

  return (
    <Fragment>
      <WidgetSetupHeader
        disabled={disabled}
        onAdd={submitHandler}
        onCancel={onClose}
      />

      <WidgetSetupLayout
        empty={!viewId ? "Select Resource Explorer View to get started." : ""}
        appBar={
          <Fragment>
            <WidgetViewSelect
              viewId={viewId}
              views={views}
              onChange={viewChangeHandler}
            />

            <LargestCostChangesGroupingSelect disabled={viewGettingLoading} />

            <LargestCostChangesPriceDirection />
            <WidgetSetupDateRangeSelect
              dates={setupDate}
              onChange={dateChangeHandler}
              disabled={viewGettingLoading}
            />
            <Stack direction="row" spacing={2}>
              <LargestCostChangesSortBy />
              <LargestCostChangesSortByItemsCount />
            </Stack>

            <LargestCostChangesMinChange type="minCostChange" />
            <LargestCostChangesMinChange type="minPercentageChange" />
          </Fragment>
        }
      >
        <LargestCostChangesWidgetSetupContent
          widgetId={buildingInProgressWidgetId}
        />
      </WidgetSetupLayout>
    </Fragment>
  );
};
