import { FC, Fragment, useCallback, useState } from "react";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DashboardV2ItemRenameDialog } from "./DashboardV2ItemRenameDialog";
import { DashboardV2ItemDeleteDialog } from "./DashboardV2ItemDeleteDialog";
import { DashboardV2ItemMoveAction } from "./move-action/DashboardV2ItemMoveAction";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";

interface DashboardV2ItemListItemActionMenuProps {
  id: string;
  name: string;
  open: boolean;
  closeMenu(): void;
  anchor: HTMLElement | null;
  folderId?: string;
}

export const DashboardV2ItemListItemActionMenu: FC<
  DashboardV2ItemListItemActionMenuProps
> = ({ name, id, open, anchor, closeMenu, folderId }) => {
  const [selectedActionType, setSelectedActionType] = useState<
    "delete" | "rename" | "move" | null
  >();

  const deleteClickHandler = useCallback(() => {
    setSelectedActionType("delete");
  }, []);

  const renameClickHandler = useCallback(() => {
    setSelectedActionType("rename");
  }, []);

  const closeDialog = useCallback(() => {
    closeMenu();
    setSelectedActionType(null);
  }, [closeMenu]);

  return (
    <Fragment>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchor}
        {...navigationHierarchyItemNoDndKey}
      >
        <MenuItem onClick={renameClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <Typography variant="body2">Rename</Typography>
        </MenuItem>

        <DashboardV2ItemMoveAction
          id={id}
          folderId={folderId}
          onClose={closeMenu}
        />

        <MenuItem onClick={deleteClickHandler} sx={menuItemSx}>
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" sx={iconSx} />
          </ListItemIcon>
          <Typography variant="body2">Delete</Typography>
        </MenuItem>
      </Menu>

      {selectedActionType === "delete" && (
        <DashboardV2ItemDeleteDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
        />
      )}

      {selectedActionType === "rename" && (
        <DashboardV2ItemRenameDialog
          open
          id={id}
          name={name}
          closeMenu={closeDialog}
        />
      )}
    </Fragment>
  );
};

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};

const iconSx = {
  minWidth: 28,
};
