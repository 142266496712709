import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchCostAndUsageSumWidgetDataBySetupThunk } from "./fetchCostAndUsageSumWidgetDataBySetupThunk";
import { RootState } from "../../../../store";
import { setWidgetSetup } from "../../../dashboardsSlice";
import { costAndUsageSumWidgetByIdSelector } from "../../../selectors/widgets/cost-and-usage/costAndUsageSumWidgetByIdSelector";

export const editCostAndUsageSumWidgetThunk = createAsyncThunk(
  "dashboards/editCostAndUsageSumWidgetThunk",
  (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;
    const widget = costAndUsageSumWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    dispatch(
      setWidgetSetup({
        widgetType: "cost_and_usage_summary",
        id: widget.id,
        name: widget.name,
        grouping: widget.grouping,
        group_values: widget.group_values,
        date: {
          from: widget.date_from,
          to: widget.date_to,
          label: widget.date_label ?? null,
        },
        viewId: widget.view_id,
        filter: widget.filter ?? [],
      }),
    );

    dispatch(fetchCostAndUsageSumWidgetDataBySetupThunk());
  },
);
