import { costBreakdownV2WidgetNotFoundSelector } from "./costBreakdownV2WidgetNotFoundSelector";
import { costBreakdownV2WidgetByIdSelector } from "./costBreakdownV2WidgetByIdSelector";
import { RootState } from "../../../../../store";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { resourceExplorerCloudProvidersByViewIdSelector } from "../../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerCloudProvidersByViewIdSelector";
import { getResourcesExplorerGroupingLabel } from "../../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/helpers/getResourcesExplorerGroupingLabel";
import { WidgetTitleProps } from "../../../../../../components/pages/dashboard/components/widgets/common/widget-header/source-and-name/WidgetSourceInfo";
import { objectToUrl } from "../../../../../../components/utils/helpers/dataToUrlToDataConvertors";

export const costBreakdownV2WidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = costBreakdownV2WidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id,
  );

  const viewProviders =
    resourceExplorerCloudProvidersByViewIdSelector(state, widget.view_id) ?? [];

  const grouping = getResourcesExplorerGroupingLabel(
    viewProviders,
    widget.grouping,
    true,
  );

  const viewNotFound = costBreakdownV2WidgetNotFoundSelector(state, widgetId);
  const path = objectToUrl([
    {
      groupedBy: widget.grouping,
      groupValues: widget.group_values,
      forecastOption: widget.forecast_option,
      dates: widget.dates,
      dateGranularity: widget.date_granularity,
      chartType: widget.chart_type,
      includedFilters: {},
    },
  ]);

  return {
    grouping,
    widgetName: widget.name,
    dates: widget.dates ?? {},
    sourceData: viewNotFound
      ? undefined
      : {
          icon: "pie",
          title: viewName ?? "",
          menu: [
            {
              title: "Open in Resource Explorer",
              url: `/resource-explorer/${widget.view_id}?path=${path}`,
            },
          ],
        },
  };
};
