import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { DimensionsState } from "../utils/types/common";
import {
  AwsFilter,
  ProviderType,
  ResourceExplorerAccount,
  ResourceExplorerTag,
} from "../../../services/cloudchipr.api";
import { filterProviderByProvider } from "../../../components/pages/dimensions/utils/constants/common";

export const setCategoryFilterGroupFilterItemByIndexReducer: CaseReducer<
  DimensionsState,
  PayloadAction<{
    id: string;
    index: number;
    provider: ProviderType;
    accountFilter?: ResourceExplorerAccount;
    tagFilter?: ResourceExplorerTag;
  }>
> = (state, action) => {
  const { id, provider, index, accountFilter, tagFilter } = action.payload;

  const filterProvider = filterProviderByProvider[provider];
  const filters = state.categories[id].filters[
    filterProvider
  ] as Required<AwsFilter>;

  if (accountFilter) {
    filters.accounts.items[index] = accountFilter;
  } else if (tagFilter) {
    filters.tags.items[index] = tagFilter;
  }
};
