import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useParams } from "react-router-dom";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { updateDashboardByIdLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard/loadings/updateDashboardByIdLoadingSelector";
import { updateDashboardHierarchyItemNameThunk } from "../../../../../../store/dashboards/thunks/dashboard-hierarchy/updateDashboardHierarchyItemNameThunk";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";

interface DashboardV2ItemRenameDialogProps {
  open: boolean;
  id: string;
  name: string;
  closeMenu(): void;
}

export const DashboardV2ItemRenameDialog: FC<
  DashboardV2ItemRenameDialogProps
> = ({ open, closeMenu, id, name }) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(name);

  const { dashboardId: activeDashboardId } = useParams<{
    dashboardId: string;
  }>();

  const loading = useAppSelector(updateDashboardByIdLoadingSelector);

  const renameHandler = useCallback(async () => {
    await dispatch(
      updateDashboardHierarchyItemNameThunk({
        dashboardId: id,
        name: value,
        activeDashboardId,
      }),
    );
    closeMenu();
  }, [dispatch, closeMenu, id, value, activeDashboardId]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [],
  );

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closeMenu}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable title="Rename Dashboard" onClose={closeMenu} />

      <DialogContent dividers sx={{ py: 3 }}>
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={value}
          size="small"
          variant="outlined"
          onChange={inputChangeHandler}
          placeholder="Dashboard Name"
          label="Dashboard Name"
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeMenu}>
          Cancel
        </Button>
        <LoadingButton
          disabled={value === name || !value}
          onClick={renameHandler}
          loading={loading}
          variant="contained"
        >
          Rename
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
