import { createAsyncThunk } from "@reduxjs/toolkit";
import { ResourceExplorerGrouping } from "../../../../services/cloudchipr.api";
import {
  setFilteredByLiveUsages,
  setResourceExplorerGrouping,
  setResourceExplorerSearch,
} from "../../resourceExplorerSlice";
import { setResourceExplorerPathThunk } from "../widgets/path/setResourceExplorerPathThunk";
import { getResourceExplorerDataThunk } from "../widgets/getResourceExplorerDataThunk";

export const resourceExplorerGroupByChangeThunk = createAsyncThunk(
  "resourceExplorer/resourceExplorerGroupByChange",
  async (
    {
      grouping,
      groupValues,
    }: { grouping: ResourceExplorerGrouping; groupValues?: string[] },
    { dispatch },
  ) => {
    dispatch(setResourceExplorerGrouping({ grouping, groupValues }));
    dispatch(setResourceExplorerPathThunk(false));
    dispatch(getResourceExplorerDataThunk());
    dispatch(setResourceExplorerSearch(""));
    dispatch(setFilteredByLiveUsages(false));
  },
);
