import { FC, useCallback, useMemo } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import AvTimerIcon from "@mui/icons-material/AvTimer";
import { useAppAbility } from "../../../../../../../../../../../../services/permissions";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../store/hooks";
import { currentAccountWorkflowDataSelector } from "../../../../../../../../../../../../store/account/selectors/current-account/currentAccountWorkflowDataSelector";
import { useCreateWorkflow } from "../../../../../../../../../utils/hooks/useCreateWorkflow.hook";
import { setWorkflowCreationDrawerOpen } from "../../../../../../../../../../../../store/schedules/schedulesSlice";
import { ResourceType } from "../../../../../../../../../../../../services/cloudchipr.api";
import { InitiateWorkflowThunkArgs } from "../../../../../../../../../../../../store/schedules/thunks/initiateWorkflowThunk";
import { getFirstParentInHierarchyResourceType } from "../../../../../../../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";

interface CreateWorkflowMenuItemProps {
  uniqueIdentifier: string;
  resourceType: ResourceType;
  onClose(): void;
}

export const CreateWorkflowMenuItem: FC<CreateWorkflowMenuItemProps> = ({
  onClose,
  resourceType,
  uniqueIdentifier,
}) => {
  const dispatch = useAppDispatch();

  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");

  const currentAccountWorkflowData = useAppSelector((state) =>
    currentAccountWorkflowDataSelector(state, resourceType),
  );

  const data = useMemo(() => {
    if (!currentAccountWorkflowData) {
      return null;
    }

    const filters = [
      {
        filter: {
          type:
            getFirstParentInHierarchyResourceType(resourceType) ?? resourceType,
          filter_groups: [0],
          operators: ["AND"],
          filter_items: [
            {
              type: resourceType,
              filter: {
                key: "provider_unique_identifier",
                operator: "in",
                value: [uniqueIdentifier],
              },
            },
          ],
        },
      },
    ];

    return {
      ...currentAccountWorkflowData,
      filters,
      resourceType,
    } as InitiateWorkflowThunkArgs;
  }, [resourceType, uniqueIdentifier, currentAccountWorkflowData]);

  const createWorkflow = useCreateWorkflow(data);

  const createHandler = useCallback(() => {
    createWorkflow();
    dispatch(setWorkflowCreationDrawerOpen(true));
    onClose();
  }, [dispatch, onClose, createWorkflow]);

  return (
    <MenuItem dense onClick={createHandler} disabled={canNotCreateSchedule}>
      <ListItemIcon>
        <AvTimerIcon fontSize="small" />
      </ListItemIcon>

      <ListItemText>Schedule a Workflow</ListItemText>
    </MenuItem>
  );
};
