import { Row } from "@tanstack/react-table";
import { OffHoursSchedule2 } from "../../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../../utils/helpers/date-time/datetime-format";
import { getOffHoursNextRun } from "../helpers";

export const offHoursNextRunFilter = (
  row: Row<OffHoursSchedule2>,
  _: string,
  keyword: string,
) => {
  const schedule = row.original;
  const status = schedule.status;
  const filterValue = keyword.toLowerCase().trim();
  const { nextRun, type } = getOffHoursNextRun(
    schedule.metadata.next_stop_run_at,
    schedule.metadata.next_start_run_at,
    schedule.status,
  );

  if (status === "inactive") {
    return false;
  }

  if (!nextRun) {
    return "never".includes(filterValue);
  }

  const formattedNextRunText = `${type && `${type.toUpperCase()} | `}
  ${formatDate(nextRun, {
    type: "date",
  })}  
  ${formatDate(nextRun, {
    type: "time",
  })}`.toLowerCase();

  return formattedNextRunText.includes(filterValue);
};
