import { FC, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import { DashboardCreateFolderOrItemDialogContent } from "./DashboardCreateFolderOrItemDialogContent";
import { DashboardCreateFolderOrItemDialogActions } from "./DashboardCreateFolderOrItemDialogActions";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { NavigationHierarchyItemType } from "../../../../../../store/navigation/utils/types";

interface DashboardCreateFolderOrItemDialogProps {
  onClose(): void;
  type: NavigationHierarchyItemType;
  folderId?: string;
}

export const DashboardCreateFolderOrItemDialog: FC<
  DashboardCreateFolderOrItemDialogProps
> = ({ onClose, type, folderId }) => {
  const [dashboardName, setDashboardName] = useState<string>("");

  const closeDialogHandler = useCallback(() => {
    onClose();
    setDashboardName("");
  }, [onClose]);

  const nameChangeHandler = useCallback((event: any) => {
    setDashboardName(event.target.value);
  }, []);

  return (
    <Dialog open={!!type} onClose={closeDialogHandler} maxWidth="sm" fullWidth>
      <DialogTitleClosable
        title={type === "item" ? "Add Dashboard" : "Add Folder"}
        onClose={closeDialogHandler}
      />

      <DialogContent
        sx={{
          pb: 3,
        }}
        dividers
      >
        <DashboardCreateFolderOrItemDialogContent
          value={dashboardName}
          onTextFieldChange={nameChangeHandler}
          label={type === "item" ? "Dashboard Name" : "Folder Name"}
        />
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <DashboardCreateFolderOrItemDialogActions
          onClose={closeDialogHandler}
          name={dashboardName}
          type={type}
          folderId={folderId}
        />
      </DialogActions>
    </Dialog>
  );
};
