import { FC, Fragment } from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ResourceExplorerV2ViewListItemActionsMenu } from "./ResourceExplorerV2ViewListItemActionsMenu";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";
import { useAppSelector } from "../../../../../../store/hooks";
import { resourceExplorerIsDefaultSelector } from "../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerIsDefaultSelector";

interface ResourceExplorerV2ViewActionButtonProps {
  id: string;
  name: string;
  folderId?: string;
}

export const ResourceExplorerV2ViewActionButton: FC<
  ResourceExplorerV2ViewActionButtonProps
> = ({ id, name, folderId }) => {
  const { cannot } = useAppAbility();
  const cannotDeleteResourceExplorer = cannot("delete", "resource-explorer");
  const isViewDefault = useAppSelector(resourceExplorerIsDefaultSelector);

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (cannotDeleteResourceExplorer || isViewDefault) {
    return null;
  }

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <ResourceExplorerV2ViewListItemActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        folderId={folderId}
      />
    </Fragment>
  );
};
