import { FC, useCallback, useMemo } from "react";
import { Button, Stack, PopoverProps } from "@mui/material";
import { DropdownSelect } from "../../../../../../common/select/dropdown-select/DropdownSelect";
import { FilterTriggerComponentProps } from "../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { AccountOptionLabel } from "../../../../../../common/account-select/components/AccountOptionLabel";
import { setCategoryFilters } from "../../../../../../../store/dimensions/dimensionsSlice";
import { accountsForCategoriesByIdsSelector } from "../../../../../../../store/dimensions/selectors/accounts/accountsForCategoriesByIdsSelector";
import { selectedAccountsInAllCategoriesSelector } from "../../../../../../../store/dimensions/selectors/accounts/selectedAccountsInAllCategoriesSelector";
import { generateCategoryFiltersFromAccountIds } from "../../../../utils/helpers/generateCategoryFiltersFromAccountIds";
import { DropdownSelectOption } from "../../../../../../common/select/dropdown-select/utils/types/types";
import { categoryAccountsFilterFn } from "../../../../utils/helpers/categoryAccountsFilterFn";

interface CategoryAccountsSelectProps {
  id: string;
  selectedAccounts: string[];
  onAccountsChange?(ids: string[]): void;
}

export const CategoryAccountsSelect: FC<CategoryAccountsSelectProps> = ({
  selectedAccounts,
  id,
  onAccountsChange,
}) => {
  const dispatch = useAppDispatch();

  const accountsById = useAppSelector(accountsForCategoriesByIdsSelector);
  const selectedAllAccountsIds = useAppSelector(
    selectedAccountsInAllCategoriesSelector,
  );

  const disabledAccountIds = useMemo(() => {
    const disabledIds = { ...selectedAllAccountsIds };
    selectedAccounts.forEach((id) => (disabledIds[id] = ""));

    return disabledIds;
  }, [selectedAllAccountsIds, selectedAccounts]);

  const renderOptions = useMemo(() => {
    if (!accountsById) {
      return null;
    }

    return Object.values(accountsById).map((account) => {
      const disabledReason = {
        reason: `This account is already in ${
          disabledAccountIds[account.id]
        } category.`,
        type: "info",
      };

      const disabled = disabledAccountIds[account.id] ? disabledReason : false;

      return {
        disabled,
        value: account.id,
        rawValue: account,
        listItemHeight: 59,
        label: (
          <AccountOptionLabel
            option={{
              value: account.id,
              label:
                account.provider_account_name || account.provider_account_id,
              providerAccountId: account.provider_account_id,
              accountType: account.provider,
              accessType: account.provider_access_type,
            }}
          />
        ),
      } as DropdownSelectOption;
    });
  }, [accountsById, disabledAccountIds]);

  const changeHandler = useCallback(
    (selectedIds: string[]) => {
      if (!accountsById) {
        return;
      }

      const filters = generateCategoryFiltersFromAccountIds(
        selectedIds,
        accountsById,
      );

      onAccountsChange?.(selectedIds);
      dispatch(setCategoryFilters({ id, filters }));
    },
    [dispatch, id, onAccountsChange, accountsById],
  );

  if (!renderOptions) {
    return null;
  }

  return (
    <Stack alignItems="center" my={1}>
      <DropdownSelect
        listWidth={468}
        submitHandlerOnClose={changeHandler}
        options={renderOptions}
        filterFn={categoryAccountsFilterFn}
        label="Select the Cloud Account/s*"
        initialSelectedValues={selectedAccounts}
        wrapperVariant="popover"
        TriggerComponent={TriggerComponent}
        PopoverProps={popoverProps}
      />
    </Stack>
  );
};

export const TriggerComponent: FC<FilterTriggerComponentProps> = ({
  onClick,
}) => <Button onClick={onClick}>Manage Accounts</Button>;

const popoverProps: Partial<PopoverProps> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
};
