import { Row } from "@tanstack/react-table";
import {
  OffHoursSchedule2,
  Schedule2,
} from "../../../../../../services/cloudchipr.api";

export const schedulesAccountFilter = <T extends Schedule2 | OffHoursSchedule2>(
  row: Row<T>,
  _: string,
  keyword: string,
) => {
  const accounts = row.original.accounts;
  const aim = accounts
    .filter(({ status }) => status === "connected" || status === undefined)
    .map(
      (account) =>
        `${account.provider_account_id} ${account.provider_account_name} ${account.provider}`,
    )
    .join(" ")
    .toLowerCase();

  return `${aim}`.includes(keyword.toLowerCase().trim());
};
