import { FC } from "react";
import { Chip, Stack, Typography } from "@mui/material";
import { common } from "@mui/material/colors";
import { ResourceType } from "../../../../../../../services/cloudchipr.api";
import { getResourceTypeName } from "../../../../../../../utils/helpers/resources/getResourceTypeName";
import { betaResourceTypes } from "../../resource-card-content/resource-card-data/utils/constants";
interface ResourceCardHeaderTitleProps {
  resourceType: ResourceType;
}

export const ResourceCardHeaderTitle: FC<ResourceCardHeaderTitleProps> = ({
  resourceType,
}) => {
  const shortTitle = (
    <Stack>
      <Typography color="grey.50" variant="subtitle1" fontWeight="bold">
        {getResourceTypeName(resourceType)}
      </Typography>
    </Stack>
  );
  const longTitle = (
    <Typography variant="caption" fontWeight="bold" color="grey.300">
      {getResourceTypeName(resourceType, {
        type: "long",
      })}
    </Typography>
  );

  if (betaResourceTypes.has(resourceType)) {
    return (
      <Stack>
        <Stack direction="row" gap={1} alignItems="center">
          {shortTitle}{" "}
          <Chip
            variant="outlined"
            label="Beta"
            size="small"
            sx={{ color: common.white, borderColor: common.white }}
          />
        </Stack>
        {longTitle}
      </Stack>
    );
  }

  return (
    <Stack>
      {shortTitle}
      {longTitle}
    </Stack>
  );
};
