import { FC, useCallback } from "react";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { DashboardBreadcrumbNavigationTrigger } from "./DashboardBreadcrumbNavigationTrigger";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentDashboardFolderSelector } from "../../../../../../store/dashboards/selectors/dashboard-hierarchy/currentDashboardFolderSelector";
import { currentDashboardNameSelector } from "../../../../../../store/dashboards/selectors/dashboard/currentDashboardNameSelector";
import { DashboardV2ItemActionButton } from "../../../../../navigation/components/dashboard-v2/components/item-action-menu/DashboardV2ItemActionButton";
import { currentDashboardIdSelector } from "../../../../../../store/dashboards/selectors/dashboard/currentDashboardIdSelector";
import { dashboardHierarchyDataSelector } from "../../../../../../store/dashboards/selectors/dashboard-hierarchy/dashboardHierarchyDataSelector";
import { HierarchicalBreadcrumbs } from "../../../../common/breadcrumbs/hierarchical-breadcrumbs/HierarchicalBreadcrumbs";

interface DashboardBreadcrumbsProps {
  loading: boolean;
}

export const DashboardAppBarNavigation: FC<DashboardBreadcrumbsProps> = ({
  loading,
}) => {
  const navigate = useNavigate();
  const name = useAppSelector(currentDashboardNameSelector);
  const currentFolder = useAppSelector(currentDashboardFolderSelector);
  const dashboardId = useAppSelector(currentDashboardIdSelector);
  const hierarchy = useAppSelector(dashboardHierarchyDataSelector);

  const changeNavigationHandler = useCallback(
    (id: string) => {
      navigate(`/dashboards/${id}`);
    },
    [navigate],
  );

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <HierarchicalBreadcrumbs
        label={name ?? ""}
        folderName={currentFolder?.name}
        hierarchy={hierarchy}
        selectedItemId={dashboardId ?? ""}
        onChange={changeNavigationHandler}
        ItemIcon={GridViewOutlinedIcon}
        loading={!name || loading}
        CustomTriggerComponent={DashboardBreadcrumbNavigationTrigger}
      />

      <DashboardV2ItemActionButton
        id={dashboardId ?? ""}
        name={name ?? ""}
        folderId={currentFolder?.id}
      />
    </Stack>
  );
};
