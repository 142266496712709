import { FC, Fragment, ReactNode, useCallback } from "react";
import {
  List,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import PieChartOutlineIcon from "@mui/icons-material/PieChartOutline";
import ListIcon from "@mui/icons-material/List";
import LaunchIcon from "@mui/icons-material/Launch";
import { useMenuHook } from "../../../../../../../../utils/hooks/useMenu.hook";

export type SourceUrlMenuDataType = {
  title: ReactNode;
  url: string;
};

export interface SourceDataChipProps {
  icon: "list" | "pie";
  title: string;
  menu: SourceUrlMenuDataType[];
}

export const SourceDataChip: FC<SourceDataChipProps> = ({
  icon,
  title,
  menu,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const clickHandler = useCallback(
    (url: string) => () => window.open(url, "_blank"),
    [],
  );

  return (
    <Fragment>
      <Stack
        px={0.5}
        spacing={0.5}
        direction="row"
        borderRadius={1}
        onClick={openMenu}
        alignItems="center"
        whiteSpace="nowrap"
        sx={{ cursor: "pointer" }}
        bgcolor={(theme) => theme.palette.primary.light}
      >
        {icons[icon]}

        <Typography
          variant="body2"
          color="primary"
          sx={{ textDecoration: "underline" }}
        >
          {title}
        </Typography>
      </Stack>

      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        PaperProps={{ sx: { p: 0 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List>
          {menu.map((menuItem) => {
            return (
              <MenuItem key={menuItem.url} onClick={clickHandler(menuItem.url)}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: "body2",
                    color: "text.secondary",
                  }}
                >
                  {menuItem.title}
                </ListItemText>

                <LaunchIcon sx={{ fontSize: 16, ml: 1 }} color="info" />
              </MenuItem>
            );
          })}
        </List>
      </Popover>
    </Fragment>
  );
};

const icons = {
  pie: <PieChartOutlineIcon sx={{ fontSize: 16 }} color="primary" />,
  list: <ListIcon color="primary" sx={{ fontSize: 16 }} />,
};
