import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { CategoryDrawerHeader } from "./CategoryDrawerHeader";
import { CategoryName } from "./CategoryName";
import { EditingCategory } from "./EditingCategory";
import { CategoryFormActions } from "./CategoryFormActions";
import { CategoryAccountsCount } from "./CategoryAccountsCount";

interface CategoryDrawerFormProps {
  onClose(): void;
}

export const CategoryDrawerForm: FC<CategoryDrawerFormProps> = ({
  onClose,
}) => {
  return (
    <Fragment>
      <CategoryDrawerHeader onClose={onClose} />

      <Stack spacing={2} p={2} pb={0}>
        <CategoryName />

        <CategoryAccountsCount />

        <EditingCategory />
      </Stack>

      <CategoryFormActions onClose={onClose} />
    </Fragment>
  );
};
