import { FC } from "react";
import { Skeleton, Stack, Typography } from "@mui/material";
import { TooltipText } from "../../../../../common/TooltipText";

interface UtilizationAndCoverageMetadataCardProps {
  primary: string;
  value: string;
  loading: boolean;
  valueColor?: string;
  cardBg?: string;
  tooltip: string;
}

export const UtilizationAndCoverageMetadataCard: FC<
  UtilizationAndCoverageMetadataCardProps
> = ({ value, valueColor, loading, primary, cardBg, tooltip }) => {
  return (
    <Stack
      py={1}
      flexGrow={1}
      borderRadius={1}
      alignItems="center"
      justifyContent="center"
      bgcolor={cardBg ?? "grey.100"}
    >
      <TooltipText
        tooltipTitle={tooltip}
        variant="body2"
        fontWeight="medium"
        color="text.secondary"
        tooltipPlacement="top"
      >
        {primary}
      </TooltipText>

      <Typography mt={0.5} variant="h5" fontWeight="medium" color={valueColor}>
        {loading ? loadingSkeleton : value}
      </Typography>
    </Stack>
  );
};

const loadingSkeleton = <Skeleton variant="text" height={32} width={150} />;
