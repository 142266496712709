import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { getResourceExplorerViewsHierarchyThunk } from "./getResourceExplorerViewsHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const deleteResourceExplorerViewThunkFixedCacheKey =
  "deleteResourceExplorerView-fixedCacheKey";

export const deleteResourceExplorerViewThunk = createAsyncThunk(
  "resourceExplorer/deleteResourceExplorerView",
  async (id: string, { dispatch }) => {
    const {
      deleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
    } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          {
            resourceExplorerViewId: id,
          },
          {
            fixedCacheKey: deleteResourceExplorerViewThunkFixedCacheKey,
          },
        ),
      ).unwrap();

      dispatch(getResourceExplorerViewsHierarchyThunk());

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "View successfully deleted.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
