import { organizationsForCommitmentsSelector } from "./organizationsForCommitmentsSelector";
import { RootState } from "../../../store";

export const organizationsForCommitmentsFetchedSelector = (
  state: RootState,
) => {
  const orgs = organizationsForCommitmentsSelector(state);

  return !!orgs;
};
