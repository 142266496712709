import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { CategoryFilterType, DimensionsState } from "../utils/types/common";
import {
  emptyAccountsFilter,
  emptyTagsFilter,
} from "../utils/constants/common";
import { ProviderType } from "../../../services/cloudchipr.api";
import { filterProviderByProvider } from "../../../components/pages/dimensions/utils/constants/common";
import { isFilterWithTags } from "../../../components/pages/dimensions/components/form-drawer/components/dimension/categories/based-on-filters/utils/helpers/typeCheckers";

export const addNewFilterItemInCategoryByProviderReducer: CaseReducer<
  DimensionsState,
  PayloadAction<{
    id: string;
    provider: ProviderType;
    type: CategoryFilterType;
  }>
> = (state, action) => {
  const { id, provider, type } = action.payload;
  const filterProvider = filterProviderByProvider[provider];

  const providerFilters = state.categories[id].filters[filterProvider];

  if (type === "account") {
    const accountFilters =
      providerFilters?.accounts ?? emptyAccountsFilter.accounts;

    state.categories[id].filters[filterProvider] = {
      ...providerFilters,
      accounts: {
        ...accountFilters,
        items: [...accountFilters.items, { operator: "contains", value: "" }],
      },
    };
  } else {
    const tagFilters =
      (providerFilters &&
        isFilterWithTags(providerFilters) &&
        providerFilters?.tags) ||
      emptyTagsFilter.tags;

    state.categories[id].filters[filterProvider] = {
      ...providerFilters,
      tags: {
        ...tagFilters,
        items: [
          ...tagFilters.items,
          { operator: "contains", tag_key: "", tag_values: "" },
        ],
      },
    };
  }
};
