import { ResourceExplorerGridDataWithId } from "../../../../../../../resource-explorer/utils/types/resourceExplorerDataGrid";

export const formatCostBreakdownWidgetTableViewLastGridItem = (
  data: ResourceExplorerGridDataWithId[],
) => {
  const mutatedData = data.reduce(
    (result, item) => {
      result.total_cost += item.total_cost ?? 0;
      result.previous_period_total_cost +=
        item?.previous_period_total_cost ?? 0;
      result.trend += item.trend ?? 0;
      return result;
    },
    {
      total_cost: 0,
      previous_period_total_cost: 0,
      trend: 0,
    } as ResourceExplorerGridDataWithId,
  );

  const dataLength = data.length > 0 ? ` (${data?.length})` : "";

  return {
    ...mutatedData,
    field: `Others${dataLength}`,
    id: "Others",
  };
};
