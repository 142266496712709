import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { selectedAccountsInAllCategoriesSelector } from "./selectedAccountsInAllCategoriesSelector";
import { accountsForCategoriesByIdsSelector } from "./accountsForCategoriesByIdsSelector";
import { calculateAccountsCountByProvider } from "../../../../components/pages/dimensions/utils/helpers/calculateAccountsCountByProvider";

export const selectedAccountsCountInAllCategoriesSelector =
  createDraftSafeSelector(
    [
      selectedAccountsInAllCategoriesSelector,
      accountsForCategoriesByIdsSelector,
    ],
    (accountsIds, allAccounts) => {
      return calculateAccountsCountByProvider(
        Object.keys(accountsIds),
        allAccounts,
      );
    },
  );
