import { CircularProgress, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FilterTemplateDropdownItem } from "./FilterTemplateDropdownItem";
import { useAppAbility } from "../../../../../../services/permissions";
import {
  AccountFilterSetV2,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";

interface FilterTemplateDropdownListProps {
  onApplyFilterTemplate(filterSetId: string): void;
  appliedFilterSetId: string;
  filterTemplates: AccountFilterSetV2[];
  loadingByFilterSetId?: Record<string, boolean> | null;
  loading?: boolean;
  provider: ProviderType | null;
}
export const FilterTemplateDropdownList: FC<
  FilterTemplateDropdownListProps
> = ({
  onApplyFilterTemplate,
  appliedFilterSetId,
  filterTemplates,
  loadingByFilterSetId,
  loading,
  provider,
}) => {
  const { cannot } = useAppAbility();

  const cannotApplyFilterSet = cannot("apply", "filter-set");

  if (loading) {
    return (
      <Stack alignItems="center">
        <CircularProgress
          disableShrink
          size={24}
          sx={{
            color: "text.disabled",
          }}
        />
      </Stack>
    );
  }
  if (!filterTemplates?.length) {
    return (
      <Typography px={2} color="text.secondary">
        No Data
      </Typography>
    );
  }

  return (
    <Stack px={3} spacing={3}>
      {filterTemplates.map((item) => {
        const applied = item.filter_set.id === appliedFilterSetId;
        return (
          <FilterTemplateDropdownItem
            key={item.filter_set.id}
            filterSet={item.filter_set}
            provider={provider}
            ems={item.ems}
            onApply={onApplyFilterTemplate}
            applied={applied}
            disabled={applied || cannotApplyFilterSet}
            loading={!!loadingByFilterSetId?.[item.filter_set.id]}
          />
        );
      })}
    </Stack>
  );
};
