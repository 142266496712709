import { EmbeddedAccount } from "../../../../../services/cloudchipr.api";

export const calculateAccountsCountByProvider = (
  ids?: string[],
  accounts?: Record<string, EmbeddedAccount>,
) => {
  if (!accounts || !ids) {
    return initialState;
  }

  return ids.reduce(
    (acc, id) => {
      const account = accounts[id];

      if (!account) {
        return acc;
      }

      const provider = account.provider;

      acc[provider] += 1;

      return acc;
    },
    { ...initialState },
  );
};

const initialState = {
  aws: 0,
  gcp: 0,
  azure: 0,
};
