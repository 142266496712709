import { dashboardHierarchyDataSelector } from "./dashboardHierarchyDataSelector";
import { RootState } from "../../../store";
import { isDashboardFolder } from "../../../../components/pages/dashboard/utils/helpers/isDashboardFolder";
import { DashboardFolder } from "../../../../services/cloudchipr.api";

export const dashboardHierarchyFoldersSelector = (
  state: RootState,
): DashboardFolder[] | undefined => {
  const hierarchy = dashboardHierarchyDataSelector(state);

  if (!hierarchy) {
    return;
  }
  return hierarchy.reduce((result, item) => {
    if (isDashboardFolder(item)) {
      result.push(item);
    }
    return result;
  }, [] as DashboardFolder[]);
};
