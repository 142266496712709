import { FC } from "react";
import { Card, Typography } from "@mui/material";

interface EmptyDataGridFooterProps {
  name: string;
}

export const EmptyDataGridFooter: FC<EmptyDataGridFooterProps> = ({ name }) => {
  return (
    <Card
      variant="outlined"
      sx={{
        p: 1,
        borderTop: "none",
        textAlign: "center",
        borderRadius: "0 0 8px 8px",
      }}
    >
      <Typography variant="body2">No {name} found</Typography>
    </Card>
  );
};
