import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { addNewCategory } from "../../../../../../../../store/dimensions/dimensionsSlice";
import { categoriesCreationDataIsInvalidSelector } from "../../../../../../../../store/dimensions/selectors/options/categories/categoriesCreationDataIsInvalidSelector";
import { categoryFilterTypeSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/categoryFilterTypeSelector";
import { generateEmptyCategory } from "../../../../../utils/helpers/generateEmptyCategory";

export const AddCategoryButton: FC = () => {
  const dispatch = useAppDispatch();
  const disabled = useAppSelector(categoriesCreationDataIsInvalidSelector);
  const filterType = useAppSelector(categoryFilterTypeSelector);

  const addCategoryHandler = useCallback(() => {
    dispatch(addNewCategory(generateEmptyCategory(filterType)));
  }, [dispatch, filterType]);

  return (
    <Button
      sx={{ alignSelf: "start", textTransform: "none" }}
      startIcon={<AddIcon />}
      onClick={addCategoryHandler}
      disabled={disabled}
    >
      Add Category
    </Button>
  );
};
