import { FC } from "react";
import { useAppSelector } from "../../../../../../../store/hooks";
import { editingCategorySelector } from "../../../../../../../store/dimensions/selectors/slice-data/editing-category/editingCategorySelector";
import { CategoryBasedOnAccounts } from "../dimension/categories/based-on-accounts/CategoryBasedOnAccounts";
import { CategoryBasedOnFilters } from "../dimension/categories/based-on-filters/CategoryBasedOnFilters";

export const EditingCategory: FC = () => {
  const editingCategory = useAppSelector(editingCategorySelector);

  if (!editingCategory?.id) {
    return null;
  }

  if (editingCategory.type === "account_based") {
    return <CategoryBasedOnAccounts category={editingCategory} />;
  }

  if (editingCategory.type === "filter_based") {
    return <CategoryBasedOnFilters category={editingCategory} />;
  }

  return null;
};
