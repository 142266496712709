import { Stack } from "@mui/material";
import { UtilizationAndCoverageDataViewTabs } from "./UtilizationAndCoverageDataViewTabs";
import { CoverageDataGridGrouping } from "./CoverageDataGridGrouping";
import { UtilizationAndCoverageCsvDownloader } from "./UtilizationAndCoverageCSVDownloader";
import { GlobalFilter } from "../../../../../../../../storybook/data-grid/toolbar/GlobalFilter";
import { RenderToolbar } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { utilizationAndCoverageDataViewTabSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/view-options/utilizationAndCoverageDataViewTabSelector";

export const UtilizationAndCoverageDataGridToolbar: RenderToolbar<unknown> = ({
  setGlobalFilter,
  globalFilter,
}) => {
  const tab = useAppSelector(utilizationAndCoverageDataViewTabSelector);

  return (
    <Stack
      pb={1.5}
      direction="row"
      alignItems="start"
      justifyContent="space-between"
    >
      <Stack spacing={1.5}>
        <UtilizationAndCoverageDataViewTabs />

        {tab === "coverage" && <CoverageDataGridGrouping />}
      </Stack>

      <Stack alignItems="center" direction="row" spacing={2}>
        <GlobalFilter
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          size="small"
        />

        <UtilizationAndCoverageCsvDownloader />
      </Stack>
    </Stack>
  );
};
