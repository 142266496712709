import { FC, useCallback } from "react";
import {
  Card,
  CardHeader,
  Collapse,
  Dialog,
  IconButton,
  Theme,
} from "@mui/material";
import { useToggle } from "rooks";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { ResourceCardHeader } from "./resource-card-header/ResourceCardHeader";
import { ResourceCardGrid } from "./resource-card-content/resource-card-data/resource-card-grid/ResourceCardGrid";
import { ResourceCardHeaderTitle } from "./resource-card-header/components/ResourceCardHeaderTitle";
import { ResourceType } from "../../../../../services/cloudchipr.api";
import { useResourceCardCollapsedState } from "../../utils/hooks/useResourceCardState.hook";

interface ResourceCardProps {
  resourceType: ResourceType;
}

export const generateResourceCardId = (resourceType: ResourceType) =>
  `${resourceType}_resource_type_card`;

export const ResourceCard: FC<ResourceCardProps> = ({ resourceType }) => {
  const { collapsedState, setCollapsedState } =
    useResourceCardCollapsedState(resourceType);

  const [collapsed, toggle] = useToggle(collapsedState);
  const [fullScreen, toggleFullScreen] = useToggle();

  const onCollapse = useCallback(
    (action: any) => {
      setCollapsedState(!collapsed);
      toggle(action);
    },
    [collapsed, setCollapsedState, toggle],
  );

  return (
    <Card variant="outlined" id={generateResourceCardId(resourceType)}>
      <ResourceCardHeader
        resourceType={resourceType}
        onCollapse={onCollapse}
        onFullScreen={toggleFullScreen}
        collapsed={collapsed}
      />

      <Collapse in={!collapsed}>
        <ResourceCardGrid resourceType={resourceType} />
      </Collapse>

      {fullScreen && (
        <Dialog
          open={true}
          onClose={toggleFullScreen}
          fullScreen
          scroll="body"
          sx={{
            zIndex: (theme: Theme) => theme.zIndex.drawer,
          }}
        >
          <Card variant="outlined">
            <CardHeader
              title={<ResourceCardHeaderTitle resourceType={resourceType} />}
              titleTypographyProps={{ color: "grey.50", variant: "h6" }}
              sx={{
                px: 2,
                py: 1,
                bgcolor: "grey.800",
                ".MuiCardHeader-action": {
                  minWidth: 40,
                  alignSelf: "center",
                },
              }}
              action={
                <IconButton
                  onClick={toggleFullScreen}
                  size="small"
                  disableRipple
                  sx={{ color: "grey.50", bgcolor: "#303030" }}
                >
                  <FullscreenExitIcon color="inherit" />
                </IconButton>
              }
            />
            <ResourceCardGrid resourceType={resourceType} fullHeight />
          </Card>
        </Dialog>
      )}
    </Card>
  );
};
