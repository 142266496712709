import { cronToString } from "./cron/cronToString";
import { getCronFrequency } from "./cron/getCronFrequency";
import { ResourceFiltersWithAction } from "../../../../../../services/cloudchipr.api";
import { formatDate } from "../../../../../../utils/helpers/date-time/datetime-format";
import { WorkflowFormData } from "../../../classic/utils/types/types";
import { OffHoursFormData } from "../../../off-hours/utils/types/types";

export const getRunDateFromCron = (cron: string | null) => {
  if (!cron) {
    return "";
  }

  const frequency = getCronFrequency(cron);
  const firstLetter = frequency.substring(0, 1).toUpperCase();
  const freq = firstLetter + frequency.substring(1);

  let cronString = cronToString(cron || "");
  if (frequency === "hourly") {
    cronString = cronString.replace("At 0 minutes past the hour,", "");
  }

  return `${freq} - ${cronString}`;
};

export const getAutomationPreviewFormatDate = (date: string) => {
  return formatDate(date, { type: "dateTime" });
};

export const getAutomationSteps = (
  isFirstCompleted: boolean,
  isSecondCompleted: boolean,
  isThirdCompleted: boolean,
  isFourthCompleted: boolean,
  labels?: { first?: string; second?: string; third?: string; fourth?: string },
) => {
  return [
    {
      label: labels?.first ?? "Select Environment",
      index: 0,
      enabled: true,
      completed: isFirstCompleted,
    },
    {
      label: labels?.second ?? "Select Services",
      index: 1,
      enabled: isFirstCompleted,
      completed: isSecondCompleted,
    },
    {
      label: labels?.third ?? "Select Action",
      index: 2,
      enabled: isSecondCompleted,
      completed: isThirdCompleted,
    },
    {
      label: labels?.fourth ?? "Set Schedule",
      index: 3,
      enabled: isThirdCompleted,
      completed: isFourthCompleted,
    },
    {
      label: "Preview",
      index: 4,
      enabled: isFourthCompleted,
      completed: isFourthCompleted,
    },
  ];
};

export const generateAutomationPayloadData = (
  schedule: WorkflowFormData | OffHoursFormData,
) => {
  return {
    body: {
      emails: schedule.emails,
      filter: schedule.filter ?? [],
      name: schedule.name,
      start_date_time: schedule.start_date_time,
      end_date_time: schedule.end_date_time,
      regions: schedule.regions,
      account_id: schedule.account_ids[0],
      time_zone: schedule.time_zone,
      notifications: schedule?.action?.includes("notify")
        ? schedule.notifications
        : null,
      account_ids: schedule.account_ids,
      min_threshold: schedule.min_threshold,
    },
  };
};

export const isFilterAdded = (data: ResourceFiltersWithAction[]) => {
  return data.length && data.every(({ filter }) => filter.filter_items?.length);
};
