import { RootState } from "../../../../../store";
import { GetUsersMeOrganisationsCurrentWidgetsCostBreakdownApiArg } from "../../../../../../services/cloudchipr.api";
import { costBreakdownV2WidgetSetupSelector } from "../../../setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";

export const costBreakdownV2WidgetSetupDataPayloadSelector = (
  state: RootState,
): GetUsersMeOrganisationsCurrentWidgetsCostBreakdownApiArg | undefined => {
  const setup = costBreakdownV2WidgetSetupSelector(state);
  if (!setup) {
    return;
  }
  const {
    grouping,
    frequency,
    viewId,
    group_values,
    forecastOption,
    dates,
    viewType,
  } = setup;

  return {
    viewId: viewId ?? "",
    dateLabel: dates?.label ?? undefined,
    dateFrom: dates?.from ?? undefined,
    dateTo: dates?.to ?? undefined,
    dateType: dates?.type ?? undefined,
    dateValue: dates?.value ?? undefined,
    dateUnit: dates?.unit ?? undefined,
    dataPoint: frequency ?? undefined,
    grouping,
    groupValues: group_values ?? undefined,
    forecastOption,
    chartType: viewType,
  };
};
