import {
  FC,
  forwardRef,
  HTMLAttributes,
  JSXElementConstructor,
  LegacyRef,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
  FocusEvent,
} from "react";
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  Chip,
  MenuItem,
  TextField,
} from "@mui/material";
import { useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery } from "../../../../../../services/cloudchipr.api";

interface EmailIntegratedEmailsSelectProps {
  integrationId: string;
  clearValue?: boolean;
  errorMessages?: string | string[];
  disabled?: boolean;
  selectedValue?: string[] | null;
  onChange(metadata: string[]): void;
}

export const EmailIntegratedEmailsSelect: FC<
  EmailIntegratedEmailsSelectProps
> = ({
  integrationId,
  selectedValue,
  errorMessages,
  onChange,
  disabled,
  clearValue = true,
}) => {
  const [options, setOptions] = useState<string[]>([]);
  const { data, isLoading, isFetching } =
    useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery(
      {
        type: "email",
        integrationId: integrationId,
      },
      { skip: !integrationId },
    );

  const value = useMemo(() => {
    if (!clearValue) {
      return selectedValue ?? [];
    }

    const isCorrectOptions = selectedValue?.every((email) =>
      options?.includes(email),
    );

    if (isCorrectOptions) {
      return selectedValue ?? [];
    }

    return [];
  }, [selectedValue, options, clearValue]);

  const changeHandler = useCallback(
    (_: SyntheticEvent | null, metadata: string[]) => {
      onChange(metadata);

      const newOptions = new Set([...metadata, ...(data?.emails ?? [])]);
      setOptions(Array.from(newOptions));
    },
    [onChange, data?.emails],
  );

  const blurHandler = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      const val = event.target.value;

      if (val) {
        changeHandler(null, [...value, val]);
      }
    },
    [changeHandler, value],
  );

  const error = !!errorMessages && !(isLoading || isFetching);
  const errorMessage =
    typeof errorMessages === "string" ? errorMessages : "Invalid Email format";

  useEffect(() => {
    setOptions(data?.emails ?? []);
  }, [data]);

  return (
    <Autocomplete
      multiple
      fullWidth
      clearOnBlur
      openOnFocus
      freeSolo
      size="small"
      value={value}
      options={options}
      loading={isLoading}
      disabled={isLoading || disabled}
      filterSelectedOptions
      onChange={changeHandler}
      onBlur={blurHandler}
      getOptionLabel={(option) => option}
      renderTags={autoCompleteRenderTags(errorMessages)}
      ListboxComponent={ListBoxComponent}
      renderOption={(props, option: string) => (
        <MenuItem {...props} key={option} sx={{ gap: 1 }}>
          {option}
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={error ? errorMessage : undefined}
          label={isLoading || isFetching ? "Loading" : "Select Emails"}
        />
      )}
    />
  );
};

const autoCompleteRenderTags =
  (errors?: string[] | string) =>
  (value: string[], getTagProps: AutocompleteRenderGetTagProps) => {
    return value.map((option, index) => (
      <Chip
        label={option}
        size="small"
        variant="outlined"
        color={Array.isArray(errors) && errors[index] ? "error" : "default"}
        {...getTagProps({ index })}
      />
    ));
  };

const ListBoxComponent: JSXElementConstructor<HTMLAttributes<HTMLElement>> =
  forwardRef((props, ref: LegacyRef<HTMLUListElement>) => (
    <ul {...props} style={{ maxHeight: "200px" }} ref={ref} />
  ));
