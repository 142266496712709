import { RootState } from "../../../store";
import { billingAccountsSelector } from "../../../common/selectors/billing-accounts/billingAccountsSelector";
import { EmbeddedAccount } from "../../../../services/cloudchipr.api";

export const accountsForCategoriesByIdsSelector = (state: RootState) => {
  const accounts = billingAccountsSelector(state);

  return accounts?.reduce(
    (acc, account) => {
      acc[account.id] = account;

      return acc;
    },
    {} as Record<string, EmbeddedAccount>,
  );
};
