import { FC } from "react";
import { Typography } from "@mui/material";
import numeral from "numeral";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";

interface TrendColumnHeaderCellProps {
  trend: number | null;
}
export const TrendColumnHeaderCell: FC<TrendColumnHeaderCellProps> = ({
  trend,
}) => {
  const plusSign = trend && trend > 0 ? "+" : "";
  return (
    <div>
      <TooltipText
        fontWeight="medium"
        tooltipPlacement="top"
        tooltipTitle="Total Cost compared with Previous Period Cost."
      >
        <div>Trend</div>
      </TooltipText>

      <Typography
        mt={0.5}
        variant="tiny"
        color="text.disabled"
        fontWeight="medium"
        component="p"
      >
        {trend ? `${plusSign}${numeral(trend).format("0.00")}%` : "-"}
      </Typography>
    </div>
  );
};
