import { ResourceType } from "../../../../../../../../../../../services/cloudchipr.api";

export const nestedGridPageSize = 8;

export const expandableResourceTypes = new Set([
  "asg",
  "node_group",
  "az_node_pool",
  "eks",
  "asg_ec2",
  "mig",
  "rds_snapshot_source",
  "az_vmss",
  "gke",
  "az_aks",
  "ecs",
]);

export const dataGridViewTypeByResourceType: Partial<
  Record<ResourceType, "table" | "card">
> = {
  node_group: "card",
  node_pool: "card",
  az_node_pool: "card",
  eks: "table",
  az_aks: "table",
  az_aks_vm: "table",
  eks_ec2: "table",
  mig_vm: "table",
  rds_snapshot: "table",
  asg_ec2: "table",
  az_vmss_vm: "table",
  gke: "table",
  gke_vm: "table",
  ecs_workload: "table",
};
