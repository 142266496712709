import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigationState } from "./utils/types";
import { TreeItems } from "../../components/navigation/components/sortable-tree/utils/types";

const initialState: NavigationState = {
  resourceExplorer: null,
  dashboard: null,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: initialState,
  reducers: {
    setNavigationResourceExplorer: (
      state,
      action: PayloadAction<TreeItems | null>,
    ) => {
      state.resourceExplorer = action.payload;
    },
    setNavigationDashboard: (
      state,
      action: PayloadAction<TreeItems | null>,
    ) => {
      state.dashboard = action.payload;
    },
  },
});

export const { setNavigationResourceExplorer, setNavigationDashboard } =
  navigationSlice.actions;

export default navigationSlice.reducer;
