import { FC } from "react";
import { AccountsCounts } from "../common/accounts-count/AccountsCounts";
import { useAppSelector } from "../../../../../../../store/hooks";
import { editingCategorySelectorAccountsCountSelector } from "../../../../../../../store/dimensions/selectors/slice-data/editing-category/editingCategorySelectorAccountsCountSelector";

export const CategoryAccountsCount: FC = () => {
  const accountsCount = useAppSelector(
    editingCategorySelectorAccountsCountSelector,
  );

  return <AccountsCounts accountsCount={accountsCount} />;
};
