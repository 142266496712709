import { FC, useCallback } from "react";
import { FilterSelect } from "./FilterSelect";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setCommitmentsRecommendationsLockBackPeriod } from "../../../../../../store/commitments/commitmentsSlice";
import { LookbackPeriod } from "../../../../../../services/cloudchipr.api";
import { commitmentsRecommendationsLockBackPeriodSelector } from "../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsLockBackPeriodSelector";
import { getCommitmentsRecommendationsThunk } from "../../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";
import { savingsPlanLockBackPeriodLabels } from "../../../utilization-and-coverage/utils/constants/labels";

export const LockBackPeriodFilter: FC = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(
    commitmentsRecommendationsLockBackPeriodSelector,
  );

  const changeHandler = useCallback(
    (period: string) => {
      dispatch(
        setCommitmentsRecommendationsLockBackPeriod(period as LookbackPeriod),
      );
      dispatch(getCommitmentsRecommendationsThunk());
    },
    [dispatch],
  );

  if (!value) {
    return null;
  }

  return (
    <FilterSelect
      value={value}
      label="Based On The Past"
      options={options}
      onChange={changeHandler}
    />
  );
};

const options = [
  {
    value: "seven_days",
    label: savingsPlanLockBackPeriodLabels.seven_days,
  },
  {
    value: "thirty_days",
    label: savingsPlanLockBackPeriodLabels.thirty_days,
  },
  {
    value: "sixty_days",
    label: savingsPlanLockBackPeriodLabels.sixty_days,
  },
];
