import { Dispatch, FC, Fragment, SetStateAction, useMemo } from "react";
import { Box } from "@mui/material";
import { TagFilterDropdownSelectItem } from "./TagFilterDropdownSelectItem";
import { TagFilterAddFilterButton } from "./TagFilterAddFilterButton";
import { TagFilterValueOptions, TagsFilter } from "../utils/types";
import { tagsFilterDefaultCombinedOperator } from "../utils/constants";
import { validateTagsFilters } from "../utils/helpers/validateTagsFilters";

export interface TagFilterDropdownSelectPopoverContentProps {
  filters: TagsFilter | null;
  setFilters: Dispatch<SetStateAction<TagsFilter | null>>;
  valuesOptions?: TagFilterValueOptions;
  keysOptions?: {
    value: string;
    title?: string;
  }[];
}

export const TagFilterDropdownSelectPopoverContent: FC<
  TagFilterDropdownSelectPopoverContentProps
> = ({ filters, setFilters, valuesOptions, keysOptions }) => {
  const alreadyFilteredTagKeys: string[] = useMemo(() => {
    return (
      filters?.value.reduce((result, item) => {
        if (item?.key) {
          result.push(item.key);
        }
        return result;
      }, [] as string[]) ?? []
    );
  }, [filters]);

  const addTagButtonDisabled = useMemo((): boolean => {
    if (!keysOptions?.length) {
      return true;
    }

    return (
      filters?.value.length === keysOptions?.length ||
      (!!filters?.value?.length &&
        validateTagsFilters(filters?.value ?? []).length !==
          filters?.value.length)
    );
  }, [filters, keysOptions]);

  return (
    <Fragment>
      {filters?.value?.map((filter, index) => {
        return (
          <TagFilterDropdownSelectItem
            valuesOptions={valuesOptions?.[filter.key] ?? []}
            keysOptions={keysOptions ?? []}
            index={index}
            filter={filter}
            key={filter.key + filter.operator}
            setFilter={setFilters}
            alreadyFilteredTagKeys={alreadyFilteredTagKeys}
            combinedOperator={
              filters?.combinationOperator ?? tagsFilterDefaultCombinedOperator
            }
          />
        );
      })}
      <Box py={1} px={2}>
        <TagFilterAddFilterButton
          setFilter={setFilters}
          disabled={addTagButtonDisabled}
          label="Add Tag"
        />
      </Box>
    </Fragment>
  );
};
