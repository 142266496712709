import { FC, useCallback } from "react";
import { TextField } from "@mui/material";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import { FilterTriggerComponentProps } from "../../dropdown-select/utils/types/filterTriggerComponentProps";

export interface AutocompleteInputProps {
  params: AutocompleteRenderInputParams;
  keyword: string;
  label: string;
  error?: boolean;
  placeholder?: string;
  size?: "xsmall" | "small" | "medium";
  setKeyword: FilterTriggerComponentProps["setKeyword"];
  openOnTyping(): void;
}

export const AutocompleteInput: FC<AutocompleteInputProps> = ({
  params,
  keyword = "",
  setKeyword,
  label,
  error,
  placeholder,
  size = "small",
  openOnTyping,
}) => {
  const changeHandler = useCallback(
    (e: any) => {
      setKeyword(e.target.value);
      openOnTyping();
    },
    [setKeyword, openOnTyping],
  );

  return (
    <TextField
      {...params}
      inputProps={{
        ...params.inputProps,
        value: keyword,
      }}
      size={size}
      error={error}
      value={keyword}
      variant="outlined"
      onChange={changeHandler}
      placeholder={placeholder}
      label={placeholder ? "" : label}
      sx={{ "& input": { pr: "20px !important" } }}
    />
  );
};
