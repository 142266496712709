import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { checkForOrganizationExistenceThunk } from "./checkForOrganizationExistenceThunk";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { commitmentsRecommendationsPayloadDataSelector } from "../../selectors/recommendations/commitmentsRecommendationsPayloadDataSelector";
import { getCommitmentsRecommendationsPostFixedCacheKey } from "../../utils/helpers/fixedCacheKeys";
import { commitmentsRecommendationsListSelector } from "../../selectors/recommendations/data/commitmentsRecommendationsListSelector";
import { setCommitmentsRecommendationsSelectedRecommendations } from "../../commitmentsSlice";

export const getCommitmentsRecommendationsThunk = createAsyncThunk(
  "commitments/getCommitmentsRecommendations",
  async (_: void, { dispatch, getState }) => {
    dispatch(checkForOrganizationExistenceThunk());

    const state = getState() as RootState;

    const {
      postUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummary,
    } = cloudChiprApi.endpoints;

    const { selectedRecommendations, ...payload } =
      commitmentsRecommendationsPayloadDataSelector(state);

    try {
      await dispatch(
        postUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummary.initiate(
          {
            body: payload,
          },
          {
            fixedCacheKey: getCommitmentsRecommendationsPostFixedCacheKey(
              payload.provider_organisation_id,
            ),
          },
        ),
      ).unwrap();

      const recommendations = commitmentsRecommendationsListSelector(
        getState() as RootState,
      );

      if (recommendations) {
        dispatch(
          setCommitmentsRecommendationsSelectedRecommendations(
            recommendations.reduce(
              (acc, rec) => {
                acc[rec.id] = true;
                return acc;
              },
              {} as Record<string, boolean>,
            ),
          ),
        );
      }
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
