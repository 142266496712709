import { FC, useState, useCallback, ReactNode } from "react";
import {
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { IntegrationsDialog } from "./components/integrations/IntegrationsDialog";
import { IntegrationItem } from "./components/integrations/IntegrationItem";
import { AddNotificationButton } from "./components/AddNotificationButton";
import { generateIntegrationMetadata } from "./utils/helpers/helpers";
import { IntegrationNotification } from "./utils/types/types";
import { NotificationsEmailSelectionWrapper } from "./NotificationsEmailSelectionWrapper";
import { IntegrationType } from "../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import { IntegrationErrors } from "../../schedule/classic/components/create/actions-step/components/utils/types";
import { IntegrationCustomMessageProps } from "../../schedule/classic/components/custom-message/IntegrationCustomMessage";
import { EmailsSelectionErrors } from "../../../common/integration-dialogs/components/email/components/email-selection/EmailsSelection";
import { getIntegrationByIdThunk } from "../../../../store/integrations/thunks/getIntegrationByIdThunk";
import { useAppDispatch } from "../../../../store/hooks";
import {
  Emails,
  Integration,
  useGetUsersMeIntegrationsQuery,
} from "../../../../services/cloudchipr.api";

interface NotificationsSelectionProps {
  emailsErrors: EmailsSelectionErrors;
  selectedEmails: Emails | null;
  emailsChangeHandler(emails: Emails | null): void;
  setSelectedIntegrations(integrations: IntegrationNotification[] | null): void;
  children?: ReactNode;
  integrationErrors?: IntegrationErrors[];
  selectedIntegrations?: IntegrationNotification[] | null;
  disabledIntegrationTypes?: IntegrationType[];
  IntegrationCustomMessage?: FC<IntegrationCustomMessageProps>;
}

export const NotificationsSelection: FC<NotificationsSelectionProps> = ({
  selectedIntegrations,
  setSelectedIntegrations,
  emailsChangeHandler,
  selectedEmails,
  emailsErrors,
  integrationErrors,
  disabledIntegrationTypes,
  children,
  IntegrationCustomMessage,
}) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useGetUsersMeIntegrationsQuery({});

  const [open, setOpen] = useState(false);

  const closeHandler = useCallback(() => setOpen(false), []);
  const openHandler = useCallback(() => setOpen(true), []);

  const newIntegrationAddHandler = useCallback(
    async (type: IntegrationType, id: string, addToFirst?: boolean) => {
      setSelectedIntegrations(
        addToFirst
          ? [{ integration_id: id, type }, ...(selectedIntegrations || [])]
          : [...(selectedIntegrations || []), { integration_id: id, type }],
      );

      if (!["slack", "email"].includes(type)) {
        return;
      }

      const response = await dispatch(
        getIntegrationByIdThunk({ id, type }),
      ).unwrap();
      const data = response?.data as Integration;
      const selected = generateIntegrationMetadata(data, type);

      setSelectedIntegrations(
        addToFirst
          ? [selected, ...(selectedIntegrations || [])]
          : [...(selectedIntegrations || []), selected],
      );
    },
    [dispatch, selectedIntegrations, setSelectedIntegrations],
  );

  const createEmailsHandler = useCallback(() => {
    emailsChangeHandler({ to: [] });
  }, [emailsChangeHandler]);

  if (!data && isLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress disableShrink />
      </Stack>
    );
  }

  return (
    <div>
      <Typography variant="body2" color="text.secondary">
        Select a notification method.
      </Typography>

      <Stack
        gap={1.5}
        mt={2}
        divider={
          <Box my={1}>
            <Divider>AND</Divider>
          </Box>
        }
      >
        <NotificationsEmailSelectionWrapper
          emailsChangeHandler={emailsChangeHandler}
          selectedEmails={selectedEmails}
          emailsErrors={emailsErrors}
          selectedIntegrations={selectedIntegrations}
          disabledIntegrationTypes={disabledIntegrationTypes}
          CustomMessage={IntegrationCustomMessage}
          onAddNewIntegration={newIntegrationAddHandler}
          onCreateDialogOpen={openHandler}
        />

        {selectedIntegrations?.map((integration, i) => (
          <IntegrationItem
            integration={integration}
            error={integrationErrors?.at(i)}
            key={integration.integration_id}
            onCreateDialogOpen={openHandler}
            selectedIntegrations={selectedIntegrations}
            setSelectedIntegrations={setSelectedIntegrations}
            disabledIntegrationTypes={disabledIntegrationTypes}
            CustomMessage={IntegrationCustomMessage}
            disableEmailsSelection={!!selectedEmails}
            onEmailsCreate={createEmailsHandler}
          />
        ))}
      </Stack>

      {children}

      <AddNotificationButton
        onCreateDialogOpen={openHandler}
        disableEmailsSelection={!!selectedEmails}
        onEmailsCreate={createEmailsHandler}
        selectedIntegrations={selectedIntegrations}
        onAddNewIntegration={newIntegrationAddHandler}
        disabledIntegrationTypes={disabledIntegrationTypes}
      />

      <IntegrationsDialog
        open={open}
        onClose={closeHandler}
        onNewIntegrationCreate={newIntegrationAddHandler}
        disabledIntegrationTypes={disabledIntegrationTypes}
      />
    </div>
  );
};
