import { FC, useCallback } from "react";
import { Box, Drawer, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { CreateWorkflowSubmittedMessage } from "./CreateWorkflowSubmittedMessage";
import { ScheduledJobsForm } from "../../../../schedule/classic/components/create/ScheduledJobsForm";
import { resetSchedulesState } from "../../../../../../store/schedules/schedulesSlice";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { workflowCreationDialogOpenSelector } from "../../../../../../store/schedules/selectors/workflowCreationDialogOpenSelector";

export const CreateWorkflowDrawer: FC = () => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const open = useAppSelector(workflowCreationDialogOpenSelector);
  const closeHandler = useCallback(() => {
    dispatch(resetSchedulesState());
  }, [dispatch]);

  const afterSubmitHandler = useCallback(
    (id: string, name: string) => {
      closeHandler();
      enqueueSnackbar("", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
          action: <CreateWorkflowSubmittedMessage id={id} name={name} />,
        },
      });
    },
    [enqueueSnackbar, closeHandler],
  );

  return (
    <Drawer
      open={open}
      anchor="bottom"
      onClose={closeHandler}
      PaperProps={{ sx: { height: "94vh", borderRadius: 3 } }}
    >
      <Stack
        px={4}
        py={3}
        top={0}
        direction="row"
        bgcolor="white"
        borderBottom={1}
        position="sticky"
        alignItems="center"
        borderColor="grey.300"
        justifyContent="space-between"
        zIndex={(theme) => theme.zIndex.appBar + 1}
      >
        <Typography variant="h6">Schedule a Workflow</Typography>

        <IconButton onClick={closeHandler}>
          <CloseIcon />
        </IconButton>
      </Stack>

      <Box px={4} my={4}>
        <ScheduledJobsForm inDrawer onAfterSubmit={afterSubmitHandler} />
      </Box>
    </Drawer>
  );
};
