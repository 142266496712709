import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { deleteResourceExplorerFolderThunkFixedCacheKey } from "../../thunks/resource-explorer-hierarchy/deleteResourceExplorerFolderThunk";

const selector =
  cloudChiprApi.endpoints
    .deleteUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId
    .select;

export const deleteResourceExplorerHierarchyItemLoadingSelector = (
  state: RootState,
): boolean => {
  return !!selector(deleteResourceExplorerFolderThunkFixedCacheKey)(state)
    ?.isLoading;
};
