import { FC, useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FilterTemplatesRow } from "./FilterTemplatesRow";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { getAccountSortedFilterTemplatesSelector } from "../../../../../../store/filters/selectors/filter-set/getAccountSortedFilterTemplatesSelector";
import { getCurrentAccountFilterTemplatesThunk } from "../../../../../../store/filters/thunks/filter-set/getCurrentAccountFilterTemplatesThunk";

interface FilterTemplatesDialogProps {
  open: boolean;
  onClose(): void;
  onApplyCallback?(): void;
}

export const FilterTemplatesDialog: FC<FilterTemplatesDialogProps> = ({
  onClose,
  open,
  onApplyCallback,
}) => {
  const dispatch = useAppDispatch();
  const [editingRowId, setEditingRowId] = useState("");
  const [duplicatingRowId, setDuplicatingRowId] = useState("");
  const templates = useAppSelector(getAccountSortedFilterTemplatesSelector);

  const applyHandler = useCallback(() => {
    dispatch(getCurrentAccountFilterTemplatesThunk());
    if (onApplyCallback) {
      onApplyCallback();
    }
  }, [onApplyCallback, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitleClosable title="Filter Templates" onClose={onClose} />

      <DialogContent dividers>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ bgcolor: "grey.100" }}>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                Filter Template
              </TableCell>
              {!editingRowId && !duplicatingRowId && (
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  Filtered Resources
                </TableCell>
              )}
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                Applied Accounts
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {templates.map((filterSet) => {
              return (
                <FilterTemplatesRow
                  filterSet={filterSet}
                  onApply={applyHandler}
                  editingRowId={editingRowId}
                  key={filterSet.filter_set.id}
                  setEditingRowId={setEditingRowId}
                  duplicatingRowId={duplicatingRowId}
                  setDuplicatingRowId={setDuplicatingRowId}
                />
              );
            })}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};
