import { ResourceExplorerDataGridView } from "./resourceExplorerDataGrid";
import {
  ChartType,
  DateDataPointNullable,
  Dates,
  ForecastOption,
  ProviderType,
  ResourceExplorerData,
  ResourceExplorerFilterItem,
  ResourceExplorerFilterKeyType,
  ResourceExplorerGrouping,
} from "../../../../services/cloudchipr.api";

export type ResourceExplorerPathFilters = Record<
  ProviderType,
  Record<string, string | [string] | object>
> & {
  cloud_providers: ProviderType[];
  category_ids?: string[] | null;
};

export type ResourceExplorerPath = {
  name: string;
  groupedBy: ResourceExplorerGrouping;
  providers: ProviderType[];
  groupingOptions: ResourceExplorerGrouping[];
  groupValues?: string[];
  includedFilters?: ResourceExplorerPathFilters;
  forecastOption?: ForecastOption;
  dates?: Dates;
  chartType?: ChartType;
  dateGranularity?: DateDataPointNullable;
};

export type ResourceExplorerChartType = Extract<
  ChartType,
  "stack" | "line" | "bar"
>;

export const resourceExplorerPossibleChartTypes: ResourceExplorerChartType[] = [
  "stack",
  "line",
  "bar",
];

export type RowSelectionPossibleFilterKey =
  | "account_ids"
  | "services"
  | "resource_id"
  | "product_families"
  | "skus"
  | "cloud_providers"
  | "regions"
  | "instance_families"
  | "instance_types"
  | "cost_allocation_tag"
  | "cost_allocation_tag_value"
  | "tags"
  | "category_ids";

export type ResourceExplorerDimension = {
  dimensionId?: string;
  categoryIds?: string[];
};

export interface ResourceExplorerInterface {
  id: string;
  filters: MultiFilters;
  providers: ProviderType[];
  data: ResourceExplorerData | null;
  path: ResourceExplorerPath[];
  lastState: {
    data: ResourceExplorerData | null;
    filters: MultiFilters | null;
  };
  chartType: ResourceExplorerChartType;
  search: string;
  filteredByLiveUsages: boolean;
  quarterEndForecastVisibility: boolean;
  dataGridView: ResourceExplorerDataGridView | null;
  dimension: ResourceExplorerDimension | null;
  csvDataHash: string;
}

export type MultiFilters = Partial<
  Record<
    ProviderType,
    Partial<Record<ResourceExplorerFilterKeyType, ResourceExplorerFilterItem>>
  >
>;
