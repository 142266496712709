import { FC, useCallback } from "react";
import { ListItem, ListItemText, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { setCommitmentsRecommendationsAccountScope } from "../../../../../../../store/commitments/commitmentsSlice";
import { getCommitmentsRecommendationsThunk } from "../../../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { connectedOrganizationExistsSelector } from "../../../../../../../store/commitments/selectors/organisations/connectedOrganizationExistsSelector";

export const RecommendationLevelFilterPayer: FC = () => {
  const dispatch = useAppDispatch();
  const orgConnected = useAppSelector(connectedOrganizationExistsSelector);
  const disabled = !orgConnected;

  const clickHandler = useCallback(() => {
    if (disabled) {
      return;
    }

    dispatch(setCommitmentsRecommendationsAccountScope("payer"));
    dispatch(getCommitmentsRecommendationsThunk());
  }, [dispatch, disabled]);

  return (
    <ListItem
      onClick={clickHandler}
      sx={{
        alignItems: "center",
        py: 0.5,
        lineHeight: 0,
        "&:hover": disabled
          ? undefined
          : { bgcolor: "grey.100", cursor: "pointer" },
        color: disabled ? "text.disabled" : "text.primary",
      }}
      secondaryAction={
        <Tooltip
          title="Payer - Recommendations at the Organizations master account level are calculated considering usage across all accounts that have Reserved Instances (RI) or Savings Plans discount sharing enabled. This is to recommend a commitment that maximizes savings across your accounts."
          placement="top"
          arrow
        >
          <InfoOutlinedIcon color="action" fontSize="small" />
        </Tooltip>
      }
    >
      <ListItemText>Payer</ListItemText>
    </ListItem>
  );
};
