import { FC } from "react";
import { Stack } from "@mui/material";

import { SlackIntegrationNotifyDialogContent } from "./components/notify/SlackIntegrationNotifyDialogContent";
import { EmailIntegrationNotifyDialogContent } from "./components/notify/EmailIntegrationNotifyDialogContent";
import { EmailIntegrationGracePeriodDialogContent } from "./components/grace-period/EmailIntegrationGracePeriodDialogContent";
import { SlackIntegrationGracePeriodDialogContent } from "./components/grace-period/SlackIntegrationGracePeriodDialogContent";
import { EmailActionExecutedDialogContent } from "./components/action-executed/EmailActionExecutedDialogContent";
import { SlackActionExecutedDialogContent } from "./components/action-executed/SlackActionExecutedDialogContent";
import { IntegrationCustomMessageContentProps } from "../IntegrationCustomMessageContent";

export interface IntegrationCustomMessageDialogContentProps
  extends IntegrationCustomMessageContentProps {
  onCustomMessageChange(message: string): void;
}

export const IntegrationCustomMessageDialogContent: FC<
  IntegrationCustomMessageDialogContentProps
> = ({ actionFrom, type, onCustomMessageChange, message }) => {
  if (actionFrom === "grace-period") {
    return (
      <Stack spacing={1.5} p={2} bgcolor="grey.50">
        {type === "email" && (
          <EmailIntegrationGracePeriodDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
          />
        )}
        {type === "slack" && (
          <SlackIntegrationGracePeriodDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
          />
        )}
      </Stack>
    );
  }
  if (actionFrom === "notify") {
    return (
      <Stack spacing={1.5} p={2} bgcolor="grey.50">
        {type === "email" && (
          <EmailIntegrationNotifyDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
          />
        )}
        {type === "slack" && (
          <SlackIntegrationNotifyDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
          />
        )}
      </Stack>
    );
  }
  if (actionFrom === "clean") {
    return (
      <Stack spacing={1.5} p={2} bgcolor="grey.50">
        {type === "email" && (
          <EmailActionExecutedDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
          />
        )}
        {type === "slack" && (
          <SlackActionExecutedDialogContent
            onCustomMessageChange={onCustomMessageChange}
            customMessage={message}
          />
        )}
      </Stack>
    );
  }
  return null;
};
