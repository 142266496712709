import { FC, Fragment, memo, useCallback } from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../store/hooks";
import { resourceExplorerGroupingSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { resourceExplorerDataGridDataCountSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridDataCountSelector";
import { resourceExplorerProvidersSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { getResourcesExplorerGroupingLabel } from "../../../../../../utils/helpers/getResourcesExplorerGroupingLabel";
import { setFilteredByLiveUsages } from "../../../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { resourceExplorerDataGridFilteredByLiveUsageResourcesSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/resourceExplorerDataGridFilteredByLiveUsageResourcesSelector";
import { getResourceExplorerDataThunk } from "../../../../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";
import { resourceExplorerPathLastItemFiltersSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathLastItemFiltersSelector";
import { resourceExplorerGranularDataCountSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data-grid/granular-costs-view/resourceExplorerGranularDataCountSelector";
import { resourceExplorerDataGridViewSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerDataGridViewSelector";

interface LabelColumnHeaderCellProps {
  showFilterIcon?: boolean;
}

export const LabelColumnHeaderCell: FC<LabelColumnHeaderCellProps> = memo(
  ({ showFilterIcon }) => {
    const dispatch = useAppDispatch();

    const grouping = useAppSelector(resourceExplorerGroupingSelector);
    const providers = useAppSelector(resourceExplorerProvidersSelector);
    const dataGridView = useAppSelector(resourceExplorerDataGridViewSelector);
    const totalDataCount = useAppSelector(
      resourceExplorerDataGridDataCountSelector,
    );
    const granularDataCount = useAppSelector(
      resourceExplorerGranularDataCountSelector,
    );
    const dataCount =
      dataGridView === "total" ? totalDataCount : granularDataCount;

    const filtered = useAppSelector(
      resourceExplorerDataGridFilteredByLiveUsageResourcesSelector,
    );
    const pathLastItemFilters = useAppSelector(
      resourceExplorerPathLastItemFiltersSelector,
    );

    const filterByLiveUsageHandler = useCallback(
      (e: any) => {
        e.stopPropagation();
        dispatch(setFilteredByLiveUsages(!filtered));
        dispatch(
          getResourceExplorerDataThunk({
            filtersFromPath: pathLastItemFilters,
          }),
        );
      },
      [filtered, pathLastItemFilters, dispatch],
    );

    if (!grouping) {
      return <Fragment>Total</Fragment>;
    }

    if (grouping === "none") {
      return null;
    }

    return (
      <Stack direction="row" spacing={2} alignItems="center">
        {showFilterIcon && (
          <Tooltip
            arrow
            placement="top"
            title={
              filtered
                ? "Show all resources."
                : "Show only live filtered resources."
            }
          >
            <IconButton
              onClick={filterByLiveUsageHandler}
              sx={{
                p: 0.5,
                border: 1,
                height: 28,
                borderRadius: 1,
                bgcolor: filtered ? "primary.light" : undefined,
                borderColor: filtered ? "primary.main" : "grey.300",
              }}
            >
              <PlayCircleFilledWhiteOutlinedIcon
                fontSize="small"
                color={filtered ? "primary" : "error"}
              />
            </IconButton>
          </Tooltip>
        )}

        <div>
          {getResourcesExplorerGroupingLabel(providers, grouping, true)}

          <Typography fontSize={10} color="text.disabled" fontWeight={500}>
            {dataCount}
          </Typography>
        </div>
      </Stack>
    );
  },
);
