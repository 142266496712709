import { FC, useCallback } from "react";
import { Box, Grid } from "@mui/material";
import { AccountFilterValueField } from "./AccountFilterValueField";
import { FilterTypeField } from "../common/FilterTypeField";
import { FilterOperatorField } from "../common/FilterOperatorField";
import { FilterItemDeleteButton } from "../common/FilterItemDeleteButton";
import {
  ProviderType,
  ResourceExplorerAccount,
  ResourceExplorerTag,
} from "../../../../../../../../../../../../../services/cloudchipr.api";
import { setCategoryFilterGroupFilterItemByIndex } from "../../../../../../../../../../../../../store/dimensions/dimensionsSlice";
import { useAppDispatch } from "../../../../../../../../../../../../../store/hooks";

interface AccountFilterItemsProps {
  categoryId: string;
  index: number;
  provider: ProviderType;
  filterItem: ResourceExplorerAccount;
}

export const AccountFilterItems: FC<AccountFilterItemsProps> = ({
  categoryId,
  provider,
  filterItem,
  index,
}) => {
  const dispatch = useAppDispatch();

  const changeHandler = useCallback(
    (filterItem: ResourceExplorerAccount | ResourceExplorerTag) => {
      dispatch(
        setCategoryFilterGroupFilterItemByIndex({
          index,
          provider,
          id: categoryId,
          accountFilter: filterItem as ResourceExplorerAccount,
        }),
      );
    },
    [dispatch, provider, index, categoryId],
  );

  return (
    <Box flex={1}>
      <Grid container spacing={2}>
        <FilterTypeField type="account" />

        <FilterOperatorField filterItem={filterItem} onChange={changeHandler} />

        <AccountFilterValueField
          provider={provider}
          filterItem={filterItem}
          value={filterItem.value}
          onChange={changeHandler}
        />

        <FilterItemDeleteButton
          type="account"
          index={index}
          provider={provider}
          categoryId={categoryId}
        />
      </Grid>
    </Box>
  );
};
