import { Stack, Typography } from "@mui/material";
import { getCoverageColumn } from "./common/getCoverageColumn";
import { SavingsPlanCoverageDataWithId } from "../../../../../../utils/types";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { commitmentsDrawerDynamicHeaderCellTitles } from "../../../../../../utils/constants/commitmentsDrawerDynamicHeaderCellTitles";

export const coverageGroupedByAccountColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      accessorKey: "account",
      header: "Account",
      cell: ({ row }) => {
        return (
          <Stack>
            <Typography variant="body2">
              {row.original.account?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {row.original.account?.provider_account_id}
            </Typography>
          </Stack>
        );
      },
    },
    {
      accessorKey: "covered_usage",
      header: () => (
        <Typography
          align="right"
          width="100%"
          variant="body2"
          fontWeight="medium"
        >
          {
            commitmentsDrawerDynamicHeaderCellTitles.coverage.account
              .coveredUsageCell
          }
        </Typography>
      ),
      meta: {
        headerTitle:
          commitmentsDrawerDynamicHeaderCellTitles.coverage.account
            .coveredUsageCell,
      },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.covered_usage)}
        </Typography>
      ),
    },
    {
      accessorKey: "uncovered_usage",
      header: () => (
        <Typography
          align="right"
          width="100%"
          variant="body2"
          fontWeight="medium"
        >
          On-Demand Spend
        </Typography>
      ),
      meta: { headerTitle: "On-Demand Spend" },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.uncovered_usage)}
        </Typography>
      ),
    },
    getCoverageColumn("account"),
  ];
