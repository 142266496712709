import { RootState } from "../../../store";
import {
  cloudChiprApi,
  PostUsersMeV2OrganisationsCurrentByTypeResourcesApiResponse,
} from "../../../../services/cloudchipr.api";

export const resourceTypeDataSelector = (
  state: RootState,
  fixedCacheKey: string,
): PostUsersMeV2OrganisationsCurrentByTypeResourcesApiResponse | undefined => {
  return cloudChiprApi.endpoints.postUsersMeV2OrganisationsCurrentByTypeResources.select(
    fixedCacheKey,
  )(state)?.data;
};
