import { FC, useCallback, useMemo } from "react";
import { Menu, MenuItem, PopoverOrigin } from "@mui/material";
import BarChartOutlined from "@mui/icons-material/BarChartOutlined";
import { ResourceExplorerV2ViewMoveActionMenuFolderItem } from "./ResourceExplorerV2ViewMoveActionMenuFolderItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerViewsHierarchyFoldersSelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyFoldersSelector";
import { updateResourceExplorerHierarchyThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/updateResourceExplorerHierarchyThunk";
import { getResourceExplorerViewsHierarchyThunk } from "../../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/getResourceExplorerViewsHierarchyThunk";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";

interface ResourceExplorerV2ViewMoveActionMenuProps {
  id: string;
  folderId?: string;
  anchor: HTMLElement | null;
  open: boolean;
  onClose(): void;
}

export const ResourceExplorerV2ViewMoveActionMenu: FC<
  ResourceExplorerV2ViewMoveActionMenuProps
> = ({ id, folderId, anchor, onClose, open }) => {
  const dispatch = useAppDispatch();
  const folders = useAppSelector(resourceExplorerViewsHierarchyFoldersSelector);

  const folderClickHandler = useCallback(
    async (folderId?: string) => {
      await dispatch(
        updateResourceExplorerHierarchyThunk({
          folderId,
          viewId: id,
        }),
      );
      dispatch(getResourceExplorerViewsHierarchyThunk());
      onClose();
    },
    [id, dispatch, onClose],
  );

  const resourceExplorerClickHandler = useCallback(async () => {
    await dispatch(
      updateResourceExplorerHierarchyThunk({
        viewId: id,
        index: 1,
      }),
    );
    dispatch(getResourceExplorerViewsHierarchyThunk());
    onClose();
  }, [id, dispatch, onClose]);

  const filteredFolders = useMemo(() => {
    return folders?.filter((item) => item.id !== folderId);
  }, [folders, folderId]);

  return (
    <Menu
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      open={open}
      onClose={onClose}
      anchorEl={anchor}
      slotProps={slotProps}
      {...navigationHierarchyItemNoDndKey}
    >
      {!!folderId && (
        <MenuItem sx={{ px: 1 }} onClick={resourceExplorerClickHandler}>
          <BarChartOutlined fontSize="small" />
          <TypographyWithTooltip
            title="Resource Explorer"
            variant="body2"
            placement="top"
            sx={{ pl: 1 }}
          />
        </MenuItem>
      )}

      {filteredFolders?.map((folder) => {
        return (
          <ResourceExplorerV2ViewMoveActionMenuFolderItem
            key={folder.id}
            id={folder.id}
            name={folder.name}
            onClick={folderClickHandler}
          />
        );
      })}
    </Menu>
  );
};

const slotProps = {
  paper: {
    sx: {
      minWidth: 200,
      maxWidth: 400,
      maxHeight: 400,
    },
  },
};
const anchorOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "right",
};
const transformOrigin: PopoverOrigin = {
  vertical: "top",
  horizontal: "left",
};
