import { FC } from "react";
import { Card, Stack } from "@mui/material";
import { CategoryFiltersItems } from "./CategoryFiltersItems";
import { CombinationOperator } from "../options/CombinationOperator";
import { ClearGroupButton } from "../options/ClearGroupButton";
import { AddFilterInFilterGroupButton } from "../options/AddFilterInFilterGroupButton";
import {
  ProviderType,
  ResourceExplorerAccounts,
  ResourceExplorerTags,
} from "../../../../../../../../../../../services/cloudchipr.api";
import { CategoryFilterType } from "../../../../../../../../../../../store/dimensions/utils/types/common";

interface CategoryFilterGroupProps {
  categoryId: string;
  provider: ProviderType;
  operator?: string;
  first?: boolean;
  type: CategoryFilterType;
  filters: ResourceExplorerAccounts | ResourceExplorerTags;
}

export const CategoryFilterGroup: FC<CategoryFilterGroupProps> = ({
  provider,
  categoryId,
  operator,
  first,
  type,
  filters,
}) => {
  return (
    <Stack direction="row" spacing={1}>
      {operator && (
        <CombinationOperator
          first={first}
          provider={provider}
          operator={operator}
          categoryId={categoryId}
        />
      )}

      <Card variant="outlined" sx={{ bgcolor: "grey.50", p: 1, flex: 1 }}>
        <CategoryFiltersItems
          provider={provider}
          categoryId={categoryId}
          filter={filters}
        />

        <Stack
          mt={2}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <AddFilterInFilterGroupButton
            type={type}
            provider={provider}
            categoryId={categoryId}
          />

          <ClearGroupButton
            type={type}
            provider={provider}
            categoryId={categoryId}
          />
        </Stack>
      </Card>
    </Stack>
  );
};
