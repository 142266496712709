export type GracePeriodFrequencyTypes = "hours" | "days" | "weeks" | "";

export const convertSecondsToUnits = (
  seconds: number,
): { label: GracePeriodFrequencyTypes; value: number; message: string } => {
  const weeks = seconds / secondsOnWeeks;

  if (weeks >= 1) {
    return {
      label: "weeks",
      value: weeks,
      message: `${weeks} ${weeks === 1 ? "Week" : "Weeks"}`,
    };
  }

  const days = seconds / secondsOnDays;
  if (days >= 1) {
    return {
      label: "days",
      value: days,
      message: `${days} ${days === 1 ? "Day" : "Days"}`,
    };
  }

  const hours = seconds / secondsOnHours;
  return {
    label: "hours",
    value: hours,
    message: `${hours} ${hours === 1 ? "Hour" : "Hours"}`,
  };
};

export const multiplyWithSecondsByFrequency = (
  value: number,
  frequency: GracePeriodFrequencyTypes,
) => {
  let val = value;

  if (frequency === "hours") {
    val = val * secondsOnHours;
  } else if (frequency === "days") {
    val = val * secondsOnDays;
  } else if (frequency === "weeks") {
    val = val * secondsOnWeeks;
  }

  return val;
};

export const secondsOnHours = 3600;
export const secondsOnDays = secondsOnHours * 24;
export const secondsOnWeeks = secondsOnDays * 7;
