import { ChangeEvent, FC, useCallback } from "react";
import { Grid, TextField } from "@mui/material";
import { TagFilterValueAutocomplete } from "./TagFilterValueAutocomplete";
import {
  ProviderType,
  ResourceExplorerAccount,
  ResourceExplorerTag,
} from "../../../../../../../../../../../../../services/cloudchipr.api";

interface TagFilterValueFieldProps {
  provider: ProviderType;
  filterItem: ResourceExplorerTag;
  onChange(item: ResourceExplorerAccount | ResourceExplorerTag): void;
}

export const TagFilterValueField: FC<TagFilterValueFieldProps> = ({
  filterItem,
  onChange,
  provider,
}) => {
  const valueChangeHandler = useCallback(
    (value: string | string[]) => {
      onChange({ ...filterItem, tag_values: value });
    },
    [onChange, filterItem],
  );

  const inputValueChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      valueChangeHandler(e.target.value);
    },
    [valueChangeHandler],
  );

  const value = filterItem.tag_values;

  return (
    <Grid item xs={3}>
      {typeof value === "string" ? (
        <TextField
          fullWidth
          value={value}
          label="Value"
          size="xsmall"
          onChange={inputValueChangeHandler}
        />
      ) : (
        <TagFilterValueAutocomplete
          value={value ?? []}
          provider={provider}
          tagKey={filterItem.tag_key}
          onChange={valueChangeHandler}
        />
      )}
    </Grid>
  );
};
