import { FC, Fragment, useCallback } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";
import { addNewFilterInCategoryByProvider } from "../../../../../../../../../../../store/dimensions/dimensionsSlice";
import { ProviderType } from "../../../../../../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../../../../../../utils/hooks/useMenu.hook";
import { CategoryFilterType } from "../../../../../../../../../../../store/dimensions/utils/types/common";

interface AddCategoryFilterButtonProps {
  categoryId: string;
  provider: ProviderType;
  accountSelected: boolean;
  tagSelected: boolean;
}

export const AddCategoryFilterButton: FC<AddCategoryFilterButtonProps> = ({
  categoryId,
  provider,
  accountSelected,
  tagSelected,
}) => {
  const dispatch = useAppDispatch();
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const filterAddHandler = useCallback(
    (type: CategoryFilterType) => {
      dispatch(
        addNewFilterInCategoryByProvider({
          id: categoryId,
          provider,
          type,
        }),
      );
      closeMenu();
    },
    [dispatch, categoryId, provider, closeMenu],
  );

  const accountFilterAddHandler = useCallback(() => {
    filterAddHandler("account");
  }, [filterAddHandler]);

  const tagFilterAddHandler = useCallback(() => {
    filterAddHandler("tag");
  }, [filterAddHandler]);

  return (
    <Fragment>
      <Button
        sx={sx}
        size="small"
        startIcon={<AddIcon />}
        onClick={provider === "azure" ? accountFilterAddHandler : openMenu}
        disabled={
          provider === "azure"
            ? accountSelected
            : accountSelected && tagSelected
        }
      >
        Add Filter
      </Button>

      <Menu open={open} onClose={closeMenu} anchorEl={anchor}>
        <MenuItem onClick={accountFilterAddHandler} disabled={accountSelected}>
          Account Filter
        </MenuItem>
        <MenuItem onClick={tagFilterAddHandler} disabled={tagSelected}>
          Tag Filter
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

const sx = { whiteSpace: "nowrap", textTransform: "none", mt: 2 };
