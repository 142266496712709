import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getDashboardsHierarchyThunk } from "./getDashboardsHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const deleteDashboardHierarchyItemThunkFixedCacheKey =
  "deleteDashboardItemThunk-fixedCacheKey";

export const deleteDashboardItemThunk = createAsyncThunk(
  "dashboard/deleteDashboardItem",
  async (id: string, { dispatch }) => {
    const { deleteUsersMeOrganisationsCurrentDashboardsByDashboardId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        deleteUsersMeOrganisationsCurrentDashboardsByDashboardId.initiate(
          {
            dashboardId: id,
          },
          {
            fixedCacheKey: deleteDashboardHierarchyItemThunkFixedCacheKey,
          },
        ),
      ).unwrap();

      dispatch(getDashboardsHierarchyThunk());

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "Dashboard successfully deleted.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
