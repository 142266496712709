import { FC, useCallback } from "react";
import { MenuItem } from "@mui/material";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";

import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

interface DashboardV2ItemMoveActionMenuFolderItemProps {
  id: string;
  name: string;
  onClick(id: string): void;
}

export const DashboardV2ItemMoveActionMenuFolderItem: FC<
  DashboardV2ItemMoveActionMenuFolderItemProps
> = ({ id, name, onClick }) => {
  const clickHandler = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  return (
    <MenuItem onClick={clickHandler} sx={{ pl: 2.5 }}>
      <FolderOpenOutlinedIcon fontSize="small" />
      <TypographyWithTooltip
        title={name}
        variant="body2"
        placement="top"
        sx={{
          pl: 1,
        }}
      />
    </MenuItem>
  );
};
