import { FC, Fragment, useMemo } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, LinearProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { OffHoursGrid } from "./components/off-hours-list/OffHoursGrid";
import { PageHeader } from "../../common/PageHeader";
import { useAppAbility } from "../../../../services/permissions";
import { useAppSelector } from "../../../../store/hooks";
import { offHourDefaultProviderSelector } from "../../../../store/schedules/selectors/offHourDefaultProviderSelector";
import { useGetV2UsersMeOffHoursSchedulesQuery } from "../../../../services/cloudchipr.api";

export const OffHours: FC = () => {
  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");

  const { data, isLoading: loading } = useGetV2UsersMeOffHoursSchedulesQuery(
    {},
  );

  const defaultProvider = useAppSelector(offHourDefaultProviderSelector);

  const breadcrumbs = useMemo(() => {
    return [{ title: "Off Hours" }];
  }, []);

  return (
    <Fragment>
      <PageHeader
        breadcrumbs={breadcrumbs}
        actions={
          data?.length ? (
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to="/schedule/off-hours/create"
              disabled={canNotCreateSchedule || !defaultProvider}
            >
              <AddIcon sx={{ mr: 1 }} /> Create off hours
            </Button>
          ) : null
        }
      />

      {loading && <LinearProgress />}

      <OffHoursGrid />
    </Fragment>
  );
};
