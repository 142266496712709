import { FC, memo, RefObject, useCallback, useMemo } from "react";
import { grey } from "@mui/material/colors";
import { Stack, Theme } from "@mui/material";
import { useFlag } from "@unleash/proxy-client-react";
import { SubRowDataGridNF, SubRowDataGridProps } from "./SubRowDataGridNF";
import { ResourcesDataGridSubRowToolbar } from "./ResourcesDataGridSubRowToolbar";
import {
  dataGridViewTypeByResourceType,
  nestedGridPageSize,
} from "../utils/data-grid/constants";
import { generateExportedResourceData } from "../utils/csv-data/generateExportedResourceData";
import { getFirstResourceTypeFromResources } from "../utils/helpers/getFirstResourceTypeFromResources";
import { generateMetricsFrequenciesFromContextData } from "../utils/csv-data/generateMetricsFrequenciesFromContextData";
import { useDataGridContext } from "../../../../../../../../../../storybook/data-grid/DataGridProvider";
import { setSelectedResources } from "../../../../../../../../../../store/account/accountSlice";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { currentAccountSelectedGroupedRowsIdsByResourceTypeSelector } from "../../../../../../../../../../store/account/selectors/current-account/resources/selection/currentAccountSelectedGroupedRowsIdsByResourceTypeSelector";
import { getParentResourceType } from "../../../../../../../../../../utils/helpers/resources/getParentResourceType";
import { actionableChildResourceTypes } from "../../../../../../../../../../utils/constants/resources/resources";

interface ResourcesDataGridSubRowProps {
  resource: SubRowDataGridProps["resource"];
  globalFilter?: string;
  parentSource: string;
  parentContainerRef: RefObject<HTMLTableElement>;
  exportedColumns?: string[];
}

export const ResourcesDataGridSubRowTableView: FC<ResourcesDataGridSubRowProps> =
  memo(
    ({
      exportedColumns,
      parentContainerRef,
      resource,
      globalFilter,
      parentSource,
    }) => {
      const enableEcsActions = useFlag("EnableECSActions");
      const {
        rowExpanding: { reEstimateSize },
        cellSpecificMetadata,
      } = useDataGridContext();
      const metricsFrequencies = generateMetricsFrequenciesFromContextData(
        cellSpecificMetadata?.data,
      );
      const subRowResourceType = getFirstResourceTypeFromResources(
        resource.resources,
      );

      const selectedResources = useAppSelector((state) =>
        currentAccountSelectedGroupedRowsIdsByResourceTypeSelector(
          state,
          subRowResourceType,
        ),
      );
      const dispatch = useAppDispatch();

      const csvData = useMemo(() => {
        if (!subRowResourceType) {
          return null;
        }

        return generateExportedResourceData(
          subRowResourceType,
          resource.resources,
          exportedColumns ?? [],
          {
            metricsFrequencies,
          },
        );
      }, [
        subRowResourceType,
        resource.resources,
        exportedColumns,
        metricsFrequencies,
      ]);

      const onRowSelectionChange = useCallback(
        (data: Record<string, boolean>): void => {
          if (!subRowResourceType) {
            return;
          }
          dispatch(
            setSelectedResources({
              resourceType: subRowResourceType,
              selectedResources: data,
              parentSource: parentSource,
            }),
          );
        },
        [dispatch, subRowResourceType, parentSource],
      );

      if (!subRowResourceType) {
        return null;
      }
      const rowSelection =
        (subRowResourceType === "ecs_workload" && !enableEcsActions) ||
        !actionableChildResourceTypes.has(subRowResourceType)
          ? undefined
          : {
              rowSelectionChange: onRowSelectionChange,
              initialSelectedItems: selectedResources?.[parentSource] ?? {},
            };

      const parentWidth = parentContainerRef?.current?.offsetWidth;

      const parentResourceType =
        subRowResourceType && getParentResourceType(subRowResourceType);
      const parentViewType =
        parentResourceType &&
        dataGridViewTypeByResourceType[parentResourceType];

      return (
        <Stack
          pt={1}
          sx={resource.resources.length ? connectorStyles : undefined}
          borderLeft={!resource.resources.length ? 0 : 2}
          borderColor="grey.300"
          width={
            rowSelection && parentWidth
              ? `calc(${parentWidth}px - 64px)`
              : "inherit"
          }
        >
          <SubRowDataGridNF
            exportedColumns={exportedColumns}
            resourceType={subRowResourceType}
            resource={resource}
            styles={resourcesDataGridSubRowStyles}
            globalFilter={globalFilter}
            pagination={{
              pageSize: nestedGridPageSize,
              placement: "left",
              onPageChange: reEstimateSize,
            }}
            rowSelection={rowSelection}
            toolbar={{
              renderToolbar: (props) => (
                <ResourcesDataGridSubRowToolbar
                  {...props}
                  csvData={csvData}
                  showResourcesCountAndFilters={parentViewType !== "card"}
                  resourceType={subRowResourceType}
                  totalCount={resource.total_resources}
                  count={resource.resources.length}
                />
              ),
            }}
          />
        </Stack>
      );
    },
  );

const resourcesDataGridSubRowStyles = {
  tableHeaderRow: {
    top: 0,
    position: "sticky",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 2,
    bgcolor: grey[100],
  },
  tableContainer: {
    maxHeight: 1000,
    overflowY: "hidden",
  },
  tableRow: {
    bgcolor: grey[100],
    height: "40px",
  },
};

const connectorStyles = {
  position: "sticky",
  left: "32px",
  "&:after": {
    left: -2,
    width: 2,
    top: -15,
    height: 20,
    content: '""',
    position: "absolute",
    bgcolor: "grey.300",
  },
};
