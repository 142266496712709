import { FC } from "react";
import { Grid, Stack } from "@mui/material";
import { CommitmentsRecommendationsDataGrid } from "./data-grid/CommitmentsRecommendationsDataGrid";
import { SelectedSavingsPlansCoverage } from "./SelectedSavingsPlansCoverage";
import { DataGridProvider } from "../../../../../../storybook/data-grid/DataGridProvider";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsRecommendationsExistsSelector } from "../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsExistsSelector";

export const CommitmentsRecommendationsTables: FC = () => {
  const recommendationsExists = useAppSelector(
    commitmentsRecommendationsExistsSelector,
  );

  return (
    <Stack>
      <DataGridProvider>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={recommendationsExists ? 8 : 12}>
            <CommitmentsRecommendationsDataGrid />
          </Grid>

          {recommendationsExists && (
            <Grid item xs={10} lg={4}>
              <SelectedSavingsPlansCoverage />
            </Grid>
          )}
        </Grid>
      </DataGridProvider>
    </Stack>
  );
};
