import { FC, useCallback, useState } from "react";
import { Stack } from "@mui/material";
import { ResourceExplorerAppBarActions } from "./components/ResourceExplorerAppBarActions";
import { ResourceExplorerBillingStatuses } from "./components/resource-explorer-billing-statuses/ResourceExplorerBillingStatuses";
import { ResourceExplorerResetButton } from "./components/ResourceExplorerResetButton";
import { ResourceExplorerToolbar } from "./components/resource-explorer-toolbar/ResourceExplorerToolbar";
import { ResourceExplorerAppBarNavigation } from "./components/breadcrumb-navigation/ResourceExplorerAppBarNavigation";
import { ResourceExplorerFilters } from "../filters/ResourceExplorerFilters";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { ResourceExplorerCreateOrEditDialog } from "../../../../common/resource-explorer-create-or-edit-dialog/ResourceExplorerCreateOrEditDialog";
import { ResourceExplorerActionType } from "../../utils/types/types";
import { PageHeader } from "../../../common/PageHeader";
import { useAppSelector } from "../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";

interface ResourceExplorerAppBarProps {
  viewId: string;
}

export const ResourceExplorerAppBar: FC<ResourceExplorerAppBarProps> = ({
  viewId,
}) => {
  const { open, openDialog, closeDialog } = useDialog();
  const [dialogType, setDialogType] = useState<
    ResourceExplorerActionType | undefined
  >();
  const widgetsGettingLoading = useAppSelector(
    resourceExplorerWidgetDataLoadingSelector,
  );
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );

  const loading = widgetsGettingLoading || viewGettingLoading;

  const openDialogHandler = useCallback(
    (type: ResourceExplorerActionType) => {
      setDialogType(type);
      openDialog();
    },
    [openDialog, setDialogType],
  );

  return (
    <PageHeader
      sticky
      loading={loading}
      breadcrumbs={<ResourceExplorerAppBarNavigation viewId={viewId} />}
      actions={
        <Stack direction="row" spacing={2}>
          <ResourceExplorerBillingStatuses />

          <ResourceExplorerResetButton />

          <ResourceExplorerAppBarActions
            openDialog={openDialogHandler}
            viewId={viewId}
          />
        </Stack>
      }
      extra={
        <Stack spacing={1}>
          <ResourceExplorerFilters viewId={viewId} />
          <ResourceExplorerToolbar viewId={viewId} />

          {dialogType && (
            <ResourceExplorerCreateOrEditDialog
              open={open}
              title="Save as New View"
              onClose={closeDialog}
              viewId={viewId}
              type={dialogType}
            />
          )}
        </Stack>
      }
    />
  );
};
