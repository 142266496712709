import { FC, useCallback } from "react";
import { UniqueIdentifier } from "@dnd-kit/core";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { ResourceExplorerV2ViewActionButton } from "../view-action-menu/ResourceExplorerV2ViewActionButton";
import { ResourceExplorerV2ViewFolderActionButton } from "../folder-actions-menu/ResourceExplorerV2ViewFolderActionButton";
import { SortableTree } from "../../../sortable-tree/SortableTree";
import {
  SortableTreeOnchangeArgs,
  SortableTreeItemActionArgs,
  SortableTreeFolderActionArgs,
} from "../../../sortable-tree/utils/types";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { navigationResourceExplorerDataSelector } from "../../../../../../store/navigation/selectors/navigationResourceExplorerDataSelector";
import { updateResourceExplorerHierarchyThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/updateResourceExplorerHierarchyThunk";
import { setNavigationResourceExplorer } from "../../../../../../store/navigation/navigationSlice";
import { useAppAbility } from "../../../../../../services/permissions";

export const ResourceExplorerHierarchyDndWrapper: FC = () => {
  const hierarchyData = useAppSelector(navigationResourceExplorerDataSelector);
  const { cannot } = useAppAbility();

  const canNotCreateView = cannot("create", "resource-explorer");
  const dispatch = useAppDispatch();
  const renderFolderAction = useCallback(
    ({ id, name }: SortableTreeFolderActionArgs) => {
      return <ResourceExplorerV2ViewFolderActionButton id={id} name={name} />;
    },
    [],
  );

  const renderItemAction = useCallback(
    ({ id, name, parentId }: SortableTreeItemActionArgs) => {
      return (
        <ResourceExplorerV2ViewActionButton
          id={id}
          name={name}
          folderId={(parentId as string) ?? undefined}
        />
      );
    },
    [],
  );

  const hierarchyChangeHandler = useCallback(
    ({ index, id, parentId, items }: SortableTreeOnchangeArgs) => {
      if (!items) {
        return;
      }
      dispatch(setNavigationResourceExplorer(items));
      dispatch(
        updateResourceExplorerHierarchyThunk({
          viewId: id as string,
          index,
          folderId: (parentId as string) ?? undefined,
        }),
      );
    },
    [dispatch],
  );

  const navigateToHandler = useCallback((id: UniqueIdentifier) => {
    return `/resource-explorer/${id}`;
  }, []);

  const resetHandler = useCallback(() => {
    dispatch(setNavigationResourceExplorer(null));
  }, [dispatch]);

  if (!hierarchyData) {
    return null;
  }

  return (
    <SortableTree
      items={hierarchyData}
      renderItemAction={renderItemAction}
      renderFolderAction={renderFolderAction}
      onChange={hierarchyChangeHandler}
      getNavigateTo={navigateToHandler}
      onReset={resetHandler}
      icon={InsertChartOutlinedOutlinedIcon}
      disabled={canNotCreateView}
      emptyText="No views inside"
    />
  );
};
