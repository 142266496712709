import { FC, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { Button, LinearProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDidMount } from "rooks";
import { ScheduledJobsGrid } from "./components/ScheduledJobsGrid";
import { PageHeader } from "../../common/PageHeader";
import { useAppAbility } from "../../../../services/permissions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getSchedulesThunk } from "../../../../store/schedules/thunks/getSchedulesThunk";
import { schedulesExistSelector } from "../../../../store/schedules/selectors/schedule/schedulesExistSelector";
import { schedulesLoadingSelector } from "../../../../store/schedules/selectors/schedule/schedulesLoadingSelector";

export const ScheduledJobs: FC = () => {
  const { cannot } = useAppAbility();
  const dispatch = useAppDispatch();
  const canNotCreateSchedule = cannot("create", "schedule");
  const exist = useAppSelector(schedulesExistSelector);
  const loading = useAppSelector(schedulesLoadingSelector);

  useDidMount(() => {
    dispatch(getSchedulesThunk());
  });

  return (
    <Fragment>
      <PageHeader
        breadcrumbs={breadcrumbs}
        actions={
          exist ? (
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              to="/schedule/classic/create"
              disabled={canNotCreateSchedule}
            >
              <AddIcon sx={{ mr: 1 }} /> Create workflow
            </Button>
          ) : null
        }
      />

      {loading && <LinearProgress />}

      <ScheduledJobsGrid />
    </Fragment>
  );
};

const breadcrumbs = [{ title: "Workflows" }];
