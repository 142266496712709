import { FC, useCallback } from "react";
import { Button, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useEffectOnceWhen } from "rooks";
import { useSearchParams } from "react-router-dom";
import { ViewSelection } from "./ViewSelection";
import { PeriodAndDateSelection } from "./PeriodAndDateSelection";
import { BudgetAmount } from "./BudgetAmount";
import { BudgetName } from "./BudgetName";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { step0FormDataSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/forms/step0FormDataSelector";
import { Step0InitialValues } from "../../utils/types/formInitialValues";
import { step0ValidationSchema } from "../../utils/constants/formikValidations";
import { setCurrentBudgetData } from "../../../../../../../../../store/budgets/budgetsSlice";
import { StepsProps } from "../../utils/types/types";
import { getResourceExplorerDataByBudgetThunk } from "../../../../../../../../../store/budgets/thunks/getResourceExplorerDataByBudgetThunk";
import { resourceExplorerViewsDataSelector } from "../../../../../../../../../store/resource-explorer/selectors/all-resource-explorers/resourceExplorerViewsDataSelector";
import { useGetUsersMeOrganisationsCurrentBudgetsQuery } from "../../../../../../../../../services/cloudchipr.api";
import { isCurrentBudgetEditingSelector } from "../../../../../../../../../store/budgets/selectors/current-budget/data-selectors/isCurrentBudgetEditingSelector";

export const Step0: FC<StepsProps> = ({ onNext }) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const viewId = searchParams.get("viewId");

  const formInitialData = useAppSelector(step0FormDataSelector);
  const views = useAppSelector(resourceExplorerViewsDataSelector);
  const isEdit = useAppSelector(isCurrentBudgetEditingSelector);

  const { data: budgets } = useGetUsersMeOrganisationsCurrentBudgetsQuery();

  const {
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    dirty,
    setValues,
    handleChange,
    errors,
  } = useFormik({
    initialValues: {} as Step0InitialValues,
    validationSchema: step0ValidationSchema,
    validateOnMount: true,
    onSubmit: (values) => {
      dispatch(
        setCurrentBudgetData({
          name: values.name,
          amount: +values.amount,
          viewId: values.viewId,
          period: values.period,
          start_date: values.startDate,
        }),
      );

      onNext();
    },
  });

  const viewChaneHandler = useCallback(
    (viewId: string) => {
      setFieldValue("viewId", viewId);
      dispatch(setCurrentBudgetData({ viewId }));
      dispatch(getResourceExplorerDataByBudgetThunk());
    },
    [dispatch, setFieldValue],
  );

  useEffectOnceWhen(() => {
    formInitialData && setValues(formInitialData);
  }, !!formInitialData);

  useEffectOnceWhen(
    () => {
      if (isEdit) {
        dispatch(getResourceExplorerDataByBudgetThunk());
      } else if (viewId && !formInitialData?.viewId) {
        viewChaneHandler(viewId);
      } else if (!formInitialData?.viewId) {
        const firstAvailableViewId = views.find((view) =>
          budgets?.every((budget) => budget.view.id !== view.id),
        )?.id;

        firstAvailableViewId && viewChaneHandler(firstAvailableViewId);
      }
    },
    isEdit || !!viewId || !!(budgets && views),
  );

  if (values.viewId === undefined) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <BudgetName name={values.name} handleChange={handleChange} />

        <ViewSelection value={values.viewId} onChange={viewChaneHandler} />

        <PeriodAndDateSelection
          period={values.period}
          startDate={values.startDate}
          setFieldValue={setFieldValue}
        />

        <BudgetAmount
          error={errors.amount}
          amount={values.amount}
          handleChange={handleChange}
        />

        <Button
          type="submit"
          variant="contained"
          disabled={!dirty || !isValid}
          sx={{ width: "max-content" }}
        >
          Next
        </Button>
      </Stack>
    </form>
  );
};
