import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const CommitmentsRecommendationsSvgIcon = ({ color }: SvgIconProps) => {
  const { palette } = useTheme();

  const fillColor =
    color === "primary" ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.49984 17.4998C7.49984 17.9582 7.87484 18.3332 8.33317 18.3332H11.6665C12.1248 18.3332 12.4998 17.9582 12.4998 17.4998V16.6665H7.49984V17.4998ZM9.99984 1.6665C6.78317 1.6665 4.1665 4.28317 4.1665 7.49984C4.1665 9.48317 5.15817 11.2248 6.6665 12.2832V14.1665C6.6665 14.6248 7.0415 14.9998 7.49984 14.9998H12.4998C12.9582 14.9998 13.3332 14.6248 13.3332 14.1665V12.2832C14.8415 11.2248 15.8332 9.48317 15.8332 7.49984C15.8332 4.28317 13.2165 1.6665 9.99984 1.6665ZM12.3748 10.9165L11.6665 11.4165V13.3332H8.33317V11.4165L7.62484 10.9165C6.49984 10.1332 5.83317 8.85817 5.83317 7.49984C5.83317 5.19984 7.69984 3.33317 9.99984 3.33317C12.2998 3.33317 14.1665 5.19984 14.1665 7.49984C14.1665 8.85817 13.4998 10.1332 12.3748 10.9165Z"
          fill={fillColor}
          fillOpacity="0.87"
        />
      </svg>
    </SvgIcon>
  );
};

CommitmentsRecommendationsSvgIcon.muiName = "CommitmentsRecommendationsSvgIcon";

export default CommitmentsRecommendationsSvgIcon;
