import { FC } from "react";
import { Card, Divider, Grid, Stack, Typography } from "@mui/material";
import { CommitmentsRecommendationsMetadataCard } from "./CommitmentsRecommendationsMetadataCard";
import { money } from "../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsRecommendationsMetaDataSelector } from "../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsMetaDataSelector";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";
import { commitmentsRecommendationsExistsSelector } from "../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsExistsSelector";

export const CommitmentsRecommendationsMetadata: FC = () => {
  const metadata = useAppSelector(commitmentsRecommendationsMetaDataSelector);
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);
  const recommendationsExists = useAppSelector(
    commitmentsRecommendationsExistsSelector,
  );
  const ifExists = checkValueOnExistence(recommendationsExists);

  return (
    <Card sx={{ bgcolor: "grey.100", p: 1 }} elevation={0}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Stack>
            <Typography variant="subtitle2" fontWeight="medium">
              Before recommended purchase
            </Typography>
            <Divider />

            <CommitmentsRecommendationsMetadataCard
              loading={loading}
              value={ifExists(money(metadata.currentOnDemand.value))}
              name="Current Monthly On-Demand Spend"
              subLabel={perHour(
                ifExists(money(metadata.currentOnDemand.hourly)),
              )}
            />
          </Stack>
        </Grid>

        <Grid item xs={8}>
          <Stack>
            <Typography variant="subtitle2" fontWeight="medium">
              After recommended purchase
            </Typography>
            <Divider />

            <Stack direction="row">
              <Stack flex={1}>
                <CommitmentsRecommendationsMetadataCard
                  loading={loading}
                  value={ifExists(money(metadata.estimatedSpend.value))}
                  name="Estimated Monthly Spend"
                  subLabel={perHour(
                    ifExists(money(metadata.estimatedSpend.hourly)),
                  )}
                />
              </Stack>

              <Stack flex={1}>
                <CommitmentsRecommendationsMetadataCard
                  success
                  loading={loading}
                  value={ifExists(money(metadata.estimatedSaving.value))}
                  name="Estimated Monthly Savings"
                  subLabel={perHour(
                    ifExists(money(metadata.estimatedSaving.hourly)),
                  )}
                />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

const checkValueOnExistence =
  (recommendationsExists: boolean) => (value: string) => {
    return recommendationsExists ? value : "$-";
  };

const perHour = (value: string) => `(${value} per hour)`;
