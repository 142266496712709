import { FC, MouseEvent, Fragment, useCallback, useEffect } from "react";
import { Card, IconButton, Stack, Typography } from "@mui/material";
import { useToggle } from "rooks";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CommitmentsRecommendationsDetailsDrawer } from "./CommitmentsRecommendationsDetailsDrawer";
import { CommitmentsRecommendationsDetailsCollapse } from "./CommitmentsRecommendationsDetailsCollapse";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { commitmentsRecommendationsExistsSelector } from "../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsExistsSelector";

export const CommitmentsRecommendationsDetails: FC = () => {
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);
  const recommendationsExists = useAppSelector(
    commitmentsRecommendationsExistsSelector,
  );

  const [open, toggleOpen] = useToggle(false);
  const { open: drawerOpen, openDialog, closeDialog } = useDialog();

  const drawerOpenHandler = useCallback(
    (event: MouseEvent) => {
      openDialog();
      event.stopPropagation();
    },
    [openDialog],
  );

  useEffect(() => {
    if (open && loading) {
      toggleOpen();
    }
  }, [toggleOpen, loading, open]);

  if (!recommendationsExists) {
    return null;
  }

  return (
    <Fragment>
      <Card sx={{ bgcolor: "grey.100", p: 1 }} elevation={0}>
        <Stack
          direction="row"
          onClick={loading ? undefined : toggleOpen}
          spacing={1}
          alignItems="center"
          sx={{ cursor: "pointer" }}
        >
          {!open ? (
            <KeyboardArrowDownIcon fontSize="small" />
          ) : (
            <KeyboardArrowUpIcon fontSize="small" />
          )}

          <Typography variant="subtitle2" fontWeight="medium">
            Recommendation details
          </Typography>

          <IconButton onClick={drawerOpenHandler} size="small" color="info">
            <HelpOutlineIcon fontSize="small" />
          </IconButton>
        </Stack>

        <CommitmentsRecommendationsDetailsCollapse open={open} />
      </Card>

      <CommitmentsRecommendationsDetailsDrawer
        open={drawerOpen}
        onClose={closeDialog}
      />
    </Fragment>
  );
};
