import { ChangeEvent, FC, useCallback } from "react";
import { Grid, TextField } from "@mui/material";
import { AccountFilterValueAutocomplete } from "./AccountFilterValueAutocomplete";
import {
  ProviderType,
  ResourceExplorerAccount,
  ResourceExplorerTag,
} from "../../../../../../../../../../../../../services/cloudchipr.api";

interface FilterValueFieldProps {
  value: string | string[];
  provider: ProviderType;
  filterItem: ResourceExplorerAccount;
  onChange(item: ResourceExplorerAccount | ResourceExplorerTag): void;
}

export const AccountFilterValueField: FC<FilterValueFieldProps> = ({
  value,
  filterItem,
  onChange,
  provider,
}) => {
  const valueChangeHandler = useCallback(
    (value: string | string[]) => {
      onChange({ ...filterItem, value });
    },
    [onChange, filterItem],
  );

  const inputValueChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      valueChangeHandler(e.target.value);
    },
    [valueChangeHandler],
  );

  return (
    <Grid item xs={4}>
      {typeof value === "string" ? (
        <TextField
          fullWidth
          value={value}
          label="Value"
          size="xsmall"
          onChange={inputValueChangeHandler}
        />
      ) : (
        <AccountFilterValueAutocomplete
          value={value}
          provider={provider}
          onChange={valueChangeHandler}
        />
      )}
    </Grid>
  );
};
