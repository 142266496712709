import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { billingAccountsSelector } from "./billingAccountsSelector";
import {
  EmbeddedAccount,
  ProviderType,
} from "../../../../services/cloudchipr.api";

export const billingAccountsGroupedByProviderSelector = createDraftSafeSelector(
  [billingAccountsSelector],
  (accounts) => {
    if (!accounts) {
      return {};
    }

    return accounts.reduce(
      (acc, account) => {
        const provider = account.provider;

        if (acc[provider]) {
          acc[provider]?.push(account);
        } else {
          acc[provider] = [account];
        }

        return acc;
      },
      {} as Partial<Record<ProviderType, EmbeddedAccount[]>>,
    );
  },
);
