import { Typography } from "@mui/material";
import { getCoverageColumn } from "./common/getCoverageColumn";
import { SavingsPlanCoverageDataWithId } from "../../../../../../utils/types";
import { SavingsPlanResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { savingsPlansResourceTypeNames } from "../../../../../../utils/constants/labels";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { commitmentsDrawerDynamicHeaderCellTitles } from "../../../../../../utils/constants/commitmentsDrawerDynamicHeaderCellTitles";

export const coverageGroupedByResourceTypeColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      id: "resource_type",
      header: "Resource Type",
      accessorKey:
        "resource_type.resource_type" as keyof SavingsPlanCoverageDataWithId,
      cell: ({ row }) => {
        const rt = row.original.resource_type
          ?.resource_type as SavingsPlanResourceType;

        return (
          <Typography variant="body2" textTransform="capitalize">
            {savingsPlansResourceTypeNames[rt]}
          </Typography>
        );
      },
    },
    {
      id: "instance_family",
      accessorKey:
        "resource_type.instance_family" as keyof SavingsPlanCoverageDataWithId,
      header: "Instance Family",
      cell: ({ row }) => row.original.resource_type?.instance_family ?? "-",
    },
    {
      id: "region",
      accessorKey:
        "resource_type.region" as keyof SavingsPlanCoverageDataWithId,
      header: "Region",
      cell: ({ row }) => row.original.resource_type?.region ?? null,
    },
    {
      accessorKey: "covered_usage",
      header: () => (
        <Typography
          align="right"
          width="100%"
          variant="body2"
          fontWeight="medium"
        >
          {
            commitmentsDrawerDynamicHeaderCellTitles.coverage.account
              .coveredUsageCell
          }
        </Typography>
      ),
      meta: {
        headerTitle:
          commitmentsDrawerDynamicHeaderCellTitles.coverage.account
            .coveredUsageCell,
      },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.covered_usage)}
        </Typography>
      ),
    },
    {
      accessorKey: "uncovered_usage",
      header: () => (
        <Typography
          align="right"
          width="100%"
          variant="body2"
          fontWeight="medium"
        >
          On-Demand Spend
        </Typography>
      ),
      meta: { headerTitle: "On-Demand Spend" },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.uncovered_usage)}
        </Typography>
      ),
    },
    getCoverageColumn("resource_type"),
  ];
