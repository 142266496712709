import { DataViewTab } from "../../../../../../store/commitments/utils/types/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../services/cloudchipr.api";

export const commitmentsDrawerDynamicHeaderCellTitles: Record<
  DataViewTab,
  Record<SavingsPlanCoverageGrouping, Record<string, string>>
> = {
  coverage: {
    account: {
      coverageCell: "Coverage",
      coveredUsageCell: "Spend Covered by Commitments",
    },
    resource_type: {
      coverageCell: "Coverage",
      coveredUsageCell: "Spend Covered by Commitments",
    },
    commitment: {
      coverageCell: "Commitment Covers",
      coveredUsageCell: "Spend Covered by Commitments",
    },
  },
  savingsPlans: {
    account: {
      coverageCell: "Covered by This Savings Plan",
      coveredUsageCell: "Spend Covered by This Savings Plan",
    },
    resource_type: {
      coverageCell: "Covered by This Savings Plan",
      coveredUsageCell: "Spend Covered by This Savings Plan",
    },
    commitment: {},
  },
};
