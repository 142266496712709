import { FC, useCallback, useState } from "react";
import {
  Box,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useDidMount } from "rooks";
import {
  convertSecondsToUnits,
  GracePeriodFrequencyTypes,
  multiplyWithSecondsByFrequency,
} from "../../../../../utils/helpers/gracePeriodCalculations";
import { getFrequencyErrorMessage } from "../utils/helpers/getFrequencyErrorMessage";

interface GracePeriodFrequencyProps {
  period?: number;
  onChange(period: number): void;
  error?: string;
}

export const GracePeriodFrequency: FC<GracePeriodFrequencyProps> = ({
  period,
  onChange,
  error,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [frequency, setFrequency] = useState<GracePeriodFrequencyTypes>("");

  const periodChangeHandler = useCallback(
    (value: string, frequency: GracePeriodFrequencyTypes) => {
      onChange(multiplyWithSecondsByFrequency(+value, frequency));
    },
    [onChange],
  );

  const inputValueChangeHandler = useCallback(
    (event: any) => {
      const value = event.target.value;
      setInputValue(value);
      periodChangeHandler(value, frequency);
    },
    [frequency, periodChangeHandler],
  );

  const frequencyChangeHandler = useCallback(
    (event: any) => {
      setFrequency(event.target.value as GracePeriodFrequencyTypes);
      periodChangeHandler(
        inputValue,
        event.target.value as GracePeriodFrequencyTypes,
      );
    },
    [inputValue, periodChangeHandler],
  );

  useDidMount(() => {
    const { value, label } = convertSecondsToUnits(period ?? 0);

    setFrequency(label);
    setInputValue(`${value}`);
  });

  return (
    <Box mb={3} mt={1}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <NotificationsNoneOutlinedIcon />

        <TextField
          size="small"
          type="number"
          variant="outlined"
          sx={{ width: 80 }}
          value={inputValue}
          onChange={inputValueChangeHandler}
          InputProps={{ inputProps: { min: 1 } }}
          error={!!error}
        />

        <Select
          variant="outlined"
          size="small"
          sx={{ width: 110 }}
          value={frequency}
          onChange={frequencyChangeHandler}
        >
          <MenuItem value="hours">Hours</MenuItem>
          <MenuItem value="days">Days</MenuItem>
          <MenuItem value="weeks">Weeks</MenuItem>
        </Select>
      </Stack>
      {error && (
        <FormHelperText error sx={{ ml: 4 }}>
          {getFrequencyErrorMessage(error, frequency)}
        </FormHelperText>
      )}
    </Box>
  );
};
