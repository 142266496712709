import { FC, useCallback, useEffect, useState } from "react";
import { Button, Card, CardHeader, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { currentUserIdSelector } from "../../../../../../../../store/profile/profileSelectors";
import { getIntegrationByIdThunk } from "../../../../../../../../store/integrations/thunks/getIntegrationByIdThunk";
import {
  slackAppConnectionErrorKey,
  slackAppConnectionIdKey,
} from "../../../../../utils/constants/slack-integration";
import { SlackAppConnectionType } from "../utils/types";
import { SLACK_CONFIG } from "../../../../../../../../utils/env-var/env-var-constants";
import { useGetUsersMeIntegrationsQuery } from "../../../../../../../../services/cloudchipr.api";

interface SlackAppConnectionCreateProps {
  setFieldValue(key: string, value: any): void;
  setSlackConnectionType(type: SlackAppConnectionType): void;
}

export const SlackAppConnectionCreate: FC<SlackAppConnectionCreateProps> = ({
  setFieldValue,
  setSlackConnectionType,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const userId = useAppSelector(currentUserIdSelector);
  const [intervalId, setIntervalId] = useState(0);
  const { refetch } = useGetUsersMeIntegrationsQuery({});

  const integrationApplyHandler = useCallback(
    (id: string) => {
      dispatch(getIntegrationByIdThunk({ id, type: "slack" })).then(
        ({ payload }: any) => {
          const name = payload?.data?.name;
          const token = payload?.data?.token;
          const workspaceId = payload?.data?.workspace_id;

          if (!token || !workspaceId || !name) {
            enqueueSnackbar("Something went wrong. Please try again.", {
              variant: "snackbarAlert",
              AlertSnackBarProps: { severity: "error" },
            });
            return;
          }

          setFieldValue("id", id);
          setFieldValue("name", name);
          setFieldValue("token", token);
          setFieldValue("workspace_id", workspaceId);

          setSlackConnectionType("app-edit");
          enqueueSnackbar("Slack successfully connected!", {
            variant: "snackbarAlert",
            AlertSnackBarProps: { severity: "success" },
          });
        },
      );
    },
    [dispatch, setFieldValue, enqueueSnackbar, setSlackConnectionType],
  );

  const clickHandler = useCallback(() => {
    const url = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CONFIG.clientId}&scope=channels:join,channels:read,chat:write,chat:write.public,files:write,groups:read,users:read,channels:manage,groups:write,im:write,mpim:write&state=${userId}`;
    window.open(url, "popup", "width=700,height=800");

    const intervalId = setInterval(() => {
      const error = localStorage.getItem(slackAppConnectionErrorKey);
      const id = localStorage.getItem(slackAppConnectionIdKey);

      if (!error && !id) {
        return;
      }

      clearInterval(intervalId);

      if (error) {
        localStorage.removeItem(slackAppConnectionErrorKey);

        enqueueSnackbar(error, {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "error" },
        });
      } else if (id) {
        localStorage.removeItem(slackAppConnectionIdKey);
        integrationApplyHandler(id);
        refetch();
      }
    }, 300);

    setIntervalId(+intervalId);
  }, [userId, integrationApplyHandler, enqueueSnackbar, refetch]);

  useEffect(() => () => clearInterval(intervalId), [intervalId]);

  return (
    <div>
      <Card variant="outlined">
        <CardHeader
          sx={{ px: 2, py: 3 }}
          title={
            <Typography variant="subtitle1" color="text.secondary">
              Connect Slack to configure notifications and alerts
            </Typography>
          }
          action={
            <Button fullWidth variant="contained" onClick={clickHandler}>
              Connect Slack
            </Button>
          }
        />
      </Card>
    </div>
  );
};
