import { RootState } from "../../../../store";
import {
  FilterSet2,
  ProviderType,
  ResourceFilters,
  ResourceType,
} from "../../../../../services/cloudchipr.api";
import { getUsersMeAccountsByAccountIdFilterSetsV2Selector } from "../getUsersMeAccountsByAccountIdFilterSetsV2Selector";
import { resourceGroups } from "../../../../../utils/constants/resources/resourceGroups";

// todo: we have the same selector for current account, that can be replaced with this
export const defaultFilterTemplateFiltersByAccountIdSelector = (
  state: RootState,
  accountId: string,
  provider?: ProviderType,
): Record<ResourceType, ResourceFilters> | undefined => {
  const data = getUsersMeAccountsByAccountIdFilterSetsV2Selector(
    state,
    accountId,
  )?.data;

  const filterSet: FilterSet2 | undefined = data?.find(({ filter_set }) => {
    return filter_set.is_default;
  })?.filter_set;

  if (!filterSet) {
    return undefined;
  }

  const resourceTypes = provider ? resourceGroups.get(provider) : [];

  return filterSet?.filters.reduce(
    (acc, resourceFilter) => {
      const data = resourceTypes?.find(
        (item) => item.value === resourceFilter.type,
      );

      if (data?.actionableResourceTypes?.length) {
        data?.actionableResourceTypes?.forEach((member) => {
          acc[member] = {
            ...resourceFilter,
            type: member,
          };
        });
      } else {
        acc[resourceFilter.type] = {
          ...resourceFilter,
          type: resourceFilter.type,
        };
      }

      return acc;
    },
    {} as Record<ResourceType, ResourceFilters>,
  );
};
