import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { DimensionsState } from "../utils/types/common";
import { ProviderType } from "../../../services/cloudchipr.api";
import { filterProviderByProvider } from "../../../components/pages/dimensions/utils/constants/common";

export const changeCategoryFilterCombinationOperatorByProviderReducer: CaseReducer<
  DimensionsState,
  PayloadAction<{ id: string; provider: ProviderType }>
> = (state, action) => {
  const { id, provider } = action.payload;

  const filterProvider = filterProviderByProvider[provider];
  const filters = state.categories[id].filters[filterProvider];

  if (filters && "combination_operator" in filters) {
    filters.combination_operator =
      filters.combination_operator === "and" ? "or" : "and";
  }
};
