import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountId,
  ResourceType,
  AccountResourceFilter2,
} from "../../services/cloudchipr.api";

export type AppliedFilterType = Record<ResourceType, AccountResourceFilter2>;
type AppliedFiltersType = Record<AccountId, AppliedFilterType>;

interface FiltersInterface {
  appliedFilterSetId: string | null;
  filterChangedManually: boolean;
  appliedFilters: AppliedFiltersType;
}

const initialState: FiltersInterface = {
  appliedFilterSetId: null,
  filterChangedManually: false,
  appliedFilters: {},
};

// TODO: ReThink the overall functionality of filters.
// TODO: rename this slice and file/folder to `account-filters`, as this is only about accounts, and change our `filters-selection-nf` to `filters-selection`
export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setAppliedFilters: (
      state,
      action: PayloadAction<{
        accountId: string;
        filters: AccountResourceFilter2;
      }>,
    ) => {
      const { accountId, filters } = action.payload;
      state.appliedFilters[accountId] = state.appliedFilters[accountId] || {};

      // @ts-expect-error todo: this is because of filter_groups type in the AccountResourceFilter2
      state.appliedFilters[accountId][filters.type] = filters;
    },
    setAppliedFilterSetId: (state, action: PayloadAction<string | null>) => {
      state.appliedFilterSetId = action.payload;
      state.filterChangedManually = !action.payload;
    },
    setFilterChangedManually: (
      state,
      action: PayloadAction<boolean | undefined>,
    ) => {
      if (action.payload === undefined) {
        state.filterChangedManually = true;
      } else {
        state.filterChangedManually = action.payload;
      }
    },
  },
});

export const {
  setAppliedFilterSetId,
  setFilterChangedManually,
  setAppliedFilters,
} = filtersSlice.actions;

export default filtersSlice.reducer;
