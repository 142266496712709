import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CommitmentsInterface,
  UtilizationAndCoverageChartType,
  DataViewTab,
  UtilizationAndCoverageDetailsDrawer,
} from "./utils/types/types";
import {
  DateDataPoint,
  Dates,
  LookbackPeriod,
  SavingsPlanAccountScope,
  SavingsPlanCoverageGrouping,
  SavingsPlanPaymentOption,
  SavingsPlanTermInYears,
  SavingsPlanType,
} from "../../services/cloudchipr.api";
import { drawerDataGridPossibleGroupings } from "../../components/pages/commtiments/utilization-and-coverage/utils/constants/drawerDataGridPossibleGroupings";

const initialState: CommitmentsInterface = {
  currentOrgId: "",
  coverageAndUtilization: {
    chartType: "area",
    coverageGrouping: "resource_type",
    dateGranularity: "daily",
    dates: { label: "past_thirty_days" },
    dataViewTab: "savingsPlans",
    detailsDrawer: {
      dates: { label: "past_thirty_days" },
      dateGranularity: "daily",
      coverageGrouping: "resource_type",
    },
  },
  recommendations: {
    account_ids: [],
    term_in_years: "one_year",
    account_scope: "payer",
    savings_plan_type: "ec2_instance",
    look_back_period: "thirty_days",
    payment_option: "no_upfront",
    selectedRecommendations: {},
  },
};

export const commitmentsSlice = createSlice({
  name: "commitments",
  initialState: initialState,
  reducers: {
    setCommitmentsOrgId: (state, action: PayloadAction<string>) => {
      state.currentOrgId = action.payload;
    },

    // utilizationAndCoverage
    setCoverageAndUtilizationDates: (state, action: PayloadAction<Dates>) => {
      state.coverageAndUtilization.dates = action.payload;
    },

    setCoverageAndUtilizationDateGranularity: (
      state,
      action: PayloadAction<DateDataPoint>,
    ) => {
      state.coverageAndUtilization.dateGranularity = action.payload;
    },
    setCoverageAndUtilizationChartType: (
      state,
      action: PayloadAction<UtilizationAndCoverageChartType>,
    ) => {
      state.coverageAndUtilization.chartType = action.payload;
    },

    setCoverageGrouping: (
      state,
      action: PayloadAction<SavingsPlanCoverageGrouping>,
    ) => {
      state.coverageAndUtilization.coverageGrouping = action.payload;
    },

    setDataViewTab: (state, action: PayloadAction<DataViewTab>) => {
      state.coverageAndUtilization.dataViewTab = action.payload;
    },

    // utilizationAndCoverage - drawer
    setCoverageAndUtilizationDrawerDateGranularity: (
      state,
      action: PayloadAction<DateDataPoint>,
    ) => {
      state.coverageAndUtilization.detailsDrawer.dateGranularity =
        action.payload;
    },

    setCoverageAndUtilizationDrawerDates: (
      state,
      action: PayloadAction<Dates>,
    ) => {
      state.coverageAndUtilization.detailsDrawer.dates = action.payload;
    },

    setCoverageAndUtilizationDrawerCoverageGrouping: (
      state,
      action: PayloadAction<SavingsPlanCoverageGrouping>,
    ) => {
      state.coverageAndUtilization.detailsDrawer.coverageGrouping =
        action.payload;
    },

    setCoverageAndUtilizationDrawerTarget: (
      state,
      action: PayloadAction<
        | {
            id: UtilizationAndCoverageDetailsDrawer["targetId"];
            type: UtilizationAndCoverageDetailsDrawer["targetType"];
          }
        | undefined
      >,
    ) => {
      const id = action.payload?.id;
      const type = action.payload?.type;

      state.coverageAndUtilization.detailsDrawer.targetId = id;
      state.coverageAndUtilization.detailsDrawer.targetType = type;

      if (type) {
        const grouping = drawerDataGridPossibleGroupings[type].at(0)?.value;

        if (grouping) {
          state.coverageAndUtilization.detailsDrawer.coverageGrouping =
            grouping;
        }
      }
    },

    // recommendations
    setCommitmentsRecommendationsAccountIds: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.recommendations.account_ids = action.payload;
    },

    setCommitmentsRecommendationsTerm: (
      state,
      action: PayloadAction<SavingsPlanTermInYears>,
    ) => {
      state.recommendations.term_in_years = action.payload;
    },

    setCommitmentsRecommendationsAccountScope: (
      state,
      action: PayloadAction<SavingsPlanAccountScope>,
    ) => {
      state.recommendations.account_scope = action.payload;
    },

    setCommitmentsRecommendationsSavingsPlanType: (
      state,
      action: PayloadAction<SavingsPlanType>,
    ) => {
      state.recommendations.savings_plan_type = action.payload;
    },

    setCommitmentsRecommendationsLockBackPeriod: (
      state,
      action: PayloadAction<LookbackPeriod>,
    ) => {
      state.recommendations.look_back_period = action.payload;
    },

    setCommitmentsRecommendationsPaymentOption: (
      state,
      action: PayloadAction<SavingsPlanPaymentOption>,
    ) => {
      state.recommendations.payment_option = action.payload;
    },
    setCommitmentsRecommendationsSelectedRecommendations: (
      state,
      action: PayloadAction<Record<string, boolean>>,
    ) => {
      state.recommendations.selectedRecommendations = action.payload;
    },
    resetCommitmentsRecommendations: (state) => {
      state.recommendations = initialState.recommendations;
    },
  },
});

export const {
  setCommitmentsOrgId,
  // utilizationAndCoverage
  setDataViewTab,
  setCoverageGrouping,
  setCoverageAndUtilizationDates,
  setCoverageAndUtilizationChartType,
  setCoverageAndUtilizationDateGranularity,
  // utilizationAndCoverage - drawer
  setCoverageAndUtilizationDrawerDates,
  setCoverageAndUtilizationDrawerTarget,
  setCoverageAndUtilizationDrawerDateGranularity,
  setCoverageAndUtilizationDrawerCoverageGrouping,
  // recommendations
  resetCommitmentsRecommendations,
  setCommitmentsRecommendationsTerm,
  setCommitmentsRecommendationsAccountIds,
  setCommitmentsRecommendationsAccountScope,
  setCommitmentsRecommendationsPaymentOption,
  setCommitmentsRecommendationsLockBackPeriod,
  setCommitmentsRecommendationsSavingsPlanType,
  setCommitmentsRecommendationsSelectedRecommendations,
} = commitmentsSlice.actions;

export default commitmentsSlice.reducer;
