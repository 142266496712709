import { FC, useCallback } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import { useDebounce, useWillUnmount } from "rooks";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setFilterValue } from "../../../../../../store/accounts/accountsSlice";
import { accountsFilterValueSelector } from "../../../../../../store/accounts/selectors/slice-data/accountsFilterValueSelector";

export const AccountsSearch: FC = () => {
  const dispatch = useAppDispatch();
  const filterValue = useAppSelector(accountsFilterValueSelector);

  const filterValueChangeHandler = useCallback(
    (value: string) => dispatch(setFilterValue(value.trim())),
    [dispatch],
  );

  const setDebouncedValue = useDebounce(filterValueChangeHandler, 300);

  const handleChange = useCallback(
    (event: any) => setDebouncedValue(event.target.value),
    [setDebouncedValue],
  );

  useWillUnmount(() => {
    dispatch(setFilterValue(""));
  });

  return (
    <TextField
      size="small"
      type="search"
      placeholder="Search account"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      variant="outlined"
      onChange={handleChange}
      defaultValue={filterValue}
    />
  );
};
