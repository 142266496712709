import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ProviderType } from "../../../../../../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";
import { addNewFilterItemInCategoryByProvider } from "../../../../../../../../../../../store/dimensions/dimensionsSlice";
import { CategoryFilterType } from "../../../../../../../../../../../store/dimensions/utils/types/common";
import { capitalizeString } from "../../../../../../../../../../../utils/helpers/capitalizeString";

interface AddFilterInFilterGroupButtonProps {
  type: CategoryFilterType;
  categoryId: string;
  provider: ProviderType;
}

export const AddFilterInFilterGroupButton: FC<
  AddFilterInFilterGroupButtonProps
> = ({ provider, categoryId, type }) => {
  const dispatch = useAppDispatch();

  const filterAddHandler = useCallback(() => {
    dispatch(
      addNewFilterItemInCategoryByProvider({
        type,
        provider,
        id: categoryId,
      }),
    );
  }, [dispatch, categoryId, provider, type]);

  return (
    <Button
      sx={sx}
      size="small"
      startIcon={<AddIcon />}
      onClick={filterAddHandler}
    >
      Add {capitalizeString(type)}
    </Button>
  );
};

const sx = { whiteSpace: "nowrap", textTransform: "none" };
