import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { useDidMount, useIntervalWhen, useWillUnmount } from "rooks";
import {
  Button,
  Divider,
  IconButton,
  LinearProgress,
  Stack,
  Box,
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {
  ImperativePanelHandle,
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from "react-resizable-panels";
import { AccountNavigation } from "./components/navigation/AccountNavigation";
import { HeaderV2 } from "./components/header/HeaderV2";
import { CreateWorkflowDrawer } from "./components/header/create-workflow/CreateWorkflowDrawer";
import { AccountResourcesArea } from "./components/account-resources-area/AccountResourcesArea";
import { CleanDialog } from "../common/clean/clean-dialog/CleanDialog";
import { AccountId } from "../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useAppAbility } from "../../../services/permissions";
import { accountInitialDataThunk } from "../../../store/account/thunks/accountInitialDataThunk";
import { useLayoutConfigHook } from "../../navigation/utils/hooks/useLayoutConfigHook";
import { COMPONENT_ID } from "../../../utils/constants/components-ids";
import { getSettingsByKeyThunk } from "../../../store/settings/getSettingsByKeyThunk";
import { reset, setRegion } from "../../../store/account/accountSlice";
import { accountRefreshDataThunk } from "../../../store/account/thunks/accountRefreshDataThunk";
import { currentAccountPageStateSelector } from "../../../store/account/selectors/current-account/currentAccountPageStateSelector";
import { OutletContextType } from "../../layout/MainLayout";

export const AccountV2: FC = () => {
  const { accountId } = useParams<{
    accountId: AccountId;
  }>();

  const pageState = useAppSelector(currentAccountPageStateSelector);

  const sideBarRef = useRef<ImperativePanelHandle>(null);

  const [collapsed, setCollapsed] = useState<boolean>();

  const handleCollapseToggle = useCallback(() => {
    if (sideBarRef.current?.isExpanded()) {
      sideBarRef.current?.collapse();
    } else {
      sideBarRef.current?.expand();
    }
  }, []);

  const handleSideBarCollapse = useCallback(() => {
    setCollapsed(true);
  }, []);

  const handleSideBarExpand = useCallback(() => {
    setCollapsed(false);
  }, []);

  const dispatch = useAppDispatch();
  const { can } = useAppAbility();
  const canViewAccount = can("view", "account");

  const { layoutSizes } = useOutletContext<OutletContextType>();

  const { min, max, collapsedSize } = useLayoutConfigHook(
    52,
    220,
    320,
    layoutSizes?.[1],
  );

  useDidMount(() => {
    setCollapsed(sideBarRef.current?.isCollapsed());
    dispatch(getSettingsByKeyThunk("smart_tag_ttl"));
  });

  useEffect(() => {
    if (accountId && canViewAccount) {
      dispatch(accountInitialDataThunk(accountId));
    }
  }, [accountId, canViewAccount, dispatch]);

  useIntervalWhen(
    () => {
      if (accountId) {
        dispatch(accountRefreshDataThunk());
      }
    },
    180_000,
    !!(accountId && canViewAccount),
    false,
  );

  useWillUnmount(() => {
    dispatch(reset());
    dispatch(setRegion(null));
  });

  if (pageState !== "fulfilled") {
    return <LinearProgress />;
  }

  if (!canViewAccount) {
    return null;
  }

  return (
    <Stack height="100%">
      <HeaderV2 />

      <PanelGroup direction="horizontal" autoSaveId="account-page-navigation">
        <Panel
          collapsible
          minSize={min}
          maxSize={max}
          collapsedSize={collapsedSize}
          ref={sideBarRef}
          onCollapse={handleSideBarCollapse}
          onExpand={handleSideBarExpand}
          style={{ overflowY: "auto" }}
        >
          {collapsed !== undefined && (
            <Box bgcolor="white" height="100%">
              {collapsed ? (
                <IconButton
                  color="primary"
                  size="small"
                  onClick={handleCollapseToggle}
                  sx={{ borderRadius: 2, m: 1 }}
                >
                  <MenuOpenIcon
                    sx={{
                      transform: "rotate(180deg)",
                    }}
                  />
                </IconButton>
              ) : (
                <Button
                  variant="text"
                  startIcon={<MenuOpenIcon />}
                  onClick={handleCollapseToggle}
                  sx={{ m: 1, textTransform: "initial" }}
                >
                  Collapse Services
                </Button>
              )}

              <AccountNavigation collapsed={collapsed} />
            </Box>
          )}
        </Panel>
        <PanelResizeHandle>
          <Divider orientation="vertical" />
        </PanelResizeHandle>
        <Panel
          style={{ overflowY: "auto" }}
          id={COMPONENT_ID.ACCOUNT_PAGE_SCROLL_AREA}
        >
          <AccountResourcesArea />
        </Panel>
      </PanelGroup>

      <CleanDialog />
      <CreateWorkflowDrawer />
    </Stack>
  );
};
