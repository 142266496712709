import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { CategoryFilterType, DimensionsState } from "../utils/types/common";
import { ProviderType } from "../../../services/cloudchipr.api";
import { filterProviderByProvider } from "../../../components/pages/dimensions/utils/constants/common";
import {
  emptyAccountsFilter,
  emptyTagsFilter,
} from "../utils/constants/common";

export const addNewFilterInCategoryByProviderReducer: CaseReducer<
  DimensionsState,
  PayloadAction<{
    id: string;
    provider: ProviderType;
    type: CategoryFilterType;
  }>
> = (state, action) => {
  const { id, provider, type } = action.payload;

  const filterProvider = filterProviderByProvider[provider];

  const newFilter = type === "tag" ? emptyTagsFilter : emptyAccountsFilter;

  state.categories[id].filters[filterProvider] = {
    ...state.categories[id].filters[filterProvider],
    ...newFilter,
  };
};
