import { FC, Fragment } from "react";
import { Skeleton, Typography } from "@mui/material";

interface CommitmentsRecommendationsMetadataCardProps {
  name: string;
  value: string;
  subLabel: string;
  success?: boolean;
  loading?: boolean;
}

export const CommitmentsRecommendationsMetadataCard: FC<
  CommitmentsRecommendationsMetadataCardProps
> = ({ name, success, subLabel, value, loading }) => {
  return (
    <Fragment>
      <Typography
        mt={1}
        variant="caption"
        fontWeight="medium"
        color="text.secondary"
      >
        {name}
      </Typography>

      <Typography
        variant="body1"
        fontWeight="bold"
        color={success ? "success.light" : undefined}
      >
        {loading ? <Skeleton height={24} width={100} /> : value}
      </Typography>

      <Typography variant="tiny" color="text.secondary">
        {loading ? <Skeleton height={15} width={100} /> : subLabel}
      </Typography>
    </Fragment>
  );
};
