import { ChangeEvent, FC, useCallback, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { updateResourceExplorerViewNameThunk } from "../../../../../../store/resource-explorer/thunks/resource-explorer-hierarchy/updateResourceExplorerViewNameThunk";
import { updateResourceExplorerLoadingSelector } from "../../../../../../store/resource-explorer/selectors/loading/updateResourceExplorerLoadingSelector";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";

interface ResourceExplorerV2ViewRenameDialogProps {
  open: boolean;
  id: string;
  name: string;
  closeMenu(): void;
}

export const ResourceExplorerV2ViewRenameDialog: FC<
  ResourceExplorerV2ViewRenameDialogProps
> = ({ open, closeMenu, id, name }) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(name);

  const loading = useAppSelector((state) =>
    updateResourceExplorerLoadingSelector(state, id),
  );

  const renameHandler = useCallback(async () => {
    await dispatch(
      updateResourceExplorerViewNameThunk({
        id,
        name: value,
      }),
    );
    closeMenu();
  }, [dispatch, closeMenu, id, value]);

  const inputChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [],
  );

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closeMenu}
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable title="Rename View" onClose={closeMenu} />

      <DialogContent dividers sx={{ py: 3 }}>
        <TextField
          autoFocus
          fullWidth
          type="text"
          value={value}
          size="small"
          variant="outlined"
          onChange={inputChangeHandler}
          placeholder="View Name"
          label="View Name"
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Button color="tertiary" onClick={closeMenu}>
          Cancel
        </Button>
        <LoadingButton
          disabled={value === name || !value}
          onClick={renameHandler}
          loading={loading}
          variant="contained"
        >
          Rename
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
