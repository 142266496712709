import { FC } from "react";
import { Button, Stack, Typography } from "@mui/material";
import { Can } from "../../../../../../services/permissions";

export interface EmptyUsersListProps {
  onActionClick(): void;
}

export const EmptyUsersList: FC<EmptyUsersListProps> = ({ onActionClick }) => {
  return (
    <Stack justifyContent="center" alignItems="center" mt={27}>
      <Typography component="h4" variant="h4" textAlign="center">
        🕒 It’s Time
        <br /> to invite your first teammate!
      </Typography>
      <Typography variant="body1" textAlign="center" mt={2} mb={6}>
        Send an invitation by sending a link via email!
      </Typography>
      <Can I="create" a="user" passThrough>
        {(isAllowed) => (
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={onActionClick}
            disabled={!isAllowed}
          >
            Send invite
          </Button>
        )}
      </Can>
    </Stack>
  );
};
