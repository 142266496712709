import { FC, ReactElement } from "react";
import {
  Box,
  TableContainer,
  TableHead,
  Typography,
  Table,
  TableBody,
  TableCell,
} from "@mui/material";

interface IntegrationCustomMessageDataGridProps {
  data: { header: string; value: string }[];
  label: string | ReactElement;
}

export const IntegrationCustomMessageDataGrid: FC<
  IntegrationCustomMessageDataGridProps
> = ({ data, label }) => {
  return (
    <Box>
      <Typography variant="caption" fontWeight="bold">
        {label}
      </Typography>
      <TableContainer
        sx={{
          border: 1,
          borderColor: "grey.300",
          borderRadius: 1,
          borderBottom: 0,
        }}
      >
        <Table size="small">
          <TableHead
            sx={{
              backgroundColor: "grey.100",
            }}
          >
            {data.map((item, index) => {
              const hasBorder = index !== data.length - 1;
              return (
                <TableCell
                  sx={{
                    borderRight: hasBorder ? 1 : "none",
                    borderRightColor: hasBorder ? "grey.300" : "none",
                  }}
                  key={item.header}
                >
                  {item.header}
                </TableCell>
              );
            })}
          </TableHead>
          <TableBody>
            {data.map((item, index) => {
              const hasBorder = index !== data.length - 1;
              return (
                <TableCell
                  key={item.header}
                  sx={{
                    borderRight: hasBorder ? 1 : "none",
                    borderRightColor: hasBorder ? "grey.300" : "none",
                  }}
                >
                  {item.value}
                </TableCell>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
