import { FC, memo, ReactElement, useCallback } from "react";
import {
  Box,
  MenuItem,
  Select,
  SelectProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartOutlineOutlined from "@mui/icons-material/PieChartOutlineOutlined";
import { ChartType } from "../../utils/types/types";
import { useMultiTypeChartContext } from "../../MultiTypeChartProvider";
import AreaChartSvgIcon from "../../../../../assets/images/icons/AreaChartSvgIcon";

const icons: Record<ChartType, ReactElement> = {
  line: <StackedLineChartIcon color="action" />,
  bar: <BarChartIcon color="action" />,
  multiStack: <StackedBarChartIcon color="action" />,
  stack: <StackedBarChartIcon color="action" />,
  pie: <PieChartOutlineOutlined color="action" />,
  area: <AreaChartSvgIcon color="action" />,
};

export type ChartTypeSelectionType = "button" | "select";

export interface ChartTypeSelectProps {
  selectionType?: ChartTypeSelectionType;
  availableTypes?: ChartType[];
  onChartTypeChange?(type: ChartType): void;
}

export const ChartTypeSelect: FC<ChartTypeSelectProps> = memo(
  ({ onChartTypeChange, availableTypes, selectionType = "button" }) => {
    const {
      hover,
      chartType: { type, onChange },
    } = useMultiTypeChartContext();

    const changeHandler = useCallback(
      (chartType: ChartType) => {
        if (!chartType) {
          return;
        }

        onChange(chartType);
        onChartTypeChange?.(chartType);
        hover.setKey("");
      },
      [hover, onChange, onChartTypeChange],
    );

    const chartTypeChangeHandler = useCallback(
      (_: unknown, chartType: ChartType) => changeHandler(chartType),
      [changeHandler],
    );

    const chartTypeSelectHandler = useCallback(
      (event: any) => changeHandler(event.target.value),
      [changeHandler],
    );

    if (!availableTypes) {
      return null;
    }

    if (selectionType === "select") {
      return (
        <Select
          value={type}
          size="small"
          sx={selectSx}
          MenuProps={selectMenuProps}
          onChange={chartTypeSelectHandler}
          renderValue={(type) => <Box pt={1}>{icons[type]}</Box>}
        >
          {availableTypes.map((type) => (
            <MenuItem value={type} key={type}>
              {icons[type]}
            </MenuItem>
          ))}
        </Select>
      );
    }

    return (
      <ToggleButtonGroup
        exclusive
        size="small"
        value={type}
        onChange={chartTypeChangeHandler}
      >
        {availableTypes.map((type) => (
          <ToggleButton value={type} key={type}>
            {icons[type]}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  },
);

const selectMenuProps: SelectProps["MenuProps"] = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: 5,
    horizontal: "left",
  },
};

const selectSx: SelectProps["sx"] = {
  height: 32,
  bgcolor: "white",
  "& .MuiSelect-select": { p: 0.5 },
};
