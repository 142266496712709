import { FC } from "react";
import { Alert, Button, Stack, Typography, Box } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import {
  useGetV2UsersMeOffHoursSchedulesQuery,
  useGetUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendationsQuery as useDailyRecommendations,
  useGetUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendationsQuery as useWeeklyRecommendations,
} from "../../../../../../services/cloudchipr.api";
import { useAppAbility } from "../../../../../../services/permissions";
import { useAppSelector } from "../../../../../../store/hooks";
import EmptyStateImage from "../../../../../../assets/images/empty_off-hours-list.png";
import { stateAccountsSelector } from "../../../../../../store/accounts/selectors/slice-data/stateAccountsSelector";
import { offHourDefaultProviderSelector } from "../../../../../../store/schedules/selectors/offHourDefaultProviderSelector";

export const OffHoursGridEmptyState: FC = () => {
  const { cannot } = useAppAbility();
  const canNotCreateSchedule = cannot("create", "schedule");
  const { data, isLoading } = useGetV2UsersMeOffHoursSchedulesQuery({});
  const allAccounts = useAppSelector(stateAccountsSelector);
  const defaultProvider = useAppSelector(offHourDefaultProviderSelector);

  const { data: computeDaily } = useDailyRecommendations({ group: "compute" });
  const { data: computeWeekly } = useWeeklyRecommendations({
    group: "compute",
  });
  const { data: dbDaily } = useDailyRecommendations({ group: "database" });
  const { data: dbWeekly } = useWeeklyRecommendations({ group: "database" });

  const recommendationExist =
    dbDaily?.length ||
    dbWeekly?.length ||
    computeDaily?.length ||
    computeWeekly?.length;

  if (isLoading || data?.length) {
    return null;
  }

  return (
    <Stack justifyContent="center" alignItems="center" mt={12}>
      <img src={EmptyStateImage} alt="EmptyStateImage" />
      <Typography component="h4" variant="h4" textAlign="center" mt={5}>
        It’s Time to plan your first Off Hours!
      </Typography>
      <Box maxWidth={recommendationExist ? "unset" : 520}>
        {!defaultProvider ? (
          !!allAccounts?.length && (
            <Alert
              variant="outlined"
              severity="warning"
              sx={{ display: "flex", alignItems: "center", mt: 5 }}
            >
              All your accounts are connected in Read-Only mode, please switch
              to the Read/Write mode in order to enable this functionality.
            </Alert>
          )
        ) : (
          <Typography variant="body1" textAlign="center" mt={2}>
            {recommendationExist
              ? "During OFF Hours selected resources will be stopped. "
              : "Select the resources and time interval to automatically STOP and START them."}
          </Typography>
        )}
      </Box>

      <Button
        sx={{ mt: 6 }}
        variant="contained"
        color="primary"
        size="small"
        component={RouterLink}
        to="/schedule/off-hours/create"
        disabled={canNotCreateSchedule || !defaultProvider}
      >
        Create off hours
      </Button>
    </Stack>
  );
};
