import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AccountId, ResourceType } from "../../services/cloudchipr.api";
import { CleanActionTypes } from "../../utils/clean-options";

export type CleanResourceType = {
  type?: string;
  id: string;
  action?: "stop" | "terminate";
  snapshot?: boolean;
  provider_unique_identifier: string;
};

export interface CleanDetails {
  accountId: AccountId;
  resourceType: ResourceType;
  resources?: CleanResourceType[];
  excludedResources?: CleanResourceType[];
}

export interface CleanProcess {
  executionLogId: string;
  cleanProcess: CleanDetails;
}

export interface CleanInterface {
  new: CleanDetails | null;
  type: CleanActionTypes | null;
  isOpen: boolean;
  processes: Record<string, CleanDetails>;
  currentOpenId: string | null;
}

const initialState: CleanInterface = {
  new: null,
  isOpen: false,
  type: null,
  processes: {},
  currentOpenId: null,
};

export const cleanSlice = createSlice({
  name: "clean",
  initialState: initialState,
  reducers: {
    setNewClean: (state, action: PayloadAction<CleanDetails | null>) => {
      state.new = action.payload;
    },
    setCurrentCleanId: (state, action: PayloadAction<string | null>) => {
      state.currentOpenId = action.payload;
    },
    setCleanOpen: (state, action: PayloadAction<boolean>) => {
      state.isOpen = action.payload;
    },
    setCleanActionType: (
      state,
      action: PayloadAction<CleanActionTypes | null>,
    ) => {
      state.type = action.payload;
    },
    addCleanProcess: (state, action: PayloadAction<CleanProcess>) => {
      const { executionLogId, cleanProcess } = action.payload;
      state.processes[executionLogId] = cleanProcess;
    },
    removeCleanProcess: (state, action: PayloadAction<string>) => {
      const { [action.payload]: value, ...withoutProcess } = state.processes;
      state.processes = withoutProcess;
    },
    removeCurrentOpenCleanProcess: (state) => {
      const currentOpenCleanId = state.currentOpenId;
      state.isOpen = false;
      state.currentOpenId = null;
      if (currentOpenCleanId) {
        const { [currentOpenCleanId]: value, ...withoutProcess } =
          state.processes;
        state.processes = withoutProcess;
      }
    },
  },
});

export const {
  setNewClean,
  setCurrentCleanId,
  setCleanOpen,
  addCleanProcess,
  removeCleanProcess,
  setCleanActionType,
} = cleanSlice.actions;

export default cleanSlice.reducer;
