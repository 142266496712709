import { FC, Fragment } from "react";
import { CategoryBasedOnAccounts } from "./based-on-accounts/CategoryBasedOnAccounts";
import { CategoryBasedOnFilters } from "./based-on-filters/CategoryBasedOnFilters";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { categoryFilterTypeSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/categoryFilterTypeSelector";
import { dimensionCategoriesEntriesSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/dimensionCategoriesEntriesSelector";

export const DimensionsCategoriesList: FC = () => {
  const categoriesEntries = useAppSelector(dimensionCategoriesEntriesSelector);
  const filterType = useAppSelector(categoryFilterTypeSelector);

  return (
    <Fragment>
      {categoriesEntries.map(([id, category]) => {
        return filterType === "filter_based" ? (
          <CategoryBasedOnFilters
            key={id}
            category={category}
            deleteDisabled={categoriesEntries.length === 1}
          />
        ) : (
          <CategoryBasedOnAccounts
            key={id}
            category={category}
            deleteDisabled={categoriesEntries.length === 1}
          />
        );
      })}
    </Fragment>
  );
};
