import { useCallback, useEffect, useRef, useState } from "react";
import { CategoricalChartFunc } from "recharts/types/chart/generateCategoricalChart";
import { ChartDataType } from "../types/types";
import { chartDataPointKey } from "../constants/constants";

export type ZoomChangeFunction = (
  start: string | number,
  end: string | number,
) => void;

export interface ChartZoomFunctionality {
  zoomed: boolean;
  zoomedData: ChartDataType[];
  zoomResetHandler(): void;
  handlers: {
    onMouseMove: CategoricalChartFunc;
    onMouseDown: CategoricalChartFunc;
    onMouseUp: CategoricalChartFunc;
  };
  referenceAreaProps: {
    x1?: number | string;
    x2?: number | string;
    y1?: number | string;
    y2?: number | string;
  };
  zooming: boolean;
}

export const useChartZoom = (
  initialData: ChartDataType[],
  enabled: boolean,
  onZoomChange?: ZoomChangeFunction,
): ChartZoomFunctionality => {
  /* eslint-disable */
  const [data, setData] = useState(initialData);
  const downRef = useRef(data?.at(0)?.[chartDataPointKey] ?? "");
  /* eslint-enable */

  const [x1, setX1] = useState("");
  const [x2, setX2] = useState("");

  const zoomHandler = useCallback(
    (upLabel: string) => {
      if (!downRef.current || !upLabel) {
        return;
      }

      setData((data) => {
        const downResult = data.findIndex(
          (item) => item[chartDataPointKey] === downRef.current,
        );
        const upResult = data.findIndex(
          (item) => item[chartDataPointKey] === upLabel,
        );

        setX1("");
        setX2("");

        if (downResult < 0 || upResult < 0 || downResult === upResult) {
          return data;
        }

        const left = Math.min(downResult, upResult);
        const right = Math.max(downResult, upResult);

        onZoomChange?.(
          data[left].recharts_chart_data_point_unique_key,
          data[right].recharts_chart_data_point_unique_key,
        );

        return data.slice(left, right + 1);
      });
    },
    [setData, onZoomChange],
  );

  const onMouseDown: CategoricalChartFunc = useCallback(
    (e) => {
      if (!enabled || !e?.activeLabel) {
        return;
      }

      setX1(e.activeLabel);
      downRef.current = e.activeLabel;
    },
    [enabled],
  );

  const onMouseUp: CategoricalChartFunc = useCallback(
    (e) => {
      if (!enabled || !e?.activeLabel) {
        return;
      }

      zoomHandler(e.activeLabel);
      downRef.current = "";
    },
    [enabled, zoomHandler],
  );

  const onMouseMove: CategoricalChartFunc = useCallback(
    (e) => {
      if (!enabled || !e?.activeLabel || !x1) {
        return;
      }

      setX2(e.activeLabel);
    },
    [enabled, x1],
  );

  const zoomResetHandler = useCallback(() => {
    setData(initialData);
    setX1("");
    setX2("");
    downRef.current = "";
  }, [setData, initialData]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    setData(initialData);
  }, [enabled, initialData]);

  return {
    zoomResetHandler,
    zoomed: initialData.length !== data.length,
    zoomedData: enabled ? data : initialData,
    referenceAreaProps: { x1, x2 },
    handlers: {
      onMouseDown,
      onMouseUp,
      onMouseMove,
    },
    zooming: !!downRef.current && !!x1,
  };
};
