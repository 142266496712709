import { FC } from "react";
import { Link, Typography } from "@mui/material";

interface CreateWorkflowSubmittedMessageProps {
  id: string;
  name: string;
}

export const CreateWorkflowSubmittedMessage: FC<
  CreateWorkflowSubmittedMessageProps
> = ({ name, id }) => {
  return (
    <Link
      gap={1}
      href={`/schedule/classic/edit/${id}`}
      display="flex"
      color="inherit"
      target="_blank"
      underline="none"
      alignItems="center"
    >
      <Typography
        fontWeight="bold"
        variant="inherit"
        sx={{ textDecoration: "underline", textUnderlineOffset: 4 }}
      >
        {name}
      </Typography>{" "}
      created!
    </Link>
  );
};
