import { FC, memo, useCallback, ChangeEvent } from "react";
import { Box, FormControl, RadioGroup, Stack, Typography } from "@mui/material";
import { CloudProvider } from "./CloudProvider";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { getProviderName } from "../../../../../../utils/helpers/providers/getProviderName";
import { awsDisabledTooltipTitleSelector } from "../../../../../../store/schedules/selectors/providers-disabled-selectors/awsDisabledTooltipTitleSelector";
import { azureDisabledTooltipTitleSelector } from "../../../../../../store/schedules/selectors/providers-disabled-selectors/azureDisabledTooltipTitleSelector";
import { gcpDisabledTooltipTitleSelector } from "../../../../../../store/schedules/selectors/providers-disabled-selectors/gcpDisabledTooltipTitleSelector";
import { AutomationType } from "../../utils/types/types";

interface SchedulesProvidersProps {
  isDisabled: boolean;
  parentScheduleType: AutomationType;
  activeProvider?: ProviderType;
  onChange(provider: ProviderType): void;
}

export const SchedulesProviders: FC<SchedulesProvidersProps> = memo(
  ({ activeProvider, onChange, isDisabled, parentScheduleType }) => {
    const awsTooltipTitle = useAppSelector((state) =>
      awsDisabledTooltipTitleSelector(
        state,
        parentScheduleType === "off-hours",
      ),
    );
    const gcpTooltipTitle = useAppSelector((state) =>
      gcpDisabledTooltipTitleSelector(
        state,
        parentScheduleType === "off-hours",
      ),
    );
    const azureTooltipTitle = useAppSelector((state) =>
      azureDisabledTooltipTitleSelector(
        state,
        parentScheduleType === "off-hours",
      ),
    );

    const providerChangeHandler = useCallback(
      (e: ChangeEvent<HTMLInputElement>) =>
        onChange(e.target.value as ProviderType),
      [onChange],
    );

    return (
      <Box alignSelf="stretch">
        <Typography variant="body1" fontWeight="bold" gutterBottom>
          Select Schedule Environment
        </Typography>

        <Stack direction="row" justifyContent="space-between" gap={2} mt={2}>
          <FormControl>
            <RadioGroup onChange={providerChangeHandler}>
              <CloudProvider
                type="aws"
                label={getProviderName("aws", { title: true })}
                isActive={activeProvider === "aws"}
                tooltipTitle={awsTooltipTitle}
                disabled={isDisabled || !!awsTooltipTitle}
              />

              <CloudProvider
                type="gcp"
                label={getProviderName("gcp", { title: true })}
                isActive={activeProvider === "gcp"}
                tooltipTitle={gcpTooltipTitle}
                disabled={isDisabled || !!gcpTooltipTitle}
              />

              <CloudProvider
                type="azure"
                label={getProviderName("azure", {
                  title: true,
                })}
                isActive={activeProvider === "azure"}
                tooltipTitle={azureTooltipTitle}
                disabled={isDisabled || !!azureTooltipTitle}
              />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Box>
    );
  },
);
