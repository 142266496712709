import { DropdownSelectOption } from "../../../../common/select/dropdown-select/utils/types/types";

export const categoryAccountsFilterFn = (
  option: DropdownSelectOption,
  keyword: string,
) => {
  keyword = keyword.trim().toLowerCase();

  if (!option.rawValue) {
    return false;
  }

  const {
    provider,
    provider_account_id,
    provider_account_name,
    provider_access_type,
  } = option.rawValue;

  const aim =
    `${provider} ${provider_account_id} ${provider_account_name} ${provider_access_type} `.toLowerCase();

  return aim.includes(keyword);
};
