import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { dimensionNameSelector } from "../../selectors/slice-data/dimensionNameSelector";
import { createCategoryByDimensionIdThunk } from "../categories/createCategoryByDimensionIdThunk";
import { dimensionCategoriesEntriesSelector } from "../../selectors/slice-data/dimensionCategoriesEntriesSelector";

export const dimensionCreateFixedCacheKey =
  "postUsersMeOrganisationsCurrentDimensions-fixed-cache-key";

export const createDimensionThunk = createAsyncThunk(
  "dimensions/createDimension",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const dimensionName = dimensionNameSelector(state);
    const categoriesEntries = dimensionCategoriesEntriesSelector(state);

    const { postUsersMeOrganisationsCurrentDimensions } =
      cloudChiprApi.endpoints;

    try {
      const dimension = await dispatch(
        postUsersMeOrganisationsCurrentDimensions.initiate(
          { body: { name: dimensionName } },
          { fixedCacheKey: dimensionCreateFixedCacheKey },
        ),
      ).unwrap();

      const promises = categoriesEntries.map(([id, category]) => {
        return dispatch(
          createCategoryByDimensionIdThunk({
            dimensionId: dimension.id,
            categoryName: category.name,
            categoryId: id,
            filters: category.filters,
          }),
        );
      });

      await Promise.all(promises);

      return dimension;
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
