import { FC } from "react";
import { Button, Stack, Tooltip } from "@mui/material";
import { OpenInResourceExplorerButton } from "./OpenInResourceExplorerButton";
import { CategoryWithCosts } from "../../../../../../services/cloudchipr.api";

interface FiltersCountButtonCellProps {
  count: number;
  onClick(): void;
  dimensionId: string;
  categoryId?: string;
  basedOn?: CategoryWithCosts["type"];
}

export const FiltersCountButtonCell: FC<FiltersCountButtonCellProps> = ({
  count,
  basedOn,
  onClick,
  dimensionId,
  categoryId,
}) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Tooltip
        title={basedOn === "account_based" ? "View Accounts" : "View Filters"}
        placement="top"
        arrow
      >
        <Button variant="outlined" size="xsmall" onClick={onClick}>
          {count} {basedOn === "account_based" ? "Accounts" : "Filters"}
        </Button>
      </Tooltip>

      <OpenInResourceExplorerButton
        dimensionId={dimensionId}
        categoryId={categoryId}
      />
    </Stack>
  );
};
