import { commitmentsRecommendationsListSelector } from "./commitmentsRecommendationsListSelector";
import { RootState } from "../../../../store";
import { commitmentsRecommendationsSelectedRecommendationsSelector } from "../commitmentsRecommendationsSelectedRecommendationsSelector";

export const commitmentsRecommendationsTotalsSelector = (state: RootState) => {
  const initialValue = { coverage: 0, notCovered: 0 };

  const recommendations = commitmentsRecommendationsListSelector(state);
  const selectedRecommendations =
    commitmentsRecommendationsSelectedRecommendationsSelector(state);

  const totals =
    recommendations?.reduce((acc, rec) => {
      if (!selectedRecommendations[rec.id]) {
        return acc;
      }

      acc.coverage =
        acc.coverage + rec.estimated_covered_usage_over_look_back_period;

      acc.notCovered =
        acc.notCovered + rec.estimated_on_demand_spend_over_look_back_period;

      return acc;
    }, initialValue) ?? initialValue;

  const percent =
    (totals.coverage / (totals.coverage + totals.notCovered)) * 100;

  return { ...totals, percentage: percent ? percent.toFixed(2) : 0 };
};
