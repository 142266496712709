import { FC, Fragment } from "react";
import { Box } from "@mui/material";
import { useDidMount } from "rooks";
import { AddDimensionButton } from "./components/AddDimensionButton";
import { DimensionsEmptyState } from "./components/DimensionsEmptyState";
import { DimensionsDataGrid } from "./components/data-grid/dimensions/DimensionsDataGrid";
import { DimensionDrawers } from "./components/form-drawer/DimensionDrawers";
import { PageHeader } from "../common/PageHeader";
import { getDimensionsThunk } from "../../../store/dimensions/thunks/dimensions/getDimensionsThunk";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { dimensionsLoadingSelector } from "../../../store/dimensions/selectors/dimensions-data/dimensionsLoadingSelector";
import { dimensionsDataEmptySelector } from "../../../store/dimensions/selectors/dimensions-data/dimensionsDataEmptySelector";
import { getCurrentOrganisationBillingAccountsThunk } from "../../../store/common/thunks/billing-accounts/getCurrentOrganisationBillingAccountsThunk";
import { getResourceExplorerEnabledProvidersPossibleFiltersThunk } from "../../../store/resource-explorer/thunks/resource-explorer/getResourceExplorerEnabledProvidersPossibleFiltersThunk";

export const Dimensions: FC = () => {
  const dispatch = useAppDispatch();
  const empty = useAppSelector(dimensionsDataEmptySelector);
  const loading = useAppSelector(dimensionsLoadingSelector);

  useDidMount(() => {
    dispatch(getDimensionsThunk());
    dispatch(getCurrentOrganisationBillingAccountsThunk());
    dispatch(getResourceExplorerEnabledProvidersPossibleFiltersThunk());
  });

  return (
    <Fragment>
      <PageHeader
        sticky
        title="Dimensions"
        loading={loading}
        actions={!empty ? <AddDimensionButton size="medium" /> : null}
      />

      <Box p={2}>
        {!loading && empty ? <DimensionsEmptyState /> : <DimensionsDataGrid />}
      </Box>

      <DimensionDrawers />
    </Fragment>
  );
};
