import { FC, Fragment, SyntheticEvent, useCallback } from "react";
import Tab from "@mui/material/Tab";
import { Divider, Tabs } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { SavingsPlanType } from "../../../../../services/cloudchipr.api";
import { setCommitmentsRecommendationsSavingsPlanType } from "../../../../../store/commitments/commitmentsSlice";
import { commitmentsRecommendationsSavingsPlanTypeSelector } from "../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTypeSelector";
import { savingsPlansTypeLabels } from "../../utilization-and-coverage/utils/constants/labels";
import { getCommitmentsRecommendationsThunk } from "../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";

export const CommitmentsRecommendationTypeSelect: FC = () => {
  const dispatch = useAppDispatch();

  const tab = useAppSelector(commitmentsRecommendationsSavingsPlanTypeSelector);
  const tabChangeHandler = useCallback(
    (_: SyntheticEvent, tab: SavingsPlanType) => {
      dispatch(setCommitmentsRecommendationsSavingsPlanType(tab));
      dispatch(getCommitmentsRecommendationsThunk());
    },
    [dispatch],
  );

  return (
    <Fragment>
      <Tabs onChange={tabChangeHandler} value={tab}>
        <Tab value="compute" label={savingsPlansTypeLabels.compute} />
        <Tab value="ec2_instance" label={savingsPlansTypeLabels.ec2_instance} />
        <Tab value="sage_maker" label={savingsPlansTypeLabels.sage_maker} />
      </Tabs>

      <Divider />
    </Fragment>
  );
};
