import { getAccountIdsFromCategoryFilters } from "./getAccountIdsFromCategoryFilters";
import { CategoryWithCosts } from "../../../../../services/cloudchipr.api";

export const getCategoryFiltersCount = (category: CategoryWithCosts) => {
  if (category.type === "account_based") {
    return getAccountIdsFromCategoryFilters(category.filters)?.length;
  }

  let res = 0;

  res += category.filters.aws_filters?.tags?.items?.length ?? 0;
  res += category.filters.aws_filters?.accounts?.items?.length ?? 0;

  res += category.filters.gcp_filters?.tags?.items?.length ?? 0;
  res += category.filters.gcp_filters?.accounts?.items?.length ?? 0;

  res += category.filters.azure_filters?.accounts?.items?.length ?? 0;

  return res;
};
