import { FC, useMemo } from "react";
import { CellContext } from "@tanstack/react-table";
import { ByteToGibCell } from "./ByteToGibCell";
import { AttachedCell } from "./AttachedCell";
import { DefaultCell } from "./DefaultCell";
import { MoneyCell } from "./MoneyCell";
import { DateTimeCell } from "./DateTimeCell";
import { HasFieldCell } from "./HasFieldCell";
import { IdentifierCell } from "./identifier-cell/IdentifierCell";
import { NodeCountCell } from "./NodeCountCell";
import { ActionsMenuCell } from "./actions-menu-cell/ActionsMenuCell";
import { NumeralCell } from "./NumeralCell";
import { CreatedByCell } from "./created-by-cell/CreatedByCell";
import { AccessModeCell } from "./AccessModeCell";
import { JiraTicketStatusCell } from "./JiraTicketStatusCell";
import { CardTableFilteredResultsCell } from "./CardTableFilteredResultsCell";
import { TotalCostChipCell } from "./TotalCostChipCell";
import { AvailabilityCell } from "./AvailabilityCell";
import { PricingTierCell } from "./PricingTierCell";
import { StateCell } from "./StateCell";
import { UnusedSinceCell } from "./UnusedSinceCell";
import { WithTooltipCell } from "./WithTooltipCell";
import { NodeGroupModeChip } from "./NodeGroupModeChip";
import { ProviderStatusCell } from "./ProviderStatus";
import { ResourceStateCell } from "./ResourceStateCell";
import { InUseCell } from "./InUseCell";
import { BillableCell } from "./BillableCell";
import { ExistCell } from "./ExistCell";
import { CapacityModeCell } from "./CapacityModeCell";
import { ReservationCell } from "./ReservationCell";
import { ReadWriteUsageCell } from "./ReadWriteUsageCell";
import { MoneyStoppedCell } from "./MoneyStoppedCell";
import { BackupType } from "./BackupType";
import { StateWithUnusedSinceCell } from "./StateWithUnusedSinceCell";
import { NetworkInOut } from "./NetworkInOut";
import { VCpuReservationCell } from "./VCpuReservationCell";
import { ProcessedDataCell } from "./ProcessedDataCell";
import { NatGatewayIdCell } from "./nat-gateway-id-cell/NatGatewayIdCell";
import { ResourceMetricsCell } from "./metrics-cells/ResourceMetricsCell";
import { AccountCell } from "./AccountCell";
import { FilterTemplateCell } from "./filter-template-cell/FilterTemplateCell";
import { LiveFilteredIdentifierCell } from "./LiveFilteredIdentifierCell";
import { TagCell } from "../../../../../../../../../common/data-grid-cells/tag-cell/TagCell";
import { CopyCell } from "../../../../../../../../../common/data-grid-cells/CopyCell";
import { ResourceType } from "../../../../../../../../../../services/cloudchipr.api";
import { TableCellTypes } from "../../../../../../../../../../storybook/data-grid/utils/types/types";

export type CustomTypes = TableCellTypes | "actions";

interface TableCellByTypeProps {
  type: CustomTypes;
  cell: CellContext<any, unknown>;
}

const TableCellByType: FC<TableCellByTypeProps> = ({ type, cell }) => {
  const originalRow = cell.row.original;
  const resource = originalRow.resource;
  const data: any = cell.getValue();
  const columnId = cell.column.id;

  const cellComponent = useMemo(() => {
    switch (type) {
      case "identifier":
        return <IdentifierCell data={data} link={resource.link} />;
      case "liveFilteredIdentifier":
        return (
          <LiveFilteredIdentifierCell
            data={data}
            link={resource.link}
            provider={resource.account.provider}
            accountId={resource.account.id}
            resourceType={resource.resource_type}
          />
        );
      case "copyWithTooltip":
        return <IdentifierCell data={data} />;
      case "identifierWithRecommendationLink":
        return (
          <IdentifierCell
            data={data}
            link={resource.link}
            resourceType={resource.resource_type}
            accountId={resource.account.id}
            rightsizingRecommendations={
              resource.recommendations?.right_sizing_recommendations
            }
            offHoursRecommendations={
              resource.recommendations?.off_hours_recommendations
            }
          />
        );
      case "nodesCount":
        return <NodeCountCell data={data} count={resource?.nodes_count} />;
      case "pricingTier":
        return (
          <PricingTierCell
            pricingTier={resource?.pricing_tier}
            type={resource?.instance_type}
          />
        );
      case "cardTableFilteredResults":
        return (
          <CardTableFilteredResultsCell
            resourceType={resource.resource_type as ResourceType}
            count={originalRow?.child_resource?.resources?.length}
            totalCount={originalRow?.child_resource?.total_resources}
          />
        );
      case "tag":
        return <TagCell tags={data || []} widthToEllipsis={120} />;
      case "smartTag":
        return <TagCell smartTags tags={data || []} widthToEllipsis={120} />;
      case "money":
        return <MoneyCell data={resource?.billable === "no" ? null : data} />;
      case "moneyStoppedCell":
        return (
          <MoneyStoppedCell
            data={resource?.billable === "no" ? null : data}
            resourceType={resource.resource_type}
            stopped={resource.resource_state === "stopped"}
          />
        );
      case "date":
        return <DateTimeCell data={data} />;
      case "wcuReservation":
        return <ReservationCell reservation="wcu" data={data} />;
      case "rcuReservation":
        return <ReservationCell reservation="rcu" data={data} />;
      case "processedData":
        return (
          <ProcessedDataCell data={data} days={resource.processed_data_days} />
        );
      case "natGatewayIdCell":
        return <NatGatewayIdCell data={data} columnId={columnId} />;

      case "readUsage":
        return (
          <ReadWriteUsageCell
            type={
              resource.capacity_mode === "Provisioned"
                ? "provisioned"
                : "on-demand"
            }
            reservation="rcu"
            data={data}
          />
        );
      case "writeUsage":
        return (
          <ReadWriteUsageCell
            type={
              resource.capacity_mode === "Provisioned"
                ? "provisioned"
                : "on-demand"
            }
            reservation="wcu"
            data={data}
          />
        );
      case "cpuStatistic":
        return <NumeralCell data={data} format="0.00" endAdornment="%" />;
      case "computeMetricsChart":
        return (
          <ResourceMetricsCell
            type="compute"
            columnId={columnId}
            metricsData={resource.inline_metrics}
          />
        );
      case "memoryUsedMetricsChart":
        return (
          <ResourceMetricsCell
            type="memory_used"
            columnId={columnId}
            metricsData={resource.inline_metrics}
          />
        );
      case "memoryAvailableMetricsChart":
        return (
          <ResourceMetricsCell
            type="memory_available"
            columnId={columnId}
            metricsData={resource.inline_metrics}
          />
        );
      case "dbMetricsChart":
        return (
          <ResourceMetricsCell
            type="database"
            columnId={columnId}
            metricsData={resource.inline_metrics}
          />
        );
      case "storageMetricsChart":
        return (
          <ResourceMetricsCell
            type="storage"
            columnId={columnId}
            metricsData={resource.inline_metrics}
          />
        );
      case "backupType":
        return <BackupType data={data} />;
      case "networkInOut":
        return (
          <NetworkInOut
            networkIn={data}
            networkOut={resource.network_bytes_out_value}
          />
        );
      case "vCpuReservation":
        return <VCpuReservationCell data={data} />;
      case "numeralWithNA":
        return <NumeralCell data={data} />;
      case "stringWithNA":
        return <DefaultCell data={data} emptyData="N/A" />;
      case "hasField":
        return <HasFieldCell hasField={!data} />;
      case "availability":
        return <AvailabilityCell enabled={data} />;
      case "accessMode":
        return <AccessModeCell isPublic={data} />;
      case "withCopy":
        return <CopyCell noWrap data={data} />;
      case "byteToGib":
        return <ByteToGibCell data={data} />;
      case "attached":
        return <AttachedCell data={data} />;
      case "exists":
        return <ExistCell data={data} />;
      case "createdBy":
        return <CreatedByCell data={data} />;
      case "unusedSince":
        return <UnusedSinceCell data={data} />;
      case "state":
        return <StateCell data={data} />;
      case "stateWithUnusedSinceCell":
        return <StateWithUnusedSinceCell data={data} />;
      case "withTooltipCell":
        return <WithTooltipCell data={data} />;
      case "noWrapText":
        return <DefaultCell isNoWrap data={data} />;
      case "inUse":
        return <InUseCell data={data} id={resource.used_by_image} />;
      case "billable":
        return <BillableCell data={data} />;
      case "jiraTicketStatus":
        return <JiraTicketStatusCell tickets={data} />;
      case "nodeGroupTotalCostChip":
        return <TotalCostChipCell data={data} />;
      case "nodeGroupMode":
        return <NodeGroupModeChip data={data} />;
      case "providerStatus":
        return <ProviderStatusCell data={data} />;
      case "capacityMode":
        return (
          <CapacityModeCell data={data} capacityMode={resource.capacity_mode} />
        );
      case "resourceState":
        return (
          <ResourceStateCell
            state={data}
            unusedSince={resource?.unused_since}
          />
        );
      case "account":
        return <AccountCell data={data} />;
      case "liveFilteredAccount":
        return (
          <AccountCell
            liveUsageIcon
            data={data}
            providerLink={resource.link}
            resourceType={resource.resource_type}
            linkTooltipTitle="View in Live Usage & Mgmt."
          />
        );
      case "filterTemplate":
        return (
          <FilterTemplateCell
            data={data}
            resourceType={resource.resource_type}
          />
        );

      case "actions":
        return (
          <ActionsMenuCell
            accountId={resource.account.id ?? ""}
            id={resource.id}
            uniqueIdentifier={resource.provider_unique_identifier}
            protectedBy={resource.protected_by}
            isProtected={resource.is_protected}
            inProgress={resource.marked_for_deletion}
            resourceType={resource.resource_type as ResourceType}
            failureReason={resource.cleanup_failure_reason}
            upcomingActions={resource.upcoming_actions}
            unavailableActions={resource.not_allowed_actions}
          />
        );

      default:
        return <DefaultCell data={data} />;
    }
  }, [resource, columnId, data, originalRow, type]);

  return cellComponent;
};

export const customCell =
  (type: CustomTypes) => (cell: CellContext<any, unknown>) => {
    return <TableCellByType type={type} cell={cell} />;
  };
