import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthInterface {
  token: string | null;
  permissions: string[];
  logout: boolean;
}

const initialState: AuthInterface = {
  token: null,
  permissions: [],
  logout: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    },
    setLogout: (state, action: PayloadAction<boolean>) => {
      state.logout = action.payload;
    },
  },
});

export const { setAuthToken, setPermissions, setLogout } = authSlice.actions;

export default authSlice.reducer;
