import { createAsyncThunk } from "@reduxjs/toolkit";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getCurrentOrganisationBillingAccountsThunk = createAsyncThunk(
  "common/getCurrentOrganisationBillingAccounts",
  async (_: void, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentBillingAccounts } =
      cloudChiprApi.endpoints;

    return await dispatch(
      getUsersMeOrganisationsCurrentBillingAccounts.initiate(),
    );
  },
);
