import { resourceExplorerAllPossibleFiltersSelector } from "../../filters/possible-filters/resourceExplorerAllPossibleFiltersSelector";
import { RootState } from "../../../../store";
import { resourceExplorerIdSelector } from "../resourceExplorerIdSelector";
import { resourceExplorerDataGridViewSelector } from "../resourceExplorerDataGridViewSelector";
import { resourceExplorerPathSelector } from "../path/resourceExplorerPathSelector";

export const isCurrentResourceExplorerInitializedSelector = (
  state: RootState,
  viewId?: string,
) => {
  const resourceExplorerId = resourceExplorerIdSelector(state);
  const dataGridView = resourceExplorerDataGridViewSelector(state);
  const path = resourceExplorerPathSelector(state);

  return (
    (!!resourceExplorerAllPossibleFiltersSelector(state) && viewId
      ? resourceExplorerId === viewId
      : !!resourceExplorerId) &&
    dataGridView !== null &&
    path.length !== 0
  );
};
