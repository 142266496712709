import { FC } from "react";
import { Box, Stack } from "@mui/material";
import { CategoryFilters } from "./CategoryFilters";
import { AddCategoryFilterButton } from "../options/AddCategoryFilterButton";
import { isFilterWithTags } from "../../utils/helpers/typeCheckers";
import { ProviderLogo } from "../../../../../../../../../../common/provider-logo/ProviderLogo";
import { ProviderType } from "../../../../../../../../../../../services/cloudchipr.api";
import { CategoryProviderFilters } from "../../../../../../../../../../../store/dimensions/utils/types/common";

interface FilterByProviderCardProps {
  provider: ProviderType;
  categoryId: string;
  border?: boolean;
  filter: CategoryProviderFilters;
}

export const FilterByProviderCard: FC<FilterByProviderCardProps> = ({
  provider,
  border,
  categoryId,
  filter,
}) => {
  return (
    <Stack
      mt={1}
      pb={border ? 2 : 1}
      borderBottom={border ? 1 : 0}
      alignItems="start"
      borderColor="grey.300"
    >
      <Box bgcolor="grey.100" p={0.5} pb={0} borderRadius={2}>
        <ProviderLogo provider={provider} width={24} />
      </Box>

      <CategoryFilters
        filters={filter}
        categoryId={categoryId}
        provider={provider}
      />

      <AddCategoryFilterButton
        categoryId={categoryId}
        provider={provider}
        accountSelected={!!filter?.accounts?.items.length}
        tagSelected={isFilterWithTags(filter) && !!filter.tags?.items.length}
      />
    </Stack>
  );
};
