import { FC } from "react";
import { useToggle } from "rooks";
import { Card, Collapse, Stack } from "@mui/material";
import { FilterByProviderCard } from "./components/filters/FilterByProviderCard";
import { CategoryCollapseHeader } from "../CategoryCollapseHeader";
import { DimensionCategory } from "../../../../../../../../../store/dimensions/utils/types/common";
import { useAppSelector } from "../../../../../../../../../store/hooks";
import { enabledProvidersArraySelector } from "../../../../../../../../../store/common/selectors/billing-analytics/enabled-providers/enabledProvidersArraySelector";
import { filterProviderByProvider } from "../../../../../../utils/constants/common";

interface CategoryBasedOnFiltersProps {
  category: DimensionCategory;
  deleteDisabled?: boolean;
}

export const CategoryBasedOnFilters: FC<CategoryBasedOnFiltersProps> = ({
  category,
  deleteDisabled,
}) => {
  const [collapsed, toggleCollapsed] = useToggle();

  const enabledProviders = useAppSelector(enabledProvidersArraySelector);

  return (
    <Card variant="outlined">
      <CategoryCollapseHeader
        title="Filters"
        deleteDisabled={!!deleteDisabled}
        collapsed={collapsed}
        category={category}
        toggleCollapsed={toggleCollapsed}
      />

      <Collapse in={!collapsed}>
        <Stack p={1} pt={0}>
          {enabledProviders.map((provider) => {
            const filterKey = filterProviderByProvider[provider];
            const filter = category.filters[filterKey];

            return (
              <FilterByProviderCard
                key={provider}
                filter={filter ?? null}
                provider={provider}
                categoryId={category.id}
                border={provider !== enabledProviders.at(-1)}
              />
            );
          })}
        </Stack>
      </Collapse>
    </Card>
  );
};
