import { FC } from "react";
import { Box } from "@mui/material";
import { UtilizationAndCoverageDataGridToolbar } from "./components/UtilizationAndCoverageDataGridToolbar";
import { SavingsPlansDataGrid } from "./SavingsPlansDataGrid";
import { CoverageDataGrid } from "./CoverageDataGrid";
import { useAppSelector } from "../../../../../../../store/hooks";
import { utilizationAndCoverageDataViewTabSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/view-options/utilizationAndCoverageDataViewTabSelector";
import { ToolbarConfig } from "../../../../../../../storybook/data-grid/utils/types/prop-types";
import { commitmentsForTableLoadingSelector } from "../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/commitmentsForTableLoadingSelector";
import { CommitmentsGridLoadingLayer } from "../../common/CommitmentsGridLoadingLayer";

export const UtilizationAndCoverageDataGrid: FC = () => {
  const tab = useAppSelector(utilizationAndCoverageDataViewTabSelector);
  const loading = useAppSelector(commitmentsForTableLoadingSelector);

  return (
    <Box position="relative" zIndex={1}>
      {loading && <CommitmentsGridLoadingLayer />}

      {tab === "savingsPlans" && <SavingsPlansDataGrid toolbar={toolbar} />}

      {tab === "coverage" && <CoverageDataGrid toolbar={toolbar} />}
    </Box>
  );
};

const toolbar: ToolbarConfig = {
  renderToolbar: (props) => {
    return <UtilizationAndCoverageDataGridToolbar {...props} />;
  },
};
