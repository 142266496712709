import { RootState } from "../../../store";
import { dimensionCategoriesEntriesSelector } from "../slice-data/dimensionCategoriesEntriesSelector";
import { getAccountIdsFromCategoryFilters } from "../../../../components/pages/dimensions/utils/helpers/getAccountIdsFromCategoryFilters";

export const selectedAccountsInAllCategoriesSelector = (state: RootState) => {
  const categories = dimensionCategoriesEntriesSelector(state);

  const accountIds: Record<string, string> = {};

  categories.forEach(([, category]) => {
    const ids = getAccountIdsFromCategoryFilters(category.filters);

    ids.forEach((id) => {
      accountIds[id] = category.name;
    });
  });

  return accountIds;
};
