import { FC, useCallback, useMemo } from "react";
import { FiltersCountButtonCell } from "../common/FiltersCountButtonCell";
import { CategoryWithCosts } from "../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../store/hooks";
import { setDimensionsState } from "../../../../../../store/dimensions/dimensionsSlice";
import { generateDimensionCategoriesFromCategoriesWithCosts } from "../../../utils/helpers/generateDimensionCategoriesFromCategoriesWithCosts";
import { getCategoryFiltersCount } from "../../../utils/helpers/getCategoryFiltersCount";

interface CategoryFiltersCountCellProps {
  dimensionId: string;
  category: CategoryWithCosts;
  categories: CategoryWithCosts[];
}

export const CategoryFiltersCountCell: FC<CategoryFiltersCountCellProps> = ({
  dimensionId,
  category,
  categories,
}) => {
  const dispatch = useAppDispatch();
  const categoryId = category.id;
  const categoryType = category.type;

  const count = useMemo(() => {
    return getCategoryFiltersCount(category);
  }, [category]);

  const buttonClickHandler = useCallback(() => {
    dispatch(
      setDimensionsState({
        dimensionId,
        drawerMode: "categoryEdit",
        dimensionName: "",
        editingCategoryId: categoryId,
        filterType: categoryType,
        categories:
          generateDimensionCategoriesFromCategoriesWithCosts(categories),
      }),
    );
  }, [dispatch, dimensionId, categories, categoryId, categoryType]);

  return (
    <FiltersCountButtonCell
      count={count}
      dimensionId={dimensionId}
      categoryId={categoryId}
      basedOn={category.type}
      onClick={buttonClickHandler}
    />
  );
};
