import { createAsyncThunk } from "@reduxjs/toolkit";
import { createResourceExplorerHierarchyViewByFolderIdThunk } from "./createResourceExplorerHierarchyViewByFolderIdThunk";
import { createResourceExplorerHierarchyViewThunk } from "./createResourceExplorerHierarchyViewThunk";
import { createResourceExplorerHierarchyFolderThunk } from "./createResourceExplorerHierarchyFolderThunk";
import { ResourceExplorerV2ViewCreateFolderOrViewTypes } from "../../../../components/navigation/components/resource-explorer-v2/utils/types";

interface CreateResourceExplorerHierarchyFolderOrViewThunkArg {
  type: ResourceExplorerV2ViewCreateFolderOrViewTypes;
  folderId?: string;
  name: string;
}
export const createResourceExplorerHierarchyFolderOrViewThunk =
  createAsyncThunk(
    "resourceExplorer/createResourceExplorerHierarchyFolderOrView",
    async (
      {
        folderId,
        name,
        type,
      }: CreateResourceExplorerHierarchyFolderOrViewThunkArg,
      { dispatch },
    ) => {
      let response;
      if (type === "view") {
        if (folderId) {
          response = await dispatch(
            createResourceExplorerHierarchyViewByFolderIdThunk({
              name,
              folderId,
            }),
          ).unwrap();
        } else {
          response = await dispatch(
            createResourceExplorerHierarchyViewThunk(name),
          ).unwrap();
        }
      } else {
        await dispatch(createResourceExplorerHierarchyFolderThunk(name));
      }

      return response;
    },
  );
