import { FC, Fragment, useCallback, useMemo } from "react";
import { ListItemIcon, MenuItem, Tooltip, Typography } from "@mui/material";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { ResourceExplorerV2ViewMoveActionMenu } from "./ResourceExplorerV2ViewMoveActionMenu";
import { useAppSelector } from "../../../../../../../store/hooks";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { resourceExplorerViewsHierarchyFoldersSelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyFoldersSelector";
import { navigationHierarchyItemNoDndKey } from "../../../../sortable-tree/utils/constants";

interface ResourceExplorerV2ViewMoveActionProps {
  id: string;
  folderId?: string;
  onClose(): void;
}

export const ResourceExplorerV2ViewMoveAction: FC<
  ResourceExplorerV2ViewMoveActionProps
> = ({ id, folderId, onClose }) => {
  const { anchor, open, openMenu, closeMenu: closeMoveMenu } = useMenuHook();
  const folders = useAppSelector(resourceExplorerViewsHierarchyFoldersSelector);

  const filteredFolders = useMemo(() => {
    return folders?.filter((item) => item.id !== folderId);
  }, [folders, folderId]);

  const moveMenuItemDisabled = !filteredFolders?.length && !folderId;

  const closeMenusHandler = useCallback(() => {
    onClose();
    closeMoveMenu();
  }, [onClose, closeMoveMenu]);

  return (
    <Fragment>
      <Tooltip
        arrow
        title={moveMenuItemDisabled ? "No available folders to move to." : ""}
        placement="top"
        {...navigationHierarchyItemNoDndKey}
      >
        <span>
          <MenuItem
            onClick={openMenu}
            sx={menuItemSx}
            disabled={moveMenuItemDisabled}
          >
            <ListItemIcon>
              <DriveFileMoveOutlinedIcon fontSize="small" sx={iconSx} />
            </ListItemIcon>
            <Typography variant="body2">Move</Typography>
            <ListItemIcon sx={{ justifyContent: "flex-end", ...iconSx }}>
              <ChevronRightOutlinedIcon fontSize="small" />
            </ListItemIcon>
          </MenuItem>
        </span>
      </Tooltip>

      <ResourceExplorerV2ViewMoveActionMenu
        folderId={folderId}
        id={id}
        open={open}
        onClose={closeMenusHandler}
        anchor={anchor}
      />
    </Fragment>
  );
};

const iconSx = { minWidth: 28 };

const menuItemSx = {
  pl: 1,
  pr: 1.5,
};
