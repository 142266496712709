import { FC } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ProviderType } from "../../../../../../../../services/cloudchipr.api";
import { ProviderLogo } from "../../../../../../../common/provider-logo/ProviderLogo";
import { isStringProviderType } from "../../../../../../../../utils/helpers/providers/isStringProviderType";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { billingAccountsGroupedByProviderSelector } from "../../../../../../../../store/common/selectors/billing-accounts/billingAccountsGroupedByProviderSelector";

export interface AccountsCountTooltipProps {
  accountsCount: Record<ProviderType, number>;
}

export const AccountsCountTooltip: FC<AccountsCountTooltipProps> = ({
  accountsCount,
}) => {
  const allAccounts = useAppSelector(billingAccountsGroupedByProviderSelector);

  return (
    <Tooltip
      title={
        <Stack spacing={1} p={1}>
          <Typography variant="tiny" fontWeight="medium">
            Accounts
          </Typography>

          {Object.entries(accountsCount).map(([provider, count]) => {
            if (!isStringProviderType(provider)) {
              return null;
            }

            return (
              <Stack direction="row" key={provider} spacing={0.5}>
                <ProviderLogo provider={provider} />

                <Typography variant="caption">
                  <Typography
                    variant="inherit"
                    component="span"
                    fontWeight="medium"
                  >
                    {count}
                  </Typography>{" "}
                  out of{" "}
                  <Typography
                    variant="inherit"
                    component="span"
                    fontWeight="medium"
                  >
                    {allAccounts[provider]?.length}
                  </Typography>{" "}
                  accounts.
                </Typography>
              </Stack>
            );
          })}
        </Stack>
      }
      componentsProps={{
        tooltip: { sx: tooltipStyles },
      }}
      placement="bottom"
      arrow
    >
      <InfoOutlinedIcon color="action" fontSize="small" />
    </Tooltip>
  );
};

const tooltipStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  "& .MuiTooltip-arrow": {
    color: "background.paper",
  },
  boxShadow: 7,
  p: 0,
};
