import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";

import { getDashboardsHierarchyThunk } from "./getDashboardsHierarchyThunk";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { updateDashboardFixedCacheKey } from "../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

type UpdateDashboardHierarchyFolderNameThunkArgs = {
  name: string;
  folderId: string;
};

export const updateDashboardHierarchyFolderNameThunk = createAsyncThunk(
  "dashboards/updateDashboardHierarchyFolderName",
  async (
    { name, folderId }: UpdateDashboardHierarchyFolderNameThunkArgs,
    { dispatch },
  ) => {
    const { patchUsersMeOrganisationsCurrentDashboardFoldersByFolderId } =
      cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentDashboardFoldersByFolderId.initiate(
          {
            folderId,
            body: { name },
          },
          { fixedCacheKey: updateDashboardFixedCacheKey },
        ),
      ).unwrap();

      dispatch(getDashboardsHierarchyThunk());

      enqueueSnackbar("Folder name successfully updated.", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "success",
        },
      });
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
