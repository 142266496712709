import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { DashboardCreateDialogActions } from "../../../dashboard/components/create-dialog/components/DashboardCreateDialogActions";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { createDashboardLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard/loadings/createDashboardLoadingSelector";
import { createDashboardHierarchyFolderOrItemThunk } from "../../../../../../store/dashboards/thunks/dashboard-hierarchy/createDashboardHierarchyFolderOrItemThunk";
import { createDashboardFolderLoadingSelector } from "../../../../../../store/dashboards/selectors/dashboard-hierarchy/createDashboardFolderLoadingSelector";
import { NavigationHierarchyItemType } from "../../../../../../store/navigation/utils/types";

interface DashboardCreateFolderOrItemDialogActionsProps {
  onClose(): void;
  type: NavigationHierarchyItemType;
  folderId?: string;
  name: string;
}

export const DashboardCreateFolderOrItemDialogActions: FC<
  DashboardCreateFolderOrItemDialogActionsProps
> = ({ onClose, type, folderId, name }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createDashboardLoading = useAppSelector(createDashboardLoadingSelector);

  const createFolderLoading = useAppSelector(
    createDashboardFolderLoadingSelector,
  );

  const createItemCallbackHandler = useCallback(
    (id: string) => {
      navigate({ pathname: `/dashboards/${id}` });
    },
    [navigate],
  );

  const submitFormHandler = useCallback(async () => {
    const response = await dispatch(
      createDashboardHierarchyFolderOrItemThunk({
        name,
        folderId,
        type,
      }),
    ).unwrap();

    if (response) {
      createItemCallbackHandler(response?.id);
    }
    onClose();
  }, [dispatch, onClose, name, type, folderId, createItemCallbackHandler]);

  return (
    <DashboardCreateDialogActions
      isLoading={createDashboardLoading || createFolderLoading}
      disabled={!name.trim()}
      onSubmit={submitFormHandler}
      onCloseDialog={onClose}
    />
  );
};
