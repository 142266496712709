import { RootState } from "../../../../../store";
import { appliedAccountIdsSelector } from "../../../filters/applied-filters/appliedAccountIdsSelector";
import { appliedRegionsSelector } from "../../../filters/applied-filters/appliedRegionsSelector";
import { appliedServiceSelector } from "../../../filters/applied-filters/appliedServiceSelector";
import { appliedProductFamilySelector } from "../../../filters/applied-filters/appliedProductFamilySelector";
import { appliedChargeTypesSelector } from "../../../filters/applied-filters/appliedChargeTypesSelector";
import { appliedInstanceFamilySelector } from "../../../filters/applied-filters/appliedInstanceFamilySelector";
import { appliedInstanceTypesSelector } from "../../../filters/applied-filters/appliedInstanceTypesSelector";
import { appliedCostAllocationTagsSelector } from "../../../filters/applied-filters/appliedCostAllocationTagsSelector";
import { appliedCreditTypesSelector } from "../../../filters/applied-filters/appliedCreditTypesSelector";

export const resourceExplorerProviderFiltersForPayloadSelector = (
  state: RootState,
) => {
  const accountIds = appliedAccountIdsSelector(state);
  const regions = appliedRegionsSelector(state);
  const services = appliedServiceSelector(state);
  const productFamilies = appliedProductFamilySelector(state);
  const chargeTypes = appliedChargeTypesSelector(state);
  const instanceFamilies = appliedInstanceFamilySelector(state);
  const instanceTypes = appliedInstanceTypesSelector(state);
  const tags = appliedCostAllocationTagsSelector(state);
  const creditTypes = appliedCreditTypesSelector(state);

  return {
    aws_filters: {
      account_ids: accountIds.aws,
      regions: regions.aws,
      services: services.aws,
      product_families: productFamilies.aws,
      charge_types: chargeTypes.aws,
      instance_types: instanceTypes.aws,
      instance_families: instanceFamilies.aws,
      tags: tags.aws,
    },
    gcp_filters: {
      account_ids: accountIds.gcp,
      regions: regions.gcp,
      services: services.gcp,
      product_families: productFamilies.gcp,
      credits: creditTypes,
      tags: tags.gcp,
    },
    azure_filters: {
      account_ids: accountIds.azure,
      regions: regions.azure,
      services: services.azure,
      product_families: productFamilies.azure,
      charge_types: chargeTypes.azure,
    },
  };
};
