import { FC } from "react";
import { Stack } from "@mui/material";
import { Schedule2 } from "../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { convertSecondsToUnits } from "../../utils/helpers/gracePeriodCalculations";
import { automationsAllActions } from "../../../common/utils/constants/common";

interface ScheduledJobsListGridActionsCellProps {
  schedule: Schedule2;
}

export const ScheduledJobsDataGridActionsCell: FC<
  ScheduledJobsListGridActionsCellProps
> = ({ schedule }) => {
  const { value: gracePeriodValue, message: gracePeriodMessage } =
    convertSecondsToUnits(schedule?.grace_period?.period ?? 0);
  const action = schedule.action && automationsAllActions[schedule.action];

  return (
    <Stack spacing={0.25} justifyContent="center">
      <TypographyWithTooltip title={action} variant="body2" />
      {!!gracePeriodValue && (
        <TypographyWithTooltip
          variant="caption"
          color="text.disabled"
          title={`Grace Period: ${gracePeriodMessage}`}
        />
      )}
    </Stack>
  );
};
