import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC, useCallback } from "react";
import { ResourceExplorerTagFilterOperator } from "../../../../../../../../../../../../services/cloudchipr.api";
import { tagFilterOperators } from "../../../../../../../../../../../common/select/tag-filter-dropdown-select/utils/constants";

interface ResourceExplorerTagFilterItemProps {
  onChange(value: string): void;
  value?: ResourceExplorerTagFilterOperator;
}
export const ResourceExplorerTagOperatorSelect: FC<
  ResourceExplorerTagFilterItemProps
> = ({ onChange, value }) => {
  const handleChange = useCallback(
    (event: any) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth size="xsmall">
      <InputLabel>Operator</InputLabel>
      <Select value={value ?? "in"} label="Operator" onChange={handleChange}>
        {tagFilterOperators.map((operator) => {
          return (
            <MenuItem key={operator.value} value={operator.value}>
              {operator.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
