import { FC, forwardRef, useMemo } from "react";
import { TextField } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { InputProps as StandardInputProps } from "@mui/material/Input/Input";
import { AccountSelectTriggerInputComponent } from "./AccountSelectTriggerInputComponent";
import { FilterTriggerComponentProps } from "../../select/dropdown-select/utils/types/filterTriggerComponentProps";

//todo: we have this kind of components, but they are input imitations, this one can be generic and used instead of "SlackConversationsDropdownTrigger" and "SlackAutocompleteTrigger", and we can use this where 'autocomplete without search' is needed
export const AccountSelectTriggerComponent: FC<FilterTriggerComponentProps> = (
  props,
) => {
  const { selectedValues, onClick, open, label } = props;

  const InputComponent: StandardInputProps["inputComponent"] = useMemo(() => {
    if (!props.selectedValues.length) {
      return;
    }

    return forwardRef(() => <AccountSelectTriggerInputComponent {...props} />);
  }, [props]);

  return (
    <TextField
      fullWidth
      label={label}
      size="small"
      focused={false}
      onClick={onClick}
      InputLabelProps={{ shrink: !!selectedValues.length || open }}
      InputProps={{
        inputComponent: InputComponent,
        endAdornment: open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
        ),
      }}
    />
  );
};
