import { createAsyncThunk } from "@reduxjs/toolkit";

import { RootState } from "../../../../store";
import { getWidgetDataByViewIdThunk } from "../common/getWidgetDataByViewIdThunk";
import { buildingInProgressWidgetIdWithNoFilter } from "../utils/constants";
import { costAndUsageSumWidgetSetupSelector } from "../../../selectors/setups/cost-and-usage/costAndUsageSumWidgetSetupSelector";

export const fetchCostAndUsageSumWidgetDataBySetupThunk = createAsyncThunk(
  "dashboards/fetchCostAndUsageSumWidgetDataBySetup",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = costAndUsageSumWidgetSetupSelector(state);

    if (!setup?.viewId) {
      return;
    }

    return await dispatch(
      getWidgetDataByViewIdThunk({
        widgetType: setup.widgetType,
        resourceExplorerViewId: setup.viewId,
        dateLabel: setup.date.label,
        dateTo: setup.date.to,
        dateFrom: setup.date.from,
        widgetId: buildingInProgressWidgetIdWithNoFilter,
        onlySummary: true,
      }),
    ).unwrap();
  },
);
