import { FC, Fragment, useCallback } from "react";
import { Box, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { ApplyFilterTemplateButton } from "./ApplyFilterTemplateButton";
import { FilterTemplateRowActions } from "./FilterTemplateRowActions";
import { FilterTemplatesModifyRow } from "./FilterTemplatesModifyRow";
import { FilterTemplatesFiltersDialog } from "./templates-filters/FilterTemplatesFiltersDialog";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import c8rLogo from "../../../../../../assets/images/logos/c8r-logo.svg";
import { money } from "../../../../../../utils/numeral/money";
import { AccountsWithPopover } from "../../../../../common/accounts-with-popover/AccountsWithPopover";
import { AccountFilterSetV2 } from "../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { useAppSelector } from "../../../../../../store/hooks";
import { currentAccountProviderTypeSelector } from "../../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";

interface FilterTemplatesRowProps {
  editingRowId: string;
  duplicatingRowId: string;
  filterSet: AccountFilterSetV2;
  onApply(): void;
  setEditingRowId(id: string): void;
  setDuplicatingRowId(id: string): void;
}

export const FilterTemplatesRow: FC<FilterTemplatesRowProps> = ({
  filterSet,
  setEditingRowId,
  editingRowId,
  onApply,
  setDuplicatingRowId,
  duplicatingRowId,
}) => {
  const { open, openDialog, closeDialog } = useDialog();
  const { filter_set, ems } = filterSet;
  const provider = useAppSelector(currentAccountProviderTypeSelector);

  const modifyHandler = useCallback(() => {
    setEditingRowId(filter_set.id);
  }, [setEditingRowId, filter_set.id]);

  const cancelModifyHandler = useCallback(() => {
    setEditingRowId("");
  }, [setEditingRowId]);

  const duplicateHandler = useCallback(() => {
    setDuplicatingRowId(filter_set.id);
  }, [setDuplicatingRowId, filter_set.id]);

  const cancelDuplicateHandler = useCallback(() => {
    setDuplicatingRowId("");
  }, [setDuplicatingRowId]);

  if (editingRowId === filter_set.id) {
    return (
      <FilterTemplatesModifyRow
        type="modify"
        filterSet={filterSet.filter_set}
        onCancel={cancelModifyHandler}
      />
    );
  }

  return (
    <Fragment>
      <TableRow sx={{ position: "relative" }}>
        <TableCell sx={{ py: 2 }}>
          {(duplicatingRowId || editingRowId) && wrapper}
          <Stack direction="row" spacing={2}>
            <TypographyWithTooltip
              width={200}
              variant="body2"
              title={filter_set?.name}
              onClick={openDialog}
              sx={{
                textDecoration: "underline",
                textUnderlineOffset: 4,
                cursor: "pointer",
              }}
            />

            {filter_set.source === "cloudchipr" && (
              <img src={c8rLogo} alt="C8R-logo" />
            )}
          </Stack>
        </TableCell>

        {!editingRowId && !duplicatingRowId && (
          <TableCell>
            <Typography variant="body2">{money(ems)}</Typography>
          </TableCell>
        )}

        <TableCell>
          <AccountsWithPopover maxWidth={200} accounts={filter_set.accounts} />
        </TableCell>

        <TableCell>
          <ApplyFilterTemplateButton
            onApply={onApply}
            filterSetId={filter_set.id}
          />
        </TableCell>

        <TableCell>
          <FilterTemplateRowActions
            filterSet={filter_set}
            onModify={modifyHandler}
            onDuplicate={duplicateHandler}
          />
        </TableCell>
      </TableRow>

      {duplicatingRowId === filter_set.id && (
        <FilterTemplatesModifyRow
          type="duplicate"
          filterSet={{
            ...filterSet.filter_set,
            name: `${filterSet.filter_set.name} Copy`,
          }}
          onCancel={cancelDuplicateHandler}
        />
      )}

      {open && (
        <FilterTemplatesFiltersDialog
          open
          provider={provider}
          onClose={closeDialog}
          filterSet={filter_set}
        />
      )}
    </Fragment>
  );
};

const wrapper = (
  <Box
    top={0}
    zIndex="1"
    height={50}
    width="100%"
    bgcolor="white"
    position="absolute"
    sx={{ opacity: 0.5 }}
  />
);
