import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { commitmentsRecommendationsDataSelector } from "./commitmentsRecommendationsDataSelector";

export const commitmentsRecommendationsMetaDataSelector =
  createDraftSafeSelector([commitmentsRecommendationsDataSelector], (data) => {
    return {
      currentOnDemand: {
        value: data?.current_monthly_on_demand_spend,
        hourly: data?.current_hourly_on_demand_spend,
      },
      estimatedSpend: {
        value: data?.estimated_monthly_on_demand_spend,
        hourly: data?.estimated_hourly_on_demand_spend,
      },
      estimatedSaving: {
        value: data?.estimated_monthly_savings,
        hourly: data?.estimated_hourly_savings,
      },
    };
  });
