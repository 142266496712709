import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  cloudChiprApi,
  Schedule2,
  ScheduleActionType2,
} from "../../../services/cloudchipr.api";
import {
  generateWorkflowUpdatingData,
  getWorkflowUpdatePayloadData,
} from "../../../components/pages/schedule/classic/utils/helpers/helpers";

export const scheduledJobsPatchFixedCacheKey =
  "patchV2UsersMeSchedulesByScheduleId-fixed-cache-key";

export const toggleScheduledJobsStatusThunk = createAsyncThunk(
  "scheduledJobs/toggleScheduleStatus",
  async (
    {
      schedule,
      status,
    }: {
      schedule: Schedule2;
      status: ScheduleActionType2;
    },
    { dispatch },
  ) => {
    const { patchV2UsersMeSchedulesByScheduleId, getV2UsersMeSchedules } =
      cloudChiprApi.endpoints;

    const payloadData = getWorkflowUpdatePayloadData(
      generateWorkflowUpdatingData({ ...schedule, status }),
    );

    await dispatch(
      patchV2UsersMeSchedulesByScheduleId.initiate(payloadData, {
        fixedCacheKey: scheduledJobsPatchFixedCacheKey,
      }),
    );
    await dispatch(getV2UsersMeSchedules.initiate({}, { forceRefetch: true }));
  },
);
