import { FC, Fragment, ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useDidMount, useEffectOnceWhen } from "rooks";
import jwtDecode from "jwt-decode";
import { posthog } from "posthog-js";
import { Userpilot } from "userpilot";
import { LinearProgress } from "@mui/material";
import { WhiteListError } from "./error-pages/WhiteListError";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { authTokenSelector, logoutSelector } from "../store/auth/authSelectors";
import { C8rJwtPayload } from "../utils/auth/c8r-jwt-payload";
import { setAuthToken, setPermissions } from "../store/auth/authSlice";
import { postAuthDataFetchThunk } from "../store/common/commonThunks";
import { currentUserSelector } from "../store/profile/profileSelectors";
import { setAppLoading } from "../store/common/commonSlice";

export const Authentication: FC<{ children?: ReactNode }> = ({ children }) => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    loginWithRedirect,
    error,
    logout,
  } = useAuth0();

  const authToken = useAppSelector(authTokenSelector);
  const user = useAppSelector(currentUserSelector);
  const appLoading = useAppSelector((state) => state.common.appLoading);

  const dispatch = useAppDispatch();
  const loggedOut = useAppSelector(logoutSelector);

  useDidMount(() => {
    dispatch(setAppLoading(true));
  });

  useEffectOnceWhen(() => {
    posthog.identify(user?.id, { ...user });
  }, !!user && posthog.__loaded);

  useEffectOnceWhen(() => {
    if (user?.id) {
      Userpilot.identify(user.id, {
        name: user?.name,
        email: user?.email,
        created_at: user?.created_at,
      });
    }
  }, !!user && isAuthenticated);

  useEffectOnceWhen(async () => {
    const accessToken = await getAccessTokenSilently();

    if (accessToken) {
      const permissions = jwtDecode<C8rJwtPayload>(accessToken).permissions;
      dispatch(setPermissions(permissions));
      dispatch(setAuthToken(accessToken));
      dispatch(postAuthDataFetchThunk());
    }
  }, isAuthenticated);

  useEffectOnceWhen(() => {
    logout({ returnTo: window.location.origin });
  }, loggedOut);

  if (!isLoading && !isAuthenticated) {
    if (error) {
      return <WhiteListError />;
    }
    loginWithRedirect();
    return null;
  }

  if (appLoading) {
    return <LinearProgress />;
  }

  if (!children || !authToken || isLoading) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};
