import { cloudChiprApi } from "../../../../../services/cloudchipr.api";
import { RootState } from "../../../../store";
import { createDashboardFixedCacheKey } from "../../../../../components/pages/dashboard/utils/constants/fixedCacheKeys";

const selector =
  cloudChiprApi.endpoints.postUsersMeOrganisationsCurrentDashboards.select;

export const createDashboardLoadingSelector = (state: RootState): boolean => {
  return !!selector(createDashboardFixedCacheKey)(state)?.isLoading;
};
