import {
  ProviderType,
  ResourceExplorerGrouping,
} from "../../../../../../../services/cloudchipr.api";
import { RowSelectionPossibleFilterKey } from "../../../../../../../store/resource-explorer/utils/types/resourceExplorer";

export const commonGroupings: ResourceExplorerGrouping[] = [
  "none",
  "account",
  "service",
  "region",
  "resource",
  "product_family",
  "cloud_provider",
];

export const specificGroupings: Record<
  ProviderType,
  ResourceExplorerGrouping[]
> = {
  aws: [
    "cost_allocation_tag",
    "cost_allocation_tag_value",
    "charge_type",
    "instance_family",
    "instance_type",
  ],
  gcp: ["cost_allocation_tag", "cost_allocation_tag_value", "credit_type"],
  azure: ["charge_type"],
};

export const unselectableRowGroupings = new Set(["charge_type", "credit_type"]);

export const resourcesExplorerGroupingLabels: Map<
  ResourceExplorerGrouping,
  string
> = new Map([
  ["none", "None"],
  ["category", "Categories"],
  ["account", "Accounts"],
  ["cloud_provider", "Cloud Providers"],
  ["service", "Services"],
  ["region", "Regions"],
  ["product_family", "Product Families"],
  ["charge_type", "Charge Types"],
  ["resource", "Resources"],
  ["credit_type", "Discounts"],
  ["instance_family", "Instance Families"],
  ["instance_type", "Instance Types"],
]);
export const resourcesExplorerGroupingSingularLabels: Map<
  ResourceExplorerGrouping,
  string
> = new Map([
  ["account", "Account"],
  ["category", "Category"],
  ["cloud_provider", "Cloud Provider"],
  ["service", "Service"],
  ["region", "Region"],
  ["product_family", "Product Family"],
  ["charge_type", "Charge Type"],
  ["resource", "Resource"],
  ["credit_type", "Discount"],
  ["instance_family", "Instance Family"],
  ["instance_type", "Instance Type"],
  ["none", "None"],
]);

export const possibleGroupingsByCurrentGrouping: Record<
  string,
  ResourceExplorerGrouping[]
> = {
  region: ["region"],
  cloud_provider: ["cloud_provider"],
  account: ["account"],
  service: ["service"],
  resource: ["product_family", "resource", "account", "region", "service"],
  product_family: ["product_family", "resource", "account", "region"],
  none: ["none"],
  category: ["category"],
};

export const nextGroupingByCurrentGrouping: Record<
  string,
  ResourceExplorerGrouping
> = {
  cloud_provider: "account",
  region: "resource",
  account: "service",
  service: "resource",
  resource: "product_family",
  product_family: "resource",
  instance_family: "instance_type",
  instance_type: "resource",
  cost_allocation_tag: "cost_allocation_tag_value",
  cost_allocation_tag_value: "resource",
  none: "cloud_provider",
  category: "account",
};

export const filterByCurrentGrouping: Record<
  string,
  { type: "string" | "array" | "object"; key: RowSelectionPossibleFilterKey }
> = {
  region: { type: "array", key: "regions" },
  account: { type: "array", key: "account_ids" },
  service: { type: "array", key: "services" },
  resource: { type: "string", key: "resource_id" },
  product_family: { type: "array", key: "product_families" },
  cloud_provider: { type: "array", key: "cloud_providers" },
  category: { type: "array", key: "category_ids" },
  instance_family: { type: "array", key: "instance_families" },
  instance_type: { type: "array", key: "instance_types" },
  cost_allocation_tag: { type: "object", key: "tags" },
  cost_allocation_tag_value: { type: "object", key: "tags" },
};
