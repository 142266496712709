import { RootState } from "../../../../store";
import { resourceExplorerViewDataByIdSelector } from "../../resource-explorer-by-id/resourceExplorerViewDataByIdSelector";
import { resourceExplorerDefaultData } from "../../../../../components/pages/resource-explorer/components/resource-explorer-card/utils/constants/common";
import { resourceExplorerDataSelector } from "../data/resourceExplorerDataSelector";
import { arrayIsEqual } from "../../../../../utils/arrayIsEqual";

export const resourceExplorerDataChangedSelector = (
  state: RootState,
  resourceExplorerId: string,
) => {
  const resourceExplorerData = resourceExplorerDataSelector(state);

  const initialResourceExplorer = resourceExplorerViewDataByIdSelector(
    state,
    resourceExplorerId,
  );

  const resourceExplorerInitialData = initialResourceExplorer?.is_default
    ? resourceExplorerDefaultData
    : initialResourceExplorer?.data;

  if (!resourceExplorerInitialData || !resourceExplorerData) {
    return false;
  }

  const resourceExplorerDataFromDate =
    resourceExplorerData.date_label && !resourceExplorerData.from_date
      ? null
      : resourceExplorerData.from_date;

  const resourceExplorerDataToDate =
    resourceExplorerData.date_label && !resourceExplorerData.to_date
      ? null
      : resourceExplorerData.to_date;

  const fromDateEqual = !(
    resourceExplorerInitialData?.from_date &&
    resourceExplorerInitialData?.date_label
  )
    ? resourceExplorerInitialData?.from_date === resourceExplorerDataFromDate
    : true;
  const toDateEqual = !(
    resourceExplorerInitialData.to_date &&
    resourceExplorerInitialData?.date_label
  )
    ? resourceExplorerInitialData.to_date === resourceExplorerDataToDate
    : true;

  return (
    resourceExplorerInitialData.grouping !== resourceExplorerData.grouping ||
    !arrayIsEqual(
      resourceExplorerInitialData.group_values ?? [],
      resourceExplorerData.group_values ?? [],
    ) ||
    resourceExplorerInitialData.forecast_option !==
      resourceExplorerData.forecast_option ||
    // todo: "EnableNewDateRangeSelector" remove old dates checks
    !fromDateEqual ||
    !toDateEqual ||
    resourceExplorerInitialData.date_label !==
      resourceExplorerData.date_label ||
    resourceExplorerInitialData.data_point !==
      resourceExplorerData.data_point ||
    resourceExplorerInitialData.show_budget_line !==
      resourceExplorerData.show_budget_line
  );
};
