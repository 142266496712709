import { FC, useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";
import { changeCategoryFilterCombinationOperatorByProvider } from "../../../../../../../../../../../store/dimensions/dimensionsSlice";
import { ProviderType } from "../../../../../../../../../../../services/cloudchipr.api";

interface CombinationOperatorProps {
  first?: boolean;
  operator?: string;
  categoryId: string;
  provider: ProviderType;
}

export const CombinationOperator: FC<CombinationOperatorProps> = ({
  first,
  operator,
  categoryId,
  provider,
}) => {
  const dispatch = useAppDispatch();

  const operatorClickHandler = useCallback(() => {
    dispatch(
      changeCategoryFilterCombinationOperatorByProvider({
        provider,
        id: categoryId,
      }),
    );
  }, [dispatch, categoryId, provider]);

  return (
    <Box minWidth={72}>
      {first ? (
        <Typography variant="body2">Where</Typography>
      ) : (
        <Button
          size="small"
          variant="outlined"
          endIcon={<UnfoldMoreIcon />}
          onClick={operatorClickHandler}
        >
          {operator}
        </Button>
      )}
    </Box>
  );
};
