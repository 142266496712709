import { ChangeEvent, FC, useCallback } from "react";
import { RadioGroup, Stack, Typography } from "@mui/material";
import { FilterTypeSelectRadioButton } from "./FilterTypeSelectRadioButton";
import { CategoryFilterBaseType } from "../../../../../../../../store/dimensions/utils/types/common";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { categoryFilterTypeSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/categoryFilterTypeSelector";
import { setCategoryFilterType } from "../../../../../../../../store/dimensions/dimensionsSlice";
import { dimensionDrawerModeSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/dimensionDrawerModeSelector";

export const FilterTypeSelection: FC = () => {
  const dispatch = useAppDispatch();
  const selectedType = useAppSelector(categoryFilterTypeSelector);
  const mode = useAppSelector(dimensionDrawerModeSelector);

  const changeHandler = useCallback(
    (_: ChangeEvent, value: string) => {
      dispatch(setCategoryFilterType(value as CategoryFilterBaseType));
    },
    [dispatch],
  );

  if (mode !== "dimensionCreate") {
    return null;
  }

  return (
    <Stack>
      <Typography variant="body2">Type</Typography>

      <RadioGroup
        row
        sx={{ ml: 1 }}
        value={selectedType}
        onChange={changeHandler}
      >
        <FilterTypeSelectRadioButton
          value="account_based"
          label="Based on Accounts"
          tooltip="Account-based dimensions support categories exclusively based on cloud accounts. If you need to categorize by additional criteria such as tags, services, or account name filters, please use filter-based dimensions."
        />

        <FilterTypeSelectRadioButton
          value="filter_based"
          label="Based on Filters"
          tooltip="Filter-based dimensions allow you to categorize your resources using a variety of criteria such as tags, services, account names, and more. "
        />
      </RadioGroup>
    </Stack>
  );
};
