import { FC } from "react";
import { Table } from "@tanstack/react-table";
import { offHoursTableColumns } from "./offHoursTableColumns";
import { OffHoursSchedule2 } from "../../../../../../services/cloudchipr.api";
import { DataGrid } from "../../../../../../storybook/data-grid/DataGrid";
import { DataGridProvider } from "../../../../../../storybook/data-grid/DataGridProvider";
import { SchedulesToolbar } from "../../../common/list/SchedulesToolbar";
import { useSchedulesColumnsSortingHook } from "../../../common/utils/hooks/useSchedulesColumnsSorting.hook";

interface OffHoursListGridProps {
  offHours: OffHoursSchedule2[];
}

export const OffHoursDataGrid: FC<OffHoursListGridProps> = ({ offHours }) => {
  const { sorting, setSorting } = useSchedulesColumnsSortingHook("off-hours");

  return (
    <DataGridProvider>
      <DataGrid
        globalFilter
        columnResizeMode="onEnd"
        enableStickyColumns={!!offHours?.length}
        columnSorting={{
          initialSort: sorting,
          onColumnsSortingChange: setSorting,
        }}
        columnVisibility
        styles={styles}
        data={offHours}
        columns={offHoursTableColumns}
        toolbar={dataGridToolbar}
      />
    </DataGridProvider>
  );
};

const dataGridToolbar = {
  renderToolbar: ({
    table,
    globalFilter,
    setGlobalFilter,
  }: {
    table: Table<any>;
    globalFilter: string | number;
    setGlobalFilter: (value: string) => void;
  }) => {
    return (
      <SchedulesToolbar
        table={table}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    );
  },
};

const styles = {
  table: {
    tableLayout: "fixed",
  },
  tableHeaderRow: {
    "& th": {
      borderTop: 1,
      borderColor: "grey.300",
      py: 0.75,
      bgcolor: "white",
    },
    "& th:first-of-type": {
      pl: 3,
    },
  },
};
