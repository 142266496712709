import { AccountFilterSetV2 } from "../../../../../../services/cloudchipr.api";

export const getDefaultFilterTemplates = (data: AccountFilterSetV2[]) => {
  let c8rTemplates = data.filter(
    ({ filter_set }) => filter_set.source === "cloudchipr",
  );

  const noFiltersTemplate = c8rTemplates.find(
    ({ filter_set }) => filter_set.name === "No Filters",
  );

  const defaultTemplate = c8rTemplates.find(
    ({ filter_set }) =>
      filter_set.is_default &&
      filter_set.id !== noFiltersTemplate?.filter_set?.id,
  );

  if (!noFiltersTemplate || !defaultTemplate) {
    return c8rTemplates;
  }

  c8rTemplates = c8rTemplates.filter(
    ({ filter_set }) =>
      ![
        noFiltersTemplate?.filter_set?.id,
        defaultTemplate?.filter_set?.id,
      ].includes(filter_set.id),
  );

  return [defaultTemplate, ...c8rTemplates, noFiltersTemplate];
};
