import { useCallback, useMemo } from "react";
import { useAppDispatch } from "../../../../../store/hooks";
import {
  ResourceFiltersWithAction,
  useGetProvidersByProviderRegionsQuery,
} from "../../../../../services/cloudchipr.api";
import {
  initiateWorkflowThunk,
  InitiateWorkflowThunkArgs,
} from "../../../../../store/schedules/thunks/initiateWorkflowThunk";
import { useResourcesVisibility } from "../../../schedule/common/utils/hooks/useResourcesVisibility.hook";

export function useCreateWorkflow(
  workflowInitialData: InitiateWorkflowThunkArgs | null,
) {
  const dispatch = useAppDispatch();

  const provider = workflowInitialData?.account?.provider;

  const { data: allRegions } = useGetProvidersByProviderRegionsQuery(
    { provider: provider ?? "aws" },
    { skip: !provider },
  );

  const resourcesVisibility = useResourcesVisibility({
    accountIds: workflowInitialData?.account?.id ?? "",
    provider,
  });

  const normalizedFilters = useMemo(() => {
    if (!workflowInitialData) {
      return null;
    }

    const filters = workflowInitialData.filters.map((filter) => {
      const actionAvailable = Object.values(resourcesVisibility).every(
        (visibility) => visibility?.[filter.filter.type]?.actions.delete,
      );

      return {
        action: actionAvailable ? "terminate" : undefined,
        ...filter,
      } as ResourceFiltersWithAction;
    });

    return { ...workflowInitialData, filters };
  }, [workflowInitialData, resourcesVisibility]);

  const normalizedRegions = useMemo(() => {
    if (!normalizedFilters) {
      return null;
    }

    const regions = normalizedFilters.regions.length
      ? normalizedFilters.regions
      : (allRegions?.map(({ key }) => key) ?? []);

    return { ...normalizedFilters, regions };
  }, [allRegions, normalizedFilters]);

  return useCallback(() => {
    if (!normalizedRegions) {
      return;
    }

    dispatch(
      initiateWorkflowThunk({
        regions: normalizedRegions.regions,
        account: normalizedRegions.account,
        filters: normalizedRegions.filters,
        resourceType: normalizedRegions.resourceType,
      }),
    );
  }, [dispatch, normalizedRegions]);
}
