import { RootState } from "../../../../store";
import { categoryFilterTypeSelector } from "../../slice-data/categoryFilterTypeSelector";
import { dimensionCategoriesEntriesSelector } from "../../slice-data/dimensionCategoriesEntriesSelector";
import { categoryFiltersAreInvalid } from "../../../utils/helpers/categoryFiltersAreInvalid";

export const categoriesCreationDataIsInvalidSelector = (state: RootState) => {
  const categories = dimensionCategoriesEntriesSelector(state);
  const filterType = categoryFilterTypeSelector(state);

  let invalid = false;

  categories.forEach(([, category]) => {
    if (invalid) {
      return;
    }

    invalid = categoryFiltersAreInvalid(category, filterType);
  });

  return invalid;
};
