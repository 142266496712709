import { RootState } from "../../../store";
import { resourceExplorerIdSelector } from "../current-resource-explorer/resourceExplorerIdSelector";
import { ResourceExplorer } from "../../../../services/cloudchipr.api";
import { resourceExplorerViewsHierarchyViewsSelector } from "../views-hierarchy/resourceExplorerViewsHierarchyViewsSelector";

export const resourceExplorerByCurrentIdSelector = (
  state: RootState,
): ResourceExplorer | undefined => {
  const id = resourceExplorerIdSelector(state);

  return resourceExplorerViewsHierarchyViewsSelector(state)?.find(
    (view) => view.id === id,
  );
};
