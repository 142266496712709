import { FC, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDidMount } from "rooks";
import { TabPanel } from "@mui/lab";
import { Box } from "@mui/material";
import { Interval } from "../../../../common/create/scheduler/Interval";
import {
  DateTimeContent,
  ScheduleDatesInterface,
} from "../../../../common/create/scheduler/DateTimeContent";
import { NextPrevious } from "../../../../common/create/NextPrevious";
import { startEndDatesValidation } from "../../../../common/utils/constants/validation";
import {
  WorkflowSchedulerData,
  WorkflowSchedulerDateChangeType,
} from "../../../utils/types/types";
import { parseCron } from "../../../../common/utils/helpers/cron/parseCron";
import { generateCronByInterval } from "../../../../common/utils/helpers/cron/generateCronByInterval";

interface InitialValuesInterface {
  interval: number;
}

const initialValues: InitialValuesInterface & ScheduleDatesInterface = {
  interval: 0,
  startDate: "",
  endDate: "",
  timeZone: "",
  id: "",
};

const validationSchema = Yup.object({
  interval: Yup.number().required().min(1, "Please select interval"),
  startDate: startEndDatesValidation,
  endDate: startEndDatesValidation,
});

interface HourlyProps {
  cron: string | null;
  setScheduleDatesData(data: WorkflowSchedulerData): void;
  onPreviousStep(): void;
  hideCancelButton?: boolean;
  isDirty: boolean;
  onDateChange(data: WorkflowSchedulerDateChangeType): void;
}

export const Hourly: FC<HourlyProps & ScheduleDatesInterface> = ({
  cron,
  setScheduleDatesData,
  startDate,
  endDate,
  timeZone,
  onPreviousStep,
  id,
  isDirty,
  hideCancelButton,
  onDateChange,
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setScheduleDatesData({
        startDate: values.startDate,
        endDate: values.endDate,
        cron: generateCronByInterval(values.interval),
        timeZone: values.timeZone,
      });
    },
  });

  const {
    values,
    errors,
    touched,
    submitForm,
    setFieldValue,
    resetForm,
    dirty,
    isValid,
    setValues,
  } = formik;

  useDidMount(() => {
    const { hours } = parseCron(cron);
    const interval = hours || 1;
    const values = { interval, startDate, endDate, timeZone, id };

    onDateChange({
      endDate,
      timeZone,
      interval,
      startDate: startDate ?? "",
      cron: generateCronByInterval(interval),
    });
    if (id) {
      resetForm({ values });
    } else {
      setValues(values);
    }
  });

  const dateChangeHandler = useCallback(
    (key: string, value: number | string) => {
      const updatedValues = {
        ...values,
        [key]: value,
      };
      const { startDate, interval, endDate, timeZone } = updatedValues;

      onDateChange({
        endDate,
        timeZone,
        interval,
        startDate: startDate ?? "",
        cron: generateCronByInterval(interval),
      });
    },
    [values, onDateChange],
  );

  const setFieldValueHandler = useCallback(
    (key: string, value: number | string) => {
      setFieldValue(key, value);
      dateChangeHandler(key, value);
    },
    [setFieldValue, dateChangeHandler],
  );

  return (
    <TabPanel value="hourly">
      <form>
        <Interval
          type="Hours"
          value={values.interval}
          error={touched.interval && errors.interval}
          setFieldValue={setFieldValueHandler}
        />
        <DateTimeContent
          endDate={values.endDate}
          startDate={values.startDate}
          timeZone={values.timeZone}
          endDateError={touched.endDate && errors.endDate}
          startDateError={touched.startDate && errors.startDate}
          setFieldValue={setFieldValueHandler}
          showNextRuns
          tabType="hourly"
        />
        <Box mt={4}>
          <NextPrevious
            hideCancelButton={hideCancelButton}
            onNext={submitForm}
            onPrevious={onPreviousStep}
            isDirty={dirty || isDirty}
            isNextDisabled={!isValid}
          />
        </Box>
      </form>
    </TabPanel>
  );
};
