import { cleanActionTypeSelector } from "./cleanActionTypeSelector";
import { RootState } from "../../store";
import { ResourceType } from "../../../services/cloudchipr.api";
import { CleanResourceType } from "../cleanSlice";
import { resourceTypeDataWithChildrenSelector } from "../../account/selectors/resource-type-data/resourceTypeDataWithChildrenSelector";
import { getFirstParentInHierarchyResourceType } from "../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { isResourceNotCleanable } from "../utils/helpers";

export const cleanProcessResourcesSelector = (
  state: RootState,
  resourceType: ResourceType,
  selectedResources: Record<string, boolean>,
): {
  resources?: CleanResourceType[];
  excludedResources?: CleanResourceType[];
} => {
  const resources = resourceTypeDataWithChildrenSelector(
    state,
    getFirstParentInHierarchyResourceType(resourceType) ?? resourceType,
  );

  const cleanActionType = cleanActionTypeSelector(state);

  if (!cleanActionType || !resources) {
    return {};
  }

  return resources.reduce(
    (
      result: {
        resources: CleanResourceType[];
        excludedResources: CleanResourceType[];
      },
      { resource },
    ) => {
      if (!resource || !selectedResources?.[resource?.id]) {
        return result;
      }

      const action: "stop" | "terminate" =
        cleanActionType === "stop" ? "stop" : "terminate";
      const snapshot = cleanActionType === "snapshot_and_delete";

      if (
        isResourceNotCleanable(
          {
            isProtected: resource.is_protected,
            markedForDeletion: !!resource.marked_for_deletion,
            notAllowedActions: resource.not_allowed_actions,
          },
          cleanActionType,
        )
      ) {
        result.excludedResources.push({
          action,
          snapshot,
          id: resource.id,
          type: resourceType,
          provider_unique_identifier: resource.provider_unique_identifier,
        });
      } else {
        result.resources.push({
          action,
          snapshot,
          id: resource.id,
          type: resourceType,
          provider_unique_identifier: resource.provider_unique_identifier,
        });
      }

      return result;
    },
    {
      resources: [],
      excludedResources: [],
    },
  );
};
