import { FC } from "react";
import { Link, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import CloudChiprLogoText from "../../assets/images/cloudchipr-text.svg";
import MaintenanceIllustration from "../../assets/images/maintenance.png";

export const MaintenanceMode: FC = () => {
  return (
    <Stack height="100vh" bgcolor="grey.50">
      <Stack p={3} bgcolor="white" alignItems="start">
        <img src={CloudChiprLogoText} alt="CloudChipr" />
      </Stack>
      <Stack alignItems="center" mt={20} spacing={2}>
        <img
          width={270}
          src={MaintenanceIllustration}
          alt="Cloudchipr will be back soon!!!"
        />
        <Typography variant="h4" color="text.primary" fontWeight="medium">
          Cloudchipr will be back soon!!!
        </Typography>
        <Typography variant="body1" textAlign="center" color="text.secondary">
          We're performing some maintenance at the moment, so we apologize for
          any inconvenience. <br />
          We will be back online shortly, so if you need to{" "}
          <Link
            href="https://communityinviter.com/apps/cloudchiprcommunity/landing-page"
            target="_blank"
          >
            contact us
          </Link>
          , feel free to do so!
        </Typography>
        <Typography variant="body1" color="text.primary">
          Cloudchipr Team
        </Typography>
      </Stack>
    </Stack>
  );
};
