import { FC, memo, useCallback, useEffect, useState } from "react";
import { Card, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import { GracePeriodAlert } from "./GracePeriodAlert";
import { Hourly } from "../tabs/Hourly";
import { Daily } from "../tabs/Daily";
import { Weekly } from "../tabs/weekly/Weekly";
import { Once } from "../tabs/Once";
import { ScheduleTabs } from "../../../../common/create/scheduler/ScheduleTabs";
import {
  WorkflowSchedulerData,
  WorkflowSchedulerDateChangeType,
} from "../../../utils/types/types";
import { AutomationTabType } from "../../../../common/utils/types/types";
import { getCronFrequency } from "../../../../common/utils/helpers/cron/getCronFrequency";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { getScheduleNextRunDatesThunk } from "../../../../../../../store/schedules/thunks/getScheduleNextRunDatesThunk";
import { ScheduleFrequency } from "../../../../../../../services/cloudchipr.api";

interface ScheduleComponentProps {
  id: string;
  period?: number;
  timeZone: string;
  startDate: string | null;
  endDate: string | null;
  weeklyInterval: number | null;
  cron: string | null;
  setScheduleDatesData(data: WorkflowSchedulerData): void;
  onPreviousStep(): void;
  hideCancelButton?: boolean;
  isDirty: boolean;
}

export const ScheduleComponent: FC<ScheduleComponentProps> = memo(
  ({
    period,
    endDate,
    startDate,
    cron,
    setScheduleDatesData,
    timeZone,
    onPreviousStep,
    id,
    isDirty,
    hideCancelButton,
    weeklyInterval,
  }) => {
    const [activeTab, setActiveTab] = useState<AutomationTabType>("daily");

    const dispatch = useAppDispatch();

    useEffect(() => {
      if (!cron && startDate) {
        setActiveTab("once");
      }

      if (!cron) {
        return;
      }

      setActiveTab(getCronFrequency(cron));
    }, [cron, startDate]);

    const nextRunDatesDispatcher = useCallback(
      (data: WorkflowSchedulerDateChangeType) => {
        const { startDate, cron, interval, endDate, timeZone } = data;

        dispatch(
          getScheduleNextRunDatesThunk({
            cron,
            startDate,
            endDate,
            interval,
            timeZone,
            frequency: getCronFrequency(cron) as ScheduleFrequency,
          }),
        );
      },
      [dispatch],
    );

    return (
      <TabContext value={activeTab}>
        <Stack direction="row" mt={6} spacing={3}>
          <ScheduleTabs
            tabs={["hourly", "daily", "weekly", "once"]}
            setActiveTab={setActiveTab}
            activeTab={activeTab}
          />
          <Stack spacing={1} flexGrow={1}>
            {period !== undefined && <GracePeriodAlert period={period} />}

            <Card variant="outlined" sx={{ width: "100%" }}>
              {activeTab === "hourly" && (
                <Hourly
                  id={id}
                  cron={cron}
                  endDate={endDate}
                  timeZone={timeZone}
                  startDate={startDate}
                  onPreviousStep={onPreviousStep}
                  hideCancelButton={hideCancelButton}
                  setScheduleDatesData={setScheduleDatesData}
                  isDirty={isDirty}
                  onDateChange={nextRunDatesDispatcher}
                />
              )}
              {activeTab === "daily" && (
                <Daily
                  id={id}
                  cron={cron}
                  endDate={endDate}
                  timeZone={timeZone}
                  startDate={startDate}
                  onPreviousStep={onPreviousStep}
                  hideCancelButton={hideCancelButton}
                  setScheduleDatesData={setScheduleDatesData}
                  isDirty={isDirty}
                  onDateChange={nextRunDatesDispatcher}
                />
              )}
              {activeTab === "weekly" && (
                <Weekly
                  id={id}
                  cron={cron}
                  endDate={endDate}
                  isDirty={isDirty}
                  timeZone={timeZone}
                  startDate={startDate}
                  weeklyInterval={weeklyInterval}
                  onPreviousStep={onPreviousStep}
                  hideCancelButton={hideCancelButton}
                  setScheduleDatesData={setScheduleDatesData}
                  onDateChange={nextRunDatesDispatcher}
                />
              )}

              <Once
                id={id}
                endDate={endDate}
                timeZone={timeZone}
                startDate={startDate}
                onPreviousStep={onPreviousStep}
                hideCancelButton={hideCancelButton}
                setScheduleDatesData={setScheduleDatesData}
                isDirty={isDirty}
              />
            </Card>
          </Stack>
        </Stack>
      </TabContext>
    );
  },
);
