import { FC, Fragment } from "react";
import { Popover, Typography } from "@mui/material";
import { ScheduledMoreNextRunsPopoverContent } from "./ScheduledMoreNextRunsPopoverContent";
import {
  FutureRunDate,
  NullableDate,
} from "../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";

interface ScheduledMoreNextRunsProps {
  nextRun?: NullableDate;
  timeZone: string;
  nextRunList?: FutureRunDate[] | null;
  disabled?: boolean;
}

export const ScheduledMoreNextRuns: FC<ScheduledMoreNextRunsProps> = ({
  nextRun,
  nextRunList,
  timeZone,
  disabled,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  return (
    <Fragment>
      <Typography
        color={disabled ? "text.disabled" : "primary"}
        sx={{
          textDecoration: "underline",
          cursor: "pointer",
          pointerEvents: disabled ? "none" : "inherit",
        }}
        variant="caption"
        onClick={openMenu}
      >
        More
      </Typography>
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        PaperProps={paperProps}
      >
        <ScheduledMoreNextRunsPopoverContent
          nextRunList={nextRunList ?? []}
          nextRun={nextRun}
          timeZone={timeZone}
        />
      </Popover>
    </Fragment>
  );
};

const paperProps = {
  sx: {
    maxHeight: 310,
    minWidth: 260,
    maxWidth: 400,
  },
};
