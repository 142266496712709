import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CategoryFilterBaseType,
  DimensionCategories,
  DimensionCategory,
  DimensionsDrawerMode,
  DimensionsState,
} from "./utils/types/common";
import { addNewFilterItemInCategoryByProviderReducer } from "./reducers/addNewFilterItemInCategoryByProviderReducer";
import { addNewFilterInCategoryByProviderReducer } from "./reducers/addNewFilterInCategoryByProviderReducer";
import { changeCategoryFilterGroupItemsCombinationOperatorByProviderReducer } from "./reducers/changeCategoryFilterGroupItemsCombinationOperatorByProviderReducer";
import { changeCategoryFilterCombinationOperatorByProviderReducer } from "./reducers/changeCategoryFilterCombinationOperatorByProviderReducer";
import { setCategoryFilterGroupFilterItemByIndexReducer } from "./reducers/setCategoryFilterGroupFilterItemByIndexReducer";
import { deleteCategoryFilterGroupReducer } from "./reducers/deleteCategoryFilterGroupReducer";
import { deleteCategoryFilterGroupFilterItemByIndexReducer } from "./reducers/deleteCategoryFilterGroupFilterItemByIndexReducer";
import { generateEmptyCategory } from "../../components/pages/dimensions/utils/helpers/generateEmptyCategory";

const initialState: DimensionsState = {
  drawerMode: null,
  dimensionName: "",
  filterType: "account_based",
  categories: {},
};

export const dimensionsSlice = createSlice({
  name: "dimensions",
  initialState: initialState,
  reducers: {
    // dimension
    resetDimensions: () => initialState,

    setDimensionsState: (_, action: PayloadAction<DimensionsState>) =>
      action.payload,

    setDimensionsDrawerMode: (
      state,
      action: PayloadAction<DimensionsDrawerMode>,
    ) => {
      state.drawerMode = action.payload;
    },

    setDimensionName: (state, action: PayloadAction<string>) => {
      state.dimensionName = action.payload;
    },

    // categories

    addNewCategory: (state, action: PayloadAction<DimensionCategories>) => {
      state.categories = { ...state.categories, ...action.payload };
    },

    deleteCategory: (state, action: PayloadAction<string>) => {
      delete state.categories[action.payload];
    },

    setCategoryName: (
      state,
      action: PayloadAction<{ id: string; name: string }>,
    ) => {
      const { id, name } = action.payload;

      if (state.categories[id]) {
        state.categories[id].name = name;
      }
    },

    setCategoryFilterType: (
      state,
      action: PayloadAction<CategoryFilterBaseType>,
    ) => {
      state.filterType = action.payload;

      const emptyCategory = generateEmptyCategory(action.payload);
      const emptyFilters = Object.values(emptyCategory)?.at(0)?.filters;

      if (emptyFilters) {
        Object.keys(state.categories).forEach((id) => {
          state.categories[id].filters = emptyFilters;
        });
      }
    },

    // category filters
    setCategoryFilters: (
      state,
      action: PayloadAction<{
        id: string;
        filters: DimensionCategory["filters"];
      }>,
    ) => {
      const { id, filters } = action.payload;

      state.categories[id].filters = filters;
    },

    addNewFilterItemInCategoryByProvider:
      addNewFilterItemInCategoryByProviderReducer,

    addNewFilterInCategoryByProvider: addNewFilterInCategoryByProviderReducer,

    changeCategoryFilterCombinationOperatorByProvider:
      changeCategoryFilterCombinationOperatorByProviderReducer,

    changeCategoryFilterGroupItemsCombinationOperatorByProvider:
      changeCategoryFilterGroupItemsCombinationOperatorByProviderReducer,

    deleteCategoryFilterGroup: deleteCategoryFilterGroupReducer,

    setCategoryFilterGroupFilterItemByIndex:
      setCategoryFilterGroupFilterItemByIndexReducer,

    deleteCategoryFilterGroupFilterItemByIndex:
      deleteCategoryFilterGroupFilterItemByIndexReducer,
  },
});

export const {
  setDimensionsDrawerMode,
  setDimensionsState,
  resetDimensions,
  setDimensionName,
  setCategoryName,
  setCategoryFilterType,
  addNewCategory,
  deleteCategory,
  setCategoryFilters,
  addNewFilterInCategoryByProvider,
  addNewFilterItemInCategoryByProvider,
  changeCategoryFilterGroupItemsCombinationOperatorByProvider,
  changeCategoryFilterCombinationOperatorByProvider,
  setCategoryFilterGroupFilterItemByIndex,
  deleteCategoryFilterGroup,
  deleteCategoryFilterGroupFilterItemByIndex,
} = dimensionsSlice.actions;

export default dimensionsSlice.reducer;
