import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";
import { Stack } from "@mui/material";
import { BreadcrumbNavigationTrigger } from "./BreadcrumbNavigationTrigger";
import { useAppSelector } from "../../../../../../../store/hooks";
import { resourceExplorerWidgetDataLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/loading/resourceExplorerWidgetDataLoadingSelector";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { currentResourceExplorerFolderSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/folder/currentResourceExplorerFolderSelector";
import { resourceExplorerNameSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerNameSelector";
import { ResourceExplorerV2ViewActionButton } from "../../../../../../navigation/components/resource-explorer-v2/components/view-action-menu/ResourceExplorerV2ViewActionButton";
import { resourceExplorerViewsHierarchyDataSelector } from "../../../../../../../store/resource-explorer/selectors/views-hierarchy/resourceExplorerViewsHierarchyDataSelector";
import { isResourceExplorerFolder } from "../../../../../../navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";
import { HierarchicalBreadcrumbs } from "../../../../../common/breadcrumbs/hierarchical-breadcrumbs/HierarchicalBreadcrumbs";

interface ResourceExplorerBreadcrumbsProps {
  viewId: string;
}

export const ResourceExplorerAppBarNavigation: FC<
  ResourceExplorerBreadcrumbsProps
> = ({ viewId }) => {
  const navigate = useNavigate();

  const widgetsGettingLoading = useAppSelector(
    resourceExplorerWidgetDataLoadingSelector,
  );
  const hierarchy = useAppSelector(resourceExplorerViewsHierarchyDataSelector);
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );
  const currentFolder = useAppSelector(currentResourceExplorerFolderSelector);
  const name = useAppSelector(resourceExplorerNameSelector);

  const modifiedHierarchy = useMemo(() => {
    return hierarchy?.map((item) => {
      if (!isResourceExplorerFolder(item)) {
        return item;
      }

      return {
        ...item,
        items: item.views,
      };
    });
  }, [hierarchy]);

  const changeNavigationHandler = useCallback(
    (id: string) => {
      navigate(`/resource-explorer/${id}`);
    },
    [navigate],
  );

  const loading = widgetsGettingLoading || viewGettingLoading;

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <HierarchicalBreadcrumbs
        label={name ?? ""}
        folderName={currentFolder?.name}
        hierarchy={modifiedHierarchy}
        selectedItemId={viewId}
        onChange={changeNavigationHandler}
        ItemIcon={InsertChartOutlinedOutlinedIcon}
        loading={!name && loading}
        CustomTriggerComponent={BreadcrumbNavigationTrigger}
      />

      <ResourceExplorerV2ViewActionButton
        id={viewId}
        name={name ?? ""}
        folderId={currentFolder?.id}
      />
    </Stack>
  );
};
