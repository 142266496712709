import { FC, memo, useCallback } from "react";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

interface DatePickerProps {
  date: string | null;
  minDate: string;
  error?: string | boolean;
  disabled?: boolean;
  onChange?(e: string | null): void;
  onAccept?(e: string | null): void;
}

export const DateTimePicker: FC<DatePickerProps> = memo(
  ({ date, onAccept, onChange, error, disabled, minDate }) => {
    const dateChangeHandler = useCallback(
      (event: any) => {
        if (onChange) {
          onChange(event);
        }
      },
      [onChange],
    );
    const dateAcceptHandler = useCallback(
      (event: any) => {
        if (onAccept) {
          onAccept(event);
        }
      },
      [onAccept],
    );
    return (
      <MobileDateTimePicker
        disableHighlightToday
        disabled={disabled}
        value={date ? moment(date) : null}
        label="Date"
        onChange={dateChangeHandler}
        onAccept={dateAcceptHandler}
        minDate={moment(minDate)}
        slotProps={{
          textField: {
            name: "startDate",
            size: "small",
            helperText: error,
            variant: "outlined",
            error: !!error,
            disabled: disabled,
          },
        }}
      />
    );
  },
);
