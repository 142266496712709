import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";
import { OffHoursFormData } from "../types/types";

export const offHoursInitialData: OffHoursFormData = {
  name: "",
  action: "silent",
  regions: [],
  emails: null,
  account_ids: [],
  filter: [],
  provider: "" as ProviderType,
  start_cron: null,
  stop_cron: null,
  start_date_time: "",
  end_date_time: null,
  time_zone: getTimeZoneObject().tzCode,
  min_threshold: null,

  id: "",
  status: "active",
  created_at: "",
  metadata: {
    next_stop_run_at: "",
    next_start_run_at: "",
    start_state: "normal",
    stop_state: "normal",
  },
};
