import { FC, useCallback } from "react";
import { FilterSelect } from "./FilterSelect";
import { setCommitmentsRecommendationsTerm } from "../../../../../../store/commitments/commitmentsSlice";
import { SavingsPlanTermInYears } from "../../../../../../services/cloudchipr.api";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { commitmentsRecommendationsSavingsPlanTermSelector } from "../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTermSelector";
import { getCommitmentsRecommendationsThunk } from "../../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";
import { savingsPlanTermLabels } from "../../../utilization-and-coverage/utils/constants/labels";

export const SavingsPlanTermFilter: FC = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(
    commitmentsRecommendationsSavingsPlanTermSelector,
  );

  const changeHandler = useCallback(
    (term: string) => {
      dispatch(
        setCommitmentsRecommendationsTerm(term as SavingsPlanTermInYears),
      );
      dispatch(getCommitmentsRecommendationsThunk());
    },
    [dispatch],
  );

  if (!value) {
    return null;
  }

  return (
    <FilterSelect
      value={value}
      label="Savings Plans Term"
      options={options}
      onChange={changeHandler}
    />
  );
};

const options = [
  {
    value: "one_year",
    label: savingsPlanTermLabels.one_year,
  },
  {
    value: "three_years",
    label: savingsPlanTermLabels.three_years,
  },
];
