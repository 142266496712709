import { CleanResourceType } from "../cleanSlice";
import { sortStringArrayByAlphanumeric } from "../../../components/common/account-select/utils/helpers/sorting/sortStringArrayByAlphanumeric";

export const getCleanResourcesCacheKey = (
  resources: CleanResourceType[] | null,
) => {
  if (!resources) {
    return "";
  }

  return resources.map((resource) => resource?.id || "").join("");
};

export const getProtectResourceCacheKey = (
  accountId: string,
  resourceIds: string[],
) => {
  const ids = sortStringArrayByAlphanumeric(resourceIds).reduce(
    (result, item) => {
      result = result + item;
      return result;
    },
    "",
  );

  return accountId + ids;
};

export const isResourceNotCleanable = (
  {
    isProtected,
    markedForDeletion,
    notAllowedActions,
  }: {
    isProtected: boolean;
    markedForDeletion: boolean;
    notAllowedActions?: string[];
  },
  cleanActionType: string,
): boolean => {
  return (
    isProtected ||
    markedForDeletion ||
    !!notAllowedActions?.includes(cleanActionType)
  );
};
