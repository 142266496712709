import { resourceExplorerViewsHierarchyDataSelector } from "./resourceExplorerViewsHierarchyDataSelector";
import { RootState } from "../../../store";
import { isResourceExplorerFolder } from "../../../../components/navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";

export const resourceExplorerViewsHierarchyDefaultViewSelector = (
  state: RootState,
): string | undefined => {
  const hierarchy = resourceExplorerViewsHierarchyDataSelector(state);

  return hierarchy?.find(
    (item) => !isResourceExplorerFolder(item) && item.is_default,
  )?.id;
};
