import { ResourceType } from "../../../../../../services/cloudchipr.api";

export const generateScheduleResourcesFetchingFixedCacheKey = (
  resourceType: ResourceType,
  id: string,
) => {
  return `postUsersMeV2OrganisationsCurrentByTypeResources-schedules-${id}-${resourceType}-fixed-cache-key`;
};

export const generateScheduleResourcesProtectionFixedCacheKey = (
  accountId: string,
) => {
  return `patchUsersMeAccountsByAccountIdResources-schedules-${accountId}-fixed-cache-key`;
};
