import { ChartDataType } from "../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";

export const calculateCostBreakdownV2Total = (data: ChartDataType[]) => {
  const chartData = data?.at(0);

  if (!chartData) {
    return 0;
  }

  return Object.keys(chartData).reduce((sum, key) => {
    if (typeof chartData[key] === "string") {
      return sum;
    }

    return sum + (chartData?.[key] ?? 0);
  }, 0);
};
