import { FC, Fragment, useCallback } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { DeleteFilterTemplateDialog } from "./DeleteFilterTemplateDialog";
import { FilterSet2 } from "../../../../../../services/cloudchipr.api";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { useAppAbility } from "../../../../../../services/permissions";
interface FilterTemplateRowActionsProps {
  filterSet: FilterSet2;
  onModify(): void;
  onDuplicate(): void;
}

export const FilterTemplateRowActions: FC<FilterTemplateRowActionsProps> = ({
  filterSet,
  onModify,
  onDuplicate,
}) => {
  const { can } = useAppAbility();
  const canDeleteFilterSet = can("delete", "filter-set");
  const canCreateFilterSet = can("create", "filter-set");

  const { anchor, open: menuOpen, openMenu, closeMenu } = useMenuHook();
  const { open, openDialog, closeDialog } = useDialog();

  const closeHandler = useCallback(() => {
    closeMenu();
    closeDialog();
  }, [closeMenu, closeDialog]);

  const duplicateHandler = useCallback(() => {
    onDuplicate();
    closeMenu();
  }, [onDuplicate, closeMenu]);

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu} sx={{ p: 0 }}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        open={menuOpen}
        onClose={closeMenu}
        anchorEl={anchor}
        sx={{
          "& li": { py: 0.5, pl: 1, pr: filterSet.accounts.length ? 1 : 4 },
        }}
      >
        <MenuItem
          dense
          onClick={duplicateHandler}
          disabled={!canCreateFilterSet}
        >
          <ListItemIcon>
            <ContentCopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Duplicate</ListItemText>
        </MenuItem>

        {filterSet.source !== "cloudchipr" && (
          <MenuItem dense onClick={onModify} disabled={!canCreateFilterSet}>
            <ListItemIcon>
              <EditOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}

        {filterSet.source !== "cloudchipr" && (
          <Tooltip
            title={
              filterSet.accounts.length
                ? "This filter template is currently in use."
                : ""
            }
            placement="top"
            arrow
          >
            <span>
              <MenuItem
                dense
                onClick={openDialog}
                disabled={!canDeleteFilterSet || !!filterSet.accounts.length}
              >
                <ListItemIcon>
                  <DeleteOutlineIcon fontSize="small" color="error" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: "error" }}>
                  Delete
                </ListItemText>
              </MenuItem>
            </span>
          </Tooltip>
        )}
      </Menu>

      {open && (
        <DeleteFilterTemplateDialog
          filterSetId={filterSet.id}
          name={filterSet.name}
          onClose={closeHandler}
        />
      )}
    </Fragment>
  );
};
