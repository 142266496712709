import { FC } from "react";
import { Card, Stack } from "@mui/material";
import { UtilizationAndCoverageMetadataCard } from "./UtilizationAndCoverageMetadataCard";
import { useAppSelector } from "../../../../../../store/hooks";
import { savingsPlansTotalCostSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/totals/savingsPlansTotalCostSelector";
import { savingsPlansNetSavingsSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/totals/savingsPlansNetSavingsSelector";
import { savingsPlansCoverageAverageSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/totals/savingsPlansCoverageAverageSelector";
import { money } from "../../../../../../utils/numeral/money";
import { commitmentsForGranularLoadingSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/commitmentsForGranularLoadingSelector";
import { useGetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsQuery } from "../../../../../../services/cloudchipr.api";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../store/commitments/selectors/commitmentsCurrentOrgIdSelector";

export const UtilizationAndCoverageMetadata: FC = () => {
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);
  const total = useAppSelector(savingsPlansTotalCostSelector);
  const netSavings = useAppSelector(savingsPlansNetSavingsSelector);
  const coverage = useAppSelector(savingsPlansCoverageAverageSelector);
  const loading = useAppSelector(commitmentsForGranularLoadingSelector);
  const { data, isLoading } =
    useGetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsQuery({
      providerOrganisationId: orgId,
    });

  return (
    <Card sx={{ p: 2 }} variant="outlined">
      <Stack
        gap={2}
        direction="row"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <UtilizationAndCoverageMetadataCard
          primary="Total Cost"
          loading={loading}
          value={money(total)}
          tooltip="Total cost for the selected date range."
        />

        <UtilizationAndCoverageMetadataCard
          primary="Total Net Savings"
          loading={loading}
          value={money(netSavings)}
          tooltip="Total Net Savings from commitments for the selected date range. Calculated by comparing the usage cost covered by Savings Plans to the public On-Demand cost."
        />

        <UtilizationAndCoverageMetadataCard
          primary="Coverage AVG"
          loading={loading}
          value={coverage !== null ? `${coverage}%` : "N/A"}
          tooltip="Average percentage of AWS usage covered by Savings Plans during the selected date range."
        />

        <UtilizationAndCoverageMetadataCard
          primary="Potential Monthly Savings"
          loading={isLoading}
          valueColor="success.light"
          cardBg="white"
          value={
            data?.savings_amount !== null
              ? `~${money(data?.savings_amount)}`
              : "N/A"
          }
          tooltip="Total amount of potential savings based on Savings Plan Recommendations."
        />
      </Stack>
    </Card>
  );
};
