import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useToggle } from "rooks";

export type FilterSelectOptions = {
  value: string;
  label: string;
  tooltip?: string;
  disabled?: boolean;
  ListItem?: FC<{ toggleOpen(action: any): void }>;
}[];

interface FilterSelectProps {
  label: string;
  value: string;
  onChange(value: string): void;
  renderValue?: SelectProps["renderValue"];
  options: FilterSelectOptions;
}

export const FilterSelect: FC<FilterSelectProps> = ({
  label,
  value,
  onChange,
  options,
  renderValue,
}) => {
  const [open, toggleOpen] = useToggle(false);
  const changeHandler = useCallback(
    (e: SelectChangeEvent) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <FormControl size="xsmall" sx={{ width: 200 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        open={open}
        value={value}
        label={label}
        onChange={changeHandler}
        renderValue={renderValue}
        onOpen={toggleOpen}
        onClose={toggleOpen}
      >
        {options?.map(({ ListItem, tooltip, label, value, disabled }) => {
          if (ListItem) {
            return <ListItem key={value} toggleOpen={toggleOpen} />;
          }

          return (
            <MenuItem key={value} value={value} disabled={disabled}>
              <ListItemText> {label}</ListItemText>

              {tooltip && (
                <Tooltip title={tooltip} placement="top" arrow>
                  <InfoOutlinedIcon color="action" fontSize="small" />
                </Tooltip>
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
