import { FC, useCallback, useMemo } from "react";
import { Card, Stack, Theme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { savingsPlansTableColumns } from "./commitmentsRecommendationsDataGridColumns";
import { CsvDataDownloader } from "./CsvDataDownloader";
import { DataGrid } from "../../../../../../../storybook/data-grid/DataGrid";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { commitmentsRecommendationsListSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsListSelector";
import { commitmentsRecommendationsSavingsPlanTypeSelector } from "../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTypeSelector";
import { connectedOrganizationExistsSelector } from "../../../../../../../store/commitments/selectors/organisations/connectedOrganizationExistsSelector";
import { setCommitmentsRecommendationsSelectedRecommendations } from "../../../../../../../store/commitments/commitmentsSlice";
import { commitmentsRecommendationsSelectedRecommendationsSelector } from "../../../../../../../store/commitments/selectors/recommendations/commitmentsRecommendationsSelectedRecommendationsSelector";
import { EmptyDataGridFooter } from "../../../../utilization-and-coverage/components/common/EmptyDataGridFooter";
import { commitmentsRecommendationsExistsSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsExistsSelector";
import { commitmentsRecommendationsLoadingSelector } from "../../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsLoadingSelector";

export const CommitmentsRecommendationsDataGrid: FC = () => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(commitmentsRecommendationsListSelector);
  const orgExists = useAppSelector(connectedOrganizationExistsSelector);
  const selectedRecommendations = useAppSelector(
    commitmentsRecommendationsSelectedRecommendationsSelector,
  );
  const savingsPlanType = useAppSelector(
    commitmentsRecommendationsSavingsPlanTypeSelector,
  );
  const recommendationsExists = useAppSelector(
    commitmentsRecommendationsExistsSelector,
  );
  const loading = useAppSelector(commitmentsRecommendationsLoadingSelector);

  const columns = useMemo(() => {
    let columns = savingsPlansTableColumns;

    if (orgExists) {
      columns = columns.filter((column) => column.accessorKey !== "account");
    }

    if (savingsPlanType === "ec2_instance") {
      return columns;
    }

    return columns.filter(
      (column) =>
        !["instance_family", "region"].includes(column.accessorKey ?? ""),
    );
  }, [orgExists, savingsPlanType]);

  const pagination = useMemo(() => {
    if (recommendationsExists || loading) {
      return undefined;
    }

    return {
      renderPagination: () => <EmptyDataGridFooter name="Recommendations" />,
    };
  }, [recommendationsExists, loading]);

  const rowSelectionChange = useCallback(
    (data: Record<string, boolean>) => {
      dispatch(setCommitmentsRecommendationsSelectedRecommendations(data));
    },
    [dispatch],
  );

  return (
    <DataGrid
      rowSelection={{
        initialSelectedItems: selectedRecommendations,
        rowSelectionChange: rowSelectionChange,
      }}
      pagination={pagination}
      styles={styles}
      data={data ?? []}
      columns={columns}
      toolbar={toolbar}
      enableStickyColumns
    />
  );
};

const toolbar = {
  renderToolbar: () => {
    return (
      <Card variant="outlined" sx={{ borderRadius: "8px 8px 0 0" }}>
        <Stack p={1} alignItems="end">
          <CsvDataDownloader />
        </Stack>
      </Card>
    );
  },
};

const styles = {
  table: { tableLayout: "fixed" },
  tableRow: { background: "white" },
  tableContainer: {
    border: `1px solid ${grey[300]}`,
    borderTop: 0,
    borderRadius: "0 0 8px 8px",
  },

  tableHeaderRow: {
    top: 0,
    position: "sticky",
    background: "white",
    zIndex: (theme: Theme) => theme.zIndex.speedDial - 1,
  },
};
