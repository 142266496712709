import moment from "moment";
import {
  generateAutomationPayloadData,
  getAutomationSteps,
  isFilterAdded,
} from "../../../common/utils/helpers/common";
import {
  OffHoursSchedule2,
  PatchV2UsersMeOffHoursSchedulesByScheduleIdApiArg,
  PostV2UsersMeOffHoursSchedulesApiArg,
  ScheduleAccount,
} from "../../../../../../services/cloudchipr.api";
import { getTimeZoneObject } from "../../../../../../utils/helpers/date-time/timeZones";
import { weekDaysIndex } from "../../../../../../utils/helpers/date-time/datetime-format";
import { offHoursInitialData } from "../constants/constants";
import { OffHoursFormData } from "../types/types";
import { AutomationsStepsType } from "../../../common/utils/types/types";
import { getFirstParentInHierarchyResourceType } from "../../../../../../utils/helpers/resources/getFirstParentInHierarchyResourceType";
import { RecommendationDataType } from "../../../../recommendations/components/off-hours-recommendations/utils/types/types";
import { generateWeeklyCron } from "../../../common/utils/helpers/cron/generateWeeklyCron";
import { generateDailyCron } from "../../../common/utils/helpers/cron/generateDailyCron";
import { getStartsOnDate } from "../../../common/utils/constants/common";

export const createOffHoursSchedule = (
  data: RecommendationDataType,
  frequency: "daily" | "weekly",
): OffHoursSchedule2 => {
  const startDate = moment()
    .set({ hours: data.to_hour, minutes: 0, seconds: 0 })
    .format();
  const stopDate = moment()
    .set({ hours: data.from_hour, minutes: 0, seconds: 0 })
    .format();

  let startCron;
  let stopCron;

  if (frequency === "weekly") {
    const weekDayFrom: number[] = [];
    const weekDayTo: number[] = [];

    if (data.from_day && data.to_day) {
      weekDayFrom.push(weekDaysIndex[data.from_day]);
      weekDayTo.push(weekDaysIndex[data.to_day]);
    }

    stopCron = generateWeeklyCron(weekDayFrom, stopDate);
    startCron = generateWeeklyCron(weekDayTo, startDate);
  } else {
    stopCron = generateDailyCron(1, stopDate);
    startCron = generateDailyCron(1, startDate);
  }

  // status is unnecessary added just for correct typing
  const accountWithStatus: ScheduleAccount = {
    ...data.account,
    status: "connected",
  };

  return {
    ...offHoursInitialData,
    name: `${data.resource_type}: ${data.provider_unique_identifier} Off Hours`,
    regions: [data.region],
    filter: [
      {
        action: "stop",
        snapshot: false,
        filter: {
          type:
            getFirstParentInHierarchyResourceType(data.resource_type) ??
            data.resource_type,
          filter_items: [
            {
              type: data.resource_type,
              filter: {
                key: "provider_unique_identifier",
                value: [data.provider_unique_identifier],
                operator: "in",
              },
            },
          ],
          filter_groups: [0],
          operators: ["AND"],
        },
      },
    ],
    accounts: [accountWithStatus],
    start_cron: startCron,
    stop_cron: stopCron,
  };
};

export const generateOffHoursSteps = (
  offHours: OffHoursFormData,
): AutomationsStepsType => {
  const isFirstCompleted = !!(
    offHours.name &&
    offHours.account_ids.length &&
    offHours.provider &&
    offHours.regions.length
  );

  const isSecondCompleted = !!(
    isFirstCompleted &&
    offHours.filter &&
    isFilterAdded(offHours.filter)
  );

  const isThirdCompleted =
    isSecondCompleted &&
    !!(offHours.action === "notify"
      ? offHours?.notifications?.length || offHours?.emails?.to?.length
      : true);

  const isFourthCompleted = !!(
    isThirdCompleted &&
    ((offHours.start_date_time && offHours.end_date_time) ||
      (offHours.start_cron && offHours.stop_cron))
  );

  return getAutomationSteps(
    isFirstCompleted,
    isSecondCompleted,
    isThirdCompleted,
    isFourthCompleted,
    { third: "Set Notification" },
  );
};

export const getOffHoursCreatePayloadData = (
  schedule: OffHoursFormData,
): PostV2UsersMeOffHoursSchedulesApiArg => {
  const data = generateAutomationPayloadData(schedule);

  return {
    body: {
      ...data.body,
      action: schedule.action,
      start_cron: schedule.start_cron!,
      stop_cron: schedule.stop_cron!,
    },
  };
};

export const getOffHoursUpdatePayloadData = (
  schedule: OffHoursFormData,
): PatchV2UsersMeOffHoursSchedulesByScheduleIdApiArg => {
  const data = generateAutomationPayloadData(schedule);

  const { start_date_time, time_zone } = schedule;
  const startDate = start_date_time || getStartsOnDate(time_zone, true);

  return {
    scheduleId: schedule.id,
    body: {
      ...data.body,
      start_date_time: startDate,
      action: schedule.action,
      start_cron: schedule.start_cron!,
      stop_cron: schedule.stop_cron!,
      status: schedule.status,
    },
  };
};

export const generateOffHoursEditingData = (
  schedule: OffHoursSchedule2,
): OffHoursFormData => {
  const { time_zone, accounts, ...other } = schedule;

  const timeZone = time_zone || getTimeZoneObject().tzCode;

  return {
    ...other,
    emails: other.emails ?? null,
    account_ids: accounts.map(({ id }) => id),
    provider: accounts?.[0]?.provider,
    time_zone: timeZone,
  };
};

export const generateOffHoursUpdatingData = (
  schedule: OffHoursSchedule2,
): OffHoursFormData => {
  const { accounts, ...other } = schedule;

  return {
    ...other,
    account_ids: accounts.map(({ id }) => id),
    provider: accounts?.[0]?.provider,
  };
};
