import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { TooltipText } from "../../../../../../../../../../common/TooltipText";
import { money } from "../../../../../../../../../../../utils/numeral/money";
import { resourceExplorerLiveUsageTotalCostSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/live-usage/resourceExplorerLiveUsageTotalCostSelector";

export const ResourceExplorerLiveFilteredColumnHeaderCell: FC = () => {
  const cost = useAppSelector(resourceExplorerLiveUsageTotalCostSelector);

  return (
    <Stack alignItems="end" flexGrow={1}>
      <TooltipText
        fontWeight="medium"
        tooltipPlacement="left"
        tooltipTitle="The total monthly cost of filtered resources found in Live Usage & Mgmt for the current view."
      >
        <div>Live Filtered</div>
      </TooltipText>

      <Typography
        mt={0.5}
        fontSize={10}
        color="text.disabled"
        fontWeight="medium"
        align="right"
      >
        {money(cost ?? 0)}
      </Typography>
    </Stack>
  );
};
