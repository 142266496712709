import { FC } from "react";
import { Stack } from "@mui/material";
import { LargestCostChangesWidgetSetupResourceItem } from "./LargestCostChangesWidgetSetupResourceItem";
import { useAppSelector } from "../../../../../../../../../../store/hooks";
import { largestCostChangesWidgetDataSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/largestCostChangesWidgetDataSelector";
import { WidgetNumeralView } from "../../../common/view-type/WidgetNumeralView";
import { largestCostChangesGetWidgetDataLoadingSelector } from "../../../../../../../../../../store/dashboards/selectors/widgets/largest-cost-changes/loading/largestCostChangesGetWidgetDataLoadingSelector";
import { largestCostChangesSetupPropertyByKeySelector } from "../../../../../../../../../../store/dashboards/selectors/setups/larges-cost-changes/largestCostChangesSetupPropertyByKeySelector";
import { WidgetContentInvalidState } from "../../../common/WidgetContentInvalidState";

interface LargestCostChangesWidgetBodyProps {
  widgetId: string;
}
export const LargestCostChangesWidgetBody: FC<
  LargestCostChangesWidgetBodyProps
> = ({ widgetId }) => {
  const data = useAppSelector((state) =>
    largestCostChangesWidgetDataSelector(state, {
      widgetId,
    }),
  );

  const itemsCount = useAppSelector(
    largestCostChangesSetupPropertyByKeySelector("itemsCount"),
  );

  const loading = useAppSelector(
    largestCostChangesGetWidgetDataLoadingSelector,
  );

  if (!data.length && !loading) {
    return (
      <WidgetContentInvalidState message="No items match the conditions. Adjust conditions to view results." />
    );
  }

  return (
    <Stack sx={{ overflow: "auto" }} flex={1}>
      <WidgetNumeralView loading={loading} skeletonCount={itemsCount}>
        {data.map((item) => {
          return (
            <LargestCostChangesWidgetSetupResourceItem
              key={item.field + item.cloud_provider}
              provider={item.cloud_provider}
              totalCost={item.total_cost}
              difference={Math.abs(item.period_total_cost_difference)}
              trend={item.trend}
              label={item.label ?? item.field}
              itemsCount={data.length}
            />
          );
        })}
      </WidgetNumeralView>
    </Stack>
  );
};
