import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { CategoryFilterType, DimensionsState } from "../utils/types/common";
import { ProviderType } from "../../../services/cloudchipr.api";
import { filterProviderByProvider } from "../../../components/pages/dimensions/utils/constants/common";
import { isFilterWithTags } from "../../../components/pages/dimensions/components/form-drawer/components/dimension/categories/based-on-filters/utils/helpers/typeCheckers";

export const changeCategoryFilterGroupItemsCombinationOperatorByProviderReducer: CaseReducer<
  DimensionsState,
  PayloadAction<{
    id: string;
    provider: ProviderType;
    type: CategoryFilterType;
  }>
> = (state, action) => {
  const { id, provider, type } = action.payload;

  const filterProvider = filterProviderByProvider[provider];
  const filters = state.categories[id].filters[filterProvider];

  if (type === "account") {
    const accounts = filters?.accounts;

    if (accounts) {
      accounts.combination_operator =
        accounts.combination_operator === "and" ? "or" : "and";
    }
  } else {
    const tags = filters && isFilterWithTags(filters) && filters.tags;

    if (tags) {
      tags.combination_operator =
        tags.combination_operator === "and" ? "or" : "and";
    }
  }
};
