import { FC, ReactNode } from "react";
import { Card, Skeleton, Stack, Typography } from "@mui/material";
import { SxProps } from "@mui/system";

interface CommitmentsDrawerContentInfoCardProps {
  title: string;
  value?: ReactNode;
  width?: number;
  loading?: boolean;
  cardSx?: SxProps;
  children?: ReactNode;
}

export const CommitmentsDrawerContentInfoCard: FC<
  CommitmentsDrawerContentInfoCardProps
> = ({ value, title, width, cardSx, loading, children }) => {
  const child = children ?? (
    <Typography variant="body1" fontWeight="medium">
      {value ?? "N/A"}
    </Typography>
  );

  return (
    <Card sx={{ width, p: 1, ...cardSx }} variant="outlined">
      <Typography variant="caption" fontWeight="medium" color="text.secondary">
        {title}
      </Typography>
      {loading ? (
        <Skeleton variant="text" height={24} width={150} />
      ) : (
        <Stack>{child}</Stack>
      )}
    </Card>
  );
};
