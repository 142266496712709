import { FC, useCallback, useEffect, useMemo } from "react";
import { TooltipProps } from "recharts";
import { CostBreakdownChartTooltipContent } from "./CostBreakdownChartTooltipContent";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { moneyFormatter } from "../../../../../../../../utils/numeral/moneyFormatter";
import { useMultiTypeChartContext } from "../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import { MultiTypeChart } from "../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChart";
import { ChartType } from "../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";
import { costBreakdownWidgetViewTotalCostDateDetailsSelector } from "../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown/costBreakdownWidgetViewTotalCostDateDetailsSelector";
import { calculateCostBreakdownTotal } from "../../utils/helpers/calculateCostBreakdownTotal";
import { costBreakdownSetupPropertyByKeySelector } from "../../../../../../../../store/dashboards/selectors/setups/cost-breakdown/costBreakdownSetupPropertyByKeySelector";

export interface CostBreakdownWidgetChartProps {
  chartType: ChartType;
  widgetId: string;
}

export const CostBreakdownWidgetChart: FC<CostBreakdownWidgetChartProps> = ({
  chartType,
  widgetId,
}) => {
  const {
    chartType: { type: chartTypeFromProvider, onChange: onChartTypeChange },
    data,
  } = useMultiTypeChartContext();

  const loading = useAppSelector(
    costBreakdownSetupPropertyByKeySelector("loading"),
  );

  const dateDetails = useAppSelector((state) =>
    costBreakdownWidgetViewTotalCostDateDetailsSelector(state, widgetId),
  );

  const total = useMemo(() => {
    return calculateCostBreakdownTotal(data);
  }, [data]);

  const TooltipContent = useCallback(
    (props: TooltipProps<any, any>) => {
      return (
        <CostBreakdownChartTooltipContent
          {...props}
          total={total}
          dateDetails={dateDetails}
        />
      );
    },
    [dateDetails, total],
  );

  const yAxisFormatter = useCallback((value: number | string) => {
    return moneyFormatter(+value);
  }, []);

  useEffect(() => {
    if (chartTypeFromProvider === chartType) {
      return;
    }

    onChartTypeChange(chartType);
  }, [chartTypeFromProvider, chartType, onChartTypeChange]);

  return (
    <MultiTypeChart
      zoom
      selectable
      highlight
      loading={loading}
      TooltipContent={TooltipContent}
      tooltipProps={tooltipProps}
      yAxisFormatter={yAxisFormatter}
      showLoader={false}
      emptyText="No data by your selected date range."
    />
  );
};
const tooltipProps = { showTotal: true };
