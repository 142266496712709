import { createDraftSafeSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { cloudChiprApi, Subscription } from "../../services/cloudchipr.api";

export const getUsersMeSubscriptionsCurrentSelector =
  cloudChiprApi.endpoints.getUsersMeSubscriptionsCurrent.select();

export const currentSubscriptionSelector = createDraftSafeSelector(
  [getUsersMeSubscriptionsCurrentSelector],
  (getUsersMeSubscriptionsCurrent): Subscription | undefined => {
    const { data: subscription } = getUsersMeSubscriptionsCurrent;

    return subscription;
  },
);

export const isSubscriptionCancelledSelector = createDraftSafeSelector(
  [currentSubscriptionSelector],
  (subscription) => {
    return subscription === null;
  },
);

export const currentSubscriptionPlanNameSelector = createDraftSafeSelector(
  [currentSubscriptionSelector],
  (current): string | undefined => {
    return current?.plan?.name;
  },
);

export const isSubscriptionPlanExpiredSelector = createDraftSafeSelector(
  [currentSubscriptionSelector],
  (subscription): boolean => {
    if (!subscription) {
      return false;
    }

    return moment(subscription.expiration_date) < moment();
  },
);

export const isSubscriptionPlanOverdueSelector = createDraftSafeSelector(
  [currentSubscriptionSelector],
  (currentSubscription): boolean => {
    return currentSubscription?.status === "overdue";
  },
);

export const isSubscriptionDisabledSelector = createDraftSafeSelector(
  [isSubscriptionPlanOverdueSelector, isSubscriptionCancelledSelector],
  (isSubscriptionOverdue, isSubscriptionCancelled): boolean => {
    return isSubscriptionOverdue || isSubscriptionCancelled;
  },
);

export const isSubscriptionPlanTrialSelector = createDraftSafeSelector(
  [currentSubscriptionSelector],
  (current): boolean => {
    return !!current?.trial;
  },
);

export const trialPeriodExpiresInSelector = createDraftSafeSelector(
  [currentSubscriptionSelector],
  (current): number | null => {
    if (!current) {
      return null;
    }

    return Math.ceil(
      moment(current.expiration_date).diff(moment(), "days", true),
    );
  },
);

export const trialPeriodEndsOnSelector = createDraftSafeSelector(
  [currentSubscriptionSelector],
  (current): string | null => {
    if (!current) {
      return null;
    }

    return moment(current.expiration_date).format(
      "dddd, MMMM Do YYYY, h:mm:ss a",
    );
  },
);

export const isCurrentSubscriptionPlanEnterprise = createDraftSafeSelector(
  [currentSubscriptionSelector],
  (current): boolean => {
    return current?.plan?.name_key === "enterprise";
  },
);

export const hasPaymentMethodAttachedSelector = createDraftSafeSelector(
  [currentSubscriptionSelector],
  (currentSubscription): boolean => {
    return !!currentSubscription?.has_payment_method_attached;
  },
);
