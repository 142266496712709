import { useResourcesVisibility } from "./useResourcesVisibility.hook";
import { resourceGroups } from "../../../../../../utils/constants/resources/resourceGroups";
import { isScheduleResourceTypeVisible } from "../helpers/isScheduleResourceTypeVisible";
import { useToggledResourceTypes } from "../../../../account/utils/hooks/useToggledResources";
import {
  ProviderType,
  ResourceType,
} from "../../../../../../services/cloudchipr.api";
import { AutomationType } from "../types/types";

export const useVisibleScheduleResourceTypes = (
  provider: ProviderType,
  scheduleType: AutomationType,
  accountIds: string[],
) => {
  const groups = resourceGroups.get(provider);
  const toggledResourceTypes = useToggledResourceTypes();

  const resourcesVisibility = useResourcesVisibility({
    accountIds: accountIds.join(", "),
    provider,
  });
  return groups?.reduce((result, item) => {
    item.resourceTypes.forEach((resourceType) => {
      if (
        isScheduleResourceTypeVisible({
          resourceType,
          resourcesVisibility,
          scheduleType,
          toggledResourceTypes,
        })
      ) {
        result.push(resourceType);
      }
    });

    return result;
  }, [] as ResourceType[]);
};
