import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { CategoryFilterType, DimensionsState } from "../utils/types/common";
import { AwsFilter, ProviderType } from "../../../services/cloudchipr.api";
import { filterProviderByProvider } from "../../../components/pages/dimensions/utils/constants/common";

export const deleteCategoryFilterGroupFilterItemByIndexReducer: CaseReducer<
  DimensionsState,
  PayloadAction<{
    id: string;
    index: number;
    provider: ProviderType;
    type: CategoryFilterType;
  }>
> = (state, action) => {
  const { id, provider, index, type } = action.payload;

  const filterProvider = filterProviderByProvider[provider];
  const filters = state.categories[id].filters[
    filterProvider
  ] as Required<AwsFilter>;

  if (type === "account") {
    filters.accounts.items = filters.accounts.items.filter(
      (_, i) => i !== index,
    );
  } else {
    filters.tags.items = filters.tags.items.filter((_, i) => i !== index);
  }
};
