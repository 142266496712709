import { FC } from "react";
import { Drawer, List, ListItem, Typography } from "@mui/material";

interface CommitmentsRecommendationsDetailsDrawerProps {
  open: boolean;
  onClose(): void;
}

export const CommitmentsRecommendationsDetailsDrawer: FC<
  CommitmentsRecommendationsDetailsDrawerProps
> = ({ open, onClose }) => {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 400, py: 5, px: 3 } }}
    >
      <Typography variant="subtitle1" fontWeight="bold">
        Recommendation details
      </Typography>

      <List
        sx={{
          px: 3,
          listStyleType: "disc",
          "& .MuiListItem-root": { display: "list-item" },
        }}
      >
        <ListItem sx={{ px: 0 }}>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight="normal"
          >
            Savings Plans recommendation details offer visibility into the
            potential impact of a recommended Savings Plan on your hourly
            On-Demand cost, coverage, and utilization. Each recommendation is
            designed to maximize your savings, taking into account your selected
            Recommendation parameters, your current Savings Plans inventory, and
            your On-Demand spend over the lookback period.
          </Typography>
        </ListItem>

        <ListItem sx={{ px: 0 }}>
          <Typography
            variant="subtitle2"
            color="text.secondary"
            fontWeight="normal"
          >
            The Savings Plans recommendations algorithm runs a simulation to
            generate the recommendation that yields the maximum amount of
            savings based on your selected parameters. The algorithm generates
            the most up-to-date recommendations by dropping any historical
            coverage provided by expired Savings Plans and extrapolating the
            coverage of recently purchased Savings Plans over the lookback
            period. This means that the data in the cost, coverage, and
            utilization charts is simulated and not historical. For historical
            data, use Cost Explorer, utilization, and coverage reports.
          </Typography>
        </ListItem>
      </List>
    </Drawer>
  );
};
