import { FC, Fragment, useMemo } from "react";
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";
import { RenderEmails } from "./RenderEmails";
import {
  GracePeriod,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../../../services/cloudchipr.api";
import { IntegrationLogo } from "../../../../../../common/integration-dialogs/components/common/IntegrationLogo";
import { convertSecondsToUnits } from "../../../../classic/utils/helpers/gracePeriodCalculations";

interface SchedulePreviewNotificationsProps {
  gracePeriod?: GracePeriod;
}

export const SchedulePreviewGracePeriod: FC<
  SchedulePreviewNotificationsProps
> = ({ gracePeriod }) => {
  const { message } = convertSecondsToUnits(gracePeriod?.period ?? 0);

  const { data } = useGetUsersMeIntegrationsQuery(
    {},
    { skip: !gracePeriod?.notifications?.length },
  );

  const { integrations, emails } = useMemo(() => {
    const initialValue = { integrations: [], emails: {} };

    return (
      gracePeriod?.notifications?.reduce((acc, notification) => {
        if (notification.integration_id) {
          acc.integrations.push(notification);
        } else {
          acc.emails = notification;
        }

        return acc;
      }, initialValue) ?? initialValue
    );
  }, [gracePeriod?.notifications]);

  return (
    <Grid container px={2} mb={4} alignItems="baseline">
      <Grid item sm={4}>
        <Typography variant="subtitle2">Grace Period Before Actions</Typography>
      </Grid>

      <Grid item sm={8}>
        <Typography variant="body2" mb={1}>
          Grace Period
        </Typography>
        {gracePeriod?.period && (
          <Typography variant="body2" mb={1}>
            {message}
          </Typography>
        )}

        {emails && (
          <Stack spacing={1} mb={2}>
            <RenderEmails label="To" emails={emails.to} />
            <RenderEmails label="Cc" emails={emails.cc} />
            <RenderEmails label="Bcc" emails={emails.bcc} />
          </Stack>
        )}

        {!!integrations?.length && (
          <Fragment>
            <Typography variant="body2" mb={1}>
              Integration(s)
            </Typography>

            <Stack direction="row">
              {data?.map(({ id, type, name }) =>
                integrations?.some(
                  (integration: any) => integration?.integration_id === id,
                ) ? (
                  <Chip
                    key={id}
                    icon={
                      <Box pl={0.5} pt={0.5}>
                        <IntegrationLogo type={type} width={14} />
                      </Box>
                    }
                    label={name}
                    variant="outlined"
                    sx={{ mr: 1, mb: 1, ".MuiChip-icon": { ml: 1 } }}
                  />
                ) : null,
              )}
            </Stack>
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};
