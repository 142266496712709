import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { resourceExplorerWidgetDataSelector } from "../resource-explorer-widget/resourceExplorerWidgetDataSelector";
import { ResourceExplorerGridMutatedData } from "../../../utils/types/resourceExplorerDataGrid";
import { resourceExplorerGroupingSelector } from "../data/resourceExplorerGroupingSelector";
import { activeStatusAccountObjectDataSelector } from "../../../../accounts/selectors/slice-data/activeStatusAccountObjectDataSelector";

export const resourceExplorerMutatedDataForDataGridSelector =
  createDraftSafeSelector(
    [
      resourceExplorerWidgetDataSelector,
      resourceExplorerGroupingSelector,
      activeStatusAccountObjectDataSelector,
    ],
    (widgetDataResponse, grouping, accountsIds) => {
      const data = widgetDataResponse?.total;
      if (!data) {
        return null;
      }

      return data?.reduce(
        (result, item, index) => {
          if (item.metadata.aggregated && item.metadata.aggregated_by_cost) {
            result.aggregatedData = item;
            result.dataCount = data?.length ? data?.length - 1 : 0;
          } else {
            const liveAccountId = item.metadata.live_account_id;

            result.gridData.push({
              ...item,
              id: item.field + index,
              resourceExplorerGrouping: grouping,
              accountExistInLiveUsage: !!accountsIds?.[liveAccountId],
            });

            result.totalCostSum = (result.totalCostSum ?? 0) + item.total_cost;

            result.prevPeriodTotalCostSum =
              (result.prevPeriodTotalCostSum ?? 0) +
              item.previous_period_total_cost;

            result.dataCount = data?.length;
          }
          return result;
        },
        {
          gridData: [],
        } as ResourceExplorerGridMutatedData,
      );
    },
  );
