import { FC, Fragment, useCallback } from "react";
import { IconButton, List, Popover, Stack } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ResourceExplorerBreadcrumbsMenuItem } from "./ResourceExplorerBreadcrumbsMenuItem";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { changePathOnBreadcrumbSelectThunk } from "../../../../../../../store/resource-explorer/thunks/widgets/path/changePathOnBreadcrumbSelectThunk";
import { resourceExplorerPathSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathSelector";
import {
  breadcrumbPathEndPartLength,
  breadcrumbPathStartPartLength,
} from "../../utils/constants/breadcrumb";
import {
  setFilteredByLiveUsages,
  setResourceExplorerSearch,
} from "../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";

export const ResourceExplorerBreadcrumbsMenu: FC = () => {
  const dispatch = useAppDispatch();

  const path = useAppSelector(resourceExplorerPathSelector);
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const cutedPath = path.slice(
    breadcrumbPathStartPartLength,
    path.length - breadcrumbPathEndPartLength,
  );

  const onClickHandler = useCallback(
    (index: number) => {
      dispatch(setFilteredByLiveUsages(false));
      dispatch(changePathOnBreadcrumbSelectThunk(index));
      dispatch(setResourceExplorerSearch(""));
    },
    [dispatch],
  );

  return (
    <Fragment>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
        }}
        spacing={0.5}
      >
        <IconButton onClick={openMenu} sx={{ p: 0 }}>
          <MoreHorizIcon sx={{ color: "text.primary" }} />
        </IconButton>

        <ArrowForwardIosIcon sx={{ fontSize: 14 }} color="action" />
      </Stack>
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List sx={{ p: 0, minWidth: 145 }}>
          {cutedPath.map((item, index) => {
            return (
              <ResourceExplorerBreadcrumbsMenuItem
                key={item.groupedBy + item.name}
                name={item.name}
                onClick={onClickHandler}
                index={index + breadcrumbPathStartPartLength}
              />
            );
          })}
        </List>
      </Popover>
    </Fragment>
  );
};
