import {
  CategoryWithCosts,
  ResourceExplorerAccount,
  ResourceExplorerTag,
} from "../../../../services/cloudchipr.api";
import { DimensionCategory } from "../types/common";

export const categoryFiltersAreInvalid = (
  category: DimensionCategory,
  filterType?: CategoryWithCosts["type"],
) => {
  if (!category.name.trim()) {
    return true;
  }

  const accounts = [
    ...(category.filters?.aws_filters?.accounts?.items ?? []),
    ...(category.filters?.gcp_filters?.accounts?.items ?? []),
    ...(category.filters?.azure_filters?.accounts?.items ?? []),
  ];
  const invalidAccounts = checkForAccounts(accounts);

  const tags = [
    ...(category.filters?.aws_filters?.tags?.items ?? []),
    ...(category.filters?.gcp_filters?.tags?.items ?? []),
  ];
  const invalidTags = checkForTags(tags);

  if (filterType === "filter_based") {
    return (
      (accounts.length && invalidAccounts) ||
      (tags.length && invalidTags) ||
      (!tags.length && !accounts.length)
    );
  }

  return invalidAccounts;
};

const checkForAccounts = (accounts: ResourceExplorerAccount[]) => {
  return (
    !accounts?.length ||
    !!accounts?.some((account) => {
      const value = account?.value;

      if (Array.isArray(value)) {
        return !value?.length;
      }

      return !value?.trim();
    })
  );
};

const checkForTags = (tags: ResourceExplorerTag[]) => {
  return (
    !tags?.length ||
    !!tags?.some((tag) => {
      const value = tag?.tag_values;
      const restInvalid = !tag?.tag_key || !tag?.operator;

      if (restInvalid) {
        return restInvalid;
      }

      if (Array.isArray(value)) {
        return !value?.length;
      }

      return !value?.trim();
    })
  );
};
