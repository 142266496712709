import { FC, useCallback } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  ResourceExplorerAccount,
  ResourceExplorerFilterOperator,
  ResourceExplorerTag,
  ResourceExplorerTagFilterOperator,
} from "../../../../../../../../../../../../../services/cloudchipr.api";
import {
  categoryFilterOperators,
  categoryValueTypeByOperator,
} from "../../../../utils/constants/operators";
import { isResourceExplorerTag } from "../../../../utils/helpers/typeCheckers";

interface FilterOperatorFieldProps {
  filterItem: ResourceExplorerAccount | ResourceExplorerTag;
  size?: "small" | "big";
  onChange(item: ResourceExplorerAccount | ResourceExplorerTag): void;
}

export const FilterOperatorField: FC<FilterOperatorFieldProps> = ({
  filterItem,
  onChange,
  size = "big",
}) => {
  const type = isResourceExplorerTag(filterItem) ? "tag" : "account";

  const dispatchHandler = useCallback(
    (operator: ResourceExplorerFilterOperator) => {
      const value = keepOldValueByOperatorCheck(
        filterItem.operator,
        operator,
        filterItem,
      );

      const isTag = isResourceExplorerTag(filterItem);
      const tagFilter: ResourceExplorerTag | null = isTag
        ? {
            tag_key: filterItem.tag_key,
            tag_values: value,
            operator: operator as ResourceExplorerTagFilterOperator,
          }
        : null;

      const accountFilter: ResourceExplorerAccount = {
        operator,
        value: value,
      };

      onChange(tagFilter ?? accountFilter);
    },
    [filterItem, onChange],
  );

  const operatorChangeHandler = useCallback(
    (e: SelectChangeEvent) => {
      const operator = e.target.value as ResourceExplorerFilterOperator;

      dispatchHandler(operator);
    },
    [dispatchHandler],
  );

  return (
    <Grid item xs={size === "small" ? 3 : 4}>
      <FormControl size="xsmall" fullWidth>
        <InputLabel>Operator</InputLabel>
        <Select
          label="Operator"
          value={filterItem.operator}
          onChange={operatorChangeHandler}
        >
          {categoryFilterOperators[type].map(({ label, value }) => {
            return (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Grid>
  );
};

const keepOldValueByOperatorCheck = (
  oldOperator: ResourceExplorerFilterOperator,
  newOperator: ResourceExplorerFilterOperator,
  filterItem: ResourceExplorerAccount | ResourceExplorerTag,
) => {
  const oldDataType = categoryValueTypeByOperator[oldOperator];
  const newDataType = categoryValueTypeByOperator[newOperator];

  const oldValue = isResourceExplorerTag(filterItem)
    ? filterItem.tag_values
    : filterItem.value;

  if (oldDataType === newDataType && oldValue) {
    return oldValue;
  }

  if (newDataType === "array") {
    return [];
  }

  return "";
};
