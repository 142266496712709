import {
  ResourceExplorer,
  ResourceExplorerFolder,
} from "../../../../../services/cloudchipr.api";
import { isResourceExplorerFolder } from "../../../../../components/navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";
import {
  TreeItem,
  TreeItems,
} from "../../../../../components/navigation/components/sortable-tree/utils/types";

export const flatteResourceExplorerHierarchyData = (
  hierarchy: (ResourceExplorer | ResourceExplorerFolder)[],
): TreeItems | null => {
  if (!hierarchy) {
    return null;
  }
  return hierarchy.reduce((result, item) => {
    if (isResourceExplorerFolder(item)) {
      const views = (item.views ?? [])?.reduce((result, item) => {
        result.push({
          id: item.id,
          name: item.name,
          type: "item",
          children: [],
        });

        return result;
      }, [] as TreeItem[]);

      result.push({
        id: item.id,
        children: views,
        name: item.name,
        type: "folder",
        collapsed: true,
      });
    } else {
      result.push({
        id: item.id,
        name: item.name,
        children: [],
        type: "item",
        disabled: item.is_default,
      });
    }
    return result;
  }, [] as TreeItems);
};
