import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { editingCategorySelector } from "./editingCategorySelector";
import { accountsForCategoriesByIdsSelector } from "../../accounts/accountsForCategoriesByIdsSelector";
import { getAccountIdsFromCategoryFilters } from "../../../../../components/pages/dimensions/utils/helpers/getAccountIdsFromCategoryFilters";
import { calculateAccountsCountByProvider } from "../../../../../components/pages/dimensions/utils/helpers/calculateAccountsCountByProvider";

export const editingCategorySelectorAccountsCountSelector =
  createDraftSafeSelector(
    [editingCategorySelector, accountsForCategoriesByIdsSelector],
    (category, allAccounts) => {
      if (!category) {
        return emptyState;
      }

      const accountsIds = getAccountIdsFromCategoryFilters(category?.filters);

      return calculateAccountsCountByProvider(accountsIds, allAccounts);
    },
  );

const emptyState = {
  aws: 0,
  gcp: 0,
  azure: 0,
};
