import { anyChangesExistOnCategoryEditSelector } from "./anyChangesExistOnCategoryEditSelector";
import { editingCategorySelector } from "../../slice-data/editing-category/editingCategorySelector";
import { categoryFiltersAreInvalid } from "../../../utils/helpers/categoryFiltersAreInvalid";
import { RootState } from "../../../../store";

export const categoriesCreateDisabledSelector = (state: RootState) => {
  const editingCategory = editingCategorySelector(state);

  if (!editingCategory) {
    return;
  }

  const invalid = categoryFiltersAreInvalid(
    editingCategory,
    editingCategory?.type,
  );
  const somethingChanged = anyChangesExistOnCategoryEditSelector(state);

  return !editingCategory?.name || invalid || !somethingChanged;
};
