import { costAndUsageSumWidgetByIdSelector } from "./costAndUsageSumWidgetByIdSelector";
import { costAndUsageWidgetNotFoundSelector } from "./costAndUsageWidgetNotFoundSelector";
import { RootState } from "../../../../store";
import { WidgetTitleProps } from "../../../../../components/pages/dashboard/components/widgets/common/widget-header/source-and-name/WidgetSourceInfo";
import { resourceExplorerViewNameByViewIdSelector } from "../../../../resource-explorer/selectors/all-resource-explorers/resourceExplorerViewNameByViewIdSelector";
import { objectToUrl } from "../../../../../components/utils/helpers/dataToUrlToDataConvertors";

export const costAndUsageSumWidgetTitlePropsSelector = (
  state: RootState,
  widgetId: string,
): WidgetTitleProps | null => {
  const widget = costAndUsageSumWidgetByIdSelector(state, widgetId);

  if (!widget) {
    return null;
  }

  const viewName = resourceExplorerViewNameByViewIdSelector(
    state,
    widget.view_id,
  );

  const viewNotFound = costAndUsageWidgetNotFoundSelector(state, widgetId);
  const path = objectToUrl([
    {
      dates: {
        from: widget.date_from,
        to: widget.date_to,
        label: widget.date_label,
      },
    },
  ]);

  return {
    widgetName: widget.name,
    dates: {
      label: widget?.date_label,
      from: widget?.date_from,
      to: widget?.date_to,
    },
    sourceData: viewNotFound
      ? undefined
      : {
          icon: "pie",
          title: viewName ?? "",
          menu: [
            {
              title: "Open in Resource Explorer",
              url: `/resource-explorer/${widget.view_id}?path=${path}`,
            },
          ],
        },
  };
};
