import { appliedFilterTemplateIdSelector } from "./appliedFilterTemplateIdSelector";
import { currentAccountFilterTemplatesSelector } from "../currentAccountFilterTemplatesSelector";
import { RootState } from "../../../../store";
import { FilterSet2 } from "../../../../../services/cloudchipr.api";

export const appliedFilterTemplateSelector = (
  state: RootState,
): FilterSet2 | undefined => {
  const filterSetsList = currentAccountFilterTemplatesSelector(state);
  const appliedFilterSetId = appliedFilterTemplateIdSelector(state);

  return filterSetsList?.find(
    ({ filter_set }) => filter_set?.id === appliedFilterSetId,
  )?.filter_set;
};
