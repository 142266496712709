import { ResourceMetricsHeaderCell } from "../../../../../cells/metrics-cells/components/ResourceMetricsHeaderCell";
import { ColumnSetupType } from "../../../../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { MigVm } from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const migVmColumns: ColumnSetupType<MigVm>[] = [
  {
    accessorKey: "image_id",
    header: "Instance Name",
    type: "identifierWithRecommendationLink",
  },
  {
    accessorKey: "price_per_month",
    header: "Monthly Price",
    type: "money",
    headerTooltip:
      "Monthly prices are calculated based on the on-demand list price of each resource.",
  },
  {
    accessorKey: "unused_since",
    header: "State",
    type: "state",
  },
  {
    accessorKey: "all_time_spent",
    header: "Total Spend",
    type: "money",
  },
  {
    accessorKey: "created_at",
    header: "Launch time",
    type: "date",
  },
  { accessorKey: "type", header: "Machine Type" },
  {
    accessorKey: "cpu_value",
    header: "CPU Max",
    headerTooltip: "The maximum usage of CPU for the last 7 days.",
    type: "cpuStatistic",
  },
  {
    size: 220,
    minSize: 165,
    maxSize: 300,
    enableSorting: false,
    meta: { cellStyles: { p: 0 }, headerTitle: "CPU Max" },
    accessorKey: "inline_metrics",
    type: "computeMetricsChart",
    header: ResourceMetricsHeaderCell,
  },
  {
    accessorKey: "availability_zone",
    header: "Av. Zone",
    type: "withTooltipCell",
  },
  { accessorKey: "tags", header: "Labels", type: "tag", enableSorting: false },
  {
    accessorKey: "tags",
    header: "Smart Labels",
    id: "smart_tag",
    type: "smartTag",
    enableSorting: false,
  },
];
