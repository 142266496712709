import { FC, useCallback } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { utilizationAndCoverageDataViewTabSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/view-options/utilizationAndCoverageDataViewTabSelector";
import { setDataViewTab } from "../../../../../../../../store/commitments/commitmentsSlice";
import { DataViewTab } from "../../../../../../../../store/commitments/utils/types/types";
import { savingsPlansGranularDataTotalUtilizationSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalUtilizationSelector";
import { savingsPlansGranularDataTotalCoverageSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalCoverageSelector";

export const UtilizationAndCoverageDataViewTabs: FC = () => {
  const dispatch = useAppDispatch();

  const tab = useAppSelector(utilizationAndCoverageDataViewTabSelector);

  const totalUtilization = useAppSelector(
    savingsPlansGranularDataTotalUtilizationSelector,
  );

  const totalCoverage = useAppSelector(
    savingsPlansGranularDataTotalCoverageSelector,
  );

  const tabChangeHandler = useCallback(
    (_: unknown, tab: string | null) => {
      if (!tab) {
        return;
      }

      dispatch(setDataViewTab(tab as DataViewTab));
    },
    [dispatch],
  );

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={tab}
      color="primary"
      onChange={tabChangeHandler}
    >
      <ToggleButton value="savingsPlans" sx={{ textTransform: "none" }}>
        Commitments{" "}
        {!!totalUtilization?.percent &&
          `(Utilization ${totalUtilization?.percent}%)`}
      </ToggleButton>
      <ToggleButton value="coverage" sx={{ textTransform: "none" }}>
        Coverage {!!totalCoverage?.percent && `(${totalCoverage?.percent}%)`}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
