import { FC, useCallback } from "react";
import { Button } from "@mui/material";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";
import { deleteCategoryFilterGroup } from "../../../../../../../../../../../store/dimensions/dimensionsSlice";
import { CategoryFilterType } from "../../../../../../../../../../../store/dimensions/utils/types/common";
import { ProviderType } from "../../../../../../../../../../../services/cloudchipr.api";

interface ClearGroupButtonProps {
  type: CategoryFilterType;
  categoryId: string;
  provider: ProviderType;
}

export const ClearGroupButton: FC<ClearGroupButtonProps> = ({
  provider,
  categoryId,
  type,
}) => {
  const dispatch = useAppDispatch();

  const groupClearHandler = useCallback(() => {
    dispatch(
      deleteCategoryFilterGroup({
        type,
        provider,
        id: categoryId,
      }),
    );
  }, [dispatch, categoryId, provider, type]);

  return (
    <Button sx={sx} size="small" onClick={groupClearHandler} color="tertiary">
      Clear Group
    </Button>
  );
};

const sx = { whiteSpace: "nowrap", textTransform: "none" };
