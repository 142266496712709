import {
  ProviderType,
  ResourceExplorerGrouping,
} from "../../../../../../../../../../../../../../services/cloudchipr.api";

export const getResourceExplorerTagsAvailabilityInTable = (
  provider: ProviderType,
  grouping?: ResourceExplorerGrouping,
): boolean => {
  return grouping === "resource" && provider !== "azure";
};
