import { FC, useMemo } from "react";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";

import { ResourceExplorerV2ViewActionButton } from "../view-action-menu/ResourceExplorerV2ViewActionButton";
import { ResourceExplorerV2ViewFolderActionButton } from "../folder-actions-menu/ResourceExplorerV2ViewFolderActionButton";
import { NavItem } from "../../../nav-item/NavItem";
import { ResourceExplorerFolder } from "../../../../../../services/cloudchipr.api";

interface ResourceExplorerV2NavigationFolderNavItemProps {
  folder: ResourceExplorerFolder;
}

export const ResourceExplorerV2NavigationFolderNavItem: FC<
  ResourceExplorerV2NavigationFolderNavItemProps
> = ({ folder }) => {
  const location = useLocation();

  const folderSelected = useMemo(() => {
    return folder?.views?.some((item) =>
      location.pathname.startsWith(`/resource-explorer/${item.id}`),
    );
  }, [folder, location.pathname]);

  return (
    <NavItem
      nested
      primary={folder.name}
      secondaryAction={
        <ResourceExplorerV2ViewFolderActionButton
          id={folder.id}
          name={folder.name}
        />
      }
      secondaryActionHover
      expandable
      icon={FolderOpenIcon}
      selected={folderSelected}
    >
      {folder?.views?.length
        ? folder?.views?.map((view) => {
            return (
              <NavItem
                nested
                key={view.id}
                primary={view.name}
                to={`/resource-explorer/${view.id}`}
                icon={InsertChartOutlinedOutlinedIcon}
                secondaryAction={
                  <ResourceExplorerV2ViewActionButton
                    id={view.id}
                    name={view.name}
                    folderId={folder.id}
                  />
                }
                secondaryActionHover
                listItemButtonSx={{
                  pl: 5,
                }}
              />
            );
          })
        : [
            <Typography
              variant="caption"
              color="text.secondary"
              pl={6}
              key="empty"
            >
              No views inside
            </Typography>,
          ]}
    </NavItem>
  );
};
