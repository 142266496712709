import { FC, Fragment } from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ResourceExplorerV2ViewFolderListItemActionsMenu } from "./ResourceExplorerV2ViewFolderListItemActionsMenu";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";

interface ResourceExplorerViewWithFolderActionButtonProps {
  id: string;
  name: string;
}

export const ResourceExplorerV2ViewFolderActionButton: FC<
  ResourceExplorerViewWithFolderActionButtonProps
> = ({ id, name }) => {
  const { cannot } = useAppAbility();
  const cannotDeleteResourceExplorer = cannot("delete", "resource-explorer");
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (cannotDeleteResourceExplorer) {
    return null;
  }

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <ResourceExplorerV2ViewFolderListItemActionsMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
      />
    </Fragment>
  );
};
