import { Row } from "@tanstack/react-table";
import { getCronFrequency } from "../../../../common/utils/helpers/cron/getCronFrequency";
import { OffHoursSchedule2 } from "../../../../../../../services/cloudchipr.api";

export const offHoursFrequencyFilter = (
  row: Row<OffHoursSchedule2>,
  _: string,
  keyword: string,
) => {
  const cron = row.original.start_cron;
  const frequency = getCronFrequency(cron).toLowerCase();

  return frequency.includes(keyword.toLowerCase().trim());
};
