import { FC, Fragment } from "react";
import {
  Collapse,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useToggle } from "rooks";
import { useFormik } from "formik";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import { usePostUsersMeOrganisationsCurrentUsersMutation } from "../../../services/cloudchipr.api";

export interface UserInviteForm {
  email: string;
  role: "admin";
}

export const AccountSetupUserInvitation: FC = () => {
  const [triggerInvite, { isLoading }] =
    usePostUsersMeOrganisationsCurrentUsersMutation();
  const [state, toggle] = useToggle();

  const formik = useFormik<UserInviteForm>({
    initialValues: { email: "", role: "admin" },
    validationSchema: yup.object({
      email: yup.string().trim().email().required(),
      role: yup.string().required(),
    }),
    onSubmit: async (values) => {
      try {
        await triggerInvite({
          body: { email: values.email.trim(), role: values.role },
        }).unwrap();
        enqueueSnackbar("Invitation has been sent successfully!", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
          },
        });
      } catch (error: any) {
        enqueueSnackbar(error.data.message, {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
          },
        });
      }
      formik.resetForm();
    },
  });
  return (
    <Fragment>
      <Stack direction="row" alignItems="center">
        <Typography variant="body1" fontWeight="medium" mt={1}>
          Need assistance with your account setup? Invite someone from your
          organization to take over or reach out to us at{" "}
          <Link href="mailto:support@cloudchipr.com">
            support@cloudchipr.com
          </Link>
        </Typography>
        <IconButton size="small" onClick={toggle}>
          {state ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </Stack>

      <Collapse in={state}>
        <Typography variant="body1" mt={3}>
          Once you send the invite, they can accept and take over the setup
          process.
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Stack direction="row" alignItems="center" mt={2} gap={2}>
            <TextField
              label="User email"
              variant="outlined"
              name="email"
              sx={{ flexGrow: 1 }}
              value={formik.values.email}
              onChange={formik.handleChange}
              size="small"
            />
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
              loading={isLoading}
            >
              send invite
            </LoadingButton>
          </Stack>
        </form>
      </Collapse>
    </Fragment>
  );
};
