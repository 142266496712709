import { ResourceWithVisibility } from "../../../../../../services/cloudchipr.api";

export type CSVDataType = Record<string, string | number>[] | null;

export const convertCsvDataForBackend = (
  fullCsvData: CSVDataType,
  tableCsvData: CSVDataType,
): ResourceWithVisibility[][] | undefined => {
  const tableFirstItem = tableCsvData?.at(0);

  return fullCsvData?.map((data) => {
    return Object.keys(data).map((key) => {
      return {
        key: key,
        value: data[key] as unknown as object,
        is_visible: !!tableFirstItem?.[key],
      };
    });
  });
};
