import { CostBreakdownWidgetSetupType } from "../types/setups/costBreakdownSetupType";
export const costBreakdownWidgetDefaultData: CostBreakdownWidgetSetupType = {
  widgetType: "cost_breakdown",
  viewType: "pie",
  frequency: "monthly",
  name: "Cost breakdown",
  date: { from: null, to: null, label: "past_fourteen_days" },
  dates: { label: "past_fourteen_days" },
  grouping: "service",
  group_values: null,
  viewId: undefined,
  forecastOption: "month",
};
