import { FC, Fragment, useCallback, useState } from "react";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { ScheduleListGridDuplicateAction } from "./ScheduleListGridDuplicateAction";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";
import { DeleteConfirmationDialog } from "../../../../../common/modals/DeleteConfirmationDialog";
import { useAppSelector } from "../../../../../../store/hooks";
import { schedulesLoadingSelector } from "../../../../../../store/schedules/selectors/schedule/schedulesLoadingSelector";

interface ScheduleListGridActionCellProps {
  name: string;
  id: string;
  isLoading: boolean;
  onEdit(): void;
  onDelete(): void;
  type: "off-hours" | "workflow";
}
export const ScheduleListGridActionCellFunctionality: FC<
  ScheduleListGridActionCellProps
> = ({ type, onEdit, onDelete, name, id, isLoading }) => {
  const { cannot } = useAppAbility();
  const canNotEditSchedule = cannot("edit", "schedule");
  const canNotDeleteSchedule = cannot("delete", "schedule");

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const schedulesLoading = useAppSelector(schedulesLoadingSelector);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const openConfirmDialogHandler = useCallback(() => {
    setConfirmDialogOpen(true);
  }, []);

  const closeConfirmDialogHandler = useCallback(() => {
    setConfirmDialogOpen(false);
  }, []);

  const deleteScheduleHandler = useCallback(async () => {
    await onDelete();
    closeMenu();
    setConfirmDialogOpen(false);
  }, [onDelete, closeMenu]);

  return (
    <Fragment>
      <IconButton onClick={openMenu}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        <MenuItem dense onClick={onEdit} disabled={canNotEditSchedule}>
          <ListItemIcon>
            <EditOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <ScheduleListGridDuplicateAction
          name={name}
          type={type}
          id={id}
          onCloseMenu={closeMenu}
        />
        <MenuItem
          dense
          onClick={openConfirmDialogHandler}
          disabled={canNotDeleteSchedule}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText color="error">Delete</ListItemText>
        </MenuItem>
      </Menu>

      <DeleteConfirmationDialog
        open={confirmDialogOpen}
        onClose={closeConfirmDialogHandler}
        onDelete={deleteScheduleHandler}
        loading={isLoading || schedulesLoading}
        title={`Delete ${type === "off-hours" ? "Off Hours" : "Workflow"}?`}
        content={
          <Typography>
            Please confirm if you want to delete the
            <Typography
              component="span"
              fontWeight="bold"
              whiteSpace="break-spaces"
              sx={{ wordBreak: "break-word" }}
            >
              {" "}
              {name}{" "}
            </Typography>
            {type === "off-hours" ? "off hours" : "workflow"}.
          </Typography>
        }
      />
    </Fragment>
  );
};
