import { FC, useCallback } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Menu, MenuItem, Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useMenuHook } from "../../../../../../../utils/hooks/useMenu.hook";
import { ResourceExplorerGrouping } from "../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { resourceExplorerProvidersSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/resourceExplorerProvidersSelector";
import { resourceExplorerGroupingSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/data/resourceExplorerGroupingSelector";
import { getResourcesExplorerGroupingLabel } from "../../utils/helpers/getResourcesExplorerGroupingLabel";
import { resourceExplorerPathLastItemGroupingOptionsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathLastItemGroupingOptionsSelector";
import { pathLastItemGroupingChangeThunk } from "../../../../../../../store/resource-explorer/thunks/widgets/path/pathLastItemGroupingChangeThunk";
import { setFilteredByLiveUsages } from "../../../../../../../store/resource-explorer/resourceExplorerSlice";

export const ResourceExplorerBreadcrumbsSelect: FC = () => {
  const dispatch = useAppDispatch();
  const providers = useAppSelector(resourceExplorerProvidersSelector);
  const groupBy = useAppSelector(resourceExplorerGroupingSelector);
  const groupingOptions = useAppSelector(
    resourceExplorerPathLastItemGroupingOptionsSelector,
  );

  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const changeHandler = useCallback(
    (grouping: ResourceExplorerGrouping) => {
      dispatch(setFilteredByLiveUsages(false));
      dispatch(pathLastItemGroupingChangeThunk(grouping));
      closeMenu();
    },
    [dispatch, closeMenu],
  );

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <CircleIcon sx={{ fontSize: 8 }} color="action" />

      <Stack
        direction="row"
        alignItems="center"
        onClick={openMenu}
        sx={{
          "&:hover": { textDecoration: "underline", cursor: "pointer" },
        }}
      >
        <Typography variant="body1" noWrap>
          {groupBy
            ? `Cost by ${getResourcesExplorerGroupingLabel(providers, groupBy)}`
            : "Ungrouped"}
        </Typography>
        <ArrowDropDownIcon />
      </Stack>

      <Menu anchorEl={anchor} open={open} onClose={closeMenu}>
        {groupingOptions?.map((grouping) => {
          return (
            <MenuItem
              key={grouping}
              onClick={changeHandler.bind(null, grouping)}
            >
              {getResourcesExplorerGroupingLabel(providers, grouping)}
            </MenuItem>
          );
        })}
      </Menu>
    </Stack>
  );
};
