import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  CategoryFilter,
  cloudChiprApi,
} from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { categoryFilterTypeSelector } from "../../selectors/slice-data/categoryFilterTypeSelector";

type Args = {
  dimensionId: string;
  categoryId: string;
  categoryName: string;
  filters: CategoryFilter;
};

export const getCategoryCreationFixedCacheKey = (id: string) =>
  `${id}-postUsersMeOrganisationsCurrentDimensionsByDimensionIdCategories-fixed-cache-key`;

export const createCategoryByDimensionIdThunk = createAsyncThunk(
  "dimensions/createCategoryByDimensionId",
  async (args: Args, { dispatch, getState }) => {
    const { dimensionId, categoryName, filters, categoryId } = args;

    const state = getState() as RootState;
    const filterType = categoryFilterTypeSelector(state);

    const { postUsersMeOrganisationsCurrentDimensionsByDimensionIdCategories } =
      cloudChiprApi.endpoints;

    if (!filterType) {
      return;
    }

    return await dispatch(
      postUsersMeOrganisationsCurrentDimensionsByDimensionIdCategories.initiate(
        {
          dimensionId,
          body: { name: categoryName, type: filterType, filter: filters },
        },
        { fixedCacheKey: getCategoryCreationFixedCacheKey(categoryId) },
      ),
    ).unwrap();
  },
);
