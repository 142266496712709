import { FC, useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useCounter, useEffectOnceWhen } from "rooks";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import {
  AccessToAccountTypeEnum,
  goToNextStep,
  setAccountAttempt,
  setPolling,
  setPollingInterrupted,
} from "../../../../../store/account-setup/accountSetupSlice";
import {
  ProviderType,
  useGetUsersMeAccountAttemptsByAccountAttemptIdQuery,
} from "../../../../../services/cloudchipr.api";
import { pollingSelector } from "../../../../../store/account-setup/selectors/pollingSelector";
import { accountAttemptSelector } from "../../../../../store/account-setup/selectors/accountAttemptSelector";
import { providerSelector } from "../../../../../store/account-setup/selectors/providerSelector";
import { gcpConnectionTypeSelector } from "../../../../../store/account-setup/selectors/gcpConnectionTypeSelector";
import { getProviderName } from "../../../../../utils/helpers/providers/getProviderName";
import { accessToAccountTypeSelector } from "../../../../../store/account-setup/selectors/accessToAccountTypeSelector";

export const POLLING_LIMIT = 180;
export const POLLING_INTERVAL = 5000;

export const PollingProgress: FC = () => {
  const dispatch = useAppDispatch();
  const polling = useAppSelector(pollingSelector);
  const provider = useAppSelector(providerSelector);
  const accountAttempt = useAppSelector(accountAttemptSelector);
  const connectionType = useAppSelector(gcpConnectionTypeSelector);
  const accessType = useAppSelector(accessToAccountTypeSelector);

  const { increment, value } = useCounter(0);

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const handleOpenConfirmationDialog = useCallback(() => {
    setConfirmationDialogOpen(true);
  }, []);
  const handleCloseConfirmationDialog = useCallback(() => {
    setConfirmationDialogOpen(false);
  }, []);

  const handleCancelPolling = useCallback(async () => {
    setConfirmationDialogOpen(false);
    dispatch(setPolling(false));
  }, [dispatch]);

  const { data, requestId } =
    useGetUsersMeAccountAttemptsByAccountAttemptIdQuery(
      { accountAttemptId: accountAttempt?.id || "" },
      {
        skip: !accountAttempt,
        pollingInterval: polling ? POLLING_INTERVAL : 0,
      },
    );

  const terraformConnection = accessType === AccessToAccountTypeEnum.TERRAFORM;

  useEffect(increment, [requestId, increment]);

  useEffectOnceWhen(
    () => {
      dispatch(setPolling(false));
      dispatch(setPollingInterrupted(true));
    },
    data?.status === "started" && value === POLLING_LIMIT,
  );

  useEffectOnceWhen(() => {
    dispatch(goToNextStep());
  }, accountAttempt?.status === "closed");

  useEffectOnceWhen(() => {
    dispatch(setPolling(false));
  }, accountAttempt?.status === "failed");

  useEffect(() => {
    if (data) {
      dispatch(setAccountAttempt(data));
    }
  }, [data, dispatch]);

  if (!polling) {
    return null;
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      bgcolor="grey.200"
      spacing={2}
      sx={{ border: 1, borderRadius: 1, borderColor: "grey.200", px: 3, py: 2 }}
    >
      <div>
        <CircularProgress
          size={24}
          color="inherit"
          sx={{ color: "grey.600" }}
        />
      </div>
      <Typography variant="caption">
        {terraformConnection
          ? getProviderLoadingMessage(AccessToAccountTypeEnum.TERRAFORM)
          : getProviderLoadingMessage(provider)}
      </Typography>
      <Button
        variant="text"
        size="small"
        onClick={handleOpenConfirmationDialog}
      >
        Cancel
      </Button>

      <Dialog open={confirmationDialogOpen} fullWidth>
        <DialogTitle>
          Terminate {provider === "aws" && "account"} connection process?
        </DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <DialogContentText gutterBottom>
            Confirm if you want to terminate{" "}
            {connectionType === "organization"
              ? "organization"
              : getProviderName(provider)}{" "}
            connection process.
          </DialogContentText>

          {provider === "aws" && !terraformConnection && (
            <Alert icon={<WarningAmberIcon />} severity="warning">
              Please manually clean the Cloudchipr stack from{" "}
              <Link
                href="https://console.aws.amazon.com/cloudformation/home"
                target="_blank"
                color="inherit"
              >
                AWS CloudFormation
              </Link>{" "}
              if it exists in any state.
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="tertiary">
            Cancel
          </Button>
          <Button
            onClick={handleCancelPolling}
            color="error"
            variant="contained"
          >
            Terminate
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

function getProviderLoadingMessage(provider: ProviderType | "terraform") {
  const loadingMessages = new Map<typeof provider, string>([
    [
      "aws",
      "Cloudchipr is waiting for the provided CloudFormation stack to be created.",
    ],
    [
      "terraform",
      "Cloudchipr is waiting for the Terraform setup to be completed. If you close this window, you can still apply your Terraform configuration within the next 24 hours, and it will connect your accounts.",
    ],
    [
      "gcp",
      "Cloudchipr is waiting for you to assign requested roles to the given service account.",
    ],
    [
      "azure",
      "Cloudchipr waits for requested roles to be assigned to the given service principal.",
    ],
  ]);

  return loadingMessages.get(provider);
}
