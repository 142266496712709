import { FC, useCallback } from "react";
import { Button, TableCell, TableRow } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useAppAbility } from "../../../../../../services/permissions";
import { setDimensionsState } from "../../../../../../store/dimensions/dimensionsSlice";
import { useAppDispatch } from "../../../../../../store/hooks";
import { CategoryWithCosts } from "../../../../../../services/cloudchipr.api";
import { generateEmptyCategory } from "../../../utils/helpers/generateEmptyCategory";
import { generateDimensionCategoriesFromCategoriesWithCosts } from "../../../utils/helpers/generateDimensionCategoriesFromCategoriesWithCosts";

interface AddCategoryButtonRowProps {
  dimensionId: string;
  dimensionName: string;
  filterType?: CategoryWithCosts["type"];
  categories: CategoryWithCosts[];
}

export const AddCategoryButtonRow: FC<AddCategoryButtonRowProps> = ({
  dimensionId,
  dimensionName,
  filterType,
  categories,
}) => {
  const dispatch = useAppDispatch();
  const { cannot } = useAppAbility();
  const canNotEditCategory = cannot("edit", "category");

  const addCategoryHandler = useCallback(() => {
    const newCategories = generateEmptyCategory(filterType);
    const newGeneratedEditingId = Object.keys(newCategories).at(0);

    const newCategory = newCategories[
      newGeneratedEditingId ?? ""
    ] as CategoryWithCosts;

    dispatch(
      setDimensionsState({
        dimensionId,
        dimensionName,
        filterType,
        categories: generateDimensionCategoriesFromCategoriesWithCosts([
          ...categories,
          newCategory,
        ]),
        drawerMode: "categoryCreate",
        editingCategoryId: newGeneratedEditingId,
      }),
    );
  }, [dispatch, dimensionName, categories, dimensionId, filterType]);

  return (
    <TableRow sx={styles}>
      <TableCell width={40} />

      <TableCell colSpan={5} sx={{ px: 0 }}>
        <Button
          sx={sx}
          size="small"
          startIcon={<AddIcon fontSize="small" />}
          disabled={canNotEditCategory}
          onClick={addCategoryHandler}
        >
          Add Category
        </Button>
      </TableCell>
    </TableRow>
  );
};

const sx = { whiteSpace: "nowrap", textTransform: "none" };

const styles = {
  bgcolor: "grey.50",
  "& td": { borderColor: "grey.200" },
};
