import { workflowsDataSelector } from "./workflowsDataSelector";
import { RootState } from "../../../store";
import { Schedule2 } from "../../../../services/cloudchipr.api";

export const workflowByIdSelector = (
  state: RootState,
  id: string,
): Schedule2 | undefined => {
  const workflows = workflowsDataSelector(state);

  return workflows?.find((workflow) => workflow.id === id);
};
