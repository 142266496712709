import { FC, useCallback, useMemo, useState } from "react";
import { Button, Stack, TableCell, TableRow, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { FilterSet2 } from "../../../../../../services/cloudchipr.api";
import { AccountSelect } from "../../../../../common/account-select/AccountSelect";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { allAccountsSelectOptionsSelector } from "../../../../../../store/accounts/selectors/all-providers/allAccountsSelectOptionsSelector";
import { currentAccountProviderTypeSelector } from "../../../../../../store/account/selectors/current-account/currentAccountProviderTypeSelector";
import { patchFilterTemplateThunk } from "../../../../../../store/filters/thunks/filter-set/patchFilterTemplateThunk";
import { createFilterTemplateThunk } from "../../../../../../store/filters/thunks/filter-set/createFilterTemplateThunk";
import { currentAccountIdSelector } from "../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { applyFilterTemplateToCurrentAccount } from "../../../../../../store/filters/thunks/filter-set/applyFilterSetToCurrentAccount";
import { defaultFilterTemplateIdSelector } from "../../../../../../store/filters/selectors/filter-set/default/defaultFilterTemplateIdSelector";

interface FilterTemplatesModifyRowProps {
  type: "duplicate" | "modify";
  filterSet: FilterSet2;
  onCancel(): void;
}

export const FilterTemplatesModifyRow: FC<FilterTemplatesModifyRowProps> = ({
  filterSet,
  onCancel,
  type,
}) => {
  const dispatch = useAppDispatch();
  const [accountIds, setAccountIds] = useState(
    filterSet.accounts?.map(({ id }) => id) ?? [],
  );
  const [name, setName] = useState(filterSet.name);
  const [loading, setLoading] = useState(false);

  const groupedAllAccounts = useAppSelector(allAccountsSelectOptionsSelector);
  const provider = useAppSelector(currentAccountProviderTypeSelector);
  const accountId = useAppSelector(currentAccountIdSelector);
  const defaultFilterTemplateId = useAppSelector((state) =>
    defaultFilterTemplateIdSelector(state, accountId ?? ""),
  );

  const options = useMemo(() => {
    return groupedAllAccounts
      .filter(({ accountType }) => accountType === provider)
      .map((data) => ({
        ...data,
        disabled: data.disabled || data.status !== "connected",
      }));
  }, [groupedAllAccounts, provider]);

  const nameChangeHandler = useCallback((event: any) => {
    setName(event.target.value);
  }, []);

  const accountsChangeHandler = useCallback((ids: any) => {
    setAccountIds(ids);
  }, []);

  const modifyHandler = useCallback(async () => {
    if (!provider) {
      return;
    }
    setLoading(true);

    await dispatch(
      patchFilterTemplateThunk({
        name,
        provider,
        accountIds,
        filterTemplateId: filterSet.id,
      }),
    );

    if (!accountIds.length && defaultFilterTemplateId) {
      await dispatch(
        applyFilterTemplateToCurrentAccount(defaultFilterTemplateId),
      );
    }

    setLoading(false);
    onCancel();
  }, [
    dispatch,
    onCancel,
    provider,
    defaultFilterTemplateId,
    filterSet.id,
    name,
    accountIds,
  ]);

  const duplicateHandler = useCallback(async () => {
    if (!provider) {
      return;
    }

    setLoading(true);

    await dispatch(
      createFilterTemplateThunk({
        name,
        provider,
        accountIds,
        filters: filterSet.filters,
      }),
    );

    setLoading(false);
    onCancel();
  }, [dispatch, onCancel, provider, accountIds, filterSet.filters, name]);

  return (
    <TableRow>
      <TableCell sx={{ py: 2 }}>
        <TextField
          fullWidth
          type="text"
          size="small"
          placeholder="Name"
          value={name}
          onChange={nameChangeHandler}
        />
      </TableCell>

      <TableCell>
        <AccountSelect
          onChange={accountsChangeHandler}
          selectedValue={accountIds}
          options={options}
        />
      </TableCell>

      <TableCell>
        <Stack direction="row" spacing={2}>
          <Button onClick={onCancel} color="tertiary" size="small">
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            disabled={loading || !name}
            size="small"
            variant="contained"
            onClick={type === "duplicate" ? duplicateHandler : modifyHandler}
          >
            Save
          </LoadingButton>
        </Stack>
      </TableCell>

      <TableCell />
    </TableRow>
  );
};
