import { v4 as uuid } from "uuid";
import { CategoryWithCosts } from "../../../../../services/cloudchipr.api";

export const generateEmptyCategory = (
  filterType?: CategoryWithCosts["type"],
) => {
  const id = uuid();

  return {
    [id]: {
      id,
      name: "",
      type: filterType,
      filters: {
        aws_filters: null,
        gcp_filters: null,
        azure_filters: null,
      },
    },
  };
};
