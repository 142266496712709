import { FC, Fragment, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { SchedulePreviewTable } from "./SchedulePreviewTable";
import { SchedulePreviewSectionTitle } from "./SchedulePreviewSectionTitle";
import { SchedulePreviewFoundResources } from "./SchedulePreviewFoundResources";
import {
  ResourceType,
  ResourceFiltersWithAction,
  ScheduleActionType2,
} from "../../../../../../services/cloudchipr.api";
import { FiltersDisabledView } from "../../../../common/filters/filters-disabled-view/FiltersDisabledView";
import { useAppSelector } from "../../../../../../store/hooks";
import { scheduleResourcesCountSelector } from "../../../../../../store/schedules/selectors/schedule-resources-preview/scheduleResourcesCountSelector";
import { getResourceTypeName } from "../../../../../../utils/helpers/resources/getResourceTypeName";

interface SchedulePreviewResourcesProps {
  filters: ResourceFiltersWithAction[];
  accountIds: string[];
  scheduleAction: string | null;
  regions: string[];
  name: string;
  scheduleId: string;
  status: ScheduleActionType2;
  gracePeriodValue?: number;
  nextRuns?: { title: string; date: string; utc?: string }[];
  frequency?: { title: string; label: string; tooltip: string }[];
}

export const SchedulePreviewResources: FC<SchedulePreviewResourcesProps> = ({
  filters,
  accountIds,
  name,
  status,
  regions,
  gracePeriodValue,
  nextRuns,
  frequency,
  scheduleAction,
  scheduleId,
}) => {
  const resourcesCountSelector = useCallback(
    (state: any) => {
      return filters.reduce(
        (acc, { filter }) => {
          acc[filter.type] = scheduleResourcesCountSelector(
            state,
            filter.type,
            scheduleId,
          );

          return acc;
        },
        {} as Record<ResourceType, number>,
      );
    },
    [filters, scheduleId],
  );

  const resourcesCount = useAppSelector(resourcesCountSelector);

  const allResourcesCount = useMemo(() => {
    return Object.values(resourcesCount).reduce((sum, num) => sum + num, 0);
  }, [resourcesCount]);

  const data = useMemo(() => {
    return filters.map(({ filter }, index) => {
      const label = <FiltersDisabledView filter={filter} />;

      return {
        id: `${index}`,
        title: (
          <Stack>
            <Typography variant="body2" fontWeight="medium">
              {getResourceTypeName(filter.type, {
                type: "long",
              })}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {resourcesCount[filter.type]} resources found
            </Typography>
          </Stack>
        ),
        label,
      };
    });
  }, [filters, resourcesCount]);

  return (
    <Fragment>
      <SchedulePreviewSectionTitle title="Selected Services" />

      <SchedulePreviewFoundResources
        scheduleAction={scheduleAction}
        scheduleId={scheduleId}
        nextRuns={nextRuns}
        frequency={frequency}
        gracePeriodValue={gracePeriodValue}
        filters={filters}
        regions={regions}
        drawerTitle={name}
        status={status}
        accountIds={accountIds}
        count={allResourcesCount}
      />

      <SchedulePreviewTable data={data} />
    </Fragment>
  );
};
