import { Row } from "@tanstack/react-table";
import { Schedule2 } from "../../../../../../../services/cloudchipr.api";
import { automationsAllActions } from "../../../../common/utils/constants/common";

export const scheduledJobsActionsFilter = (
  row: Row<Schedule2>,
  _: string,
  keyword: string,
) => {
  const schedule = row.original;
  const action = schedule.action && automationsAllActions[schedule.action];

  return action.toLowerCase().trim().includes(keyword.toLowerCase().trim());
};
