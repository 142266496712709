import { enqueueSnackbar } from "notistack";
import { createAsyncThunk } from "@reduxjs/toolkit";

import { setNavigationDashboard } from "../../../navigation/navigationSlice";
import { flattenDashboardHierarchyData } from "../../../../components/pages/dashboard/utils/helpers/flattenDashboardHierarchyData";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const getDashboardsHierarchyThunk = createAsyncThunk(
  "dashboard/getDashboardsHierarchy",
  async (_, { dispatch }) => {
    const { getUsersMeOrganisationsCurrentDashboardHierarchy } =
      cloudChiprApi.endpoints;

    try {
      const response = await dispatch(
        getUsersMeOrganisationsCurrentDashboardHierarchy.initiate(
          {},
          { forceRefetch: true },
        ),
      ).unwrap();

      if (response) {
        dispatch(
          setNavigationDashboard(flattenDashboardHierarchyData(response)),
        );
      }
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  },
);
