import { FC, ReactNode } from "react";
import { SxProps, Theme } from "@mui/system";
import { Chip, Tooltip } from "@mui/material";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { blue } from "@mui/material/colors";
import { accessTypeNames } from "./utils/constants/accessTypeNames";
import { ProviderAccessType } from "../../../../services/cloudchipr.api";

export interface AccountAccessTypeChipProps {
  sx?: SxProps<Theme>;
  accessType?: ProviderAccessType;
  textType?: "long" | "short";
  size?: "small" | "medium";
  warning?: boolean;
  customWarning?: ReactNode;
  customTooltipStyles?: SxProps<Theme>;
}

export const AccountAccessTypeChip: FC<AccountAccessTypeChipProps> = ({
  sx,
  accessType,
  textType = "short",
  size = "small",
  warning,
  customWarning,
  customTooltipStyles,
}) => {
  if (!accessType) {
    return null;
  }

  const color = accessType === "read" ? "default" : "primary";
  const warningMessage = customWarning ?? "Missing Permissions.";

  return (
    <Tooltip
      arrow
      placement={warning ? "left" : "bottom"}
      title={
        warning
          ? warningMessage
          : `This account is in ${accessTypeNames[accessType].long} mode`
      }
      componentsProps={{
        tooltip: { sx: customTooltipStyles },
      }}
    >
      <Chip
        sx={{
          ml: 1,
          pl: warning ? 0.5 : 0,
          bgcolor: warning ? blue[50] : undefined,
          ...sx,
        }}
        size={size}
        label={accessTypeNames[accessType][textType]}
        variant="outlined"
        icon={warning ? <WarningAmberIcon /> : undefined}
        color={warning ? "info" : color}
      />
    </Tooltip>
  );
};
