import { slackMdToHtml } from "../../../../../common/integration-dialogs/components/slack/utils/slackMdToHtml";
import { Schedule2 } from "../../../../../../services/cloudchipr.api";

export const formatWorkflowNotificationsFromSlackToHtml = (
  notifications: Schedule2["notifications"],
) => {
  return notifications?.map((item) => {
    if (item.type === "slack") {
      item = {
        ...item,
        custom_message: slackMdToHtml(item.custom_message ?? ""),
      };
      return item;
    }
    return item;
  });
};
