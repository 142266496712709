import {
  EmbeddedAccount,
  ProviderType,
} from "../../../../../services/cloudchipr.api";
import {
  CategoryProviderFilters,
  DimensionCategory,
} from "../../../../../store/dimensions/utils/types/common";

export const generateCategoryFiltersFromAccountIds = (
  selectedIds: string[],
  accountsById: Record<string, EmbeddedAccount>,
): DimensionCategory["filters"] => {
  const selectedAccounts = selectedIds.map((id) => accountsById[id]);

  const { aws, gcp, azure } = selectedAccounts.reduce(
    (acc, account) => {
      if (account?.provider) {
        acc[account.provider].push(account.id);
      }

      return acc;
    },
    { aws: [], gcp: [], azure: [] } as Record<ProviderType, string[]>,
  );

  return {
    aws_filters: getFilters(aws),
    gcp_filters: getFilters(gcp),
    azure_filters: getFilters(azure),
  };
};

const getFilters = (filters: string[]): CategoryProviderFilters => {
  if (!filters.length) {
    return null;
  }

  return {
    accounts: {
      items: [{ value: filters, operator: "in" }],
      combination_operator: "and",
    },
    combination_operator: "and",
  };
};
