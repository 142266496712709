import { FC, useCallback } from "react";
import { Grid, IconButton } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { deleteCategoryFilterGroupFilterItemByIndex } from "../../../../../../../../../../../../../store/dimensions/dimensionsSlice";
import { ProviderType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../../../../../../../../store/hooks";
import { CategoryFilterType } from "../../../../../../../../../../../../../store/dimensions/utils/types/common";

interface FilterItemDeleteButtonProps {
  categoryId: string;
  index: number;
  provider: ProviderType;
  type: CategoryFilterType;
}

export const FilterItemDeleteButton: FC<FilterItemDeleteButtonProps> = ({
  provider,
  categoryId,
  index,
  type,
}) => {
  const dispatch = useAppDispatch();

  const deleteHandler = useCallback(() => {
    dispatch(
      deleteCategoryFilterGroupFilterItemByIndex({
        type,
        index,
        provider,
        id: categoryId,
      }),
    );
  }, [dispatch, provider, categoryId, index, type]);

  return (
    <Grid item xs={1} justifyContent="center" display="flex">
      <IconButton onClick={deleteHandler} size="small">
        <DeleteOutlinedIcon fontSize="small" />
      </IconButton>
    </Grid>
  );
};
