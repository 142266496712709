import { Stack, Typography } from "@mui/material";
import { CommitmentsRecommendationsDataGridActionCell } from "./cells/CommitmentsRecommendationsDataGridActionCell";
import { CommitmentsRecommendationsColumnsSwitchCell } from "./cells/CommitmentsRecommendationsColumnsSwitchCell";
import { ColumnSetupType } from "../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanRecommendation } from "../../../../../../../services/cloudchipr.api";
import { savingsPlansPaymentOptionLabels } from "../../../../utilization-and-coverage/utils/constants/labels";
import { money } from "../../../../../../../utils/numeral/money";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";

export const savingsPlansTableColumns: ColumnSetupType<SavingsPlanRecommendation>[] =
  [
    {
      minSize: 180,
      accessorKey: "term_in_years",
      header: () => (
        <Typography pl={5.5} py={1} variant="body2" fontWeight="medium">
          Savings Plans term
        </Typography>
      ),
      cell: CommitmentsRecommendationsColumnsSwitchCell,
    },

    { accessorKey: "instance_family", header: "Instance Family" },

    { accessorKey: "region", header: "Region" },

    {
      accessorKey: "payment_option",
      header: "Payment option",
      cell: ({ row }) =>
        savingsPlansPaymentOptionLabels[row.original.payment_option],
    },

    {
      accessorKey: "account",
      header: "Account",
      cell: ({ row }) => (
        <Stack>
          <TypographyWithTooltip
            variant="body2"
            title={row.original.account.provider_account_name}
          />
          <TypographyWithTooltip
            variant="body2"
            color="text.secondary"
            title={row.original.account.provider_account_id}
          />
        </Stack>
      ),
    },

    {
      accessorKey: "hourly_commitment",
      header: "Commitment",
      cell: (cell) => money(cell.getValue() as number),
    },

    {
      minSize: 220,
      accessorKey: "estimated_coverage",
      header: "Estimated On-Demand Coverage",
      cell: (cell) => `${cell.getValue() as number}%`,
    },

    {
      minSize: 200,
      accessorKey: "estimated_monthly_savings",
      header: "Estimated Monthly Savings",
      cell: (cell) => money(cell.getValue() as number),
    },

    {
      accessorKey: "action_url",
      header: "Action",
      cell: CommitmentsRecommendationsDataGridActionCell,
      meta: { sticky: "right" },
    },
  ];
