import { offHoursDataSelector } from "./offHoursDataSelector";
import { RootState } from "../../../store";
import { OffHoursSchedule2 } from "../../../../services/cloudchipr.api";

export const offHoursByIdSelector = (
  state: RootState,
  id: string,
): OffHoursSchedule2 | undefined => {
  const offHours = offHoursDataSelector(state);

  return offHours?.find((offHour) => offHour.id === id);
};
