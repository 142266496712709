import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { cloudChiprApi, User } from "../../services/cloudchipr.api";

export const getUsersMeSelector = cloudChiprApi.endpoints.getUsersMe.select({});

export const currentUserSelector = createDraftSafeSelector(
  [getUsersMeSelector],
  (getUsersMe): User | null => {
    const { data: user } = getUsersMe;

    return user ?? null;
  },
);

export const currentUserIdSelector = createDraftSafeSelector(
  [currentUserSelector],
  (currentUser): string | undefined => {
    return currentUser?.id;
  },
);

export const currentUserEmailSelector = createDraftSafeSelector(
  [currentUserSelector],
  (currentUser): string | null | undefined => {
    return currentUser?.email;
  },
);
