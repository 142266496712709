import {
  CategoryFilter,
  ProviderType,
} from "../../../../../services/cloudchipr.api";

export const filterProviderByProvider: Record<
  ProviderType,
  keyof CategoryFilter
> = {
  aws: "aws_filters",
  gcp: "gcp_filters",
  azure: "azure_filters",
};
