import { RootState } from "../../../store";
import { cloudChiprApi, FilterSet } from "../../../../services/cloudchipr.api";

const selector =
  cloudChiprApi.endpoints.getUsersMeOrganisationsCurrentFilterSets.select;

export const organisationCurrentFilterSetsSelector = (
  state: RootState,
): FilterSet[] | undefined => {
  return selector()(state)?.data;
};
