import { resourceExplorerViewsHierarchyDataSelector } from "./resourceExplorerViewsHierarchyDataSelector";
import { ResourceExplorerFolder } from "../../../../services/cloudchipr.api";
import { RootState } from "../../../store";
import { isResourceExplorerFolder } from "../../../../components/navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";

export const resourceExplorerViewsHierarchyFoldersSelector = (
  state: RootState,
): ResourceExplorerFolder[] | undefined => {
  const hierarchy = resourceExplorerViewsHierarchyDataSelector(state);

  if (!hierarchy) {
    return;
  }
  return hierarchy.reduce((result, item) => {
    if (isResourceExplorerFolder(item)) {
      result.push(item);
    }
    return result;
  }, [] as ResourceExplorerFolder[]);
};
