import { FC, Fragment, useMemo } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { SchedulesDataGridStatusChip } from "../../../common/list/SchedulesDataGridStatusChip";
import { OffHoursSchedule2 } from "../../../../../../services/cloudchipr.api";
import {
  dateToUtcByTimeZone,
  formatDate,
} from "../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../common/TooltipText";
import { getOffHoursNextRun } from "../utils/helpers";

interface OffHoursListGridNextRunGridCellProps {
  schedule: OffHoursSchedule2;
}

export const OffHoursDataGridNextRunGridCell: FC<
  OffHoursListGridNextRunGridCellProps
> = ({ schedule }) => {
  const { status, time_zone: timeZone } = schedule;

  const { nextRun, type } = useMemo(
    () =>
      getOffHoursNextRun(
        schedule.metadata.next_stop_run_at,
        schedule.metadata.next_start_run_at,
        schedule.status,
      ),
    [
      schedule.metadata.next_stop_run_at,
      schedule.metadata.next_start_run_at,
      schedule.status,
    ],
  );

  if (status === "inactive") {
    return <SchedulesDataGridStatusChip status={status} />;
  }

  return (
    <Fragment>
      {nextRun ? (
        <Stack direction="row" alignItems="center">
          <Box m={0} mr={2}>
            <Typography variant="body2" sx={{ whiteSpace: "nowrap" }}>
              {type && `${type.toUpperCase()} | `}
              {formatDate(nextRun, {
                type: "date",
              })}
            </Typography>
            <TooltipText
              tooltipTitle={`${formatDate(
                dateToUtcByTimeZone(nextRun, timeZone) || nextRun,
                {
                  type: "dateTime",
                },
              )} in UTC time`}
            >
              {formatDate(nextRun, {
                type: "time",
              })}
            </TooltipText>
          </Box>
        </Stack>
      ) : (
        "Never"
      )}
    </Fragment>
  );
};
