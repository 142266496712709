import { FC, Fragment, useCallback } from "react";
import { Chip, Typography } from "@mui/material";
import { RenderTagsComponentProps } from "../../../../../../../../../../../../common/select/autocomplete-select/components/AutocompleteRenderTags";
import { TypographyWithTooltip } from "../../../../../../../../../../../../common/TypographyWithTooltip";

interface AccountFilterValueAutocompleteRenderTagsComponentProps
  extends RenderTagsComponentProps {
  label: string;
}

export const FilterValueAutocompleteRenderTagsComponent: FC<
  AccountFilterValueAutocompleteRenderTagsComponentProps
> = ({ selectedValues, onChange, options, disabled, label }) => {
  const allSelected =
    options.length > 1 && options.length === selectedValues.length;

  const deleteAllHandler = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const deleteHandler = useCallback(() => {
    const values = selectedValues.filter(
      (s: string) => s !== selectedValues[0],
    );

    onChange(values);
  }, [selectedValues, onChange]);

  if (allSelected) {
    return (
      <Chip
        size="small"
        variant="outlined"
        disabled={disabled}
        sx={{ minWidth: "70px" }}
        onDelete={deleteAllHandler}
        label={
          <TypographyWithTooltip variant="inherit" title={`All ${label}`} />
        }
      />
    );
  }

  const firstItem = selectedValues?.at(0) ?? "";
  const selectedOption = options.find((option) => option.value === firstItem);

  return (
    <Fragment>
      <Chip
        size="small"
        variant="outlined"
        label={selectedOption?.label ?? firstItem}
        disabled={disabled}
        onDelete={deleteHandler}
        sx={{ minWidth: "70px" }}
      />

      {selectedValues.length > 1 && (
        <Typography
          ml={1}
          variant="body2"
          whiteSpace="nowrap"
          color="text.secondary"
        >
          + {selectedValues.length - 1}
        </Typography>
      )}
    </Fragment>
  );
};
