import { FC, useCallback, useMemo } from "react";
import { Grid } from "@mui/material";
import { AutocompleteSelect } from "../../../../../../../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { TypographyWithTooltip } from "../../../../../../../../../../../../common/TypographyWithTooltip";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../../../../store/hooks";
import {
  ProviderType,
  ResourceExplorerAccount,
  ResourceExplorerTag,
} from "../../../../../../../../../../../../../services/cloudchipr.api";
import { resourceExplorerPossibleFiltersByProviderSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/filters/possible-filters/resourceExplorerPossibleFiltersByProviderSelector";
import { getCostAllocationTagValuesThunk } from "../../../../../../../../../../../../../store/resource-explorer/thunks/getCostAllocationTagValuesThunk";
import { categoryValueTypeByOperator } from "../../../../utils/constants/operators";
import { RenderTagsComponentProps } from "../../../../../../../../../../../../common/select/autocomplete-select/components/AutocompleteRenderTags";

interface TagFilterKeyAutocompleteProps {
  value: string;
  provider: ProviderType;
  filterItem: ResourceExplorerTag;
  onChange(item: ResourceExplorerAccount | ResourceExplorerTag): void;
}

export const TagFilterKeyAutocomplete: FC<TagFilterKeyAutocompleteProps> = ({
  value,
  provider,
  filterItem,
  onChange,
}) => {
  const dispatch = useAppDispatch();

  const changeHandler = useCallback(
    (value: string[]) => {
      const key = value.at(0);

      if (key) {
        dispatch(
          getCostAllocationTagValuesThunk({
            tagKey: key,
            provider,
          }),
        );

        const dataType = categoryValueTypeByOperator[filterItem.operator];

        onChange({
          ...filterItem,
          tag_key: key,
          tag_values: dataType === "string" ? "" : [],
        });
      }
    },
    [dispatch, onChange, provider, filterItem],
  );

  const possibleTags = useAppSelector((state) =>
    resourceExplorerPossibleFiltersByProviderSelector(state, provider),
  )?.cost_allocation_tag;

  const renderOptions = useMemo(() => {
    return possibleTags?.values?.map((tag) => ({
      value: tag.value,
      label: (
        <TypographyWithTooltip variant="body2" title={tag.title || tag.value} />
      ),
    }));
  }, [possibleTags]);

  return (
    <Grid item xs={3}>
      <AutocompleteSelect
        singleSelect
        size="xsmall"
        label="Key"
        placeholder="Key"
        onChange={changeHandler}
        options={renderOptions ?? []}
        initialSelectedValues={[value]}
        RenderTagsComponent={value ? RenderTagsComponent : undefined}
      />
    </Grid>
  );
};

const RenderTagsComponent: FC<RenderTagsComponentProps> = ({
  selectedValues,
}) => {
  const firstItem = selectedValues?.at(0) ?? "";

  return (
    <TypographyWithTooltip variant="body2" title={firstItem} minWidth={70} />
  );
};
