import { liveFilteredTrackedResourcesSelector } from "../liveFilteredTrackedResourcesSelector";
import { RootState } from "../../../../store";
import { PostUsersMeOrganisationsCurrentTrackedResourcesApiResponse } from "../../../../../services/cloudchipr.api";

export const liveFilteredTrackedResourcesDataForViewSelector = (
  state: RootState,
): PostUsersMeOrganisationsCurrentTrackedResourcesApiResponse | undefined => {
  const response = liveFilteredTrackedResourcesSelector(state);
  return response?.data;
};
