import { FC, Fragment } from "react";
import { Button, Link, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { v4 as uuid } from "uuid";
import { coverageDrawerRecommendationsGridColumns } from "./CoverageDrawerRecommendationsGridColumns";
import { useGetUsersMeOrganisationsCurrentSavingsPlansRecommendationsQuery } from "../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { DataGrid } from "../../../../../../../../storybook/data-grid/DataGrid";
import { StylesProps } from "../../../../../../../../storybook/data-grid/utils/types/prop-types";
import { coverageRecommendationsPayloadSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/recommendations/coverageRecommendationsPayloadSelector";

export const CoverageDrawerRecommendationsSection: FC = () => {
  const args = useAppSelector(coverageRecommendationsPayloadSelector);

  const { data } =
    useGetUsersMeOrganisationsCurrentSavingsPlansRecommendationsQuery(args, {
      selectFromResult: ({ data }) => {
        return {
          data: data?.map((recommendation) => ({
            ...recommendation,
            id: uuid(),
          })),
        };
      },
    });

  return (
    <Stack p={2} spacing={2}>
      <Typography variant="h5" fontWeight="bold">
        Recommendations
      </Typography>

      {!data?.length ? (
        <Stack alignItems="center" p={3}>
          <Typography
            variant="subtitle1"
            fontWeight="medium"
            color="text.secondary"
          >
            No Recommendations
          </Typography>
        </Stack>
      ) : (
        <Fragment>
          <DataGrid
            enableStickyColumns
            columnSorting
            styles={styles}
            data={data}
            columns={coverageDrawerRecommendationsGridColumns}
          />

          <Button
            component={Link}
            href={`/commitments/recommendations/${args.providerOrganisationId}`}
            target="_blank"
            variant="outlined"
            endIcon={<OpenInNewOutlinedIcon />}
            size="small"
            sx={{ alignSelf: "end" }}
          >
            View All Recommendations
          </Button>
        </Fragment>
      )}
    </Stack>
  );
};

const styles: StylesProps["styles"] = {
  table: {
    borderCollapse: "separate",
    tableLayout: "fixed",
    "& th": { py: 2, bgcolor: "white" },
    "& th:first-of-type": { pl: 2 },
    "& td:first-of-type": { pl: 2 },
    "& tr:hover td": { bgcolor: grey[100] },
    "& tr td": { borderColor: grey[100], bgcolor: "white" },
  },

  tableContainer: {
    bgcolor: "white",
    borderRadius: 2,
    border: "1px solid",
    borderColor: "grey.300",
  },
};
