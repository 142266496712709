import { FC, Fragment } from "react";
import { Divider, Stack, Typography } from "@mui/material";

export interface PageTitleProps {
  title: string;
  subTitle?: string;
}

export const PageTitle: FC<PageTitleProps> = ({ title, subTitle }) => {
  return (
    <Fragment>
      <Stack spacing={1} m={3}>
        <Typography variant="h5" fontWeight="bold">
          {title}
        </Typography>
        <Typography color="text.secondary">{subTitle}</Typography>
      </Stack>
      <Divider />
    </Fragment>
  );
};
