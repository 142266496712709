import { FC, ReactElement, useCallback, useMemo } from "react";
import {
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CreateIntegrationButton } from "./integrations/CreateIntegrationButton";
import { IntegrationNotification } from "../utils/types/types";
import { emailNotificationId } from "../utils/constants/constants";
import { IntegrationType } from "../../../integrations/components/integrations-create/components/common/integration-type/IntegrationTypeCard";
import {
  EmailsSelection,
  EmailsSelectionErrors,
} from "../../../../common/integration-dialogs/components/email/components/email-selection/EmailsSelection";
import { IntegrationLogo } from "../../../../common/integration-dialogs/components/common/IntegrationLogo";
import {
  Emails,
  useGetUsersMeIntegrationsQuery,
} from "../../../../../services/cloudchipr.api";

export interface NotificationsEmailSelectionProps {
  onCreateDialogOpen(): void;
  selectedEmails: Emails | null;
  emailsErrors: EmailsSelectionErrors;
  emailsChangeHandler(emails: Emails | null): void;
  onAddNewIntegration(
    type: IntegrationType,
    integrationId: string,
    addToFirst?: boolean,
  ): void;
  CustomMessage?: ReactElement;
  disabledIntegrationTypes?: IntegrationType[];
  selectedIntegrations?: IntegrationNotification[] | null;
}

export const NotificationsEmailSelection: FC<
  NotificationsEmailSelectionProps
> = ({
  emailsErrors,
  selectedEmails,
  emailsChangeHandler,
  CustomMessage,
  selectedIntegrations,
  disabledIntegrationTypes,
  onAddNewIntegration,
  onCreateDialogOpen,
}) => {
  const { data } = useGetUsersMeIntegrationsQuery({});
  const options = useMemo(() => {
    return data?.filter(
      (integration) => !disabledIntegrationTypes?.includes(integration.type),
    );
  }, [data, disabledIntegrationTypes]);

  const deleteEmailsHandler = useCallback(() => {
    emailsChangeHandler(null);
  }, [emailsChangeHandler]);

  const changeHandler = useCallback(
    (event: SelectChangeEvent<string>) => {
      const id = event.target.value;

      const type = options?.find((integration) => integration.id === id)?.type;

      if (!type) {
        return;
      }

      deleteEmailsHandler();
      onAddNewIntegration(type, id, true);
    },
    [options, onAddNewIntegration, deleteEmailsHandler],
  );

  if (!selectedEmails) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} alignItems="start">
      <Stack flexGrow={1} gap={2}>
        <FormControl size="small" fullWidth>
          <InputLabel>Select Notification Method</InputLabel>

          <Select
            onChange={changeHandler}
            label="Select Notification Method"
            value={emailNotificationId}
          >
            <MenuItem value={emailNotificationId}>
              <Stack direction="row" spacing={2}>
                <IntegrationLogo variant="colored" type="email" width={20} />

                <ListItemText>EMAIL</ListItemText>
              </Stack>
            </MenuItem>

            <Divider />

            <ListSubheader sx={{ lineHeight: 1 }}>
              <Typography variant="caption" color="text.secondary">
                INTEGRATIONS
              </Typography>
            </ListSubheader>

            {options?.map((integration) => {
              return (
                <MenuItem
                  key={integration.id}
                  value={integration.id}
                  disabled={selectedIntegrations?.some(
                    ({ integration_id }) => integration_id === integration.id,
                  )}
                >
                  <Stack direction="row" spacing={2}>
                    <IntegrationLogo
                      variant="colored"
                      type={integration.type}
                      width={20}
                    />

                    <ListItemText>{integration.name}</ListItemText>
                  </Stack>
                </MenuItem>
              );
            })}

            <CreateIntegrationButton
              onClick={onCreateDialogOpen}
              empty={!options?.length}
            />
          </Select>
        </FormControl>

        {CustomMessage ? (
          <Stack spacing={2}>
            <EmailsSelection
              errors={emailsErrors}
              toEmails={selectedEmails.to}
              ccEmails={selectedEmails.cc}
              bccEmails={selectedEmails.bcc}
              onEmailsChange={emailsChangeHandler}
            />

            {CustomMessage}
          </Stack>
        ) : (
          <EmailsSelection
            errors={emailsErrors}
            toEmails={selectedEmails.to}
            ccEmails={selectedEmails.cc}
            bccEmails={selectedEmails.bcc}
            onEmailsChange={emailsChangeHandler}
          />
        )}
      </Stack>
      <IconButton onClick={deleteEmailsHandler}>
        <DeleteOutlineIcon />
      </IconButton>
    </Stack>
  );
};
