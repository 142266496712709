import { useCallback, useState } from "react";

export interface UseDialogReturnDataType {
  open: boolean;
  openDialog(): void;
  closeDialog(): void;
}
export function useDialog(initialState?: boolean): UseDialogReturnDataType {
  const [open, setOpen] = useState(!!initialState);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    openDialog,
    closeDialog,
  };
}
