import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { CategoryFilterGroup } from "./CategoryFilterGroup";
import { isFilterWithTags } from "../../utils/helpers/typeCheckers";
import { CategoryProviderFilters } from "../../../../../../../../../../../store/dimensions/utils/types/common";
import { ProviderType } from "../../../../../../../../../../../services/cloudchipr.api";

interface CategoryFiltersProps {
  filters: CategoryProviderFilters;
  provider: ProviderType;
  categoryId: string;
}

export const CategoryFilters: FC<CategoryFiltersProps> = ({
  filters,
  provider,
  categoryId,
}) => {
  const tagsInFilter = isFilterWithTags(filters);
  const noTagFilters =
    (tagsInFilter && !filters.tags?.items?.length) || !tagsInFilter;

  const noFilters = !filters?.accounts?.items?.length && noTagFilters;
  const operator = tagsInFilter ? filters.combination_operator : undefined;

  if (noFilters) {
    return (
      <Typography variant="body1" mt={1}>
        No filters applied
      </Typography>
    );
  }

  const renderTags = isFilterWithTags(filters) && filters.tags?.items?.length;
  const renderAccounts = !!filters.accounts?.items?.length;
  const bothExist = !!(renderTags && renderAccounts);

  return (
    <Stack mt={1} width="100%" spacing={2}>
      {renderAccounts && filters.accounts && (
        <CategoryFilterGroup
          first={!renderTags || bothExist}
          type="account"
          provider={provider}
          filters={filters.accounts}
          operator={operator}
          categoryId={categoryId}
        />
      )}

      {!!(isFilterWithTags(filters) && filters.tags?.items?.length) && (
        <CategoryFilterGroup
          type="tag"
          first={!renderAccounts}
          provider={provider}
          filters={filters.tags}
          operator={operator}
          categoryId={categoryId}
        />
      )}
    </Stack>
  );
};
