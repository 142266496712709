import { commitmentsRecommendationsPayloadDataSelector } from "./commitmentsRecommendationsPayloadDataSelector";
import { RootState } from "../../../store";

export const commitmentsRecommendationsSelectedRecommendationsSelector = (
  state: RootState,
) => {
  const data = commitmentsRecommendationsPayloadDataSelector(state);

  return data?.selectedRecommendations;
};
