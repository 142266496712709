import { PayloadAction, CaseReducer } from "@reduxjs/toolkit";
import { CategoryFilterType, DimensionsState } from "../utils/types/common";
import { AwsFilter, ProviderType } from "../../../services/cloudchipr.api";
import { filterProviderByProvider } from "../../../components/pages/dimensions/utils/constants/common";

export const deleteCategoryFilterGroupReducer: CaseReducer<
  DimensionsState,
  PayloadAction<{
    id: string;
    provider: ProviderType;
    type: CategoryFilterType;
  }>
> = (state, action) => {
  const { id, provider, type } = action.payload;
  const filterProvider = filterProviderByProvider[provider];
  const filters = state.categories[id].filters[filterProvider] as AwsFilter;

  if (type === "account") {
    delete filters.accounts;
  } else {
    delete filters.tags;
  }
};
