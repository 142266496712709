import { FC, Fragment, useMemo } from "react";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { IconButton, ListItemProps } from "@mui/material";
import InsertChartOutlinedOutlinedIcon from "@mui/icons-material/InsertChartOutlinedOutlined";

import { NavItem } from "../nav-item/NavItem";

export interface SortableTreeItemWrapperProps {
  collapsed?: boolean;
  id: string;
  onCollapse?(): void;
  name: string;
  disabled?: boolean;
  type: "folder" | "item";
  renderFolderAction?: (args: {
    id: string;
    name: string;
  }) => ListItemProps["secondaryAction"];
  renderItemAction?: (args: {
    id: string;
    name: string;
    parentId?: string | null;
  }) => ListItemProps["secondaryAction"];
  parentId?: string | null;
}

export const SortableTreeItemWrapper: FC<SortableTreeItemWrapperProps> = ({
  collapsed,
  onCollapse,
  name,
  type,
  disabled,
  renderFolderAction,
  renderItemAction,
  id,
  parentId,
}) => {
  const localItemAction = useMemo(() => {
    return renderItemAction?.({ id, name, parentId });
  }, [renderItemAction, id, name, parentId]);

  const localFolderAction = useMemo(() => {
    return renderFolderAction?.({ id, name });
  }, [renderFolderAction, id, name]);

  const ArrowIcon = collapsed ? ArrowDropUpIcon : ArrowDropDownIcon;

  return (
    <Fragment>
      {onCollapse && (
        <IconButton onClick={onCollapse}>
          <ArrowIcon sx={{ color: "text.disabled", ml: 1 }} />
        </IconButton>
      )}
      {type === "folder" ? (
        <NavItem
          nested
          primary={name}
          secondaryAction={localFolderAction}
          secondaryActionHover
          icon={FolderOpenIcon}
        />
      ) : (
        <NavItem
          nested
          primary={name}
          to={`/resource-explorer/${id}`}
          icon={InsertChartOutlinedOutlinedIcon}
          secondaryAction={!disabled ? localItemAction : null}
          secondaryActionHover
        />
      )}
    </Fragment>
  );
};
