import { RootState } from "../../../../store";
import { resourceExplorerViewsHierarchyDataSelector } from "../../views-hierarchy/resourceExplorerViewsHierarchyDataSelector";
import { isResourceExplorerFolder } from "../../../../../components/navigation/components/resource-explorer-v2/utils/helpers/isResourceExplorerFolder";
import { resourceExplorerIdSelector } from "../resourceExplorerIdSelector";

export const currentResourceExplorerFolderSelector = (state: RootState) => {
  const viewId = resourceExplorerIdSelector(state);
  const hierarchy = resourceExplorerViewsHierarchyDataSelector(state);

  return hierarchy?.find((item) => {
    if (isResourceExplorerFolder(item)) {
      return item.views?.some((view) => view.id === viewId);
    }
  });
};
