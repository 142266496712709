import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";

export const updateResourceExplorerFolderNameThunkFixedCacheKey =
  "updateResourceExplorerFolderName-fixedCacheKey";

interface UpdateResourceExplorerFolderNameThunkArg {
  id: string;
  name: string;
}
export const updateResourceExplorerFolderNameThunk = createAsyncThunk(
  "resourceExplorer/updateResourceExplorerFolderName",
  async (
    { name, id }: UpdateResourceExplorerFolderNameThunkArg,
    { dispatch },
  ) => {
    const {
      patchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId,
    } = cloudChiprApi.endpoints;

    try {
      await dispatch(
        patchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId.initiate(
          {
            resourceExplorerFolderId: id,
            body: { name },
          },
          {
            fixedCacheKey: updateResourceExplorerFolderNameThunkFixedCacheKey,
          },
        ),
      ).unwrap();

      const snackbarId: SnackbarKey = enqueueSnackbar(
        "Folder name successfully updated.",
        {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "success",
            onClose: () => closeSnackbar(snackbarId),
          },
        },
      );
    } catch (e) {
      // @ts-expect-error TODO: return to this after adding error type
      const errMessage = e?.data?.message || "Something went wrong";
      const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
          onClose: () => closeSnackbar(snackbarId),
        },
      });
    }
  },
);
