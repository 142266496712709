import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { updateCategoryByIdThunk } from "./updateCategoryByIdThunk";
import { createCategoryByDimensionIdThunk } from "./createCategoryByDimensionIdThunk";
import { RootState } from "../../../store";
import { getDimensionsThunk } from "../dimensions/getDimensionsThunk";
import { dimensionDrawerModeSelector } from "../../selectors/slice-data/dimensionDrawerModeSelector";
import { dimensionIdSelector } from "../../selectors/slice-data/dimensionIdSelector";
import { editingCategorySelector } from "../../selectors/slice-data/editing-category/editingCategorySelector";

export const categorySaveHandlerThunk = createAsyncThunk(
  "dimensions/categorySaveHandler",
  async (_: void, { dispatch, getState }) => {
    const state = getState() as RootState;
    const mode = dimensionDrawerModeSelector(state);
    const dimensionId = dimensionIdSelector(state);
    const category = editingCategorySelector(state);

    if (!dimensionId || !category?.id) {
      return;
    }

    const create = mode === "categoryCreate";

    try {
      let response;

      if (create) {
        response = await dispatch(
          createCategoryByDimensionIdThunk({
            dimensionId,
            categoryId: category.id,
            categoryName: category.name,
            filters: category.filters,
          }),
        );
      } else {
        response = await dispatch(
          updateCategoryByIdThunk({
            categoryId: category.id,
            name: category.name,
            dimensionId,
            filter: category.filters,
          }),
        );
      }

      if (response?.payload) {
        await dispatch(getDimensionsThunk(true));

        enqueueSnackbar(
          `Category successfully ${create ? "created" : "updated"}.`,
          {
            variant: "snackbarAlert",
            AlertSnackBarProps: { severity: "success" },
          },
        );

        return response;
      }
    } catch (e) {
      // @ts-expect-error todo: fix api spec
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });
    }
  },
);
