import { FC } from "react";
import {
  FormControlLabel,
  Radio,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CategoryFilterBaseType } from "../../../../../../../../store/dimensions/utils/types/common";

interface FilterTypeSelectRadioButtonProps {
  label: string;
  tooltip: string;
  value: CategoryFilterBaseType;
}

export const FilterTypeSelectRadioButton: FC<
  FilterTypeSelectRadioButtonProps
> = ({ tooltip, value, label }) => {
  return (
    <FormControlLabel
      value={value}
      control={<Radio size="small" />}
      label={
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Typography variant="body1">{label}</Typography>

          <Tooltip arrow title={tooltip} placement="top">
            <InfoOutlinedIcon color="info" fontSize="small" />
          </Tooltip>
        </Stack>
      }
    />
  );
};
