import { ChangeEvent, FC, useCallback, useState } from "react";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { ResourceExplorerV2ViewCreateFolderOrViewDialogContent } from "./components/ResourceExplorerV2ViewCreateFolderOrViewDialogContent";
import { ResourceExplorerV2ViewCreateFolderOrViewDialogActions } from "./components/ResourceExplorerV2ViewCreateFolderOrViewDialogActions";
import { ResourceExplorerV2ViewCreateFolderOrViewTypes } from "../../utils/types";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { navigationHierarchyItemNoDndKey } from "../../../sortable-tree/utils/constants";

interface ResourceExplorerV2ViewCreateFolderOrViewDialogProps {
  onClose(): void;
  type?: ResourceExplorerV2ViewCreateFolderOrViewTypes;
  folderId?: string;
}
export const ResourceExplorerV2ViewCreateFolderOrViewDialog: FC<
  ResourceExplorerV2ViewCreateFolderOrViewDialogProps
> = ({ onClose, type, folderId }) => {
  const [name, setName] = useState<string>("");

  const closeDialogHandler = useCallback(() => {
    onClose();
    setName("");
  }, [onClose]);

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setName(event.target.value);
    },
    [],
  );

  if (!type) {
    return null;
  }

  return (
    <Dialog
      open={!!type}
      onClose={closeDialogHandler}
      maxWidth="sm"
      fullWidth
      {...navigationHierarchyItemNoDndKey}
    >
      <DialogTitleClosable
        title={type === "view" ? "Add View" : "Add Folder"}
        onClose={closeDialogHandler}
      />
      <DialogContent
        sx={{
          py: 3,
        }}
        dividers
      >
        <ResourceExplorerV2ViewCreateFolderOrViewDialogContent
          value={name}
          onChange={nameChangeHandler}
          label={type === "view" ? "View Name" : "Folder Name"}
        />
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <ResourceExplorerV2ViewCreateFolderOrViewDialogActions
          onCloseDialog={closeDialogHandler}
          name={name}
          type={type}
          folderId={folderId}
        />
      </DialogActions>
    </Dialog>
  );
};
