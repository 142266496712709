import { FC, useCallback, useMemo } from "react";
import { CellContext } from "@tanstack/react-table";
import { FiltersCountButtonCell } from "../common/FiltersCountButtonCell";
import { generateDimensionCategoriesFromCategoriesWithCosts } from "../../../utils/helpers/generateDimensionCategoriesFromCategoriesWithCosts";
import { getCategoryFiltersCount } from "../../../utils/helpers/getCategoryFiltersCount";
import { DimensionWithCosts } from "../../../../../../services/cloudchipr.api";
import { setDimensionsState } from "../../../../../../store/dimensions/dimensionsSlice";
import { useAppDispatch } from "../../../../../../store/hooks";

export const DimensionFiltersCount: FC<
  CellContext<DimensionWithCosts, any>
> = ({ row }) => {
  const dispatch = useAppDispatch();
  const dimension = row.original;

  const count = useMemo(() => {
    return dimension.categories.reduce((sum, category) => {
      const count = getCategoryFiltersCount(category);

      return sum + count;
    }, 0);
  }, [dimension.categories]);

  const buttonClickHandler = useCallback(() => {
    dispatch(
      setDimensionsState({
        drawerMode: "dimensionEdit",
        dimensionId: dimension.id,
        dimensionName: dimension.name,
        filterType: dimension.categories[0].type,
        categories: generateDimensionCategoriesFromCategoriesWithCosts(
          dimension.categories,
        ),
      }),
    );
  }, [dispatch, dimension]);

  return (
    <FiltersCountButtonCell
      count={count}
      dimensionId={dimension.id}
      onClick={buttonClickHandler}
      basedOn={row.original?.categories?.at(0)?.type}
    />
  );
};
