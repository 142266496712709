import { FC, Fragment } from "react";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { DashboardV2ItemListItemActionMenu } from "./DashboardV2ItemListItemActionMenu";
import { useMenuHook } from "../../../../../../utils/hooks/useMenu.hook";
import { useAppAbility } from "../../../../../../services/permissions";

interface DashboardV2ItemActionButtonProps {
  id: string;
  name: string;
  folderId?: string;
}

export const DashboardV2ItemActionButton: FC<
  DashboardV2ItemActionButtonProps
> = ({ id, name, folderId }) => {
  const { cannot } = useAppAbility();
  const cannotDeleteDashboard = cannot("delete", "dashboard");
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  if (cannotDeleteDashboard) {
    return null;
  }

  return (
    <Fragment>
      <IconButton size="small" onClick={openMenu}>
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <DashboardV2ItemListItemActionMenu
        id={id}
        name={name}
        open={open}
        anchor={anchor}
        closeMenu={closeMenu}
        folderId={folderId}
      />
    </Fragment>
  );
};
