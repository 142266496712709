import { FC } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Tooltip } from "@mui/material";
import { ResourceActionMissingPermissionsTooltipTitle } from "./ResourceActionMissingPermissionsTooltipTitle";

interface ResourceActionMissingPermissionsTooltipProps {
  missingPermissionAccountIds: string[];
}

export const ResourceActionMissingPermissionsTooltip: FC<
  ResourceActionMissingPermissionsTooltipProps
> = ({ missingPermissionAccountIds }) => {
  return (
    <Tooltip
      title={
        <ResourceActionMissingPermissionsTooltipTitle
          missingPermissionAccountIds={missingPermissionAccountIds}
        />
      }
      placement="right-start"
      componentsProps={{
        tooltip: { sx: tooltipStyles },
      }}
    >
      <WarningAmberIcon color="warning" />
    </Tooltip>
  );
};

const tooltipStyles = {
  color: "text.primary",
  bgcolor: "background.paper",
  minWidth: 425,
  boxShadow: 7,
  p: 0,
};
