import { FC, useCallback, useMemo } from "react";
import { AutocompleteSelect } from "../../autocomplete-select/AutocompleteSelect";
import { DropdownFooterComponent } from "../../dropdown-select/utils/types/types";
import { TypographyWithTooltip } from "../../../TypographyWithTooltip";
import { ResourceExplorerTagFilterOperator } from "../../../../../services/cloudchipr.api";
import { multipleValueSelectOperators } from "../../../../pages/common/filters/utils/constants/filter-types/autocomplete-multipe-selection-operator-types";
import { tagsFilterDefaultOperator } from "../utils/constants";

interface TagFilterDropdownSelectValuesProps {
  options: {
    value: string;
    title?: string;
  }[];
  values?: string[] | string;
  onChange: (values: string[] | string) => void;
  DropdownFooter?: DropdownFooterComponent;
  label?: string;
  placeholder?: string;
  operator?: ResourceExplorerTagFilterOperator;
  disabled: boolean;
}

export const TagFilterDropdownSelectValues: FC<
  TagFilterDropdownSelectValuesProps
> = ({
  operator = tagsFilterDefaultOperator,
  options,
  values,
  onChange,
  DropdownFooter,
  placeholder,
  label,
  disabled,
}) => {
  const singleSelect = !multipleValueSelectOperators.includes(operator ?? "");

  const dropdownOptions = useMemo(() => {
    return (
      options?.map(({ value, title }) => {
        return {
          value: value,
          label: (
            <TypographyWithTooltip variant="body2" title={title || value} />
          ),
        };
      }) ?? []
    );
  }, [options]);

  const valueChangeHandler = useCallback(
    (selectedValue: any) => {
      const data = singleSelect ? selectedValue[0]?.trim() : selectedValue;
      const value = data?.length ? data : [];
      onChange(value);
    },
    [singleSelect, onChange],
  );

  const data = useMemo(() => {
    const stringValue = values && typeof values === "string" ? [values] : [];
    return Array.isArray(values) ? values : stringValue;
  }, [values]);

  return (
    <AutocompleteSelect
      size="xsmall"
      freeSolo={singleSelect}
      creatable={singleSelect}
      singleSelect={singleSelect}
      DropdownFooter={DropdownFooter}
      label={label ?? "Select Value"}
      placeholder={placeholder ?? "Select Value"}
      options={dropdownOptions ?? []}
      initialSelectedValues={data}
      onChange={valueChangeHandler}
      listWidth={500}
      values={data}
      disabled={disabled}
    />
  );
};
