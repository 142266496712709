import { FC, memo } from "react";
import { Stack } from "@mui/material";
import { TimeZones } from "./TimeZones";
import { StartDate } from "./StartDate";
import { EndDate } from "./EndDate";
import { NextRunDatesSection } from "./NextRunDatesSection";
import { AutomationTabType } from "../../utils/types/types";

export interface ScheduleDatesInterface {
  id: string;
  startDate: string | null;
  endDate: string | null;
  timeZone: string;
}

export interface DateTimeContentProps {
  startDate: string | null;
  endDate: string | null;
  timeZone: string;
  spacing?: "long" | "short";
  startDateError?: string | boolean;
  endDateError?: string | boolean;
  setFieldValue(key: string, value: any): void;
  showNextRuns?: boolean;
  tabType?: AutomationTabType;
}

export const DateTimeContent: FC<DateTimeContentProps> = memo(
  ({
    setFieldValue,
    startDate,
    endDate,
    startDateError,
    endDateError,
    timeZone,
    spacing = "short",
    showNextRuns,
    tabType,
  }) => {
    return (
      <Stack>
        <Stack alignItems="baseline" gap={6} my={2}>
          <StartDate
            spacing={spacing}
            timeZone={timeZone}
            startDate={startDate}
            error={startDateError}
            setFieldValue={setFieldValue}
          />

          <EndDate
            spacing={spacing}
            timeZone={timeZone}
            endDate={endDate}
            error={endDateError}
            setFieldValue={setFieldValue}
          />
        </Stack>

        <TimeZones timeZone={timeZone} setFieldValue={setFieldValue} />

        {showNextRuns && (
          <NextRunDatesSection
            timeZone={timeZone}
            spacing={spacing}
            tabType={tabType}
          />
        )}
      </Stack>
    );
  },
);
