import { FC } from "react";
import { Box, Card, Stack, Typography } from "@mui/material";
import { grey, teal } from "@mui/material/colors";
import { Cube } from "../../../../../../storybook/charts/multi-type-chart/components/common/Cube";
import { money } from "../../../../../../utils/numeral/money";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsRecommendationsTotalsSelector } from "../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsTotalsSelector";

export const SelectedSavingsPlansCoverage: FC = () => {
  const totals = useAppSelector(commitmentsRecommendationsTotalsSelector);

  return (
    <Card variant="outlined">
      <Stack
        p={1.5}
        borderBottom={1}
        borderColor="grey.300"
        alignItems="center"
      >
        <Typography variant="subtitle2" fontWeight="medium">
          Selected Savings Plans Coverage
        </Typography>
      </Stack>

      <Stack p={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            flex={1}
            height={14}
            borderRadius={2}
            overflow="hidden"
            bgcolor="grey.300"
          >
            <Box
              width={`${totals.percentage}%`}
              height="100%"
              bgcolor={teal[300]}
            />
          </Box>
          <Typography color={teal[300]} variant="body1" fontWeight="medium">
            {totals.percentage}%
          </Typography>
        </Stack>

        <Stack direction="row" mt={2}>
          <Stack flex={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Cube width={12} height={12} color={teal[300]} />
              <Typography variant="caption" color="text.secondary">
                Estimated Coverage
              </Typography>
            </Stack>

            <Typography variant="subtitle1" fontWeight="bold">
              {money(totals.coverage)}
            </Typography>
          </Stack>

          <Stack flex={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Cube width={12} height={12} color={grey[300]} />
              <Typography variant="caption" color="text.secondary">
                Not Covered (On-Demand)
              </Typography>
            </Stack>

            <Typography variant="subtitle1" fontWeight="bold">
              {money(totals.notCovered)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};
