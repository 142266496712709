import { FC, Fragment, useCallback } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { WidgetViewSelect } from "../../common/toolbar/WidgetViewSelect";
import { WidgetFrequencySelect } from "../../common/toolbar/WidgetFrequencySelect";
import { WidgetForecastSelect } from "../../common/toolbar/WidgetForecastSelect";
import { sortedResourceExplorersSelector } from "../../../../../../../../../store/resource-explorer/selectors/all-resource-explorers/sortedResourceExplorersSelector";
import { costBreakdownV2ForecastChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2ForecastChangeThunk";
import { costBreakdownV2FrequencyChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2FrequencyChangeThunk";
import { resourceExplorerViewByIdLoadingSelector } from "../../../../../../../../../store/resource-explorer/selectors/resource-explorer-by-id/resourceExplorerViewByIdLoadingSelector";
import { CostBreakdownV2WidgetViewTypeSelect } from "../components/side-bar/CostBreakdownV2WidgetViewTypeSelect";
import { CostBreakdownV2WidgetSetupGroupingSelect } from "../components/side-bar/CostBreakdownV2WidgetSetupGroupingSelect";
import { costBreakdownV2REViewChangeThunk } from "../../../../../../../../../store/dashboards/thunks/widgets/cost-breakdown-v2/setup-change/costBreakdownV2REViewChangeThunk";
import { costBreakdownV2WidgetSetupForecastedOptionsSelector } from "../../../../../../../../../store/dashboards/selectors/widgets/cost-breakdown-v2/setup/costBreakdownV2WidgetSetupForecastedOptionsSelector";
import { CostBreakdownV2WidgetSetupDateRangeSelect } from "../components/side-bar/CostBreakdownV2WidgetSetupDateRangeSelect";
import { costBreakdownV2SetupPropertyByKeySelector } from "../../../../../../../../../store/dashboards/selectors/setups/cost-breakdown-v2/costBreakdownV2SetupPropertyByKeySelector";

export const CostBreakdownV2WidgetSetupSidebar: FC = () => {
  const dispatch = useAppDispatch();

  const viewId = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("viewId"),
  );

  const frequency = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("frequency"),
  );
  const viewType = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("viewType"),
  );
  const forecastOption = useAppSelector(
    costBreakdownV2SetupPropertyByKeySelector("forecastOption"),
  );

  const views = useAppSelector(sortedResourceExplorersSelector);
  const viewGettingLoading = useAppSelector((state) =>
    resourceExplorerViewByIdLoadingSelector(state, viewId),
  );

  const forecastedCostsOptions = useAppSelector(
    costBreakdownV2WidgetSetupForecastedOptionsSelector,
  );

  const frequencyChangeHandler = useCallback(
    (e: any) => {
      dispatch(costBreakdownV2FrequencyChangeThunk(e.target.value));
    },
    [dispatch],
  );
  const forecastChangeHandler = useCallback(
    (event: any) => {
      dispatch(costBreakdownV2ForecastChangeThunk(event.target.value));
    },
    [dispatch],
  );

  const viewChangeHandler = useCallback(
    (e: any) => {
      dispatch(costBreakdownV2REViewChangeThunk(e.target.value));
    },
    [dispatch],
  );

  if (!viewType) {
    return null;
  }

  return (
    <Fragment>
      <WidgetViewSelect
        viewId={viewId}
        views={views}
        onChange={viewChangeHandler}
      />

      <CostBreakdownV2WidgetSetupGroupingSelect disabled={viewGettingLoading} />
      <CostBreakdownV2WidgetSetupDateRangeSelect />

      <WidgetFrequencySelect
        frequency={frequency}
        onChange={frequencyChangeHandler}
        disabled={
          ["pie", "numeral", "table"].includes(viewType) || viewGettingLoading
        }
      />
      <CostBreakdownV2WidgetViewTypeSelect />
      <WidgetForecastSelect
        forecast={forecastOption}
        onChange={forecastChangeHandler}
        options={forecastedCostsOptions}
      />
    </Fragment>
  );
};
