import moment from "moment/moment";
import { ScheduleActionType2 } from "../../../../../../services/cloudchipr.api";

export const getOffHoursNextRun = (
  nextStopRunAt: string,
  nextStartRunAt: string,
  status: ScheduleActionType2,
) => {
  if (status === "inactive" || (!nextStopRunAt && !nextStartRunAt)) {
    return { type: undefined, nextRun: null };
  }

  if (nextStartRunAt && !nextStopRunAt) {
    return { type: "start", nextRun: nextStartRunAt };
  }

  if (nextStopRunAt && !nextStartRunAt) {
    return { type: "stop", nextRun: nextStopRunAt };
  }

  const isStopSmaller =
    moment(nextStopRunAt).valueOf() < moment(nextStartRunAt).valueOf();

  return {
    type: isStopSmaller ? "stop" : "start",
    nextRun: isStopSmaller ? nextStopRunAt : nextStartRunAt,
  };
};
