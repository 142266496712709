import { FC } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { CategoryFiltersCountCell } from "./CategoryFiltersCountCell";
import { CategoryActions } from "./actions/CategoryActions";
import { AddCategoryButtonRow } from "./AddCategoryButtonRow";
import { CategoryWithCosts } from "../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import { money } from "../../../../../../utils/numeral/money";

interface CategoriesTableProps {
  dimensionId: string;
  dimensionName: string;
  categories: CategoryWithCosts[];
}

export const CategoriesTable: FC<CategoriesTableProps> = ({
  dimensionName,
  dimensionId,
  categories,
}) => {
  const filterType = categories.at(0)?.type;

  return (
    <Table sx={styles.table}>
      <TableBody>
        {categories.map((category) => (
          <TableRow sx={styles.tableRow} key={category.id}>
            <TableCell width={40} />

            <TableCell width={250} sx={{ px: 1 }}>
              <TypographyWithTooltip
                title={category.name}
                variant="subtitle1"
                fontWeight="medium"
                color="text.secondary"
              />
            </TableCell>

            <TableCell width={150} sx={{ pr: 5 }}>
              <Typography align="right" variant="subtitle1">
                {money(category.total_cost)}
              </Typography>
            </TableCell>

            <TableCell width={200} sx={{ pr: 5 }}>
              <Typography align="right" variant="subtitle1" color="error.dark">
                {money(category.filtered_resource_costs)}
              </Typography>
            </TableCell>

            <TableCell width={250} sx={{ px: 1 }}>
              <CategoryFiltersCountCell
                category={category}
                categories={categories}
                dimensionId={dimensionId}
              />
            </TableCell>

            <TableCell width={40} sx={{ px: 1 }}>
              <CategoryActions
                category={category}
                categories={categories}
                dimensionId={dimensionId}
              />
            </TableCell>
          </TableRow>
        ))}

        <AddCategoryButtonRow
          dimensionId={dimensionId}
          filterType={filterType}
          categories={categories}
          dimensionName={dimensionName}
        />
      </TableBody>
    </Table>
  );
};

const styles = {
  table: {
    tableLayout: "fixed",
  },

  tableRow: {
    bgcolor: "grey.50",
    "& td": { borderColor: "grey.200" },
  },
};
