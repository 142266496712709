import { FC, useCallback } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../services/cloudchipr.api";
import { setCoverageAndUtilizationDrawerCoverageGrouping } from "../../../../../../../../store/commitments/commitmentsSlice";
import { utilizationAndCoverageDrawerCoverageGroupingSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerCoverageGroupingSelector";
import { getUtilizationAndCoverageDetailsThunk } from "../../../../../../../../store/commitments/thunks/getUtilizationAndCoverageDetailsThunk";
import { utilizationAndCoverageDrawerTargetTypeSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/details-drawer/options/utilizationAndCoverageDrawerTargetTypeSelector";
import { drawerDataGridPossibleGroupings } from "../../../../utils/constants/drawerDataGridPossibleGroupings";

export const CommitmentsDrawerDataGridGrouping: FC = () => {
  const dispatch = useAppDispatch();
  const type = useAppSelector(utilizationAndCoverageDrawerTargetTypeSelector);
  const grouping = useAppSelector(
    utilizationAndCoverageDrawerCoverageGroupingSelector,
  );

  const tabChangeHandler = useCallback(
    (_: unknown, grouping: SavingsPlanCoverageGrouping) => {
      if (!grouping) {
        return;
      }

      dispatch(setCoverageAndUtilizationDrawerCoverageGrouping(grouping));
      dispatch(getUtilizationAndCoverageDetailsThunk());
    },
    [dispatch],
  );

  if (!type) {
    return null;
  }

  return (
    <ToggleButtonGroup
      exclusive
      size="small"
      value={grouping}
      color="primary"
      onChange={tabChangeHandler}
    >
      {drawerDataGridPossibleGroupings[type].map(({ value, label }) => {
        return (
          <ToggleButton
            key={value}
            value={value}
            sx={{ textTransform: "none" }}
          >
            {label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};
