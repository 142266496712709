import { dashboardHierarchyFoldersSelector } from "./dashboardHierarchyFoldersSelector";
import { RootState } from "../../../store";
import { Dashboard } from "../../../../services/cloudchipr.api";

export const dashboardHierarchyItemsByFolderIdSelector = (
  state: RootState,
  folderId: string,
): Dashboard[] | undefined => {
  const folders = dashboardHierarchyFoldersSelector(state);

  return folders?.find((item) => item.id === folderId)?.items;
};
