import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { Divider, Popover, PopoverOrigin } from "@mui/material";
import {
  DropdownSelectWithOperatorsSelectedValue,
  TagFilterValueOptions,
  TagsFilter,
} from "./utils/types";
import { TagFilterDropdownSelectPopoverHeader } from "./components/TagFilterDropdownSelectPopoverHeader";
import { TagFilterDropdownSelectPopoverContent } from "./components/TagFilterDropdownSelectPopoverContent";
import { validateTagsFilters } from "./utils/helpers/validateTagsFilters";
import { FilterTrigger } from "../dropdown-select/components/FilterTrigger";
import { useMenuHook } from "../../../../utils/hooks/useMenu.hook";

export interface TagFilterDropdownSelectProps {
  disabled?: boolean;
  label: string;
  initialSelectedValues?: DropdownSelectWithOperatorsSelectedValue[];
  filters?: TagsFilter;
  valuesOptions?: TagFilterValueOptions;
  keysOptions?: {
    value: string;
    title?: string;
  }[];
  onCloseSubmit?(filter: TagsFilter | null): void;
}

export const TagFilterDropdownSelect: FC<TagFilterDropdownSelectProps> = ({
  disabled,
  label,
  filters,
  keysOptions,
  valuesOptions,
  onCloseSubmit,
}) => {
  const { anchor, open, openMenu, closeMenu } = useMenuHook();

  const [localFilters, setLocalFilters] = useState<TagsFilter | null>(null);

  const isSelected = !!localFilters?.value.length;

  const handlePopoverCloseAndSubmit = useCallback(() => {
    closeMenu();
    const validatedFilters = validateTagsFilters(localFilters?.value ?? []);
    let mutatedFilters = localFilters;
    if (validatedFilters?.length !== localFilters?.value?.length) {
      mutatedFilters = {
        ...localFilters,
        value: validatedFilters,
      };
      setLocalFilters(mutatedFilters);
    }

    onCloseSubmit?.(mutatedFilters);
  }, [closeMenu, onCloseSubmit, localFilters]);

  const triggerProps = {
    open,
    label,
    optionsLength: keysOptions?.length,
    selectedValues: localFilters?.value.map((item) => item.key) ?? [],
    onClick: openMenu,
    disabled: !!disabled,
    selected: isSelected,
  };

  useEffect(() => {
    if (filters) {
      setLocalFilters(filters);
    }
  }, [filters]);

  return (
    <Fragment>
      <FilterTrigger {...triggerProps} />

      <Popover
        open={!!anchor}
        onClose={handlePopoverCloseAndSubmit}
        PaperProps={paperProps}
        anchorEl={anchor}
        anchorOrigin={anchorOrigin}
      >
        <Fragment>
          <TagFilterDropdownSelectPopoverHeader
            title="Tags/Labels"
            onCloseIconClick={handlePopoverCloseAndSubmit}
          />
          <Divider />

          <TagFilterDropdownSelectPopoverContent
            filters={localFilters}
            setFilters={setLocalFilters}
            valuesOptions={valuesOptions}
            keysOptions={keysOptions}
          />
        </Fragment>
      </Popover>
    </Fragment>
  );
};

const anchorOrigin: PopoverOrigin = {
  vertical: "bottom",
  horizontal: "left",
};

const paperProps = {
  sx: { width: 580, maxHeight: 500 },
};
