import { FC, useCallback } from "react";
import { Box, Link, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  FilterSet2,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../store/hooks";
import { applyFilterSetLoadingSelector } from "../../../../../../store/filters/selectors/filter-set/applyFilterSetLoadingSelector";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import c8rLogo from "../../../../../../assets/images/logos/c8r-logo.svg";
import { money } from "../../../../../../utils/numeral/money";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { FilterTemplatesFiltersDialog } from "../templates-table/templates-filters/FilterTemplatesFiltersDialog";

interface FilterTemplateDropdownItemProps {
  ems: number;
  filterSet: FilterSet2;
  onApply(filterSetId: string): void;
  applied: boolean;
  disabled: boolean;
  loading?: boolean;
  provider: ProviderType | null;
}

export const FilterTemplateDropdownItem: FC<
  FilterTemplateDropdownItemProps
> = ({ ems, onApply, applied, disabled, loading, filterSet, provider }) => {
  const { name, source, id } = filterSet;
  const applyFilterLoading = useAppSelector((state) =>
    applyFilterSetLoadingSelector(state, id),
  );

  const { open, openDialog, closeDialog } = useDialog();

  const applyClickHandler = useCallback(() => {
    onApply(id);
  }, [onApply, id]);

  return (
    <Stack justifyContent="space-between" direction="row" alignItems="center">
      <Stack overflow="hidden">
        <Link component="button" variant="body1" onClick={openDialog}>
          <Stack direction="row" spacing={0.5}>
            {source === "cloudchipr" && <img src={c8rLogo} alt="C8R-logo" />}
            <TypographyWithTooltip title={name} />
          </Stack>
        </Link>

        <Stack direction="row" spacing={0.2}>
          <Typography variant="caption" color="text.secondary" fontSize={10}>
            Filtered resources:
          </Typography>
          <Typography
            color="error.main"
            variant="caption"
            fontWeight="500"
            fontSize={10}
          >
            {money(ems)}
          </Typography>
        </Stack>
      </Stack>

      <Box>
        <LoadingButton
          size="small"
          loading={applyFilterLoading || loading}
          variant="outlined"
          disabled={applyFilterLoading || loading || disabled || applied}
          onClick={applyClickHandler}
          sx={{ minWidth: 75 }}
        >
          {applied ? "Applied" : "Apply"}
        </LoadingButton>
      </Box>

      {open && (
        <FilterTemplatesFiltersDialog
          open
          provider={provider}
          onClose={closeDialog}
          filterSet={filterSet}
        />
      )}
    </Stack>
  );
};
