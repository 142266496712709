import { useCallback } from "react";
import { SortingState } from "@tanstack/table-core";
import { AutomationType } from "../types/types";
import { useLocalStorageHook } from "../../../../../../utils/hooks/useLocalStorage.hook";

export const useSchedulesColumnsSortingHook = (
  scheduleType: AutomationType,
) => {
  const { getItem, setItem } = useLocalStorageHook();
  const key = `c8r:${scheduleType}-sorting`;

  const config = getItem(key);
  const initialSorting = config?.sorting || [{ id: "name", desc: false }];

  const setSorting = useCallback(
    (sorting: SortingState) => {
      setItem(key, { ...config, sorting });
    },
    [key, setItem, config],
  );

  return {
    sorting: initialSorting as { id: string; desc: boolean }[],
    setSorting,
  };
};
