import { isDashboardFolder } from "./isDashboardFolder";
import {
  Dashboard,
  DashboardFolder,
} from "../../../../../services/cloudchipr.api";
import {
  TreeItem,
  TreeItems,
} from "../../../../navigation/components/sortable-tree/utils/types";

export const flattenDashboardHierarchyData = (
  hierarchy: (Dashboard | DashboardFolder)[],
): TreeItems | null => {
  if (!hierarchy) {
    return null;
  }
  return hierarchy.reduce((result, item) => {
    if (isDashboardFolder(item)) {
      const items = (item.items ?? [])?.reduce((result, item) => {
        result.push({
          id: item.id,
          name: item.name,
          type: "item",
          children: [],
        });

        return result;
      }, [] as TreeItem[]);

      result.push({
        id: item.id,
        children: items,
        name: item.name,
        type: "folder",
        collapsed: true,
      });
    } else {
      result.push({
        id: item.id,
        name: item.name,
        children: [],
        type: "item",
      });
    }
    return result;
  }, [] as TreeItems);
};
