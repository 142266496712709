import { CategoryFilter } from "../../../../../services/cloudchipr.api";

export const getAccountIdsFromCategoryFilters = (filters: CategoryFilter) => {
  const ids: string[] = [];

  filters.aws_filters?.accounts?.items?.forEach((item) => {
    if (Array.isArray(item.value)) {
      ids.push(...item.value);
    } else {
      ids.push(item.value);
    }
  });
  filters.gcp_filters?.accounts?.items?.forEach((item) => {
    if (Array.isArray(item.value)) {
      ids.push(...item.value);
    } else {
      ids.push(item.value);
    }
  });
  filters.azure_filters?.accounts?.items?.forEach((item) => {
    if (Array.isArray(item.value)) {
      ids.push(...item.value);
    } else {
      ids.push(item.value);
    }
  });

  return ids;
};
