import { FC } from "react";
import { Collapse, Stack, Typography } from "@mui/material";
import { money } from "../../../../../../utils/numeral/money";
import {
  savingsPlanLockBackPeriodLabels,
  savingsPlansTypeLabels,
  savingsPlanTermLabels,
} from "../../../utilization-and-coverage/utils/constants/labels";
import { useAppSelector } from "../../../../../../store/hooks";
import { commitmentsRecommendationsDataSelector } from "../../../../../../store/commitments/selectors/recommendations/data/commitmentsRecommendationsDataSelector";
import { commitmentsRecommendationsLockBackPeriodSelector } from "../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsLockBackPeriodSelector";
import { commitmentsRecommendationsSavingsPlanTermSelector } from "../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTermSelector";
import { commitmentsRecommendationsSavingsPlanTypeSelector } from "../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTypeSelector";

interface CommitmentsRecommendationsDetailsCollapseProps {
  open: boolean;
}

export const CommitmentsRecommendationsDetailsCollapse: FC<
  CommitmentsRecommendationsDetailsCollapseProps
> = ({ open }) => {
  const infoData = useAppSelector(commitmentsRecommendationsDataSelector);
  const lockBackPeriod = useAppSelector(
    commitmentsRecommendationsLockBackPeriodSelector,
  );
  const savingsPlanTerm = useAppSelector(
    commitmentsRecommendationsSavingsPlanTermSelector,
  );
  const savingsPlanType = useAppSelector(
    commitmentsRecommendationsSavingsPlanTypeSelector,
  );

  return (
    <Collapse in={open}>
      <Stack py={1} width={625} spacing={1}>
        <Typography variant="caption" fontWeight="bold">
          You could save an estimated{" "}
          {money(infoData?.estimated_monthly_savings)} monthly by purchasing the
          recommended{" "}
          {savingsPlanType && savingsPlansTypeLabels[savingsPlanType]} Savings
          Plan.
        </Typography>

        <Typography variant="caption" color="text.secondary">
          Based on your past{" "}
          <Typography variant="inherit" fontWeight="medium" component="span">
            {lockBackPeriod && savingsPlanLockBackPeriodLabels[lockBackPeriod]}
          </Typography>{" "}
          of usage, we recommend purchasing{" "}
          <Typography variant="inherit" fontWeight="medium" component="span">
            {infoData?.recommendations?.length} Savings Plans
          </Typography>{" "}
          with a total commitment of{" "}
          <Typography variant="inherit" fontWeight="medium" component="span">
            {infoData?.total_hourly_commitment}
          </Typography>
          /hour for a{" "}
          <Typography variant="inherit" fontWeight="medium" component="span">
            {savingsPlanTerm && savingsPlanTermLabels[savingsPlanTerm]}
          </Typography>{" "}
          term. With this commitment, we project that you could save an average
          of{" "}
          <Typography variant="inherit" fontWeight="medium" component="span">
            {infoData?.estimated_hourly_savings}
          </Typography>
          /hour - representing a{" "}
          <Typography variant="inherit" fontWeight="medium" component="span">
            {infoData?.estimated_savings_percentage}% savings
          </Typography>{" "}
          compared to On-Demand. To account for variable usage patterns, this
          recommendation maximizes your savings by leaving an average{" "}
          <Typography variant="inherit" fontWeight="medium" component="span">
            {infoData?.estimated_hourly_on_demand_spend_over_look_back_period}
          </Typography>
          /hour of On-Demand spend. Recommendations require up to 24 hours to
          update after a purchase.
        </Typography>
      </Stack>
    </Collapse>
  );
};
