import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";

const UtilizationAndCoverageSvgIcon = ({ color }: SvgIconProps) => {
  const { palette } = useTheme();

  const fillColor =
    color === "primary" ? palette.primary.main : palette.action.active;

  return (
    <SvgIcon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.58333 13.7502H5.49167C5.725 12.3585 6.525 11.1668 7.65833 10.4168H4.58333V13.7502Z"
          fill={fillColor}
          fillOpacity="0.87"
        />
        <path
          d="M2.91667 15.4168V3.75016H14.5833V9.5835H15.4167C15.7 9.5835 15.975 9.61683 16.25 9.6585V3.75016C16.25 2.8335 15.5 2.0835 14.5833 2.0835H2.91667C2 2.0835 1.25 2.8335 1.25 3.75016V15.4168C1.25 16.3335 2 17.0835 2.91667 17.0835H6.09167C5.8 16.5752 5.59167 16.0168 5.49167 15.4168H2.91667Z"
          fill={fillColor}
          fillOpacity="0.87"
        />
        <path
          d="M7.91667 5.41683H4.58333V8.75016H7.91667V5.41683Z"
          fill={fillColor}
          fillOpacity="0.87"
        />
        <path
          d="M12.9167 5.41683H9.58333V8.75016H12.9167V5.41683Z"
          fill={fillColor}
          fillOpacity="0.87"
        />
        <path
          d="M12.0833 16.2502H10.4167C9.5 16.2502 8.75 15.5002 8.75 14.5835C8.75 13.6668 9.5 12.9168 10.4167 12.9168H12.0833V11.2502H10.4167C8.575 11.2502 7.08333 12.7418 7.08333 14.5835C7.08333 16.4252 8.575 17.9168 10.4167 17.9168H12.0833V16.2502Z"
          fill={fillColor}
          fillOpacity="0.87"
        />
        <path
          d="M15.4167 11.2502H13.75V12.9168H15.4167C16.3333 12.9168 17.0833 13.6668 17.0833 14.5835C17.0833 15.5002 16.3333 16.2502 15.4167 16.2502H13.75V17.9168H15.4167C17.2583 17.9168 18.75 16.4252 18.75 14.5835C18.75 12.7418 17.2583 11.2502 15.4167 11.2502Z"
          fill={fillColor}
          fillOpacity="0.87"
        />
        <path
          d="M15.4167 15.4168V13.7502H10.4167V15.4168H15.4167Z"
          fill={fillColor}
          fillOpacity="0.87"
        />
      </svg>
    </SvgIcon>
  );
};

UtilizationAndCoverageSvgIcon.muiName = "UtilizationAndCoverageSvgIcon";

export default UtilizationAndCoverageSvgIcon;
