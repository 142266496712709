import { FC, ReactElement, useCallback } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import BarChartIcon from "@mui/icons-material/BarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { resourceExplorerChartTypeSelector } from "../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerChartTypeSelector";
import { ResourceExplorerChartType } from "../../../../../../../../store/resource-explorer/utils/types/resourceExplorer";
import { useResourceExplorerPreferences } from "../../../../../utils/hooks/useResourceExplorerPreferences.hook";
import { setResourceExplorerChartType } from "../../../../../../../../store/resource-explorer/resourceExplorerSlice";

interface ResourceExplorerChartTypeSelectProps {
  viewId: string;
}

export const ResourceExplorerChartTypeSelect: FC<
  ResourceExplorerChartTypeSelectProps
> = ({ viewId }) => {
  const dispatch = useAppDispatch();
  const chartType = useAppSelector(resourceExplorerChartTypeSelector);
  const { changeChartPreferences } = useResourceExplorerPreferences(viewId);

  const chartTypeChangeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const chartType = event.target.value as ResourceExplorerChartType;

      if (!chartType) {
        return;
      }

      changeChartPreferences("type", chartType);
      dispatch(setResourceExplorerChartType(chartType));
    },
    [dispatch, changeChartPreferences],
  );

  const renderValue = useCallback((value: ResourceExplorerChartType) => {
    return <Box lineHeight={0}>{icons[value]}</Box>;
  }, []);

  return (
    <FormControl size="xsmall">
      <Select
        sx={selectSx}
        value={chartType}
        renderValue={renderValue}
        onChange={chartTypeChangeHandler}
      >
        <MenuItem value="stack">{icons.stack}</MenuItem>
        <MenuItem value="line">{icons.line}</MenuItem>
        <MenuItem value="bar">{icons.bar}</MenuItem>
      </Select>
    </FormControl>
  );
};

const icons: Record<ResourceExplorerChartType, ReactElement> = {
  line: <StackedLineChartIcon color="action" />,
  bar: <BarChartIcon color="action" />,
  stack: <StackedBarChartIcon color="action" />,
};

const selectSx: SelectProps["sx"] = {
  bgcolor: "white",
  "& .MuiSelect-select": { p: 0.5 },
};
