import { FC, Fragment, useCallback } from "react";
import { FormikHelpers } from "formik";
import { NotificationThreshold } from "./NotificationThreshold";
import { IntegrationCustomMessageProps } from "../../../classic/components/custom-message/IntegrationCustomMessage";
import { NotificationsSelection } from "../../../../common/notification-selection/NotificationsSelection";
import { ModalFilterItem } from "../../../../common/filters/filters-selection/utils/types/types";
import { IntegrationNotification } from "../../../../common/notification-selection/utils/types/types";
import { Emails, Schedule2 } from "../../../../../../services/cloudchipr.api";

interface SchedulesNotificationsProps {
  errors: any;
  threshold: boolean;
  selectedEmails: Emails | null;
  selectedMinThreshold?: Schedule2["min_threshold"];
  selectedIntegrations?: IntegrationNotification[] | null;
  setFieldValue: FormikHelpers<ModalFilterItem>["setFieldValue"];
  setFieldError: FormikHelpers<ModalFilterItem>["setFieldError"];
  IntegrationCustomMessage?: FC<IntegrationCustomMessageProps>;
}

export const SchedulesNotifications: FC<SchedulesNotificationsProps> = ({
  setFieldValue,
  errors,
  selectedEmails,
  selectedIntegrations,
  selectedMinThreshold,
  setFieldError,
  threshold,
  IntegrationCustomMessage,
}) => {
  const integrationsChangeHandler = useCallback(
    (integrations: IntegrationNotification[] | null) => {
      setFieldValue("notifications", integrations);
    },
    [setFieldValue],
  );

  const emailsChangeHandler = useCallback(
    (emails: Emails | null) => {
      if (!emails) {
        setFieldError("emails", undefined);
      }

      setFieldValue("emails", emails);
    },
    [setFieldValue, setFieldError],
  );

  const minThresholdChangeHandler = useCallback(
    (value: null | number) => {
      setFieldValue("min_threshold", value);
    },
    [setFieldValue],
  );

  return (
    <Fragment>
      <NotificationsSelection
        emailsErrors={errors.emails}
        integrationErrors={errors.notifications}
        selectedEmails={selectedEmails}
        selectedIntegrations={selectedIntegrations}
        emailsChangeHandler={emailsChangeHandler}
        setSelectedIntegrations={integrationsChangeHandler}
        disabledIntegrationTypes={["jira"]}
        IntegrationCustomMessage={IntegrationCustomMessage}
      />

      {threshold && (
        <NotificationThreshold
          minThreshold={selectedMinThreshold}
          onThresholdChange={minThresholdChangeHandler}
          error={errors?.min_threshold}
        />
      )}
    </Fragment>
  );
};
