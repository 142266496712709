import { FC, useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { ProviderType } from "../../../../../../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../../../../../../store/hooks";
import { changeCategoryFilterGroupItemsCombinationOperatorByProvider } from "../../../../../../../../../../../store/dimensions/dimensionsSlice";
import { CategoryFilterType } from "../../../../../../../../../../../store/dimensions/utils/types/common";

interface GroupItemsCombinationOperatorProps {
  first: boolean;
  operator?: string;
  categoryId: string;
  provider: ProviderType;
  type: CategoryFilterType;
}

export const GroupItemsCombinationOperator: FC<
  GroupItemsCombinationOperatorProps
> = ({ first, operator, categoryId, type, provider }) => {
  const dispatch = useAppDispatch();

  const operatorClickHandler = useCallback(() => {
    dispatch(
      changeCategoryFilterGroupItemsCombinationOperatorByProvider({
        type,
        provider,
        id: categoryId,
      }),
    );
  }, [dispatch, categoryId, type, provider]);

  return (
    <Box minWidth={72}>
      {first ? (
        <Typography variant="body2">Where</Typography>
      ) : (
        <Button
          size="small"
          variant="outlined"
          endIcon={<UnfoldMoreIcon />}
          onClick={operatorClickHandler}
        >
          {operator}
        </Button>
      )}
    </Box>
  );
};
