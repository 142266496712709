import { dashboardHierarchyDataSelector } from "./dashboardHierarchyDataSelector";
import { RootState } from "../../../store";
import { isDashboardFolder } from "../../../../components/pages/dashboard/utils/helpers/isDashboardFolder";
import { Dashboard } from "../../../../services/cloudchipr.api";

export const dashboardHierarchyItemsSelector = (
  state: RootState,
): Dashboard[] | undefined => {
  const hierarchy = dashboardHierarchyDataSelector(state);

  if (!hierarchy) {
    return;
  }
  return hierarchy.reduce((result, item) => {
    if (!isDashboardFolder(item)) {
      result.push(item);
    } else {
      item?.items?.forEach((itemsInsideFolder) => {
        result.push(itemsInsideFolder);
      });
    }
    return result;
  }, [] as Dashboard[]);
};
