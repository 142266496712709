import { FC, Fragment, memo } from "react";
import { Box, IconButton, Link, Popover, Stack, Tooltip } from "@mui/material";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import { useFlag } from "@unleash/proxy-client-react";
import { PlayCircleOutlined } from "@mui/icons-material";
import { ResourceDetails } from "./components/ResourceDetails";
import {
  RowSelectionButton,
  RowSelectionButtonProps,
} from "./RowSelectionButton";
import { getResourceExplorerTagsAvailabilityInTable } from "./components/utils/helpers/getResourceExplorerTagsAvailabilityInTable";
import { ProviderLogo } from "../../../../../../../../../../common/provider-logo/ProviderLogo";
import { useAppSelector } from "../../../../../../../../../../../store/hooks";
import { isResourceExplorerViewMultiCloudSelector } from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/checkers/isResourceExplorerViewMultiCloudSelector";
import { TypographyWithTooltip } from "../../../../../../../../../../common/TypographyWithTooltip";
import { useMenuHook } from "../../../../../../../../../../../utils/hooks/useMenu.hook";

import { tagLabels } from "../../../../../../utils/constants/tagLabels";
import { capitalizeString } from "../../../../../../../../../../../utils/helpers/capitalizeString";
import { LiveIndicator } from "../../../../../resource-explorer-metadata/components/live-usage/LiveIndicator";
import { useMultiTypeChartContext } from "../../../../../../../../../../../storybook/charts/multi-type-chart/MultiTypeChartProvider";
import {
  resourceExplorerChartDataOthersKey,
  resourceExplorerChartKeyGenerator,
} from "../../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/chart/resourceExplorerChartDataSelector";
import { TypographyWithCopy } from "../../../../../../../../../../common/TypographyWithCopy";
import { useHover } from "../../../../../../../../../../../utils/hooks/useHover.hook";
import { ResourceExplorerGrouping } from "../../../../../../../../../../../services/cloudchipr.api";

interface LabelColumnCellProps extends RowSelectionButtonProps {
  label?: string | null;
  accountId?: string | null;
  liveAccountId?: string | null;
  isTracked: boolean;
  accountExistInLiveUsage: boolean;
  filterValue?: string | null;
  grouping?: ResourceExplorerGrouping;
}

export const LabelColumnCell: FC<LabelColumnCellProps> = memo(
  ({
    aggregatedByValue,
    isTracked,
    label,
    field,
    clickable,
    accountId,
    provider,
    accountExistInLiveUsage,
    liveAccountId,
    filterValue,
    grouping,
  }) => {
    const enableLiveUsageOnResourceExplorerDataRow = useFlag(
      "EnableLiveUsageOnResourceExplorerDataRow",
    );
    const isMultiCloud = useAppSelector(
      isResourceExplorerViewMultiCloudSelector,
    );
    const { anchor, open, openMenu, closeMenu } = useMenuHook();
    const { ref, hovered } = useHover();
    const { colors } = useMultiTypeChartContext();

    const title = label || field;
    const resourceId = field ?? undefined;
    const tooltip = !label ? "" : field;
    const key = resourceExplorerChartKeyGenerator(resourceId, label);
    const color = colors[key] ?? colors[resourceExplorerChartDataOthersKey];
    const tagsAvailable =
      accountId &&
      getResourceExplorerTagsAvailabilityInTable(provider, grouping);

    return (
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        width="100%"
        minHeight={42}
        ref={ref}
      >
        {color && (
          <Box minWidth={4} borderRadius={0.5} height={35} bgcolor={color} />
        )}

        <RowSelectionButton
          field={field}
          provider={provider}
          clickable={clickable}
          aggregatedByValue={aggregatedByValue}
          filterValue={filterValue}
        />

        {enableLiveUsageOnResourceExplorerDataRow && isTracked && (
          <Tooltip
            arrow
            title="This resource is filtered in Live Usage and Management. It is  underutilized or requires attention."
            placement="top"
          >
            <span>
              <LiveIndicator />
            </span>
          </Tooltip>
        )}

        {provider && isMultiCloud && <ProviderLogo provider={provider} />}

        <Stack
          direction="row"
          alignItems="center"
          gap={0.3}
          overflow="hidden"
          ref={ref}
        >
          <Stack width="100%" justifyContent="center" overflow="hidden">
            {title && (
              <TypographyWithCopy
                overrideHovered={hovered}
                value={title}
                hideCopyButtonArea
              />
            )}
            {tooltip && (
              <TypographyWithTooltip
                variant="body2"
                title={tooltip ?? ""}
                color="text.disabled"
                fontSize={10}
              />
            )}
          </Stack>

          {accountExistInLiveUsage && liveAccountId && hovered && (
            <Tooltip title="View in Live Usage and Mgmt." placement="top" arrow>
              <IconButton
                component={Link}
                href={`/${provider}/${liveAccountId}`}
                target="_blank"
                size="small"
              >
                <PlayCircleOutlined color="action" fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        {tagsAvailable && (
          <Fragment>
            <Tooltip
              arrow
              placement="top"
              title={capitalizeString(tagLabels.get(provider) ?? "") ?? "Tags"}
            >
              <IconButton size="small" onClick={openMenu}>
                <DiscountOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>

            {open && (
              <Popover
                open={open}
                anchorEl={anchor}
                onClose={closeMenu}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              >
                {resourceId && (
                  <ResourceDetails
                    needToFetch={open}
                    resourceId={resourceId}
                    accountId={accountId}
                    provider={provider}
                  />
                )}
              </Popover>
            )}
          </Fragment>
        )}
      </Stack>
    );
  },
);
