import { Typography } from "@mui/material";
import { getCoverageColumn } from "./common/getCoverageColumn";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../../utils/types";
import { commitmentTypesLabels } from "../../../../../../utils/constants/labels";
import { labelsFilterFn } from "../../../../../../utils/helpers/labelsFilterFn";

export const coverageGroupedByCommitmentColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      id: "savings_plan_id",
      header: "Commitment",
      accessorKey:
        "savings_plan.provider_id" as keyof SavingsPlanCoverageDataWithId,
      cell: ({ row }) => (
        <Typography variant="body2">
          {row.original.savings_plan?.provider_id}
        </Typography>
      ),
    },
    {
      id: "savings_plan_type",
      accessorKey: "savings_plan.type" as keyof SavingsPlanCoverageDataWithId,
      header: "Commitment Type",
      cell: ({ row }) => {
        const type = row.original.savings_plan?.type;

        return (
          <Typography variant="body2" textTransform="capitalize">
            {type && commitmentTypesLabels[type]}
          </Typography>
        );
      },
      filterFn: (row, _, filterValue) =>
        labelsFilterFn(
          row.original.savings_plan?.type ?? "",
          commitmentTypesLabels,
          filterValue,
        ),
    },
    getCoverageColumn("commitment"),
  ];
