import { TableCellTypes } from "../../../../../../../../../../../../storybook/data-grid/utils/types/types";

export const columnSizes: Map<TableCellTypes, number> = new Map([
  ["tag", 200],
  ["smartTag", 200],
  ["jiraTicketStatus", 210],
  ["statistic", 200],
  ["hasField", 200],
  ["identifier", 160],
  ["cpuStatistic", 150],
  ["withTooltipCell", 170],
  ["date", 170],
  ["money", 190],
  ["instances", 80],
]);

export const getColumnSize = (options: {
  type: TableCellTypes;
  headerTooltip?: string;
  enableSorting?: boolean;
}) => {
  let minSize = 120;
  const columnMinSize = columnSizes.get(options.type);

  if (options.enableSorting === undefined) {
    minSize += 36;
  }
  if (options.headerTooltip) {
    minSize += 36;
  }
  return columnMinSize ?? minSize;
};
