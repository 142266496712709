import { FC, useCallback, useMemo } from "react";
import { AutocompleteSelect } from "../../../../../../../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { TypographyWithTooltip } from "../../../../../../../../../../../../common/TypographyWithTooltip";
import { useAppSelector } from "../../../../../../../../../../../../../store/hooks";
import { ProviderType } from "../../../../../../../../../../../../../services/cloudchipr.api";
import { getCostAllocationTagValuesLoadingSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/filters/cost-allocations-tags/getCostAllocationTagValuesLoadingSelector";
import { FilterValueAutocompleteRenderTagsComponent } from "../common/FilterValueAutocompleteRenderTagsComponent";
import { RenderTagsComponentProps } from "../../../../../../../../../../../../common/select/autocomplete-select/components/AutocompleteRenderTags";
import { RootState } from "../../../../../../../../../../../../../store/store";
import { costAllocationTagValuesDataSelector } from "../../../../../../../../../../../../../store/resource-explorer/selectors/filters/cost-allocations-tags/costAllocationTagValuesDataSelector";

interface TagFilterValueAutocompleteProps {
  value: string[];
  tagKey: string;
  provider: ProviderType;
  onChange(value: string[]): void;
}

export const TagFilterValueAutocomplete: FC<
  TagFilterValueAutocompleteProps
> = ({ value, onChange, provider, tagKey }) => {
  const tagValuesSelector = useCallback(
    (state: RootState) =>
      costAllocationTagValuesDataSelector(state, provider, tagKey),
    [provider, tagKey],
  );
  const tagValues = useAppSelector(tagValuesSelector);

  const loadingSelector = useCallback(
    (state: RootState) =>
      getCostAllocationTagValuesLoadingSelector(state, provider, tagKey),
    [provider, tagKey],
  );
  const loading = useAppSelector(loadingSelector);

  const renderOptions = useMemo(() => {
    return tagValues?.map((value) => ({
      value: value,
      label: <TypographyWithTooltip title={value} />,
    }));
  }, [tagValues]);

  const RenderTagsComponent = useCallback((props: RenderTagsComponentProps) => {
    return (
      <FilterValueAutocompleteRenderTagsComponent label="Tags" {...props} />
    );
  }, []);

  return (
    <AutocompleteSelect
      size="xsmall"
      label="Value"
      placeholder="Value"
      disabled={!tagKey}
      onChange={onChange}
      options={renderOptions ?? []}
      values={value}
      optionsLoading={loading}
      RenderTagsComponent={RenderTagsComponent}
    />
  );
};
