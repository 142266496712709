import { FC, Fragment, useCallback } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ResourceExplorerBreadcrumbsSelect } from "./ResourceExplorerBreadcrumbsSelect";
import { ResourceExplorerPath } from "../../../../../../../store/resource-explorer/utils/types/resourceExplorer";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../store/hooks";
import { TypographyWithTooltip } from "../../../../../../common/TypographyWithTooltip";
import { resourceExplorerPathSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathSelector";
import { resourceExplorerPathLastItemGroupingOptionsSelector } from "../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathLastItemGroupingOptionsSelector";
import {
  setFilteredByLiveUsages,
  setResourceExplorerSearch,
} from "../../../../../../../store/resource-explorer/resourceExplorerSlice";

interface ResourceExplorerBreadcrumbItemProps {
  index: number;
  pathItem: ResourceExplorerPath;
  onBreadcrumbClick(index: number): void;
  pathLength?: number;
}

export const ResourceExplorerBreadcrumbItem: FC<
  ResourceExplorerBreadcrumbItemProps
> = ({ pathItem, index, onBreadcrumbClick, pathLength }) => {
  const dispatch = useAppDispatch();

  const path = useAppSelector(resourceExplorerPathSelector);
  const groupingOptions = useAppSelector(
    resourceExplorerPathLastItemGroupingOptionsSelector,
  );

  const breadcrumbClickHandler = useCallback(() => {
    dispatch(setFilteredByLiveUsages(false));
    onBreadcrumbClick(index);
    dispatch(setResourceExplorerSearch(""));
  }, [dispatch, onBreadcrumbClick, index]);

  const isLast = index === path.length - 1;

  return (
    <Fragment>
      <TypographyWithTooltip
        title={pathItem.name}
        onClick={!isLast ? breadcrumbClickHandler : undefined}
        sx={{
          "&:hover": !isLast
            ? { textDecoration: "underline", cursor: "pointer" }
            : {},
          minWidth: 36,
        }}
        variant="body1"
        key={pathLength}
      />

      {isLast && (groupingOptions?.length ?? 0) > 1 && (
        <ResourceExplorerBreadcrumbsSelect />
      )}

      {!isLast && <ArrowForwardIosIcon sx={{ fontSize: 14 }} color="action" />}
    </Fragment>
  );
};
