import {
  AwsFilter,
  ResourceExplorerAccount,
  ResourceExplorerTag,
} from "../../../../../../../../../../../services/cloudchipr.api";

export const isResourceExplorerTag = (
  filter: ResourceExplorerAccount | ResourceExplorerTag,
): filter is ResourceExplorerTag => {
  return "tag_key" in filter;
};

export const isFilterWithTags = (
  filter: AwsFilter | null,
): filter is AwsFilter => {
  return !!(filter && "tags" in filter);
};
