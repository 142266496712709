import { Stack, Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../storybook/data-grid/utils/types/types";
import {
  SavingsPlanCoverageGrouping,
  SavingsPlanResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { money } from "../../../../../../../../utils/numeral/money";
import { CommitmentsProgressBar } from "../../../common/CommitmentsProgressBar";
import { SavingsPlanCoverageDataWithId } from "../../../../utils/types";
import { CommitmentsTableName } from "../components/cells/CommitmentsTableName";
import { savingsPlansResourceTypeNames } from "../../../../utils/constants/labels";
import { HeaderCellWidthDateTooltip } from "../components/cells/HeaderCellWidthDateTooltip";

const groupedByAccountColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      accessorKey: "account.name" as keyof SavingsPlanCoverageDataWithId,
      header: "Account",
      cell: ({ row }) => {
        return (
          <CommitmentsTableName
            type="account"
            id={row.original.id}
            name={
              <Typography variant="body2" align="left">
                {row.original.account?.name}
                <br />
                {row.original.account?.provider_account_id}
              </Typography>
            }
          />
        );
      },
    },
  ];

const groupedByResourceColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      id: "resource_type",
      accessorKey:
        "resource_type.resource_type" as keyof SavingsPlanCoverageDataWithId,
      header: "Resource Type",
      cell: ({ row }) => {
        const rt = row.original.resource_type
          ?.resource_type as SavingsPlanResourceType;

        return (
          <CommitmentsTableName
            type="resource_type"
            id={row.original.id}
            name={
              <Typography variant="body2" align="left">
                {savingsPlansResourceTypeNames[rt]}
              </Typography>
            }
          />
        );
      },
    },
    {
      id: "instance_family",
      accessorKey:
        "resource_type.instance_family" as keyof SavingsPlanCoverageDataWithId,
      header: "Instance Family",
      cell: ({ row }) => {
        return row.original.resource_type?.instance_family ?? "-";
      },
    },
    {
      id: "region",
      accessorKey:
        "resource_type.region" as keyof SavingsPlanCoverageDataWithId,
      header: "Region",
      cell: ({ row }) => {
        return row.original.resource_type?.region ?? null;
      },
    },
  ];

const columnsWithoutGrouping: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      accessorKey: "covered_usage",
      header: () => (
        <Stack alignItems="end" flex={1}>
          <HeaderCellWidthDateTooltip title="Spend Covered by Commitments" />
        </Stack>
      ),

      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.covered_usage)}
        </Typography>
      ),
      meta: { headerTitle: "Spend Covered by Commitment" },
    },
    {
      accessorKey: "uncovered_usage",
      header: () => (
        <Stack alignItems="end" flex={1}>
          <HeaderCellWidthDateTooltip title="On-Demand Spend" />
        </Stack>
      ),
      meta: { headerTitle: "On-Demand Spend" },
      cell: ({ row }) => (
        <Typography align="right" pr={4} variant="body2">
          {money(row.original.uncovered_usage)}
        </Typography>
      ),
    },
    {
      accessorKey: "coverage",
      header: () => <HeaderCellWidthDateTooltip title="Coverage" />,
      cell: ({ row }) => (
        <CommitmentsProgressBar value={row.original.coverage} />
      ),
    },
  ];

const accountGroupedColumns = [
  ...groupedByAccountColumns,
  ...columnsWithoutGrouping,
];
const resourceGroupedColumns = [
  ...groupedByResourceColumns,
  ...columnsWithoutGrouping,
];

export const coverageTableColumns = (
  grouping: SavingsPlanCoverageGrouping,
): ColumnSetupType<SavingsPlanCoverageDataWithId>[] => {
  if (grouping === "account") {
    return accountGroupedColumns;
  }

  if (grouping === "resource_type") {
    return resourceGroupedColumns;
  }

  return [];
};
