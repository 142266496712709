import { FC, useCallback, useMemo } from "react";
import { Stack, Typography } from "@mui/material";
import { ProviderLogo } from "../../../../../../common/provider-logo/ProviderLogo";
import { useAppSelector } from "../../../../../../../store/hooks";
import { AccountAccessTypeChip } from "../../../../../../common/chips/account-access-type-chip/AccountAccessTypeChip";
import { accountsForCategoriesByIdsSelector } from "../../../../../../../store/dimensions/selectors/accounts/accountsForCategoriesByIdsSelector";
import { accountEmsByAccountIdSelector } from "../../../../../../../store/accounts/selectors/costs/accountEmsByAccountIdSelector";
import { accountTotalSpendByAccountIdSelector } from "../../../../../../../store/accounts/selectors/costs/accountTotalSpendByAccountIdSelector";
import { money } from "../../../../../../../utils/numeral/money";
import { RootState } from "../../../../../../../store/store";
import { OpenInResourceExplorerButton } from "../../../data-grid/common/OpenInResourceExplorerButton";
import { dimensionIdSelector } from "../../../../../../../store/dimensions/selectors/slice-data/dimensionIdSelector";

interface SelectedAccountsProps {
  categoryId: string;
  selectedAccounts: string[];
}

export const SelectedAccounts: FC<SelectedAccountsProps> = ({
  selectedAccounts,
  categoryId,
}) => {
  const allAccounts = useAppSelector(accountsForCategoriesByIdsSelector);
  const dimensionId = useAppSelector(dimensionIdSelector);

  const emsAndTotalStatesSelector = useCallback(
    (state: RootState) => (id: string) => {
      return {
        ems: accountEmsByAccountIdSelector(state, id),
        total: accountTotalSpendByAccountIdSelector(state, id),
      };
    },
    [],
  );

  const getEmsAndTotal = useAppSelector(emsAndTotalStatesSelector);

  const accounts = useMemo(() => {
    if (!allAccounts) {
      return [];
    }

    return selectedAccounts.map((id) => allAccounts[id]);
  }, [selectedAccounts, allAccounts]);

  if (!selectedAccounts?.length) {
    return (
      <Typography variant="body1" color="text.disabled" align="center" p={1}>
        No selected accounts
      </Typography>
    );
  }

  return (
    <Stack px={1} spacing={1} maxHeight={460} overflow="auto" mt={1}>
      {accounts.map((account) => {
        if (!account) {
          return null;
        }

        const { ems, total } = getEmsAndTotal(account.id);

        return (
          <Stack
            p={1}
            direction="row"
            borderRadius={2}
            key={account.id}
            bgcolor="grey.100"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack spacing={2} direction="row">
              {account.provider && (
                <ProviderLogo provider={account.provider} width={24} />
              )}

              <Stack>
                <Typography variant="body1">
                  {account.provider_account_name ?? "-"}
                </Typography>
                <Typography variant="tiny" color="text.disabled">
                  {account.provider_account_id}
                </Typography>

                <Stack direction="row">
                  <Typography color="error" variant="tiny">
                    {money(ems)}{" "}
                  </Typography>
                  <Typography color="text.secondary" variant="tiny">
                    {" / "} {money(total)}
                  </Typography>
                </Stack>
              </Stack>

              {account.provider_access_type && (
                <AccountAccessTypeChip
                  accessType={account.provider_access_type}
                />
              )}
            </Stack>

            {dimensionId && (
              <OpenInResourceExplorerButton
                dimensionId={dimensionId}
                categoryId={categoryId}
              />
            )}
          </Stack>
        );
      })}
    </Stack>
  );
};
