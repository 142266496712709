import { ResourceExplorerFilterOperator } from "../../../../../../../../../../../services/cloudchipr.api";
import { tagFilterOperators } from "../../../../../../../../../../common/select/tag-filter-dropdown-select/utils/constants";

const accountFilterOperators: {
  label: string;
  value: ResourceExplorerFilterOperator;
}[] = [
  ...tagFilterOperators,
  { label: "Starts with", value: "starts_with" },
  { label: "Ends with", value: "ends_with" },
];

export const categoryFilterOperators = {
  account: accountFilterOperators,
  tag: tagFilterOperators,
};

export const categoryValueTypeByOperator = {
  in: "array",
  not_in: "array",
  contains: "string",
  does_not_contain: "string",
  exists: "string",
  does_not_exist: "string",
  starts_with: "string",
  ends_with: "string",
};
