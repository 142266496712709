import { dashboardHierarchyDataSelector } from "./dashboardHierarchyDataSelector";
import { RootState } from "../../../store";
import { currentDashboardIdSelector } from "../dashboard/currentDashboardIdSelector";
import { isDashboardFolder } from "../../../../components/pages/dashboard/utils/helpers/isDashboardFolder";

export const currentDashboardFolderSelector = (state: RootState) => {
  const dashboardId = currentDashboardIdSelector(state);
  const hierarchy = dashboardHierarchyDataSelector(state);

  return hierarchy?.find((item) => {
    if (isDashboardFolder(item)) {
      return item.items?.some((item) => item.id === dashboardId);
    }
  });
};
