import { FC } from "react";
import { Divider, Stack } from "@mui/material";
import { UtilizationAndCoverageMultiTypeChartMetadataCard } from "./UtilizationAndCoverageMultiTypeChartMetadataCard";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { savingsPlansGranularDataTotalCommitmentSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalCommitmentSelector";
import { savingsPlansGranularDataTotalCoverageSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalCoverageSelector";
import { savingsPlansGranularDataTotalUtilizationSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataTotalUtilizationSelector";
import { savingsPlansGranularDataUncoveredUsageSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataUncoveredUsageSelector";

import { commitmentsForGranularLoadingSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/commitmentsForGranularLoadingSelector";
import { savingsPlanDataIsNotAvailableSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/data/savingsPlanDataIsNotAvailableSelector";
import { money } from "../../../../../../../../utils/numeral/money";

export const UtilizationAndCoverageMultiTypeChartMetadata: FC = () => {
  const totalCommitment = useAppSelector(
    savingsPlansGranularDataTotalCommitmentSelector,
  );
  const totalUtilization = useAppSelector(
    savingsPlansGranularDataTotalUtilizationSelector,
  );

  const totalCoverage = useAppSelector(
    savingsPlansGranularDataTotalCoverageSelector,
  );
  const uncoveredUsage = useAppSelector(
    savingsPlansGranularDataUncoveredUsageSelector,
  );

  const loading = useAppSelector(commitmentsForGranularLoadingSelector);
  const notAvailable = useAppSelector(savingsPlanDataIsNotAvailableSelector);
  const notAvailableMessage = notAvailable ? "N/A" : "-";
  const utilizationAmount =
    totalUtilization?.amount === null
      ? notAvailableMessage
      : money(totalUtilization?.amount);

  return (
    <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
      <Stack
        spacing={2}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <UtilizationAndCoverageMultiTypeChartMetadataCard
          title="Utilization"
          loading={loading}
          value={utilizationAmount}
          percent={totalUtilization?.percent}
        />
        <UtilizationAndCoverageMultiTypeChartMetadataCard
          loading={loading}
          title="Commitments"
          value={money(totalCommitment)}
        />
      </Stack>

      <Stack
        spacing={2}
        direction="row"
        divider={<Divider orientation="vertical" flexItem />}
      >
        <UtilizationAndCoverageMultiTypeChartMetadataCard
          loading={loading}
          title="Covered"
          value={money(totalCoverage?.amount)}
          percent={totalCoverage?.percent}
        />
        <UtilizationAndCoverageMultiTypeChartMetadataCard
          loading={loading}
          value={money(uncoveredUsage)}
          title="Not Covered(On-Demand)"
        />
      </Stack>
    </Stack>
  );
};
